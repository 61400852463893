import { Component } from '../component';
import { UserInfoItem } from './user.info.item';
import userInfoItemTemplate from './user.info.item.html';
/**
 * Wraps {@link TaskInfoItem} component into a directive.
 *
 * @author Tobias Straller [Tobias.Straller.bp@nttdata.com]
 *
 * @ngInject
 */
export function userInfoItemDirective(): ng.IDirective {
  'use strict';
  return Component.createDirective(UserInfoItem, {
    restrict: 'E',
    scope: {},
    templateUrl: userInfoItemTemplate,
  });
}
