import { IUserSetting, IUserSettingOption } from './user-setting.model.interface';
import { DeviceService } from '../../util/device.service';
import { WorkplaceContextService } from '../workplace/workplace.context.service';
import JSData from 'js-data';
import { ILanguage } from '../../util/language.model.interface';
import _ from 'lodash';

export class UserSettingService {
  static CHANNEL: string = 'UserSettingsServiceChannel';
  static USER_SETTINGS_REFRESHED: string = 'UserSettingsRefreshed';
  channel: IChannelDefinition<IUserSetting[]>;

  private _store: JSData.DSResourceDefinition<IUserSetting>;
  private _httpService: ng.IHttpService;
  private _deviceService: DeviceService;
  private _workplaceContextService: WorkplaceContextService;
  private _postal: IPostal;
  private _language: ILanguage;

  /**
   * @ngInject
   */
  constructor(
    userSettingsStore: JSData.DSResourceDefinition<IUserSetting>,
    $http: ng.IHttpService,
    deviceService: DeviceService,
    workplaceContextService: WorkplaceContextService,
    postal: IPostal,
    language: ILanguage,
    private $q: ng.IQService
  ) {
    this._store = userSettingsStore;
    this._httpService = $http;
    this._postal = postal;
    this.channel = this._postal.channel(UserSettingService.CHANNEL);
    this._deviceService = deviceService;
    this._language = language;
    this._workplaceContextService = workplaceContextService;
  }

  /**
   * Returns all settings.
   * @returns {IPromise<IUserSetting[]>}
   */
  getSettings(): ng.IPromise<IUserSetting[]> {
    const deferred = this.$q.defer<IUserSetting[]>();
    this._store
      .findAll({
        lang: this._language.lang,
        deviceType: this._deviceService.device,
        demoMode: this._workplaceContextService.demoMode,
      })
      .then((settings: IUserSetting[]) => {
        deferred.resolve(settings);
      });
    return deferred.promise;
  }

  /**
   * Returns the setting with the given description
   * @param description
   * @returns {IPromise<IUserSetting>}
   */
  getSettingByDescription(description: string): ng.IPromise<IUserSetting> {
    return this.getSettings().then((settings: IUserSetting[]) => {
      return settings.find((setting: IUserSetting) => setting.description === description);
    });
  }

  /**
   * Force refresh store with backend results.
   *
   * @return {angular.IPromise<IUserSetting[]>}
   */
  async refreshSettings(): Promise<IUserSetting[]> {
    const settings = await this._store.findAll(
      {
        lang: this._language.lang,
        deviceType: this._deviceService.device,
        demoMode: this._workplaceContextService.demoMode,
      },
      <JSData.DSAdapterOperationConfiguration>{
        bypassCache: true,
        nocache: true,
      }
    );
    this.channel.publish(UserSettingService.USER_SETTINGS_REFRESHED);
    return settings;
  }

  /**
   * Returns the startup settings
   * @returns null if the user has nothing set
   */
  getStartupSettings(): ng.IPromise<IUserSetting> {
    return this.getSettingByDescription('startup');
  }

  /** if start dashboard is configured (or option does not exist) than clear the state and remove the listeners. */
  async isRestoreSessionActive(): Promise<boolean> {
    const startupSettings = await this.getStartupSettings();
    const startDashboard: IUserSettingOption = !startupSettings
      ? undefined
      : startupSettings.optionsList.find((opt: IUserSettingOption) => opt.description === 'startdashboard');

    return startDashboard && !!!startDashboard.enabled;
  }

  /**
   * Update the setting value.
   * @param setting
   * @returns {JSDataPromise<IUserSetting>}
   */
  updateSetting(setting: IUserSetting): ng.IPromise<IUserSetting> {
    const deferred = this.$q.defer<IUserSetting>();
    this._store.update(setting.id, setting).then((updatedSetting: IUserSetting) => {
      deferred.resolve(updatedSetting);
    });
    return deferred.promise;
  }

  /**
   * Check if the given dashboard should be opened when the app starts.
   *
   * @param {string} name
   * @return {angular.IPromise<boolean>}
   */
  autoStartDashboard(name: string): ng.IPromise<boolean> {
    return this.getStartupSettings().then((startupSettings: IUserSetting) => {
      if (!startupSettings || !startupSettings.optionsList) {
        return false;
      }

      const foundDash: number = _.findIndex(startupSettings.optionsList, (option: IUserSettingOption) => {
        return option.values.includes(name) && option.description === 'startdashboard';
      });

      return foundDash !== -1;
    });
  }
}
