import { Component } from '../component';
import { Loader } from './loader';
import loaderTemplate from './loader.html';
/**
 * @ngInject
 */
export function loaderDirective(): ng.IDirective {
  'use strict';
  return Component.createDirective(Loader, {
    restrict: 'E',
    scope: {
      loading: '=',
      message: '@',
      containerStretch: '@',
      spinnerSize: '@',
      theme: '@',
      centerContent: '@',
    },
    templateUrl: loaderTemplate,
  });
}
