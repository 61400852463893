import * as uiRouter from 'angular-ui-router';
import { UrlHelper } from '../util/url.helper';
import { ActionConstants } from './actionLog/action-constants';
import { ActionLogService } from './actionLog/action-log.service';
import { AppAdminUploadComponent } from './appAdminUpload/appAdminUpload.component';
import appAdminUploadTemplate from './appAdminUpload/appAdminUpload.html';
import { AppsService } from './apps/apps.service';
import { DashboardService } from './dashboard/dashboard.service';
import errorTemplate from './errors/error.html';
import { FrameLayoutService } from './frameLayout/frame-layout.service';
import layoutTemplate from './layout/layout.html';
import { multiLinkUploadInit } from './multiLinkUpload/multi-link-upload.controller';
import multiLinkUploadTemplate from './multiLinkUpload/multi-link-upload.html';
import { SessionDataService } from './sessionData/session-data.service';
import { WorkplaceApiService } from './workplace/workplace.api.service';
import { OnboardingFlowService } from './web-components/onboarding-flow/onboarding-flow.service';

('use strict');

export const APP_ROUTES = {
  ONBARDING_FLOW: 'start',
};

/**
 * Routes for the module app are defined here.
 *
 * @author Tobias Straller [Tobias.Straller.bp@nttdata.com]
 */
export class RouterConfig {
  /** @ngInject */
  constructor($stateProvider: uiRouter.IStateProvider, $urlRouterProvider: uiRouter.IUrlRouterProvider) {
    $urlRouterProvider
      .when('/start', routingOnboarding)
      .when('/start/:name', routingOnboarding)
      .when('/app/:name', routingApp)
      .when('/app/:name/:params', routingApp)
      .when('/app/:name/:params/:hash', routingApp)
      .when('/app/:name/:params/:hash/:tabTitle', routingApp)
      .when('/refresh/:name', routingRefresh)
      .when('/refresh/:name/:params', routingRefresh)
      .when('/refresh/:name/:params/:hash', routingRefresh)
      .when('/connect/:name', routingRefresh)
      .when('/connect/:name/:params', routingRefresh)
      .when('/connect/:name/:params/:hash', routingRefresh)
      .when('/dashboard/:name', routingDashboard)
      .when('/dashboard/:name/:params', routingDashboard)
      .when('/layout/:name/widget/:widgetId', routingLayoutWidget)
      .when('/layout/:name/widget/:widgetId/:params', routingLayoutWidget)
      .when('/layout/:name/widget/:widgetId/:params/:hash', routingLayoutWidget)
      .when('/layout/:name/widget/:widgetId/:params/:hash/:hash2', routingLayoutWidget)
      .when('/layout/:name/widget/:widgetId/:params/:hash/:hash2/:hash3', routingLayoutWidget)
      .when('/layout/:name/widget/:widgetId/:params/:hash/:hash2/:hash3/:hash4', routingLayoutWidget)
      .when('/layout/:name', routingLayout)
      .when('/layout/:name/:params', routingLayout)
      .otherwise('/');

    $stateProvider
      .state('error', {
        url: '/error',
        templateUrl: errorTemplate,
        controller: 'LayoutController',
        controllerAs: 'vm',
      })
      .state('app', {
        url: '/',
        templateUrl: layoutTemplate,
        controller: 'LayoutController',
        controllerAs: 'vm',
      })
      .state('multiLinkUpload', {
        url: '/multiLinkUpload',
        templateUrl: multiLinkUploadTemplate,
        controller: multiLinkUploadInit,
      })
      .state('appAdminUpload', {
        url: '/appAdminUpload',
        templateUrl: appAdminUploadTemplate,
        controller: AppAdminUploadComponent,
        controllerAs: 'vm',
      });

    function routingOnboarding(
      $match: any,
      $state: uiRouter.IStateService,
      onboardingFlowService: OnboardingFlowService
    ): boolean {
      if (!$state.is('app')) {
        $state.go('app', null, { location: false });
      }

      return true;
    }

    /**
     *
     * @param $match
     * @param appsService
     * @param $state
     * @param $location
     * @param workplaceApiService
     * @param actionLogService
     * @param sessionDataService
     * @param frameLayoutService
     * @ngInject
     */
    function routingApp(
      $match: any,
      appsService: AppsService,
      $state: uiRouter.IStateService,
      $location: ng.ILocationService,
      workplaceApiService: WorkplaceApiService,
      actionLogService: ActionLogService,
      sessionDataService: SessionDataService,
      frameLayoutService: FrameLayoutService
    ): boolean {
      console.log('routingApp');
      // location is not updated, so this will be evaluated twice
      if (!$state.is('app')) {
        $state.go('app', null, { location: false });
      } else {
        sessionDataService.waitForStartupComplete().then(() => {
          // only open the app if we are already in the state 'app'
          if (typeof $match.name === 'string') {
            // app name / layout id
            const name = $match.name.replace(/%2F/gi, '/').split('/')[0];
            // this is the app's own deep link
            const path = $match.name.replace(/%2F/gi, '/').replace(name, '');

            const queryParams =
              $match.params && $match.params && $match.params !== '-'
                ? UrlHelper.fromQueryString($match.params.replace(/~/g, '&'))
                : {};
            const hash = $match.hash ? decodeURIComponent($match.hash) : null;
            const tabTitle = $match.tabTitle && $match.tabTitle !== '-' ? decodeURIComponent($match.tabTitle) : null;

            openAppOrLayoutAsApp(
              name,
              queryParams,
              path,
              hash,
              tabTitle,
              actionLogService,
              appsService,
              frameLayoutService,
              workplaceApiService
            );

            $state.go('app', null, { location: true });
          }
        });
      }
      return true;
    }

    /**
     *
     * @param name
     * @param queryParams
     * @param path
     * @param hash
     * @param tabTitle
     * @param actionLogService
     * @param appsService
     * @param frameLayoutService
     * @ngInject
     */
    function openAppOrLayoutAsApp(
      name: string,
      queryParams: { [key: string]: string | string[] },
      path: string,
      hash: string,
      tabTitle: string,
      actionLogService: ActionLogService,
      appsService: AppsService,
      frameLayoutService: FrameLayoutService,
      workplaceApiService: WorkplaceApiService,
      refresh: boolean = false
    ): void {
      const layout = frameLayoutService.getLayoutById(name);
      if (layout) {
        actionLogService.logAction({
          category: ActionConstants.CATEGORY_LAYOUT,
          action: ActionConstants.ACTION_LAYOUT_OPEN_DEEPLINK,
          actionInfo: name,
        });
        const firstFrameId = layout.frames[0].widget.id;
        frameLayoutService
          .openLayoutById(name, undefined, {
            widgetId: firstFrameId,
            path,
            //don't add hash when calling "refresh" api event
            hash: refresh ? null : hash,
            //don't add query params when calling "refresh" api event
            params: refresh ? null : queryParams,
          })
          .then(() => {
            if (refresh) {
              workplaceApiService
                .getConnection(firstFrameId, name)
                .then(connection => connection.callApi('refresh', queryParams, hash));
            }
          });
      } else {
        actionLogService.logAction({
          category: ActionConstants.CATEGORY_APPS,
          action: ActionConstants.ACTION_APPS_LAUNCH_DEEPLINK,
          actionInfo: name,
        });
        appsService
          .openAppByName({
            name,
            //don't add query params when calling "refresh" api event
            queryParams: refresh ? null : queryParams,
            //don't add hash when calling "refresh" api event
            hash: refresh ? null : hash,
            path,
            tabTitle,
          })
          .then(id => {
            if (refresh) {
              workplaceApiService
                .getConnection(id)
                .then(connection => connection.callApi('refresh', queryParams, hash));
            }
          });
      }
    }

    /**
     * @param $match
     * @param dashboardService
     * @param actionLogService
     * @param $state
     * @param sessionDataService
     * @ngInject
     */
    function routingDashboard(
      $match: any,
      dashboardService: DashboardService,
      $state: uiRouter.IStateService,
      actionLogService: ActionLogService,
      sessionDataService: SessionDataService
    ): boolean {
      // location is not updated, so this will be evaluated twice
      if (!$state.is('app')) {
        $state.go('app', null, { location: false });
      } else {
        sessionDataService.waitForStartupComplete().then(() => {
          const params =
            $match.params && $match.params && $match.params !== '-'
              ? UrlHelper.fromQueryString($match.params.replace(/~/g, '&'))
              : {};
          // only open the dashboard if we are already in the state 'dashboard'
          if (typeof $match.name === 'string') {
            actionLogService.logAction({
              category: ActionConstants.CATEGORY_DASHBOARD,
              action: ActionConstants.ACTION_DASHBOARD_LAUNCH_DEEPLINK,
              actionInfo: $match.name,
            });
            dashboardService.openDeepLinkDashboard($match.name, params);
            $state.go('app', null, { location: true });
          }
        });
      }
      return true;
    }

    /**
     *
     * @param $match
     * @param appsService
     * @param $state
     * @param $location
     * @param actionLogService
     * @param workplaceApiService
     * @param sessionDataService
     * @ngInject
     */
    function routingRefresh(
      $match: any,
      appsService: AppsService,
      $state: uiRouter.IStateService,
      $location: ng.ILocationService,
      actionLogService: ActionLogService,
      workplaceApiService: WorkplaceApiService,
      sessionDataService: SessionDataService,
      frameLayoutService: FrameLayoutService
    ): boolean {
      // location is not updated, so this will be evaluated twice
      if (!$state.is('app')) {
        $state.go('app', null, { location: false });
      } else {
        sessionDataService.waitForStartupComplete().then(() => {
          // only open the app if we are already in the state 'app'
          if (typeof $match.name === 'string') {
            const name = $match.name.replace(/%2F/gi, '/').split('/')[0];
            const path = $match.name.replace(/%2F/gi, '/').replace(name, '');
            const queryParams =
              $match.params && $match.params !== '-' ? UrlHelper.fromQueryString($match.params.replace(/~/g, '&')) : {};
            const hash = $match.hash ? decodeURIComponent($match.hash) : null;

            openAppOrLayoutAsApp(
              name,
              queryParams,
              path,
              hash,
              null,
              actionLogService,
              appsService,
              frameLayoutService,
              workplaceApiService,
              true
            );
            $state.go('app', null, { location: true });
          }
        });
      }
      return true;
    }

    function routingLayout(
      $match: any,
      frameLayoutService: FrameLayoutService,
      $state: uiRouter.IStateService,
      actionLogService: ActionLogService,
      $location: ng.ILocationService,
      sessionDataService: SessionDataService
    ): boolean {
      if (!$state.is('app')) {
        $state.go('app', null, { location: false });
      } else {
        sessionDataService.waitForStartupComplete().then(() => {
          const params =
            $match.params && $match.params && $match.params !== '-'
              ? UrlHelper.fromQueryString($match.params.replace(/~/g, '&'))
              : {};
          // only open the dashboard if we are already in the state 'layout'
          if (typeof $match.name === 'string') {
            actionLogService.logAction({
              category: ActionConstants.CATEGORY_LAYOUT,
              action: ActionConstants.ACTION_LAYOUT_OPEN_DEEPLINK,
              actionInfo: $match.name,
            });
            frameLayoutService.openLayoutById($match.name, params);
            $state.go('app', null, { location: true });
          }
        });
      }
      return true;
    }

    function routingLayoutWidget(
      $match: any,
      frameLayoutService: FrameLayoutService,
      $state: uiRouter.IStateService,
      $location: ng.ILocationService,
      sessionDataService: SessionDataService
    ): boolean {
      if (!$state.is('app')) {
        $state.go('app', null, {
          location: false,
        });
      } else {
        sessionDataService.waitForStartupComplete().then(() => {
          const widgetId = $match.widgetId.replace(/%2F/gi, '/').split('/')[0];
          if (!widgetId) {
            return true;
          }
          const path = $match.widgetId.replace(/%2F/gi, '/').replace(widgetId, '');
          const params =
            $match.params && $match.params && $match.params !== '-'
              ? UrlHelper.fromQueryString($match.params.replace(/~/g, '&'))
              : {};
          let hash = $match.hash ? decodeURIComponent($match.hash) : null;
          const hash2 = $match.hash2 ? decodeURIComponent($match.hash2) : null;
          const hash3 = $match.hash3 ? decodeURIComponent($match.hash3) : null;
          const hash4 = $match.hash4 ? decodeURIComponent($match.hash4) : null;
          // only open the dashboard if we are already in the state 'layout'
          hash =
            (hash ? `${hash}` : '') +
            (hash2 ? `/${hash2}` : '') +
            (hash3 ? `/${hash3}` : '') +
            (hash4 ? `/${hash4}` : '');
          if (typeof $match.name === 'string') {
            frameLayoutService.openLayoutById($match.name, params, { widgetId, path, hash, params });
            $state.go('app', null, { location: true });
          }
        });
      }
      return true;
    }
  }
}
