const VALIDATION_REGEX = {
  WINDOWS: '^(.*)?(Win)(.*)?$',
  LINUX: '^(.*)?(Linux)(.*)?$',
  MAC: '^(.*)?(Mac)(.*)?$',
  IOS: '^(.*)?(iPad|iPhone|iPod)(.*)?$',
};

enum OS_TYPE {
  WINDOWS,
  LINUX,
  MAC,
  IOS,
  NOT_DETECTED,
}

var osType = (function getClientOs(): OS_TYPE {
  const navigatorPlatform: string = window.navigator.platform;
  if (new RegExp(VALIDATION_REGEX.WINDOWS).test(navigatorPlatform)) {
    return OS_TYPE.WINDOWS;
  }
  if (new RegExp(VALIDATION_REGEX.LINUX).test(navigatorPlatform)) {
    return OS_TYPE.LINUX;
  }
  if (new RegExp(VALIDATION_REGEX.MAC).test(navigatorPlatform)) {
    return OS_TYPE.MAC;
  }
  if (new RegExp(VALIDATION_REGEX.IOS).test(navigatorPlatform)) {
    return OS_TYPE.IOS;
  }
  return OS_TYPE.NOT_DETECTED;
})();

function getClientOs(): OS_TYPE {
  'use strict';
  return osType;
}

export { OS_TYPE, getClientOs };
