import { LayoutService } from './layout.service';
import { ILayoutServiceChannelResizeMessage } from './layout.service';
import _ from 'lodash';
/**
 * Directive used to spy on the layout, whether we are in mobile or desktop layout
 * Usage
 * <code>
 *    <div app-layout-responsive-spy responsive-spy-callback="vm.responsiveSpyCallback(responsiveInfo)"></div>
 * </code>
 *
 * @author Tobias Straller [Tobias.Straller.bp@nttdata.com]
 *
 * @ngInject
 */
export function appLayoutResponsiveSpyDirective(layoutService: LayoutService): ng.IDirective {
  'use strict';

  function getResponsiveInfo(el: JQuery): any {
    var responsiveInfo = {};
    el.show();

    ['hidden-xs', 'hidden-sm', 'hidden-md', 'hidden-lg'].forEach((val: string) => {
      el.addClass(val);
      responsiveInfo[val.substr(7)] = el.css('display') === 'none';
      el.removeClass(val);
    });

    el.hide();
    return responsiveInfo;
  }

  return {
    restrict: 'A',
    scope: {
      responsiveSpyCallback: '&',
    },
    link(scope: any, el: JQuery, attr: any): void {
      el.addClass(attr.appLayoutResponsiveSpy);
      layoutService.mobileLayout = el.css('display') === 'none';
      var subscription = layoutService.channelResize.subscribe(
        LayoutService.TOPIC_RESIZE,
        _.throttle(
          (message: ILayoutServiceChannelResizeMessage) => {
            scope.responsiveSpyCallback({ responsiveInfo: getResponsiveInfo(el) });
          },
          100,
          {
            trailing: true,
          }
        )
      );
      // initial call
      scope.responsiveSpyCallback({ responsiveInfo: getResponsiveInfo(el) });
      scope.$on('$destroy', () => {
        subscription.unsubscribe();
      });
    },
  };
}
