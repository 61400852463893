import { Component } from '../component';
import { DropdownMenuItem } from './dropdown-menu-item';
import dropdownMenuItemTemplate from './dropdown-menu-item.html';
/**
 *  Wrapper directive for a dropdown menu item's component.
 *
 *  @ngInject
 */
export function dropdownMenuItemDirective(): ng.IDirective {
  'use strict';
  return Component.createDirective(DropdownMenuItem, {
    restrict: 'E',
    scope: {
      iconCls: '@',
      key: '@',
      isInternet: '=',
      errorMsg: '@',
      itemClick: '&',
    },
    templateUrl: dropdownMenuItemTemplate,
  });
}
