import { Component } from '../component';
import { UserService } from '../../user/user.service';
import { User } from '../../user/user.model';
import { UserSettingService } from '../../userSetting/user-setting.service';
import { IUserSetting, IUserSettingOption } from '../../userSetting/user-setting.model.interface';

/**
 * Component for a tasks info item.
 */
export class UserInfoItem extends Component {
  user: User;
  image: string = '';

  private _userService: UserService;
  private _userSettingService: UserSettingService;

  /**
   * @ngInject
   * @param tasksService
   */
  constructor(userService: UserService, userSettingService: UserSettingService) {
    super();
    this._userService = userService;
    this._userSettingService = userSettingService;
    this._userService.getUser().then((user: User) => {
      this.user = user;

      this.user.roles.sort((baseItem: any, compareItem: any) =>
        baseItem.roleDescription.localeCompare(compareItem.roleDescription)
      );
    });
    this.setUserImg();
    this._userSettingService.channel.subscribe(UserSettingService.USER_SETTINGS_REFRESHED, this.setUserImg.bind(this));
  }

  /**
   * Destroy the component
   */
  destroy(): void {
    this.user = null;
    this._userService = null;
  }

  private setUserImg(): void {
    this._userSettingService.getSettingByDescription('profileImage').then((setting: IUserSetting) => {
      if (!setting || setting === null) {
        return;
      }
      let found = setting.optionsList.find((option: IUserSettingOption) => {
        if (option.description === 'profileImageSet') {
          return true;
        }
      });
      this.image = found.values[0];
    });
  }
}
