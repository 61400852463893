import * as Interact from 'interactjs';

/**
 * Directive for creating dropzones
 *
 * @returns {ng.IDirective}
 * @ngInject
 */
export function holdDirective($parse: ng.IParseService): ng.IDirective {
  'use strict';

  return {
    restrict: 'A',
    compile: function (el: JQuery, attr: any): ng.IDirectivePrePost {
      var fn = $parse(attr.utilInteractHold);
      return {
        post: function link($scope: any, el: JQuery): void {
          var holding = false;
          var holdHandler = function (event: Interact.PointerEvent): void {
            holding = true;
            $scope.$apply(() => {
              fn($scope, { $event: event });
            });
          };
          var clickHandler = function (event: Interact.PointerEvent): void {
            if (holding) {
              event.preventDefault();
              event.stopImmediatePropagation();
              holding = false;
            }
          };
          var interactable = Interact.interact(el.get(0)).on('hold', holdHandler).on('click', clickHandler, true);
          el.on('$destroy', () => {
            interactable.off('hold', holdHandler).off('click', clickHandler).unset();
          });
        },
      };
    },
  };
}
