import { TabService } from '../tab/tab.service';
import { LayoutService } from '../layout/layout.service';
import { UserSettingService } from '../userSetting/user-setting.service';
import { IUserSetting, IUserSettingOption } from '../userSetting/user-setting.model.interface';
import { DashboardService } from '../dashboard/dashboard.service';
import { TabManager } from '../tab/tab.manager';
import { ITab } from '../tab/tab';
import { ITabContent, ITabContentIFrame, TabContentType } from '../tab/tab.content.interface';
import { AppsService } from '../apps/apps.service';
import { FrameLayoutService } from '../frameLayout/frame-layout.service';
import { IDashboard } from '../dashboard/dashboard.model.interface';
import { SessionRestoreProperties } from './session-data.model.interface';
import { ILocalStorareWrapper } from './local-storage-wrapper.factory';
import { ActionConstants } from '../actionLog/action-constants';
import { ActionLogService } from '../actionLog/action-log.service';
import _ from 'lodash';
import { WorkplaceApiService } from '../workplace/workplace.api.service';
import { NotificationService } from '../notification/notification.service';
import JSData from 'js-data';
import { IFrameLayout } from '../frameLayout/frame-layout.model.interface';

/**
 * Service for hanlding session data
 */
export class SessionDataService {
  public static CHANNEL: string = 'SESSION_DATA_CHANNEL';
  public static MESSAGE_STARTUP_COMPLETE: string = 'SESSION_DATA_MESSAGE_STARTUP_COMPLETE';

  channel: IChannelDefinition<void>;
  saveState: () => void;
  private _tabService: TabService;
  private _layoutService: LayoutService;
  private _userSettingService: UserSettingService;
  private _dashboardService: DashboardService;
  private _qService: ng.IQService;
  private _appsService: AppsService;
  private _startupComplete: ng.IDeferred<void>;
  private _frameLayoutService: FrameLayoutService;
  private _localStorageService: ILocalStorareWrapper;
  private _sessionRestoreListeners: ISubscriptionDefinition<any>[];
  private _actionLogService: ActionLogService;
  private _apiService: WorkplaceApiService;
  private _notificationService: NotificationService;
  private _layoutStore: JSData.DSResourceDefinition<IFrameLayout>;

  /**
   * @ngInject
   */
  constructor(
    tabService: TabService,
    layoutService: LayoutService,
    userSettingService: UserSettingService,
    dashboardService: DashboardService,
    actionLogService: ActionLogService,
    $q: ng.IQService,
    appsService: AppsService,
    postal: IPostal,
    frameLayoutService: FrameLayoutService,
    debounce: any,
    localStorageFactory: ILocalStorareWrapper,
    notificationService: NotificationService,
    frameLayoutsStore: JSData.DSResourceDefinition<IFrameLayout>
  ) {
    this._tabService = tabService;
    this._layoutService = layoutService;
    this._userSettingService = userSettingService;
    this._dashboardService = dashboardService;
    this._frameLayoutService = frameLayoutService;
    this._localStorageService = localStorageFactory;
    this._qService = $q;
    this._appsService = appsService;
    this._actionLogService = actionLogService;
    this._notificationService = notificationService;
    this.channel = postal.channel<void>(SessionDataService.CHANNEL);
    this._startupComplete = this._qService.defer<void>();
    this._sessionRestoreListeners = [];
    this._layoutStore = frameLayoutsStore;
    this.saveState = debounce(this._saveState.bind(this), 250, false, false);
    this._userSettingService.channel.subscribe(
      UserSettingService.USER_SETTINGS_REFRESHED,
      this.activateSessionRestore.bind(this)
    );
  }

  setApiService(apiService: WorkplaceApiService): void {
    this._apiService = apiService;
  }

  async restoreSession(): Promise<void> {
    const sessionRestoreActive = await this._userSettingService.isRestoreSessionActive();
    const startupSettings = await this._userSettingService.getStartupSettings();
    const startDashboardIdx = startupSettings.optionsList.findIndex(
      (opt: IUserSettingOption) => opt.description === 'startdashboard'
    );

    if (!sessionRestoreActive) {
      this.defaultStart(startupSettings, startDashboardIdx);
      return;
    }
    this._dashboardService.getDashboards().then((dashboards: IDashboard[]) => {
      let savedTabManagers = this._tabService.getSettings();
      let tabManagers;

      // Migrate settings. TODO: Rewrite this block when settings migration is done.
      if (savedTabManagers === null) {
        tabManagers = this._localStorageService.getProperty(SessionRestoreProperties.TAB_MANAGERS);
      } else {
        tabManagers = savedTabManagers;
      }

      const dashboardIds = dashboards.map((d: IDashboard) => d.name);
      // no tab managers found, fallback to default start.
      // but we want to also add the listeners to save the state.
      if (!tabManagers) {
        this.defaultStart(startupSettings, startDashboardIdx);
        this.addListeners();
        return;
      }
      const tabManagerKeys: string[] = _.keys(<{ [index: string]: TabManager }>tabManagers);
      tabManagerKeys.forEach((tabviewId: string, keyIndex: number) => {
        const tabMgrTemplate = tabManagers[tabviewId];
        this._tabService.createTabmanager(tabMgrTemplate.id, {
          maxTabs: tabMgrTemplate.maxTabs !== null ? tabMgrTemplate.maxTabs : Number.POSITIVE_INFINITY,
        });
        /**
         * Added an extra call for dashboards to prevent the data store from being empty.
         */
        const openTabsPromise: ng.IPromise<any>[] = [];
        tabMgrTemplate.tabs.forEach((tab: ITab<ITabContent>, index: number) => {
          if (tab.content.type === TabContentType.IFRAME && (<ITabContentIFrame>tab.content).app) {
            this._actionLogService.logAction({
              category: ActionConstants.CATEGORY_APPS,
              action: ActionConstants.ACTION_APPS_LAUNCH_SESSION_RESTORE,
              actionInfo: (<ITabContentIFrame>tab.content).app.name,
            });
            const openConfig = {
              name: (tab.content as ITabContentIFrame).app.name,
              path: tab.path ? tab.path : null,
              tabTitle: tab.providedTitle ? tab.providedTitle : (tab.content as ITabContentIFrame).app.description,
            };
            openTabsPromise.push(this._appsService.openAppByName(openConfig, tabviewId, index, false));
            return;
          }
          /** Restore Layouts (type = 2) */
          if (
            tab.content.type === TabContentType.FRAME_LAYOUT_TEMPLATE &&
            !!(<ITabContentIFrame>tab.content).frameLayout &&
            ![null, undefined].includes((<ITabContentIFrame>tab.content).frameLayout.id)
          ) {
            /** get layout from store and update it with providedTitle */
            let layout = this._layoutStore.get((tab.content as ITabContentIFrame).frameLayout.id);
            layout.providedTitle = tab.providedTitle;
            this._layoutStore.inject(layout);
            /** open layout */
            this._frameLayoutService.openLayoutById((tab.content as ITabContentIFrame).frameLayout.id);
            return;
          }
          /**
           * Only open the dashboards that are present in the /dashboards response(so we don't open deleted dashboards).
           * We need to fetch their controls' information first and then open them.
           * Make sure we preserve the order by opening the tab at the original.
           */
          if (dashboardIds.indexOf(tab.id) !== -1) {
            this._actionLogService.logAction({
              category: ActionConstants.CATEGORY_DASHBOARD,
              action: ActionConstants.ACTION_DASHBOARD_LAUNCH_SESSION_RESTORE,
              actionInfo: tab.id,
            });

            const dashboard = this._dashboardService.findDashboardInCache(tab.id);

            /** validate strongAuth permissions for opening the dashboard */
            this._apiService.checkStrongAuthPermissions(dashboard).then((isValid: boolean) => {
              if (!isValid) {
                this._notificationService.showError('oidc.permissions.denied');
                return;
              }
              this._tabService.openTabAt(tab, index, tabviewId, false);
            });

            openTabsPromise.push(Promise.resolve(dashboard));
            return;
          }
        });
        /**
         * If no tabs are to be opened (all the dashboards were deleted)
         * open the default dashboard from /settings (it should always exist)
         */
        if (!openTabsPromise.length) {
          startupSettings.optionsList[startDashboardIdx].values.forEach((dashboardId: string) =>
            openTabsPromise.push(this._dashboardService.openDashboardByName(dashboardId))
          );
        }

        Promise.allSettled(openTabsPromise).then(() => {
          /**
           * make sure the active tab is also restored.
           * @type {ITab<ITabContent>}
           */
          const activeTab = tabMgrTemplate.tabs.find((tab: ITab<ITabContent>) => tab.active);
          if (activeTab) {
            this._tabService.selectTab(activeTab.id);
          }

          /**
           * Trigger restore complete when tabs / dashboards have been restored for all tab managers.
           */
          if (keyIndex === tabManagerKeys.length - 1) {
            this.addListeners();
            this.channel.publish(SessionDataService.MESSAGE_STARTUP_COMPLETE);
            this._startupComplete.resolve();

            // Migrate settings. TODO: Remove this block when settings migration is done.
            if (savedTabManagers === null) {
              this._tabService.saveSettings();
            }
          }
        });
      });
    });
  }

  /**
   * Call this method to wait upon startup has been completed
   * @returns {angular.IPromise<void>}
   */
  waitForStartupComplete(): ng.IPromise<void> {
    return this._startupComplete.promise;
  }

  private addListeners(): void {
    this._sessionRestoreListeners.push(
      this._tabService.channel.subscribe(TabService.TOPIC_TAB_ADD, this.saveState.bind(this)),
      this._tabService.channel.subscribe(TabService.TOPIC_TAB_REMOVE, this.saveState.bind(this)),
      this._tabService.channel.subscribe(TabService.TOPIC_TAB_MOVE, this.saveState.bind(this)),
      this._layoutService.channelUpdate.subscribe(LayoutService.TOPIC_UPDATE, this.saveState.bind(this)),
      this._frameLayoutService.channel.subscribe(FrameLayoutService.CACHE_LAYOUTS, this.saveState.bind(this)),
      this._tabService.channel.subscribe(TabService.TOPIC_TAB_SELECT, this.saveState.bind(this)),
      this._tabService.channel.subscribe(TabService.TOPIC_TAB_REMOVE, this.saveState.bind(this))
    );
  }

  /**
   * the default start procedure, when no reload last state is desired.
   *
   * @param {IUserSetting} startupSettings
   * @param {number} startDashboard
   */
  private defaultStart(startupSettings: IUserSetting, startDashboard: number): void {
    // just in case
    this._tabService.registerTabview('main', { maxTabs: TabManager.MAX_TABS });
    const promises: ng.IPromise<IDashboard>[] = [];
    startupSettings.optionsList[startDashboard].values.forEach((dashboardId: string) =>
      promises.push(this._dashboardService.openDashboardByName(dashboardId))
    );
    Promise.all(promises)
      .then(() => {
        this.channel.publish(SessionDataService.MESSAGE_STARTUP_COMPLETE);
        this._startupComplete.resolve();
      })
      .catch((err: any) => console.log('Default startup procedure failed because', err));
  }

  /**
   * On a user settings refresh, determines if the restore session must be activated.
   */
  private async activateSessionRestore(): Promise<void> {
    const activateRestore: boolean = await this._userSettingService.isRestoreSessionActive();
    if (activateRestore) {
      this._saveState();
      this.addListeners();
      return;
    }
    this._sessionRestoreListeners.forEach((listner: ISubscriptionDefinition<any>) => listner.unsubscribe());
  }

  private _saveState(): void {
    this._layoutService.saveSettings();
    this._tabService.saveSettings();
  }
}
