import { ILanguage } from '../../../util/language.model.interface';
import { getToken } from '../../app.auth';
import { DashboardActionsService } from '../../dashboard/dashboard-actions/dashboard-actions.service';
import { IUser } from '../../user/user.model.interface';
import { UserService } from '../../user/user.service';
import { ILayoutServiceChannelEventMessage, LayoutService } from '../../layout/layout.service';
import { IAnalytics, PlatformAnalyticsFactory } from '@myworkplace/api';
import { ActionLogService } from '../../actionLog/action-log.service';
import * as uiRouter from 'angular-ui-router';
import { EventListenerManager } from '../event-listener-manager';

type OnboardingFlowInputs = {
  getToken: () => void;
  isClientLoading: boolean;
  user: IUser;
  analytics: IAnalytics;
};

export const enum RequestState {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  SUCCEED = 'SUCCEED',
  FAILED = 'FAILED',
  ABORTED = 'ABORTED',
  PENDING = 'PENDING',
  CONFIRMATION_ENDED = 'CONFIRMATION_ENDED',
}

export class OnboardingFlowService {
  private static readonly CREATE_ACTION_LOG_FUNC = 'createActionLog';
  isActive = null;
  loaded = false;
  layoutServiceChannelEvent: IChannelDefinition<ILayoutServiceChannelEventMessage>;

  // Onboarding flow can be triggered by the menus (from inside myWorkplace) and can also be triggered by a URL.
  private triggeredFromInsideMyWorkplace = false;
  private onboardingFlowEl: HTMLElement & OnboardingFlowInputs;
  private handleOnboardingStateChangeFunction = (event: MouseEvent) => this.handleOnboardingStateChange(event);

  /**
   * @ngInject
   */
  constructor(
    private readonly language: ILanguage,
    private readonly dashboardActionsService: DashboardActionsService,
    private readonly userService: UserService,
    private readonly actionLogService: ActionLogService,
    private readonly $state: uiRouter.IStateService,
    private readonly eventListenerManager: EventListenerManager,
    readonly postal: IPostal
  ) {
    this.layoutServiceChannelEvent = postal.channel(LayoutService.CHANNEL_EVENT);
  }

  startOnboardingScreen(
    options: {
      triggeredFromInsideMyWorkplace?: boolean;
      open?: boolean;
    } = { open: true }
  ): Promise<boolean> {
    this.triggeredFromInsideMyWorkplace = options.triggeredFromInsideMyWorkplace;

    const element = document.querySelector('onboarding-flow') as HTMLElement;

    if (element) {
      // z-index: 1002;
      element.style.zIndex = this.triggeredFromInsideMyWorkplace ? 'unset' : '1002';
    }

    return new Promise(resolve => this.loadOnboarding(resolve));
  }

  async importShepherdGuidedTour(dashboardId: string): Promise<void> {
    const { shepherdTour } = await import('dashboards/guided-tour');
    shepherdTour['mountShepherdTour']();

    const element = document.querySelector('mwp-guided-tour') as HTMLElement & {
      dashboardId: string;
      lang: string;
    };

    if (element) {
      element.dashboardId = dashboardId;
      element.lang = this.language.lang;
    } else {
      console.error('It could not find tag <mwp-guided-tour>.');
    }
  }

  /**
   * Loads the onboarding screen in case the user is at /start
   * Returns a promise with TRUE if the onboarding has loaded and FALSE if not
   */
  private async loadOnboarding(resolve: Function) {
    this.isActive = true;

    if (!this.loaded) {
      try {
        await import('onboardingFlow');
        this.loaded = true;
      } catch (error) {
        console.log('Error loading Onboarding Flow', error);
        resolve(false);
        return;
      }
    }

    await this.setupHandlers();
    this.eventListenerManager.addEventListener(
      this.onboardingFlowEl,
      'onboardingStateChange',
      this.handleOnboardingStateChangeFunction
    );

    // Use setTimeout to throttle the startup, simulating animation time
    setTimeout(() => resolve(true), 500);
  }

  private async setupHandlers(): Promise<void> {
    if (!this.onboardingFlowEl) {
      this.onboardingFlowEl = document.getElementById('onboarding-flow') as HTMLElement & OnboardingFlowInputs;
    }

    if (!this.onboardingFlowEl) return;

    const user = await this.userService.getUser();
    this.onboardingFlowEl.lang = this.language.lang;
    this.onboardingFlowEl.getToken = getToken;
    this.onboardingFlowEl.user = {
      ...user,
      fullName: `${user.name} ${user.surname}`,
      qnumber: `${user.userId.toUpperCase()}`,
    } as IUser;
    this.onboardingFlowEl.analytics = this.getPlatformAnalytics();
  }

  private getPlatformAnalytics(): IAnalytics {
    return PlatformAnalyticsFactory({
      callApi: (name: string, ...params: any[]): Promise<any> => {
        if (name !== OnboardingFlowService.CREATE_ACTION_LOG_FUNC) {
          return Promise.reject('Wrong API function');
        }
        const logEntry = params[0];
        this.actionLogService.logAction(logEntry);
      },
    });
  }

  private deleteHandlers(): void {
    this.eventListenerManager.removeEventListener(
      this.onboardingFlowEl,
      'onboardingStateChange',
      this.handleOnboardingStateChangeFunction
    );
  }

  /**
   * Handle dashboard creation after onboarding flow
   * For now it just continues with start up
   */
  private async handleOnboardingStateChange(event: MouseEvent) {
    const payload = event.detail as unknown as any & { creatingState: RequestState };
    const { dashboardId, parentId, creatingState, title, mode } = payload || {};

    switch (creatingState) {
      case RequestState.LOADING: {
        this.handleOnboardingLoading();
        return;
      }
      case RequestState.IDLE: {
        this.handleOnboardingIdle();
        return;
      }
      case RequestState.SUCCEED: {
        this.deleteHandlers();
        this.handleOnboardingSucceed(mode, dashboardId, parentId, title);
        this.$state.go('app', null, { location: true });
        return;
      }
      case RequestState.ABORTED: {
        this.onboardingFlowEl.isClientLoading = false;
        this.isActive = false;
        this.deleteHandlers();
        break;
      }
      case RequestState.FAILED: {
        this.onboardingFlowEl.isClientLoading = false;
        this.isActive = false;
        this.deleteHandlers();
        break;
      }
      case RequestState.PENDING: {
        // Since the onboarding flow is still opened is supposed to wait for the user to finish the confirmation flow
        return;
      }
      case RequestState.CONFIRMATION_ENDED: {
        // Since the onboarding flow finished the confirmation flow we now have to open the guided tour and close the
        // onboarding flow
        this.deleteHandlers();
        this.handleOnboardingSucceed(mode, dashboardId, parentId, title);
        this.$state.go('app', null, { location: true });
        return;
      }
      default: {
        this.onboardingFlowEl.isClientLoading = false;
        this.isActive = false;
        this.deleteHandlers();
      }
    }
  }

  private handleOnboardingLoading(): void {
    if (this.onboardingFlowEl) {
      this.onboardingFlowEl.style.zIndex = '1002';
    }
  }

  private handleOnboardingIdle(): void {
    if (this.onboardingFlowEl && this.triggeredFromInsideMyWorkplace) {
      this.onboardingFlowEl.style.zIndex = 'unset';
    }
  }

  private handleOnboardingSucceed(mode: string, dashboardId: string, parentId: string, title: string): void {
    this.onboardingFlowEl.isClientLoading = true;

    if (mode === 'TEMPLATE') {
      this.layoutServiceChannelEvent.publish(LayoutService.CLOSE_WEB_COMPONENTS_SIDE_PANEL);
    }

    this.importShepherdGuidedTour(dashboardId);

    this.dashboardActionsService.createTemporaryDashboardAndOpen(dashboardId, parentId, title).then(() => {
      this.onboardingFlowEl.isClientLoading = false;
      this.isActive = false;
    });
  }
}
