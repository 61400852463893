/**
 * ms-word: (for .docx, .doc, .docm)
 * ms-powerpoint: (for .pptx, .ppt, .pptm)
 * ms-excel: (for .xlsx, .xls, .xlsm)
 * ms-visio: (for .vsd, .vsdx, .vsdm)
 * ms-access: (for .accdb)
 * ms-project: (for .mpp)
 * ms-publisher: (for .pub)
 */

export const ENUM_OFFICE_PREFIXES = {
  ms_word: 'ms-word',
  ms_powerpoint: 'ms-powerpoint',
  ms_excel: 'ms-excel',
  ms_visio: 'ms-visio',
  ms_access: 'ms-access',
  ms_project: 'ms-project',
  ms_publisher: 'ms-publisher',
};

export const ENUM_OFFICE_EXTENSIONS = {
  DOCX: 'docx',
  DOC: 'doc',
  DOCM: 'docm',
  PPTX: 'pptx',
  PPT: 'ppt',
  PPTM: 'pptm',
  XLSX: 'xlsx',
  XLS: 'xls',
  XLSM: 'xlsm',
  VSD: 'vsd',
  VSDX: 'vsdx',
  VSDM: 'vsdm',
  ACCDB: 'accdb',
  MPP: 'mpp',
  PUB: 'pub',
};

export const ALL_OFFICE_EXTENSIONS = [
  ENUM_OFFICE_EXTENSIONS.DOCX,
  ENUM_OFFICE_EXTENSIONS.DOC,
  ENUM_OFFICE_EXTENSIONS.DOCM,
  ENUM_OFFICE_EXTENSIONS.PPTX,
  ENUM_OFFICE_EXTENSIONS.PPT,
  ENUM_OFFICE_EXTENSIONS.PPTM,
  ENUM_OFFICE_EXTENSIONS.XLSX,
  ENUM_OFFICE_EXTENSIONS.XLS,
  ENUM_OFFICE_EXTENSIONS.XLSM,
  ENUM_OFFICE_EXTENSIONS.VSD,
  ENUM_OFFICE_EXTENSIONS.VSDX,
  ENUM_OFFICE_EXTENSIONS.VSDM,
  ENUM_OFFICE_EXTENSIONS.ACCDB,
  ENUM_OFFICE_EXTENSIONS.MPP,
  ENUM_OFFICE_EXTENSIONS.PUB,
];

export const ALL_OFFICE_PREFIXES = {
  [ENUM_OFFICE_EXTENSIONS.DOCX]: ENUM_OFFICE_PREFIXES.ms_word,
  [ENUM_OFFICE_EXTENSIONS.DOC]: ENUM_OFFICE_PREFIXES.ms_word,
  [ENUM_OFFICE_EXTENSIONS.DOCM]: ENUM_OFFICE_PREFIXES.ms_word,
  [ENUM_OFFICE_EXTENSIONS.PPTX]: ENUM_OFFICE_PREFIXES.ms_powerpoint,
  [ENUM_OFFICE_EXTENSIONS.PPT]: ENUM_OFFICE_PREFIXES.ms_powerpoint,
  [ENUM_OFFICE_EXTENSIONS.PPTM]: ENUM_OFFICE_PREFIXES.ms_powerpoint,
  [ENUM_OFFICE_EXTENSIONS.XLSX]: ENUM_OFFICE_PREFIXES.ms_excel,
  [ENUM_OFFICE_EXTENSIONS.XLS]: ENUM_OFFICE_PREFIXES.ms_excel,
  [ENUM_OFFICE_EXTENSIONS.XLSM]: ENUM_OFFICE_PREFIXES.ms_excel,
  [ENUM_OFFICE_EXTENSIONS.VSD]: ENUM_OFFICE_PREFIXES.ms_visio,
  [ENUM_OFFICE_EXTENSIONS.VSDX]: ENUM_OFFICE_PREFIXES.ms_visio,
  [ENUM_OFFICE_EXTENSIONS.VSDM]: ENUM_OFFICE_PREFIXES.ms_visio,
  [ENUM_OFFICE_EXTENSIONS.ACCDB]: ENUM_OFFICE_PREFIXES.ms_access,
  [ENUM_OFFICE_EXTENSIONS.MPP]: ENUM_OFFICE_PREFIXES.ms_project,
  [ENUM_OFFICE_EXTENSIONS.PUB]: ENUM_OFFICE_PREFIXES.ms_publisher,
};

export const ENUM_OFFICE_OPEN_MODES = {
  VIEW: 'view',
  EDIT: 'edit',
};
export const OFFICE_OPEN_VIEW_MODE = 'ofv|u|';
export const OFFICE_OPEN_EDIT_MODE = 'ofe|u|';
