import { GraphQLService } from '../workplace/graphql.service';
import { getUser } from '../workplace/user-utils';
import { UserService } from '../user/user.service';
import { ApolloError } from '@apollo/client';

export interface WebComponentSettings {
  [key: string]: unknown;

  tooltipDismissed: boolean;
}

export interface UserWebComponentSettings {
  settings: WebComponentSettings;
  updatedAt: number;
}

export class WebComponentSettingsService {
  private readonly userId: string;

  /**
   * @ngInject
   */
  constructor(private readonly graphqlService: GraphQLService, private readonly userService: UserService) {
    this.userId = getUser(this.userService);
  }

  private readonly getSettingsGraphql = `
    query getComponentSettings($componentId: String!) {
      webComponentSettingsGetById(componentId: $componentId) {
         settings
      }
    }
  `;

  private readonly saveSettingsGraphql = `
    mutation updateComponentSettings($componentId: String!, $userWebComponentSettingsDtoInput: userWebComponentSettingsDtoInput!) {
      webComponentSettingsUpdate(componentId: $componentId, input: $userWebComponentSettingsDtoInput) {
        settings
      }
    }
  `;

  async loadSettings(componentId: string): Promise<UserWebComponentSettings> {
    try {
      const response = await this.graphqlService.query<{ userWebComponentSettingsGetById: UserWebComponentSettings }>(
        this.getSettingsGraphql,
        {
          variables: {
            componentId,
          },
        }
      );

      return response.data.userWebComponentSettingsGetById;
    } catch (e) {
      if ((e as ApolloError).graphQLErrors[0].extensions.statusCode === 404)
        return {
          settings: {
            tooltipDismissed: false,
          },
        } as UserWebComponentSettings;

      return null;
    }
  }

  async saveSettings(componentId: string, userWebComponentSettings: UserWebComponentSettings): Promise<boolean> {
    try {
      await this.graphqlService.mutation(this.saveSettingsGraphql, {
        variables: {
          componentId,
          userWebComponentSettingsDtoInput: userWebComponentSettings,
        },
      });
    } catch (e) {
      return false;
    }
  }
}
