import * as Interact from 'interactjs';

export class DropzoneController<T> {
  /**
   * Set by attribute
   */
  onDrop: (params: { transfer: T }) => void;

  /**
   * Set by attribute
   */
  onDragEnter: (params: { transfer: T }) => void;

  /**
   * Set by attribute
   */
  onDragLeave: (params: { transfer: T }) => void;

  /**
   * Set by attribute
   */
  onDropMove: (params: { transfer: T; event: Interact.DropEvent }) => void;

  onDropzoneDeactivate: (params: { event: Interact.DropEvent }) => void;

  onDropzoneActivate: (params: { transfer: T; event: Interact.DropEvent }) => void;

  /**
   * Only draggables from the same group are accepted.
   * Set by attribute.
   */
  ddGroup: string;

  /**
   * Set by attribute
   */
  enabled: boolean;

  private _dropzone: Interact.Interactable;
  private _el: JQuery;
  private _$: JQueryStatic;
  private _transfer: any;

  /**
   *
   * @param jQuery
   * @ngInject
   */
  constructor(jQuery: JQueryStatic) {
    this._$ = jQuery;
  }

  initialize(el: JQuery): void {
    this._el = el;
    this.enable(this.enabled);
  }

  createDropzone(el: JQuery): void {
    this._dropzone = Interact.interact(el.get(0)).dropzone({
      accept: this.ddGroup ? this.ddGroup : null,
      ondragenter: this.handleDragEnter.bind(this),
      ondragleave: this.handleDragLeave.bind(this),
      ondrop: this.handleDrop.bind(this),
      ondropactivate: this.handleDropActivate.bind(this),
      ondropdeactivate: this.handleDropDeActivate.bind(this),
      ondropmove: this.handleDropMove.bind(this),
    });
  }

  removeDropzone(): void {
    if (this._dropzone) {
      this._dropzone.unset();
      this._dropzone = null;
      this._transfer = null;
    }
  }

  enable(value: boolean): void {
    if (value) {
      this.createDropzone(this._el);
    } else {
      this.removeDropzone();
    }
  }

  destroy(): void {
    this.removeDropzone();
    this._$ = null;
    this._el = null;
    this._transfer = null;
  }

  private handleDropActivate(event: Interact.DropEvent): void {
    this._el.addClass('util-dropzone-active');
    this._transfer = this._$(event.relatedTarget).data('transfer');
    this.onDropzoneActivate({ transfer: this._transfer, event });
  }

  private handleDropDeActivate(event: Interact.DropEvent): void {
    this._el.removeClass('util-dropzone-active util-dropzone-over');
    this._transfer = null;
    this.onDropzoneDeactivate({ event });
  }

  private handleDragEnter(): void {
    this._el.addClass('util-dropzone-over');
    if (this.onDragEnter) {
      this.onDragEnter({ transfer: this._transfer });
    }
  }

  private handleDragLeave(): void {
    this._el.removeClass('util-dropzone-over');
    if (this.onDragLeave) {
      this.onDragLeave({ transfer: this._transfer });
    }
  }

  private handleDrop(): void {
    if (this.onDrop) {
      this.onDrop({ transfer: this._transfer });
    }
  }

  private handleDropMove(event: Interact.DropEvent): void {
    if (this.onDropMove) {
      this.onDropMove({ transfer: this._transfer, event });
    }
  }
}
