import { Widget } from '../widget';
import { IWidgetData } from '../widget.data.interface';
import { WidgetService } from '../widget.service';
import { WorkplaceApiService } from '../../workplace/workplace.api.service';
import { WorkplaceContextService } from '../../workplace/workplace.context.service';
import { UserService } from '../../user/user.service';
import { INotificationService } from '../../notification/notification.service.interface';
import { ActionLogService } from '../../actionLog/action-log.service';
import { IWidgetSettings } from '../widget.settings.interface';
import { ILanguage } from '../../../util/language.model.interface';
import angular from 'angular';

export class Sus extends Widget<IWidgetSettings> {
  scaleWidth: number = 0;
  appLocale: string;

  /**
   * @ngInject
   */
  constructor(
    $timeout: ng.ITimeoutService,
    widgetService: WidgetService,
    workplaceApiService: WorkplaceApiService,
    workplaceContextService: WorkplaceContextService,
    userService: UserService,
    notificationService: INotificationService,
    $translate: angular.translate.ITranslateService,
    private language: ILanguage,
    actionLogService: ActionLogService
  ) {
    super(
      $timeout,
      widgetService,
      workplaceApiService,
      workplaceContextService,
      userService,
      notificationService,
      $translate,
      actionLogService
    );
  }

  $onInit() {
    this.definition.dataUrl = this.definition.dataUrl.replace('{campaignId}', this.definition.customSettings['campaign']);
    this.appLocale = this.language.locale;
  }

  getDefaultIcon(): string {
    return null;
  }

  renderChart(el: JQuery, data: IWidgetData): void {
    this.scaleWidth = angular.element(document.getElementById('scale'))[0].clientWidth;
  }
}
