import JSData from 'js-data';
import { ILanguage } from '../../util/language.model.interface';
import { AppsService } from '../apps/apps.service';
import { INotificationService } from '../notification/notification.service.interface';
import { UserService } from '../user/user.service';
import { ITask } from './tasks.model.interface';
import { TasksService } from './tasks.service';
import { DeviceService } from '../../util/device.service';

/**
 * Created by mariab on 02.12.2015.
 */

export class TasksPolling {
  private _pollingInterval: number;

  /** @ngInject */
  constructor() {
    this._pollingInterval = 0;
  }

  setPollingInterval(pollingInterval: number): void {
    this._pollingInterval = pollingInterval;
  }

  /** @ngInject */
  $get(
    tasksStore: JSData.DSResourceDefinition<ITask>,
    $translate: angular.translate.ITranslateService,
    $interval: ng.IIntervalService,
    notificationService: INotificationService,
    language: ILanguage,
    appsService: AppsService,
    $http: ng.IHttpService,
    postal: IPostal,
    $q: ng.IQService,
    debounce: any,
    userService: UserService,
    deviceService: DeviceService
  ): TasksService {
    return new TasksService(
      tasksStore,
      $translate,
      $interval,
      this._pollingInterval,
      notificationService,
      language,
      appsService,
      $http,
      postal,
      $q,
      debounce,
      userService,
      deviceService
    );
  }
}
