import { IAnalytics, IAnalyticsFeature, IAnalyticsFeatureConfig, LogLevel } from '@myworkplace/api';
import { BaseTracker } from './baseTracker';

export class Tracker extends BaseTracker {
  private readonly analytics: IAnalytics;
  private features: Map<string, IAnalyticsFeature> = new Map();

  constructor(analytics: IAnalytics) {
    super();
    this.analytics = analytics;
    this.analytics.analyticsConfig = {
      logLevel: LogLevel.DEBUG,
    };
  }

  initFeatures(featuresConfigs: Map<string, IAnalyticsFeatureConfig>): void {
    featuresConfigs.forEach((featureConfig: IAnalyticsFeatureConfig, key: string) => {
      this.features.set(key, this.analytics.createFeature(featureConfig));
    });
  }

  trackFeature(featureName: string, step?: number): void {
    if (step) {
      this.features.get(featureName)?.track(step);
    } else {
      this.features.get(featureName)?.track();
    }

    if (this.features.get(featureName)?.isFinished) {
      this.features.get(featureName)?.reset();
    }
  }

  resetFeature(featureName: string): void {
    this.features.get(featureName)?.reset();
  }
}
