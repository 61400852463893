import _ from 'lodash';
/**
 * Interface describing objects that are nested and can be recursively iterated
 */
export interface INestedObject<T> {
  children?: T[];
}

/**
 * Interface describing objects that can be turned into nodes
 */
export interface INode extends INestedObject<INode> {
  id: string | number;
}

/**
 * Basic tree node implementation
 * @author Tobias Straller [Tobias.Straller.bp@nttdata.com]
 */
export class Node implements INode {
  parent: Node;
  children: Node[];

  private _id: string | number;

  /**
   * @constructor
   * @param config
   */
  constructor(config: INode) {
    this.children = [];
    // copy all values from config (will invoke setters)
    Object.keys(config).forEach((key: string) => {
      if (key === 'children') {
        config.children.forEach((child: Node) => this.addChild(child));
      } else {
        this[key] = config[key];
      }
    });
    // Invalidate method memoization on init
    // Funny things happen when nodes are rebuilt from localstorage, since the method itself
    // is rebuilt with memoized values.
    Object.keys(this.findChildById).forEach((key: string) => (this.findChildById[key] = undefined));
  }

  /**
   * Parse and create a new node instance from a plain object
   * @param clazz
   * @param args
   * @returns {T}
   */
  static deserialize<T extends Node>(clazz: { new (...args: any[]): T }, obj: INode): T {
    if (obj.children) {
      const children = obj.children.map((child: INode): T => Node.deserialize<T>(clazz, child));
      obj.children = children;
    }
    return new clazz(obj);
  }

  /**
   * Remove circular references from node, in order to prepare the object for json to string.
   *
   * @param obj
   * @returns {T}
   */
  static serialize<T extends Node>(node: T): T {
    const clone = _.cloneDeep(node);
    return Node._serialize(clone);
  }

  /**
   * Walk tree using depth first method.
   * @param filter
   * @param node
   * @param self
   * @param strategy value can be "pre" or "post"
   * @returns {any}
   */
  static walkDepthFirst<T>(
    filter: (node: INestedObject<T>) => any,
    node: INestedObject<T>,
    self: boolean = true,
    strategy: string = 'post'
  ): boolean {
    let result;
    if (self && strategy === 'pre') {
      result = filter(node);
    }
    if (result) {
      return result;
    }
    if (node.children) {
      result = node.children.find((child: INestedObject<T>): boolean => {
        return Node.walkDepthFirst(filter, child, true, strategy);
      });
    }
    if (result) {
      return result;
    }
    if (self && strategy === 'post') {
      result = filter(node);
    }
    return result;
  }

  /**
   * Internal serializer for nodes.
   * Public method calls this one, but giving in firstly cloned
   * node.
   *
   * @param obj
   * @returns {T}
   * @private
   */
  private static _serialize<T extends Node>(obj: T): T {
    if (obj.parent) {
      delete obj.parent;
    }
    const children = obj.children.map((child: T): T => {
      return Node._serialize(child);
    });
    obj.children = children;
    return obj;
  }

  /**
   * Adds a child to the current node
   * @param node
   * @param index
   */
  addChild(node: Node, index?: number): void {
    if (node.parent) {
      node.parent.removeChild(node);
    }
    if (typeof index === 'undefined') {
      index = this.children.length;
    }
    this.children.splice(index, 0, node);
    node.parent = this;
  }

  /**
   * Removes a child from the current node. The node is NOT destroyed
   * @param node
   */
  removeChild(node: Node): void {
    const index = this.children.indexOf(node);
    if (index > -1) {
      this.children.splice(index, 1);
      delete this.findChildById[node.id];
      node.parent = null;
    }
  }

  /**
   * Replaces a child node with another node
   * @param child
   * @param newChild
   */
  replaceChild(child: Node, newChild: Node): void {
    const index = this.children.indexOf(child);
    if (index > -1) {
      this.removeChild(child);
      this.addChild(newChild, index);
    }
  }

  /**
   * Walk the tree by using depth first strategy.
   * The filter function will be applied to every node visited that way.
   * The filter function is first applied to the children and then to the parent node.
   *
   * @param filter
   * @param self include the current node
   * @returns {boolean}
   */
  walkDepthFirstPost(filter: (node: Node) => any, self: boolean = true): boolean {
    let result;
    result = this.children.find((node: Node): boolean => {
      return node.walkDepthFirstPost(filter);
    });
    if (result) {
      return result;
    }
    if (self) {
      result = filter(this);
    }
    return result;
  }

  /**
   * Walk the tree by using depth first strategy.
   * The filter function will be applied to every node visited that way.
   * The filter function is first applied to the parent node and then to the children.
   *
   * @param filter
   * @param self
   * @returns {boolean}
   */
  walkDepthFirstPre(filter: (node: Node) => any, self: boolean = true): boolean {
    let result;
    if (self) {
      result = filter(this);
    }
    if (result) {
      return result;
    }
    result = this.children.find((node: Node): boolean => {
      return node.walkDepthFirstPre(filter);
    });
    return result;
  }

  /**
   * Find a child by using a filter function
   * @param filter
   * @returns {Node} null if not found
   */
  findChild<T extends Node>(filter: (node: Node) => boolean): T {
    let child = null;
    this.walkDepthFirstPre((node: Node) => {
      if (filter(node)) {
        child = node;
        return true;
      }
    }, false);
    return <T>child;
  }

  /**
   * Find child by its id
   * Memoized, as it is called way too often.
   * @param id
   * @returns {Node} null if not found
   */
  findChildById<T extends Node>(id: string | number): T {
    if (this.findChildById[id]) {
      return this.findChildById[id];
    }

    const result: T = <T>this.findChild((node: Node): boolean => node.id === id);
    this.findChildById[id] = result;
    return result;
  }

  /**
   * Returns all leaves of the tree structure
   * @returns {T[]}
   */
  leaves<T extends Node>(): T[] {
    const leaves: T[] = [];
    this.walkDepthFirstPre((node: Node) => {
      if (node.children.length === 0) {
        leaves.push(<T>node);
      }
    }, false);
    return leaves;
  }

  /**
   * Returns the id of the node
   *
   * @returns {string|number}
   */
  get id(): string | number {
    return this._id;
  }

  /**
   * Set the id
   * @param value
   */
  set id(value: string | number) {
    this._id = value;
  }

  /**
   * Clones a node
   * @returns {Node}
   */
  clone(constructorFn: new (...args: any[]) => Node): Node {
    const clone = new constructorFn({ id: this._id });
    this.children.forEach((n: Node) => clone.addChild(n.clone(constructorFn)));
    return clone;
  }

  /**
   * Destroy this node
   */
  destroy(): void {
    this.children.forEach((node: Node) => node.destroy());
    this.children = [];
    this.parent = null;
  }
}
