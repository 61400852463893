/**
 * @deprecated
 *
 *  TODO: Remove this class after user settings migration is done.
 */
export interface ILocalStorareWrapper {
  /** Retrieves session info from the local storage */
  getProperty: (id: string) => any;
}

/** @ngInject */
export function localStorageFactory(): ILocalStorareWrapper {
  'use strict';

  const getUserIdentity = (): string => {
    // identify user from init call header, if available
    if (window['UserId'] && typeof window['UserId'] === 'string') {
      return window['UserId'].toLowerCase();
    } else {
      // identify user from session cookie, to use it for the local storage
      const value = '; ' + document.cookie;
      const parts = value.split('; SM_UID=');
      return parts.length === 2 ? parts.pop().split(';').shift() : null;
    }
  };

  return {
    getProperty(id: string): any {
      const usrIdentity = getUserIdentity();
      const usrStorage = usrIdentity ? localStorage.getItem(usrIdentity) : undefined;
      return usrStorage ? JSON.parse(usrStorage)[id] : undefined;
    },
  };
}
