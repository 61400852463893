import { ITabContent } from './tab.content.interface';

('use strict');

export interface ITab<T extends ITabContent> {
  id: string;
  iconCls?: string;
  /**
   * Computed title.
   */
  title?: string;
  content?: T;
  path?: string;
  active?: boolean;
  visible?: boolean;
  refreshable?: boolean;
  closeable?: boolean;
  waitForClose?: boolean;
  /**
   * For when a tab should have a given name.
   * This will override the computed title property.
   */
  providedTitle?: string;
  neverSelected: boolean;
  contextMenuExpanded?: boolean;
  loaded?: boolean;
}

/**
 * Class representing Tabs
 * @author Tobias Straller [Tobias.Straller.bp@nttdata.com]
 */
export class Tab<T extends ITabContent> implements ITab<T> {
  id: string;
  iconCls: string;
  content: T;
  path?: string;
  active: boolean = false;
  visible: boolean = true;
  refreshable: boolean = false;
  closeable: boolean = true;
  waitForClose: boolean = false;
  neverSelected: boolean = true;
  providedTitle: string;
  contextMenuExpanded: boolean = false;

  private _title: string;

  /**
   * @constructor
   * @param data
   * @ngInject
   */
  constructor(data: ITab<T>) {
    this.id = data.id;
    this.iconCls = data.iconCls;
    this.providedTitle = data.providedTitle;
    this.title = data.title || (<any>data)._title;
    this.content = data.content;
    this.path = data.path ? data.path : null;
    if (typeof data.active !== 'undefined') {
      this.active = data.active;
    }
    if (typeof data.visible !== 'undefined') {
      this.visible = data.visible;
    }
    if (typeof data.refreshable !== 'undefined') {
      this.refreshable = data.refreshable;
    }
    if (typeof data.closeable !== 'undefined') {
      this.closeable = data.closeable;
    }
    if (typeof data.waitForClose !== 'undefined') {
      this.waitForClose = data.waitForClose;
    }
  }

  set title(value: string) {
    this._title = this.providedTitle ? this.providedTitle : value;
  }

  get title(): string {
    return this._title;
  }
}
