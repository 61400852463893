import { Component } from '../component';

export class DialogHeader extends Component {
  closeHandler: Function;
  dialogTitle: string;
  icon: string;
  /**
   * Set by attribute
   * @type {boolean}
   */
  closeable: boolean;

  /**
   *
   * @ngInject
   */
  constructor() {
    super();
  }

  $onInit() {
    if (typeof this.closeable === 'undefined') {
      this.closeable = true;
    }
  }

  /**
   * Forwards the function call of the container.
   */
  closeButtonHandler(): void {
    this.closeHandler();
  }
}
