import { Dashboard } from './dashboard';
import { Component } from '../components/component';
import dashboardTemplate from './dashboard.html';
/**
 * Wraps {@link Dashboard} component into a directive.
 *
 * @author Tobias Straller [Tobias.Straller.bp@nttdata.com]
 *
 * @ngInject
 */
export function dashboardDirective(): ng.IDirective {
  'use strict';
  return Component.createDirective(Dashboard, {
    restrict: 'E',
    scope: {
      dashboardName: '@',
      dashboardParams: '=',
    },
    templateUrl: dashboardTemplate,
  });
}
