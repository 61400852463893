import { IApplication } from './application.model.interface';

/**
 * Interface describing an application frame
 *
 * @author Tobias Straller [Tobias.Straller.bp@nttdata.com]
 */
export interface IAppsFrame {
  id: string;
  connectionGroupId?: string;
  app: IApplication;
  /**
   * Will trigger visibility of the frame
   */
  visible: boolean;
  /**
   * As long as iframe is hidden, it cannot be visible
   */
  hidden?: boolean;
  relativePosition?: boolean;
  // defines the bounding rectangle, values are relative such as '100%'
  boundingBox: {
    top: number | string;
    left: number | string;
    bottom: number | string;
    right: number | string;
    'margin-left'?: number | string;
    'margin-top'?: number | string;
    width?: number | string;
    height?: number | string;
  };
  type: AppsFrameType;
}

export enum AppsFrameType {
  'APP',
  'LAYOUT',
  'DASHBOARD',
}
