import { Sidebar } from './sidebar';
import { Component } from '../component';
import sidebarTemplate from './sidebar.html';

/**
 * Wraps {@link Sidebar} component into a directive.
 *
 * @author Tobias Straller [Tobias.Straller.bp@nttdata.com]
 *
 * @ngInject
 */
export function sidebarDirective(): ng.IDirective {
  'use strict';
  return Component.createDirective(Sidebar, {
    restrict: 'E',
    scope: {
      customClass: '@',
      id: '@',
      _expanded: '=expanded',
      _autoClose: '=sidebarAutoClose',
    },
    transclude: true,
    templateUrl: sidebarTemplate,
  });
}
