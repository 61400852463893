import { Component } from '../component';
import {
  INewTaskData,
  ITaskColumn,
  ITaskFilterItem,
  ITaskFilters,
  ITaskItem,
  ITaskServiceMessage,
  SYSTEM_NAMES,
  TasksService,
} from '../../task/tasks.service';
import { ITask, ITaskType, TASK_SYSTEMS } from '../../task/tasks.model.interface';
import { DATE_FORMAT, DATE_FORMAT_PLACEHOLDERS, ILanguage } from '../../../util/language.model.interface';
import { IUser } from '../../user/user.model.interface';
import { UserService } from '../../user/user.service';
import { MenuService, IMenuServiceSubMenuMessage } from '../../menu/menu.service';
import { ISharingSearchSource } from '../../favorite/search-source.model.interface';
import { EmployeeService } from '../../employee/employee.service';
import { ISharingChip } from '../../favorite/favorite-chip.model.interface';
import { User } from '../../user/user.model';
import { NotificationService } from '../../notification/notification.service';
import { PopupService } from '../../notification/popup.service';
import { MenuOverlayService } from '../../menu/menu.overlay.service';
import { Devices, DeviceService } from '../../../util/device.service';
import { APP_SETTINGS } from '../../apps/application.model.interface';
import { AppsService } from '../../apps/apps.service';
import { TrackingService } from '../../feature-tracking/tracking.service';
import {
  CREATE_NEXTONE_TASK,
  MY_OPEN_TASKS_FEATURES,
  OPEN_MY_TASKS_DETAILS,
  OPEN_MY_TASKS_DETAILS_MENU,
} from './analytics/feature-analytics-conf';

/**
 * Created by MARIAB on 16.11.2015.
 */
export class Tasks extends Component {
  allTasks: ITaskItem[];
  taskTypes: ITaskType[];
  visibleTasks: ITaskItem[];
  appLocale: string;
  placeholder: string;
  format: string;
  columns: ITaskColumn[] = [
    {
      id: 'system_',
      name: 'widget.tasks.column.system',
      sortField: 'system',
      asc: true,
      sortActive: false,
    },
    {
      id: 'id_',
      name: 'widget.tasks.column.id',
      sortField: 'id_sort',
      asc: true,
      sortActive: false,
    },
    {
      id: 'title_',
      name: 'widget.tasks.column.title',
      sortField: 'title_',
      asc: true,
      sortActive: false,
    },
    {
      id: 'dueDate_',
      name: 'widget.tasks.column.dueDate',
      sortField: 'dueDate_',
      asc: false,
      sortActive: true,
    },
    {
      id: 'actions_',
      name: '',
      sortField: 'actions_',
      asc: false,
      sortActive: false,
    },
  ];
  filters: ITaskFilters = {
    systemFilters: [],
    dueDateFilters: {
      start: undefined,
      end: undefined,
    },
  };
  startDateOpen: boolean = false;
  endDateOpen: boolean = false;
  dateOptions: any = {
    showWeeks: false,
    startingDay: 1,
    closeOnDateSelection: true,
  };
  isFiltersOpen: boolean = false;
  isDeputizerOpen: boolean = false;
  deputies: IUser[] = [];
  selectedDeputy: IUser = null;
  activeFiltersCount: number;
  searchText: string;
  createTaskMode: boolean = false;
  selectedTaskResponsible: IUser = null;
  selectedTaskDate: Date = null;
  selectedTaskTitle: string = null;
  searchResponible: boolean = false;
  searchSource: ISharingSearchSource = null;
  searchTerm: string = '';
  taskDateOpen: boolean = false;
  taskTitlePlaceholder: string;
  isMobile: boolean = false;
  titleAutoFocus: boolean = false;
  scrollTop: boolean = false;
  loading: boolean = false;
  tableItemHeight = 32;
  contextMenuItemHeight = 50;
  distanceToPageBottom = 100;

  private UNASIGNED: IUser = {
    uid: null,
    name: '',
    surname: '',
    userId: 'NONE',
    email: null,
    phone: null,
    external: false,
    strongAuth: null,
    department: null,
  };

  private _tasksService: TasksService;
  private _userService: UserService;
  private _menuService: MenuService;
  private _translateService: angular.translate.ITranslateService;
  private _subscriptions: ISubscriptionDefinition<any>[] = [];
  private _timeout: ng.ITimeoutService;
  private _employeeService: EmployeeService;
  private _accountDeletedText: string;
  private _user: User;
  private _notificationService: NotificationService;
  private _location: ng.ILocationService;
  private _popupService: PopupService;
  private _menuOverlayService: MenuOverlayService;
  private _deviceService: DeviceService;
  private _appsService: AppsService;
  private _taskChannel: IChannelDefinition<ITaskServiceMessage>;
  private _postal: IPostal;

  /**
   * @ngInject
   */
  constructor(
    tasksService: TasksService,
    language: ILanguage,
    userService: UserService,
    $translate: angular.translate.ITranslateService,
    menuService: MenuService,
    employeeService: EmployeeService,
    $timeout: ng.ITimeoutService,
    notificationService: NotificationService,
    $location: ng.ILocationService,
    popupService: PopupService,
    menuOverlayService: MenuOverlayService,
    deviceService: DeviceService,
    appsService: AppsService,
    postal: IPostal,
    private readonly trackingService: TrackingService
  ) {
    super();
    this._postal = postal;
    this._location = $location;
    this._popupService = popupService;
    this._employeeService = employeeService;
    this._notificationService = notificationService;
    this._timeout = $timeout;
    this._tasksService = tasksService;
    this._userService = userService;
    this._menuService = menuService;
    this._translateService = $translate;
    this._menuOverlayService = menuOverlayService;
    this._deviceService = deviceService;
    this.appLocale = language.locale;
    this._appsService = appsService;
    this.placeholder = DATE_FORMAT_PLACEHOLDERS[language.locale.toLowerCase()] || '';
    this.format = DATE_FORMAT[language.locale.toLowerCase()] || '';
    this.setUpAnalytics();
  }

  $onInit(): void {
    this._userService.getUserDeputies().then((deputies: IUser[]) => (this.deputies = deputies));
    this._tasksService.getTaskTypes().then((taskTypes: ITaskType[]) => {
      this.taskTypes = taskTypes;
      this.updateData();
      this.updateOnShow();
    });

    this._taskChannel = this._postal.channel<ITaskServiceMessage>(TasksService.CHANNEL_TASKS);

    this._subscriptions.push(
      this._taskChannel.subscribe(TasksService.UPDATED_DEPUTIES, () => {
        this._userService.getUserDeputies().then((deputies: IUser[]) => {
          this.deputies = deputies;
          /** update display for global deputy - check if it's still valid */
          this.selectedDeputy = this.deputies.some(d => d.userId === this.selectedDeputy.userId)
            ? this.selectedDeputy
            : null;
        });
      })
    );
    this._subscriptions.push(
      this._tasksService.channel.subscribe(TasksService.TOPIC_TASKS_UPDATE, (data: ITaskServiceMessage) => {
        if (data.addedTask) {
          this.visibleTasks.unshift(data.addedTask);
        } else {
          this.updateData();
        }
      })
    );
    this._subscriptions.push(
      this._menuOverlayService.channel.subscribe(MenuOverlayService.MENU_OVERLAY_CLOSED, () => {
        this.resetTaskForm();
      })
    );
    this._subscriptions.push(
      this._taskChannel.subscribe(TasksService.TOPIC_TASKS_UPDATE, () => {
        this.filters = this.buildFilters(this.allTasks);
      })
    );
    this._translateService([
      'tooltip.user.account.deleted',
      'sidebar.addTask.title.placeholder',
      'sidebar.addTask.user.unasigned',
    ]).then((t: { [key: string]: string }) => {
      this._accountDeletedText = t['tooltip.user.account.deleted'];
      this.taskTitlePlaceholder = t['sidebar.addTask.title.placeholder'];
      this.UNASIGNED.name = t['sidebar.addTask.user.unasigned'];
    });
    this._userService.getUser().then((user: User) => {
      this._user = user;
      this.selectedTaskResponsible = user;
      this.searchTerm = this.selectedTaskResponsible ? this.getLabelForUser(this.selectedTaskResponsible) : '';
    });
    this.createTaskMode = false;
    this.initTaskResponsible();
    this.isMobile = this._deviceService.device === Devices.MOBILE;
  }

  private setUpAnalytics(): void {
    this.trackingService.getTracker().initFeatures(MY_OPEN_TASKS_FEATURES);
  }

  /**
   * Show 'no tasks' if there are no tasks but the service is available
   * or 'service unavailable' if tasks cannot be fetched from backend
   */
  get emptyTasksPanelMessage(): string {
    return this._tasksService.comError ? 'tasks.tasksServiceUnavailable' : 'tasks.noTasks';
  }

  parseData(tasks: ITask[]): any[] {
    if (!tasks) {
      return [];
    }

    return tasks.map((task: ITask) =>
      Object.assign({}, task, {
        // TODO properties??
        system_: this._tasksService.getSystemIcon(task.system),
        id_:
          task.system === TASK_SYSTEMS.NEXTONE && (task.parentId || task.type === 'subtask')
            ? `${task.parentId}-${task.id}`
            : task.id,
        id_sort: this.getSortId(task),
        title_: task.taskTitle,
        dueDate_: task.taskDue,
        confidential_: task.confidential,
      })
    );
  }

  filterData(tasks: ITaskItem[]): ITaskItem[] {
    let filteredData = [...tasks];
    if (this.filters.dueDateFilters.start) {
      filteredData = filteredData.filter((t: ITaskItem) => t.dueDate_ >= this.filters.dueDateFilters.start.getTime());
    }
    if (this.filters.dueDateFilters.end) {
      filteredData = filteredData.filter((t: ITaskItem) => t.dueDate_ <= this.filters.dueDateFilters.end.getTime());
    }
    const systems = this.filters.systemFilters
      .filter((s: ITaskFilterItem) => s.isSelected)
      .map((s: ITaskFilterItem) => s.name);
    filteredData = filteredData.filter((t: ITaskItem) => systems.indexOf(t.system) !== -1);

    if (this.searchText) {
      filteredData = filteredData.filter(
        (t: ITaskItem) =>
          (t.id_ && t.id_.toLowerCase().indexOf(this.searchText.toLowerCase()) !== -1) ||
          (t.title_ && t.title_.toLowerCase().indexOf(this.searchText.toLowerCase()) !== -1)
      );
    }

    return filteredData;
  }

  toggleSort(col: ITaskColumn): void {
    col.asc = col.sortActive ? !col.asc : false;
    this.sortData(col);
  }

  sortData(col: ITaskColumn): void {
    this.columns = this.columns.map((c: ITaskColumn) => {
      return c.id === col.id ? Object.assign(c, { sortActive: true }) : Object.assign(c, { sortActive: false });
    });
    this.visibleTasks = this.sort(this.visibleTasks, col.sortField, col.asc);
  }

  handleRowClick(task: ITask, event: MouseEvent, fromMenu?: boolean): void {
    event.stopPropagation();

    if (!fromMenu) {
      this.trackingService.getTracker().trackFeature(OPEN_MY_TASKS_DETAILS, 1);
    }

    if (this.selectedDeputy) {
      this._tasksService.openTask(task, {}, this.selectedDeputy.userId);
    } else {
      this._tasksService.openTask(task);
    }
    this._menuService.hideActiveSubMenu();
  }

  openStartDatepicker(): void {
    this.endDateOpen = false;
    this.startDateOpen = true;
  }

  openEndDatepicker(): void {
    this.startDateOpen = false;
    this.endDateOpen = true;
  }

  getSystemName(system: string): string {
    return SYSTEM_NAMES[system] ? SYSTEM_NAMES[system] : system;
  }

  getSystemIcon(system: string): string {
    return this._tasksService.getSystemIcon(system);
  }

  resetFilterValues(): void {
    this.filters = this.buildFilters(this.allTasks, true);
    this.activeFiltersCount = 0;
    this.visibleTasks = this.filterData(this.allTasks).map((t: ITaskItem) => {
      t.contextMenu = false;
      return t;
    });
    this.sortData(this.defaultSortColumn());
  }

  applyFilterValues(): void {
    this.visibleTasks = this.filterData(this.allTasks).map((t: ITaskItem) => {
      t.contextMenu = false;
      return t;
    });
    this.activeFiltersCount = 0;
    for (const f in this.filters) {
      if (this.filters[f]) {
        this.activeFiltersCount += this.countActive(f);
      }
    }
    this.sortData(this.defaultSortColumn());
    this.isFiltersOpen = false;
  }

  onSearch(): void {
    this.visibleTasks = this.filterData(this.allTasks).map((t: ITaskItem) => {
      t.contextMenu = false;
      return t;
    });
    this.sortData(this.defaultSortColumn());
  }

  selectDeputy(deputy: IUser): void {
    this.selectedDeputy = deputy;
    this.scrollTop = true;
    this.loading = true;
    this._tasksService.setSidebarTasksDeputy(deputy);
    this._tasksService.getTasks().then(() => {
      this.updateData();
      this.loading = false;
      this._timeout(() => (this.scrollTop = false));
    });
    this.isDeputizerOpen = false;
  }

  getLabelForUser(user: IUser): string {
    if (!user) {
      return '';
    }
    if (this.getLabelForUser[user.userId]) {
      return this.getLabelForUser[user.userId];
    }

    const fullName = `${user.name} ${user.surname}`.trim();
    if (user.external) {
      const dep = this._translateService.instant('sidebar.deputizer.external.department', {
        department: user.department,
      });
      this.getLabelForUser[user.userId] = `${fullName}, ${dep}`;
      return `${fullName}, ${dep}`;
    }

    this.getLabelForUser[user.userId] = user.department ? `${fullName}, ${user.department}` : `${fullName}`;
    return user.department ? `${fullName}, ${user.department}` : `${fullName}`;
  }

  clearStartDate(): void {
    this.filters.dueDateFilters.start = null;
    this.startDateOpen = false;
  }

  clearEndDate(): void {
    this.filters.dueDateFilters.end = null;
    this.endDateOpen = false;
  }

  /**
   * ADD TASK FUNCTIONALITY
   */
  // Show add task form (for desktop) or modal (for mobile)
  showAddTask(event: MouseEvent): void {
    event.stopPropagation();
    if (this.isMobile) {
      // Mobile view
      this._popupService.showModalWindow('appModalsCreateTask', {}, false, 'full-screen');
    } else {
      // Desktop
      this.createTaskMode = true;
      this.titleAutoFocus = true;
    }
  }

  // Responsible
  enableSearchResponsible(event: MouseEvent): void {
    event.stopPropagation();
    this.searchResponible = true;
    this.doSearchResponsible();
  }

  doSearchResponsible(): void {
    this.searchSource.searchFunction(this.searchTerm);
  }

  initTaskResponsible(): void {
    this.searchSource = {
      iconCls: 'iwp-icon-gen_user',
      text: 'dialogs.favorite.share.search.for.person.text',
      placeholder: 'dialogs.favorite.share.person.search.placeholder',
      searchFunction: this.searchUsers.bind(this),
      createChip: (): ng.IPromise<ISharingChip> => {
        return;
      },
      updateSharedObject: (): void => {
        return;
      },
      tableConfig: {
        headers: [
          {
            title: 'dialogs.favorite.share.search.table.column.header.name',
            dataField: (user: IUser): string => this.getLabelForUser(user),
            dataIconCls: (user: IUser): string => (user.qaccount ? 'iwp-icon-gen_user_add' : 'iwp-icon-gen_user_c'),
          },
        ],
        data: [],
        disableCondition: this.hasQNumber.bind(this),
        tooManyUsersMessage: 'sidebar.addTask.responsible.tooManyResults',
        nbDisplayedResults: 5,
      },
    };
  }

  searchUsers(term: string, scope: string): void {
    if (!term || term === '') {
      this._timeout(() => (this.searchSource.tableConfig.data = null), 0);
      return;
    }

    if (term.length < 3) {
      return;
    }
    this._employeeService.findEmployees(
      {
        searchTerm: term,
      },
      (users: IUser[]) => {
        this.searchSource.tableConfig.data = [];
        users.forEach((user: IUser) => this.searchSource.tableConfig.data.push(user));
      }
    );
  }

  resetSearch(): void {
    this.searchTerm = this.selectedTaskResponsible ? this.getLabelForUser(this.selectedTaskResponsible) : '';
    this.searchResponible = false;
    this.searchSource.tableConfig.data = [];
  }

  handleEntryClicked(entry: any): void {
    if (this.searchSource.tableConfig.disableCondition(entry)) {
      return;
    }
    this.selectedTaskResponsible = entry;
    this.searchResponible = false;
    this.searchSource.tableConfig.data = [];
    this.searchTerm = this.selectedTaskResponsible ? this.getLabelForUser(this.selectedTaskResponsible) : '';
  }

  showRemoveResponsibleButton(): boolean {
    return !this.searchResponible && this.selectedTaskResponsible.userId !== 'NONE';
  }

  removeResponsible(event: any): void {
    event.stopPropagation();
    this.selectedTaskResponsible = this.UNASIGNED;
    this.searchTerm = this.selectedTaskResponsible ? this.getLabelForUser(this.selectedTaskResponsible) : '';
  }

  // Datepicker
  openTaskDatepicker(): void {
    this.taskDateOpen = true;
  }

  clearTaskDatepicker(event: MouseEvent): void {
    event.stopPropagation();
    this.selectedTaskDate = null;
    this.taskDateOpen = false;
  }

  // Title
  clearTaskTitle(event: MouseEvent): void {
    event.stopPropagation();
    this.selectedTaskTitle = null;
  }

  // Task form actions
  cancelTaskAdd(event: MouseEvent): void {
    event.stopPropagation();
    this.resetTaskForm();
  }

  saveTaskAdd(event: MouseEvent): void {
    event.stopPropagation();
    const taskObject: INewTaskData = {
      title: this.selectedTaskTitle,
      date: this.selectedTaskDate,
      responsible: this.selectedTaskResponsible,
      attachment: null,
    };

    this._tasksService.createTask(taskObject).then(() => this.resetTaskForm());
  }

  resetTaskForm(): void {
    this.selectedTaskTitle = null;
    this.selectedTaskDate = null;
    this.selectedTaskResponsible = this._user;
    this.searchSource.tableConfig.data = [];
    this.searchTerm = this.selectedTaskResponsible ? this.getLabelForUser(this.selectedTaskResponsible) : '';
    this.searchResponible = false;
    this.createTaskMode = false;
    this.titleAutoFocus = false;
  }

  onKeyDown(event: any): void {
    if (event.key === 'Escape') {
      this.cancelTaskAdd(event);
      return;
    }

    if (event.key !== 'Enter' || !this.selectedTaskTitle) {
      return;
    }
    this.saveTaskAdd(event);
  }

  /**
   * Link to task subscription
   */
  taskSubscriptionLink(): void {
    this._appsService.openAppByName({
      name: APP_SETTINGS,
      path: 'settings/taskSubscriptions',
    });
  }

  /**
   * KONTEXT MENU
   * @param event
   * @param item
   */
  toggleContextMenu(event: MouseEvent, item: ITaskItem): void {
    event.stopPropagation();
    event.preventDefault();
    /** hide filter dropdown */
    this.isFiltersOpen = false;
    /** reset all visible tasks context and then toggle the current task context */
    let numberOfExtraElements = 0;
    this.visibleTasks.map((t: ITaskItem) => {
      if (t.id_ && t.id_ === item.id_) {
        t.contextMenu = !t.contextMenu;
        if (t.system === 'jira' || (t.system === 'jira_secure' && !t.confidential)) {
          numberOfExtraElements = 1;
        }
      } else {
        t.contextMenu = false;
      }
      return t;
    });

    const contextMenu: HTMLElement = <HTMLElement>document.getElementById('context-menu-' + item.id_);

    if (
      window.innerHeight - event.clientY <=
      this.distanceToPageBottom + this.tableItemHeight * numberOfExtraElements
    ) {
      contextMenu.style.top = `-${this.contextMenuItemHeight + this.tableItemHeight * numberOfExtraElements}px`;
    } else {
      contextMenu.style.top = `${this.tableItemHeight}px`;
    }
  }

  editNextoneTask(event: MouseEvent, item: ITaskItem): void {
    event.stopPropagation();
    event.preventDefault();
    this.trackingService.getTracker().trackFeature(OPEN_MY_TASKS_DETAILS_MENU, 1);

    // same functionality as handleRowClick
    this.handleRowClick(item, event, true);
    // close dropdown
    this.visibleTasks.map((t: ITaskItem) => {
      t.contextMenu = false;
      return t;
    });
  }

  addJiraNextoneTask(event: MouseEvent, item: ITaskItem): void {
    event.stopPropagation();
    event.preventDefault();
    this.trackingService.getTracker().trackFeature(CREATE_NEXTONE_TASK, 1);
    // call open jira task depending on deputy
    if (this.selectedDeputy) {
      this._tasksService.openJiraTask(item, {}, this.selectedDeputy.userId);
    } else {
      this._tasksService.openJiraTask(item);
    }
    this._menuService.hideActiveSubMenu();
    // close dropdown
    this.visibleTasks.map((t: ITaskItem) => {
      t.contextMenu = false;
      return t;
    });
  }

  /**
   * Destroy the component
   */
  destroy(): void {
    this._tasksService = null;
    this._subscriptions.forEach((subscription: ISubscriptionDefinition<any>): void => subscription.unsubscribe());
  }

  setFilterOff(): void {
    this.isFiltersOpen = false;
  }

  /**
   * Ensure that the tasks are beeing updated when the submenu is shown.
   */
  private updateOnShow(): void {
    this._tasksService.updateTasks();
    this._subscriptions.push(
      this._postal
        .channel(MenuService.CHANNEL_SUBMENU)
        .subscribe(MenuService.TOPIC_SUBMENU_SHOWN, (message: IMenuServiceSubMenuMessage) => {
          if (message.menuItem.name === 'tasks') {
            this._tasksService.updateTasks();
          }
        })
    );
  }

  private updateData(): void {
    this.allTasks = this.parseData(this._tasksService.tasks);
    this.filters = this.buildFilters(this.allTasks);
    this.applyFilterValues();
  }

  private countActive(filterId: string): number {
    let count = 0;
    if (filterId === 'dueDateFilters' && !!this.filters[filterId].start) {
      count++;
    }
    if (filterId === 'dueDateFilters' && !!this.filters[filterId].end) {
      count++;
    }
    if (filterId === 'systemFilters') {
      count += this.filters[filterId].filter((s: ITaskFilterItem) => s.isSelected).length;
    }
    return count;
  }

  // build filter lists; filter panel only shows systems that are available in the response
  // pass rest = true if you want to reset the filters
  private buildFilters(tasks: ITaskItem[], reset: boolean = false): ITaskFilters {
    return {
      // first I check if the filter was manualy set and then I return the new array of systemFilters
      systemFilters: this.availableSystems(tasks).map((s: string) => {
        const filter = this.filters.systemFilters.find(item => item.name === s);
        const taskType = this.taskTypes.find(type => type.name === s);
        return {
          name: s,
          isSelected: !filter || !!filter.isSelected || reset,
          label: taskType.label,
          description: taskType.description,
          iconName: taskType.iconName,
        };
      }),
      dueDateFilters: {
        start: undefined,
        end: undefined,
      },
    };
  }

  private availableSystems(tasks: ITaskItem[]): string[] {
    return tasks.reduce((systems: string[], t: ITaskItem) => {
      if (t['system'] && systems.indexOf(t['system']) === -1) {
        systems.push(t['system']);
      }
      return systems;
    }, []);
  }

  private sort(value: any[], key: any, reverse?: boolean): any[] {
    const array: any[] = value.sort((a: any, b: any): number => {
      if (b[key] && (a[key] === null || a[key] === undefined)) {
        return 1;
      }
      if (a[key] && (b[key] === null || b[key] === undefined)) {
        return -1;
      }
      // if searching by dueDate and 2 tasks have the same date sort by id
      if (key === 'dueDate_' && a[key] === b[key]) {
        return this.naturalSort(a['id_sort'], b['id_sort']);
      }
      return this.naturalSort(a[key], b[key]);
    });

    if (reverse) {
      return array.reverse();
    }

    return array;
  }

  private naturalSort(
    a: any,
    b: any,
    lang: string = 'en',
    options: any = {
      numeric: true,
      sensitivity: 'base',
    }
  ): number {
    const collator = new Intl.Collator(lang, options);
    return collator.compare(a, b);
  }

  private getSortId(task: ITask): string {
    if (task.system === TASK_SYSTEMS.NEXTONE) {
      return task.parentId || task.type === 'subtask' ? `${task.parentId}.${task.id}` : task.id;
    }
    return task.id;
  }

  private defaultSortColumn(): ITaskColumn {
    return (
      this.columns.find((c: ITaskColumn) => c.sortActive) || this.columns.find((c: ITaskColumn) => c.id === 'dueDate_')
    );
  }

  private hasQNumber(user: IUser): boolean {
    return !(user && user !== null && User.prototype.hasQNumber.call(user));
  }
}
