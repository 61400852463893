import { IAnalyticsFeatureConfig } from '@myworkplace/api';

export const TAB = 'tab';
export const DASHBOARD_ADDICIONAL_OPTIONS = 'dashboardAddicionalOptions';
export const APP_ADDICIONAL_OPTIONS = 'appAddicionalOptions';
export const APP_COPY_LINK = 'appCopyLink';
export const APP_HELP = 'appHelp';
export const DASHBOARD_COPY_LINK = 'dashboardCopyLink';
export const DASHBOARD_CREATE_LINK = 'dashboardCreateLink';
export const DASHBOARD_ADD_NEW_CATEGORY = 'dashboardAddNewCategory';
export const DASHBOARD_OPEN_STORE_WIDGET = 'dashboardOpenStoreWidget';
export const DASHBOARD_RENAME_DASHBOARD = 'dashboardRenameDashboard';
export const DASHBOARD_RESET_DASHBOARD = 'dashboardResetingDashboard';
export const DASHBOARD_SHARING_DASHBOARD = 'dashboardSharingDashboard';
export const DASHBOARD_EDITING_BACKGROUND_IMAGE = 'dashboardEditingBackgroundImage';
export const DRAG_AND_DROP_SPLIT_SCREEN = 'dragAndDropSplitScreen';

export const TAB_FEATURES = new Map<string, IAnalyticsFeatureConfig>([
  [
    APP_ADDICIONAL_OPTIONS,
    {
      category: [TAB],
      name: 'Opening Application Addicional Options',
      steps: ['Open Application Addicional Options'],
    },
  ],
  [
    DASHBOARD_ADDICIONAL_OPTIONS,
    {
      category: [TAB],
      name: 'Opening Dashboard Addicional Options',
      steps: ['Open Dashboard Addicional Options'],
    },
  ],
  [
    APP_COPY_LINK,
    {
      category: [TAB],
      name: 'Copying App Link',
      steps: ['Copy App Link'],
    },
  ],
  [
    APP_HELP,
    {
      category: [TAB],
      name: 'App Help',
      steps: ['App Help'],
    },
  ],
  [
    DASHBOARD_COPY_LINK,
    {
      category: [TAB],
      name: 'Copying Dashboard Link',
      steps: ['Copy Dashboard Link'],
    },
  ],
  [
    DASHBOARD_CREATE_LINK,
    {
      category: [TAB],
      name: 'Creating Dashboard Link',
      steps: ['Create Dashboard Link'],
    },
  ],
  [
    DASHBOARD_ADD_NEW_CATEGORY,
    {
      category: [TAB],
      name: 'Adding Dashboard New Category',
      steps: ['Add Dashboard New Category'],
    },
  ],
  [
    DASHBOARD_OPEN_STORE_WIDGET,
    {
      category: [TAB],
      name: 'Opening Store Widget',
      steps: ['Open Store Widget'],
    },
  ],
  [
    DASHBOARD_RENAME_DASHBOARD,
    {
      category: [TAB],
      name: 'Renaming Dashboard',
      steps: ['Rename Dashboard'],
    },
  ],
  [
    DASHBOARD_RESET_DASHBOARD,
    {
      category: [TAB],
      name: 'Reseting Dashboard',
      steps: ['Reset Dashboard'],
    },
  ],
  [
    DASHBOARD_SHARING_DASHBOARD,
    {
      category: [TAB],
      name: 'Sharing Dashboard',
      steps: ['Share Dashboard'],
    },
  ],
  [
    DASHBOARD_EDITING_BACKGROUND_IMAGE,
    {
      category: [TAB],
      name: 'Editing Dashboard Image',
      steps: ['Edit Dashboard Image'],
    },
  ],
  [
    DRAG_AND_DROP_SPLIT_SCREEN,
    {
      category: [TAB],
      name: 'Split screen through Drag and Drop',
      steps: ['Split screen through Drag and Drop'],
    },
  ],
]);
