/**
 * Directive for file uploads
 *
 * @returns {ng.IDirective}
 * @ngInject
 */
export function fileUploadDirective(): ng.IDirective {
  'use strict';
  return {
    restrict: 'A',
    scope: {
      utilUploadChange: '&',
    },
    link<T>($scope: any, $element: JQuery, attrs: ng.IAttributes): void {
      $element.on('change', (event: JQueryEventObject) => {
        $scope.$apply(() => {
          $scope.utilUploadChange({ $event: event });
        });
      });
      $scope.$on('$destroy', function (): void {
        $element.off();
      });
    },
  };
}
