import { IUser } from '../../user/user.model.interface';
import { IGroup } from '../../groups/group.model.interface';
import { IDialogConfig } from './dialog-config.interface';
/**
 * Created by MUELW2 on 19.06.2017.
 */

export interface IShareDialogConfig extends IDialogConfig {
  id: string;
  title: string;
  /** possible values: "widget", "dashboard", "application-store-item", "widget-store-item" */
  shareType: string;
}

export interface IShareObject {
  id: string;
  title: string;
  /** possible values: "view" or "edit" */
  scope: string;
  /** possible values: "widget", "dashboard", "application-store-item", "widget-store-item" */
  shareType: string;
  users: IUser[];
  groups: IGroup[];
}

export enum ShareType {
  APPLICATION = 'application',
  DASHBOARD = 'dashboard',
  STORE_ITEM = 'STORE-ITEM',
  WIDGET = 'widget',
}
