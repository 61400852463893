import { LayoutService } from '../../app/layout/layout.service';
import 'angular-ui-bootstrap';
/**
 * Directive to initialize bootstrap popovers
 *
 * @returns {ng.IDirective}
 * @ngInject
 */
export function popoverDirective(
  $compile: ng.ICompileService,
  $templateRequest: ng.ITemplateRequestService,
  $templateCache: ng.ITemplateCacheService,
  layoutService: LayoutService
): ng.IDirective {
  'use strict';
  return {
    restrict: 'A',
    scope: {
      utilBootstrapPopoverViewModel: '=',
      utilBootstrapPopoverTemplate: '=',
    },
    link<T>($scope: any, el: JQuery, attr: ng.IAttributes): void {
      let resizeSubscription;
      let hidePopoversSubscription;

      $templateRequest($scope.utilBootstrapPopoverTemplate)
        .then((template: string) => {
          $templateCache.put($scope.utilBootstrapPopoverTemplate, template);
          $scope.vm = $scope.utilBootstrapPopoverViewModel;

          const templateAsString = $templateCache.get<string>($scope.utilBootstrapPopoverTemplate);
          const compiledTemplate: any = $compile(templateAsString)($scope);

          // [MWP-6686]->[MWP-9080] Compiled template should be serverd for bindings to work
          // article: https://stackoverflow.com/questions/23710382/angularjs-bootstrap-popover-data-binding-not-working
          el.popover({
            html: true,
            content: compiledTemplate,
            viewport: { selector: 'body', padding: 5 },
          });

          el.on('click', (event: JQueryEventObject) => {
            if (el.attr('aria-describedby')) {
              el.popover('hide');
              return false;
            } else {
              el.focus();
            }
          });
          resizeSubscription = layoutService.channelResize.subscribe(LayoutService.TOPIC_RESIZE, () => el.popover('hide'));
          /**
           * Hide popover when a widget-control
           */
          hidePopoversSubscription = layoutService.channelEvent.subscribe(LayoutService.TOPIC_EVENT_HIDE_POPOVERS, () =>
            el.popover('hide')
          );
        })
        .catch(error => console.log(error));

      el.on('$destroy', () => {
        el.popover('destroy');

        if (!!resizeSubscription) {
          resizeSubscription.unsubscribe();
        }

        if (!!hidePopoversSubscription) {
          hidePopoversSubscription.unsubscribe();
        }
      });
    },
  };
}
