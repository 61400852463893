import { Component } from '../components/component';
import { MenuOverlayService } from './menu.overlay.service';
import Tether from 'tether';

/**
 * @author Tobias Straller [Tobias.Straller.bp@nttdata.com]
 */
export class MenuOverlay extends Component {
  static CHANNEL: string = 'MENU_OVERLAY_CHANNEL';
  static TOPIC_HEADER_CLICKED: string = 'MENU_OVERLAY_TOPIC_HEADER_CLICKED';

  menuContent: {
    template: string;
    vm?: any;
    // a function that returns a valid css inline style
    customStyle?: () => any;
  };

  /**
   * Set by attribute
   */
  customClass: string;

  label: string;

  isSubmenu: boolean = false;

  channel: IChannelDefinition<void>;

  private _tether: Tether;
  private _el: JQuery;
  private _animateService: angular.animate.IAnimateService;
  private _timeoutService: ng.ITimeoutService;
  private _qService: ng.IQService;
  private _menuOverlayService: MenuOverlayService;

  /**
   * @ngInject
   */
  constructor(
    $animate: angular.animate.IAnimateService,
    postal: IPostal,
    $timeout: ng.ITimeoutService,
    $q: ng.IQService,
    menuOverlayService: MenuOverlayService
  ) {
    super();
    this._animateService = $animate;
    this.channel = postal.channel<void>(MenuOverlay.CHANNEL);
    this._timeoutService = $timeout;
    this._qService = $q;
    this._menuOverlayService = menuOverlayService;
  }

  static customTetherPositioning(options: any): void {
    const $el = $((<any>this).element);
    if ($el.hasClass('app-menu-MenuOverlay')) {
      $el.css('height', $(document.body).height() - options.top);
    }
  }

  show(targetEl: JQuery, targetAttachment: string, attachment: string = 'top left', animate: boolean = true): ng.IPromise<void> {
    const deferred = this._qService.defer<void>();
    this._timeoutService(() => {
      this._el.css('display', 'inherit');
      // add custom class for each menu based on used template
      const template = this.menuContent ? this.menuContent.template.split('>')[0].toString() : '';
      const customClass = 'custom-menu-overlay-' + template.replace(/</g, '');
      const customStyle = this.menuContent && this.menuContent.customStyle ? this.menuContent.customStyle() : undefined;
      this._el.addClass(customClass);

      this._el.css(customStyle || {});
      animate
        ? this._animateService
            .addClass(this._el.find('.container-fluid').first(), 'animate-show')
            .then(() => this._el.find('.side-button').first().addClass('is-visible'))
        : this._el.find('.container-fluid').first().addClass('animate-show');

      this._tether = new Tether({
        element: this._el.get(0),
        attachment: attachment,
        target: targetEl.get(0),
        targetAttachment: targetAttachment,
        constraints: [
          {
            to: $('.overlay-container').get(0),
            pin: ['right', 'left'],
            attachment: 'together',
          },
        ],
      });
      deferred.resolve();
    });
    return deferred.promise;
  }

  hide(): void {
    if (!this._el) {
      return;
    }
    this._el.find('.side-button').removeClass('is-visible');
    this._animateService.removeClass(this._el.find('.container-fluid'), 'animate-show').then(() => {
      this._el.css('display', 'none');
      this.positionTether();
      this._menuOverlayService.closedMenuOverlay();
    });
  }

  /**
   *
   * @param $event
   */
  handleHeaderClick($event: JQueryEventObject): void {
    this.channel.publish(MenuOverlay.TOPIC_HEADER_CLICKED);
    $event.stopPropagation();
    this.hide();
  }

  onRenderComponent(el: JQuery, $scope: ng.IScope): void {
    this._el = el;
  }

  destroy(): void {
    if (this._tether) {
      this._tether.destroy();
    }
    this._tether = null;
    this._el = null;
    this._animateService = null;
    this._timeoutService = null;
    this._qService = null;
  }

  positionTether(): void {
    if (this._tether) {
      this._tether.position();
    }
  }
}
