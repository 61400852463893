import { Component } from '../components/component';
import { IWidgetData } from './widget.data.interface';
import { IWidgetServiceMessage, WidgetService } from './widget.service';
import { WorkplaceApiService } from '../workplace/workplace.api.service';
import { WorkplaceContextService } from '../workplace/workplace.context.service';
import { IDiffValue, IRole } from '../user/user.model.interface';
import { UserService } from '../user/user.service';
import { INotificationService } from '../notification/notification.service.interface';
import { IHttpServerError } from '../errors/http.server.error.interface';
import { IWidgetDescriptor } from './widget.descriptor.model';
import { ActionLogService } from '../actionLog/action-log.service';
import { WidgetControlsDisplayAdapter } from './widget.controls.display.adapter';
//add legent template to cache
import './widget.legend.html';
import _ from 'lodash';
import { IUserSettingsStoreable } from '../workplace/user-settings-storeable.interface';

/**
 * Base implementation for widget
 *
 * @author Tobias Straller [Tobias.Straller.bp@nttdata.com]
 */
export abstract class Widget<T> extends Component implements IUserSettingsStoreable {
  /**
   * Set by attribute
   * Name of the dashboard that hosts this widget instance
   */
  dashboardName: string;

  /**
   * Set by attribute
   */
  dashboardContext: any;

  /**
   * Set by attribute
   */
  categoryId: string;

  /**
   * Set by attribute
   */
  contextDiffValues: IDiffValue[];

  /** set by attribute, indicates if the widget is opened in an workplace modal to be displayed as 'maximized' */
  maximized: boolean;

  /**
   * Set by attribute
   * Used to identify this widget instance on the dashboard
   */
  instanceId: string;

  /**
   * Set by attribute
   */
  definition: IWidgetDescriptor<any>;
  /**
   * Set by attribute.
   * Will be called when rendered.
   */
  onRender: (params: { id: string; widget: Widget<T> }) => void;
  /**
   * Set by attribute.
   * Will be called when destroyed
   */
  onDestroy: (params: { id: string; widget: Widget<T> }) => void;
  /**
   * The dom element for this widget
   * @type {JQuery}
   */
  el: JQuery;

  /**
   * Trigger loading state
   */
  loading: boolean = false;

  /**
   * Cached data from widget data service
   */
  data: IWidgetData;

  error: string;

  noData: string;

  settingsStorageKey = 'widget';

  workplaceApiService: WorkplaceApiService;
  workplaceContextService: WorkplaceContextService;
  translationService: angular.translate.ITranslateService;
  actionLogService: ActionLogService;
  mergedContext: { [key: string]: IDiffValue[] } = {};

  protected _timeoutService: ng.ITimeoutService;
  protected _userService: UserService;
  protected ctrlAdapterBuilder: () => void;
  protected _notificationService: INotificationService;
  private _promiseRender: ng.IPromise<void>;
  protected _widgetService: WidgetService;
  private widgetControlsAdapter: WidgetControlsDisplayAdapter;
  private _subscriptions: ISubscriptionDefinition<any>[];
  private _unbinders: Function[];

  /**
   *
   * @param $timeoutService
   * @ngInject
   */
  constructor(
    $timeout: ng.ITimeoutService,
    widgetService: WidgetService,
    workplaceApiService: WorkplaceApiService,
    workplaceContextService: WorkplaceContextService,
    userService: UserService,
    notificationService: INotificationService,
    $translate: angular.translate.ITranslateService,
    actionLogService: ActionLogService
  ) {
    super();
    this._timeoutService = $timeout;
    this._widgetService = widgetService;
    this.workplaceApiService = workplaceApiService;
    this.workplaceContextService = workplaceContextService;
    this._userService = userService;
    this._notificationService = notificationService;
    this.translationService = $translate;
    this.actionLogService = actionLogService;
    this._subscriptions = [];
    this._unbinders = [];
    /**
     * Called whenever definition or data changes are detected. Therefore give it some time.
     */
    this.ctrlAdapterBuilder = _.throttle(() => {
      this.widgetControlsAdapter = new WidgetControlsDisplayAdapter(this.definition, this.data);
    }, 1000);
  }

  get title(): string {
    return this.definition ? this.definition.settings.title || this.definition.title : '';
  }

  get multiLineTitle(): string[] {
    return this.definition ? this.definition.settings.multilineTitle || this.definition.multilineTitle : [];
  }

  get iconCls(): string {
    return this.definition ? this.definition.iconCls : '';
  }

  /**
   * The role used for this widget's context
   * @returns {IRole}
   */
  get contextRole(): IRole {
    return this._userService.selectedUserRole;
  }

  /**
   * Creates a directive configuration object.
   * @static
   * @param clazz
   * @param config
   * @returns {ng.IDirective}
   */
  static createWidgetDirective(clazz: Function, config: ng.IDirective): ng.IDirective {
    const directive = Component.createDirective(clazz, config);
    directive.scope = Object.assign({}, directive.scope, {
      instanceId: '=',
      definition: '=',
      categoryId: '=',
      onRender: '&',
      onDestroy: '&',
      dashboardName: '=?',
      dashboardContext: '=?',
      contextDiffValues: '=?',
      maximized: '=?',
    });

    return directive;
  }

  getSettingsStoragePath(): string[] {
    return [this.dashboardName, this.categoryId, this.definition.widgetDefinitionId, this.instanceId];
  }

  /**
   * Checks whether the widget will show a certain control
   * @param control
   * @returns {any}
   */
  hasControl(control: string): boolean {
    return this.widgetControlsAdapter && this.widgetControlsAdapter.hasControl(control);
  }

  /**
   * Check for widget controls
   */
  hasControls(): boolean {
    return this.widgetControlsAdapter && this.widgetControlsAdapter.hasVisibleControls();
  }

  /**
   * Checks if the widget is owned by the current user.
   * @returns {boolean}
   */
  belongsToUser(): boolean {
    return this._userService.user.userId === this.definition.sharedBy.userId;
  }

  /**
   * Checks whether a widgets control that triggers a dropdown menu has the option with the given name
   *
   * @param option
   */
  hasOption(control: string, option: string): boolean {
    return this.widgetControlsAdapter && this.widgetControlsAdapter.hasOption(control, option);
  }

  /**
   * Checks to see if a control has options at all
   *
   * @param control
   * @returns {IOperationControl[]|boolean}
   */
  hasOptions(control: string): boolean {
    return this.widgetControlsAdapter && this.widgetControlsAdapter.hasOptions(control);
  }

  /**
   * Refresh the widget
   * The catch block will show an error message instead of the widget body
   * when a server error is detected. The content of each message can be found in the appropriate
   * /definitions/*.json
   * @returns {IPromise<TResult>}
   */
  refresh(): ng.IPromise<void> {
    this.loading = true;
    return this._widgetService
      .getWidgetData(this.definition, this.dashboardContext)
      .then((data: IWidgetData) => {
        if (this.el) {
          this.noData = null;
          this.error = null;
          this.data = data || { data: [] };

          if (0 === this.data.data.length) {
            this.translationService(this.definition.title).then((name: string) =>
              this.translationService('widget.noData', { name }).then((noDataMessage: string) => (this.noData = noDataMessage))
            );
          }
          this.mergedContext =
            this.dashboardContext && this.dashboardContext.diffValues && this.dashboardContext.diffValues.length > 0
              ? this.workplaceContextService.getDashboardContextOnly(
                  this.dashboardContext.diffValues,
                  this.definition.dataUrlContextParams
                )
              : this.workplaceContextService.getMergedContext(
                  this.contextRole && this.contextRole !== null ? this.contextRole.diffValues : [],
                  this.contextDiffValues,
                  this.definition.dataUrlContextParams
                );
          this.renderChart(this.el, this.data);
        }
      })
      .catch((reason: IHttpServerError) => {
        this.data = { data: [] };
        this.setWidgetError(reason);
        this.removeChart();
      })
      .finally(() => (this.loading = false));
  }

  setWidgetError(reason: IHttpServerError): void {
    if (this.definition && this.definition.hasErrorMsg) {
      let translationErrorKey = 'widget.definition.errorMsg.' + reason.status;
      this.translationService(translationErrorKey).then((translation: string) => (this.error = translation));
    }
  }
  /**
   * Renders the actual chart
   */
  abstract renderChart(el: JQuery, data: IWidgetData): void;

  /**
   * Removes the actual chart
   */
  removeChart(): void {
    return;
  }

  /**
   * Render the widget
   */
  render(): void {
    if (this._promiseRender) {
      this._timeoutService.cancel(this._promiseRender);
    }
    this._promiseRender = this._timeoutService(() => {
      if (this.data) {
        this.mergedContext =
          this.dashboardContext && this.dashboardContext.diffValues && this.dashboardContext.diffValues.length > 0
            ? this.workplaceContextService.getDashboardContextOnly(
                this.dashboardContext.diffValues,
                this.definition.dataUrlContextParams
              )
            : this.workplaceContextService.getMergedContext(
                this.contextRole && this.contextRole !== null ? this.contextRole.diffValues : [],
                this.contextDiffValues,
                this.definition.dataUrlContextParams
              );
        this.renderChart(this.el, this.data);
      } else {
        this.refresh();
      }
      this._promiseRender = null;
    }, 100);
  }

  /**
   * Resize the widget
   */
  resize(): void {
    this.render();
  }

  /**
   * On render component
   */
  onRenderComponent(el: JQuery, scope: ng.IScope): void {
    this._subscriptions.push(
      this._widgetService.channel.subscribe(WidgetService.WIDGET_UPDATED, (message: IWidgetServiceMessage) => {
        if (message && message.widget && message.widget.id === this.definition.id) {
          this.definition = _.clone(message.widget);
          this.ctrlAdapterBuilder();
        }
      })
    );
    this.el = el.find('.__el');
    this._unbinders.push(scope.$watch('vm.definition', this.ctrlAdapterBuilder.bind(this)));
    this._unbinders.push(scope.$watch('vm.data', this.ctrlAdapterBuilder.bind(this)));
    // if the dashboard context is updated, we need to refresh the widget and re-fetch its data
    // triggered by an update of the bindings.
    this._unbinders.push(scope.$watch('vm.dashboardContext', this.refresh.bind(this)));
    if (!this.el.length) {
      this.el = el;
    }
    this.render();
    this.onRender({ id: this.instanceId, widget: this });
  }

  abstract getDefaultIcon(): string;

  /**
   * Destroy the component
   */
  destroy(): void {
    this.onDestroy({ id: this.instanceId, widget: this });
    if (this._promiseRender) {
      this._timeoutService.cancel(this._promiseRender);
      this._promiseRender = null;
    }
    if (this._subscriptions) {
      this._subscriptions.forEach((subscription: ISubscriptionDefinition<any>) => subscription.unsubscribe());
      this._subscriptions = null;
    }
    if (this._unbinders) {
      this._unbinders.forEach((u: Function) => u());
      this._unbinders = null;
    }
    this._timeoutService = null;
    this._widgetService = null;
    this._userService = null;
    this.data = null;
    this.workplaceApiService = null;
    this.el = null;
  }
}
