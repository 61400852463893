import { Component } from '../component';
import { AboutDialog } from './about-dialog';
import aboutDialogTemplate from './about-dialog.html';

/**
 * Directive wrapper for sharing a dialog.
 * @returns {ng.IDirective}
 * @ngInject
 */
export function aboutDialogDirective(): ng.IDirective {
  'use strict';
  return Component.createDirective(AboutDialog, {
    restrict: 'E',
    templateUrl: aboutDialogTemplate,
    scope: {
      close: '&',
      dismiss: '&',
      resolve: '&',
    },
  });
}
