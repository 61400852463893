import { IIFrameComponent } from '../components/iframe-component';
import { IDndCoordinates, IDragObject } from './interframe-drag-manager.interface';

/**
 * Service for handling dragging and dropping between app/widget iframes.
 */
export class InterframeDragService {
  static IFRAME_DND_CHANNEL: string = 'IframeDnDChannel';
  static IFRAME_DND_START: string = 'IframeDnDStart';
  static IFRAME_DND_END: string = 'IframeDnDEnd';

  channel: IChannelDefinition<IInterframeDragManagerServiceMessage>;

  private dragObject: IDragObject;
  private jquery: JQueryStatic;
  private iFrameDragProxy: any;
  private log: ng.ILogService;
  private domMouseMoveHandler: (event: MouseEvent) => void;
  private domMouseDownHandler: () => void;

  /**
   * @ngInject
   * @param jQuery
   * @param $log
   * @param postal
   */
  constructor(jQuery: JQueryStatic, $log: ng.ILogService, postal: IPostal) {
    this.jquery = jQuery;
    this.log = $log;
    this.domMouseMoveHandler = (event: MouseEvent) => {
      this.updateDndProxy(event);
    };
    this.domMouseDownHandler = () => {
      this.endDrag();
    };

    this.channel = postal.channel(InterframeDragService.IFRAME_DND_CHANNEL);
  }

  /**
   * Updates drag proxy coordinates.
   * @param frame
   * @param position
   */
  setDragCoordinates(frame: IIFrameComponent, position: IDndCoordinates): void {
    if (!this.isDragActive()) {
      return;
    }
    this.dragObject.position = position;
    this.setIframeDragProxyPosition(frame, position);
  }

  /**
   * Creates and sets the drag proxy.
   *
   * @param frame
   * @param connections
   * @param dragObject
   */
  setDragObject(frame: IIFrameComponent, dragObject: IDragObject): void {
    /**
     * if drag Object is a not valid Object, dragging is canceled.
     */
    if (!dragObject) {
      this.endDrag();
      return;
    }

    /**
     * A drag is already active destroy the old proxy and create a new one
     */
    if (this.dragObject && this.iFrameDragProxy) {
      this.iFrameDragProxy.remove();
    }

    this.dragObject = dragObject;

    /**
     * create drag proxy object
     * @type {JQuery}
     */
    this.iFrameDragProxy = this.jquery('<div></div>');
    this.iFrameDragProxy
      .css({
        position: 'absolute',
        color: '#888',
        backgroundColor: '#fff',
        border: '1px solid #ddd',
        padding: '6px',
        zIndex: 4,
      })
      .html(this.dropRejectedTag);

    /**
     * set the initial position of the drag proxy
     */
    this.setIframeDragProxyPosition(frame, dragObject.position);

    /**
     * update drag proxy position when the mouse is moving over the workplace, out of the drag origin iframe.
     */
    document.addEventListener('mousemove', this.domMouseMoveHandler, false);
    document.addEventListener('mousedown', this.domMouseDownHandler, false);
    this.jquery('body').append(this.iFrameDragProxy);

    this.channel.publish(InterframeDragService.IFRAME_DND_START, {
      targetFrameId: frame.getId(),
      dragObject: dragObject,
    });
  }

  endDrag(): void {
    document.removeEventListener('mousemove', this.domMouseMoveHandler, false);
    document.removeEventListener('mousedown', this.domMouseDownHandler, false);
    this.channel.publish(InterframeDragService.IFRAME_DND_END);
    if (this.iFrameDragProxy) {
      this.iFrameDragProxy.remove();
    }
  }

  acceptDrop(): void {
    if (this.iFrameDragProxy) {
      this.iFrameDragProxy.html(this.dragAcceptedTag);
    }
  }

  rejectDrop(): void {
    if (this.iFrameDragProxy) {
      this.iFrameDragProxy.html(this.dropRejectedTag);
    }
  }

  private isDragActive(): boolean {
    return this.dragObject && this.dragObject !== null;
  }

  private setIframeDragProxyPosition(frame: IIFrameComponent, position: IDndCoordinates): void {
    if (this.iFrameDragProxy) {
      this.iFrameDragProxy.css({
        top: frame === null ? position.y - 10 : frame.getIframePosition().y + position.y + 30,
        left: frame === null ? position.x + 10 : frame.getIframePosition().x + position.x + 20,
        right: 'auto',
        bottom: 'auto',
      });
    }
  }

  /**
   * update the drag proxy for when drag is active and mouse is moving over the workplace, not over an iframe
   * @param event
   */
  private updateDndProxy(event: MouseEvent): void {
    if (this.iFrameDragProxy && this.dragObject) {
      this.iFrameDragProxy.html(this.dropRejectedTag);
    }
    this.setDragCoordinates(null, {
      x: event.x,
      y: event.y,
    });
  }

  private get dragAcceptedTag(): string {
    return `<span style="font-size: 16px; position: absolute; color: #009000;" class="iwp-icon-gen_ok"></span>
            <span style="padding-left: 20px;">${this.dragObject ? this.dragObject.dragText : ''}</span>`;
  }

  private get dropRejectedTag(): string {
    return `<span style="font-size: 16px; position: absolute; color: #CC3333;" class="iwp-icon-gen_error"></span>
            <span style="padding-left: 20px;">${this.dragObject ? this.dragObject.dragText : ''}</span>`;
  }
}

/**
 * Message sent over the pub/sub channel
 */
export interface IInterframeDragManagerServiceMessage {
  targetFrameId: string;
  dragObject: IDragObject;
}
