import { IShareObject } from '../components/dialogs/share-dialog-config.interface';
import { IDashboard } from '../dashboard/dashboard.model.interface';
import { IWidgetDescriptor } from '../widget/widget.descriptor.model';
export class SharingService {
  static CHANNEL: string = 'SharingServiceChannel';
  static DASHBOARD_ACCEPTED: string = 'DashboardShareAccepted';

  channel: IChannelDefinition<IDashboard | IWidgetDescriptor<any>>;

  private ENDPOINT: string = './rest/sharing';
  private httpService: ng.IHttpService;
  private postal: IPostal;

  /**
   * @ngInject
   */
  constructor($http: ng.IHttpService, postal: IPostal) {
    this.httpService = $http;
    this.postal = postal;
    this.channel = this.postal.channel(SharingService.CHANNEL);
  }

  getSharedUsers(id: string, shareType: string): ng.IPromise<IShareObject[]> {
    return this.httpService
      .get(this.ENDPOINT, {
        params: {
          entityId: id,
          shareType: shareType,
        },
      })
      .then((response: ng.IHttpPromiseCallbackArg<IShareObject[]>) => {
        return response.data;
      });
  }

  setSharedUsers(shared: IShareObject[]): ng.IPromise<any> {
    return this.httpService.post(this.ENDPOINT, shared);
  }

  acceptSharedDashboard(dashboardId: string): ng.IPromise<IDashboard> {
    return this.httpService
      .post(`${this.ENDPOINT}/dashboard/accept?entityId=${dashboardId}`, {})
      .then((response: ng.IHttpPromiseCallbackArg<IDashboard>) => {
        this.channel.publish(SharingService.DASHBOARD_ACCEPTED, response.data);
        return response.data;
      });
  }

  openSharedStoreItem(itemId: string): ng.IPromise<any> {
    return this.httpService.post(`${this.ENDPOINT}/store/${itemId}/open`, {});
  }
}
