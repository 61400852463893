import { AppsMenu } from './appsmenu';
import { Component } from '../component';
import appsMenuTemplate from './appsmenu.html';

/**
 * Wraps {@link AppsMenu} component into a directive.
 *
 * @author Tobias Straller [Tobias.Straller.bp@nttdata.com]
 *
 * @ngInject
 */
export function appsmenuDirective(): ng.IDirective {
  'use strict';
  return Component.createDirective(AppsMenu, {
    restrict: 'E',
    scope: {
      apps: '=',
      title: '@appsMenuTitle',
      filter: '=',
      itemClickHandler: '&',
      menuId: '@',
      headerIcons: '=',
      type: '@',
    },
    templateUrl: appsMenuTemplate,
    transclude: true,
  });
}
