import { IAnalyticsFeatureConfig } from '@myworkplace/api';

export const MY_TASKS = 'myTasks';
export const OPEN_MY_TASKS = 'openMyTasks';
export const OPEN_MY_TASKS_DETAILS = 'openMyTasksDetails';
export const OPEN_MY_TASKS_DETAILS_MENU = 'openMyTasksDetailsMenu';
export const CREATE_NEXTONE_TASK = 'createNextoneTask';

export const MY_OPEN_TASKS_FEATURES = new Map<string, IAnalyticsFeatureConfig>([
  [
    OPEN_MY_TASKS,
    {
      category: [MY_TASKS],
      name: 'Opening My Tasks',
      steps: ['Open My Tasks'],
    },
  ],
  [
    OPEN_MY_TASKS_DETAILS,
    {
      category: [MY_TASKS],
      name: 'Opening My Tasks Details',
      steps: ['Open My Tasks Details'],
    },
  ],
  [
    OPEN_MY_TASKS_DETAILS_MENU,
    {
      category: [MY_TASKS],
      name: 'Opening My Tasks Details Menu',
      steps: ['Open My Tasks Details Menu'],
    },
  ],
  [
    CREATE_NEXTONE_TASK,
    {
      category: [MY_TASKS],
      name: 'Creating Nextone Task',
      steps: ['Create Nextone Task'],
    },
  ],
]);
