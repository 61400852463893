import { Component } from '../component';
import { AppsMenuItem } from './appsmenu.item';
/**
 * Wraps {@link AppsMenu} component into a directive.
 *
 * @author Tobias Straller [Tobias.Straller.bp@nttdata.com]
 *
 * @ngInject
 */
export function appsmenuItemDirective($compile: ng.ICompileService): ng.IDirective {
  'use strict';
  return Component.createDirective(AppsMenuItem, {
    restrict: 'E',
    scope: {
      data: '=',
      itemClickHandler: '&',
    },
    template: `<li class="app-components-appsmenu-item"
    ng-class="::{
      group: vm.isGroup,
      external: vm.isExternalForNewWindow || vm.isExternalForNewTab,
      strongAuth: vm.isStrongAuth
      }">
  <a href=""
     ng-click="vm.onItemClick()"
     ng-class="{collapsed: vm.collapsed}"
     uib-tooltip="{{::vm.data.description}}"
     tooltip-placement="right"
     tooltip-enable="vm.tooltipEnabled"
     tooltip-append-to-body="true">


    <div class="item-content">
      <i class="group-toggle glyphicon iwp-icon-gen_nav_arrow_up"></i>
      <span class="sidebar-icon" ng-class="::vm.iconCls"></span>
      <span class="item-label">{{::vm.data.description}}</span>
    </div>

    <div class="item-actions">
      <i class="infoIcon infoIcon-strongAuth iwp-icon-gen_token" ng-if="vm.isStrongAuth"
           title="{{::'appsmenu.item.strongAuth' | translate }}"></i>
        <i class="infoIcon infoIcon-external iwp-icon-gen_external_app" ng-if="vm.isExternalForNewWindow"
           title="{{::'appsmenu.item.externalWindow' | translate }}"></i>
        <i class="infoIcon infoIcon-external iwp-icon-gen_external_app" ng-if="vm.isExternalForNewTab"
           title="{{::'appsmenu.item.externalTab' | translate }}"></i>
    </div>
  </a>
  <ul class="nav appsmenu-list" ng-if="::vm.isGroup" uib-collapse="vm.collapsed">
    <app-components-apps-menu-item ng-repeat="item in ::vm.data.children track by item.name"
                                   data="::item"
                                   item-click-handler="vm.onItemClick(app)"></app-components-apps-menu-item>
  </ul>
</li>`,
  });
}
