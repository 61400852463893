import { IApplication } from '../../apps/application.model.interface';
import { Component } from '../component';
import { Node } from '../../../util/tree/node';

('use strict');
import { IMenuHeaderIcon } from '../../apps/menu-header-icon.model.interface';
import { descriptionSorter } from '../../../util/description-sorter';

/**
 * AppsMenu component
 *
 * @author Tobias Straller [Tobias.Straller.bp@nttdata.com]
 *
 */
export class AppsMenu extends Component {
  /**
   * set by attribute
   */
  apps: IApplication[];
  /**
   * Set by attribute
   */
  title: string;
  /**
   * Set by attribute
   */
  filter: boolean;
  /**
   * Set by attribute
   */
  menuId: string;
  /**
   * Current filter term
   */
  filterTerm: string;

  itemClickHandler: (message: { app: IApplication }) => void;

  /**
   * A list of icons shown by the side of the appmenu header and their according handlers, if any
   */
  headerIcons: IMenuHeaderIcon[];

  /**
   * Type of contained menu items.
   *
   * It should be either 'dashboards' or 'apps' at this point.
   */
  type: string;

  private unbinders: Function[] = [];

  /**
   *
   * @ngInject
   */
  constructor() {
    super();
  }

  onRenderComponent(el: JQuery, $scope: ng.IScope): void {
    this.unbinders.push($scope.$watch('vm.apps', () => this.recursiveAppSort(this.apps)));
  }

  /**
   * Returns a list of filtered applications
   */
  get filteredApps(): IApplication[] {
    return this.filterTerm && this.filterTerm.trim().length >= 2 ? this._filterApps(this.apps) : this.apps;
  }

  /**
   * Handle item click
   */
  onItemClick(app: IApplication): void {
    this.itemClickHandler({ app });
  }

  reset(): void {
    this.filterTerm = '';
  }

  destroy(el: JQuery): void {
    this.unbinders.forEach((u: Function) => u());
  }

  /**
   *
   * @param apps
   * @private
   */
  private _filterApps(apps: IApplication[]): IApplication[] {
    const filteredApps = [];
    apps.forEach((app: IApplication) =>
      Node.walkDepthFirst(
        (node: IApplication) => {
          if (
            (!node.children || node.children.length === 0) &&
            node.description.toLocaleLowerCase().includes(this.filterTerm.toLocaleLowerCase())
          ) {
            filteredApps.push(node);
          }
        },
        app,
        true
      )
    );
    return filteredApps;
  }

  private recursiveAppSort(apps: IApplication[]): void {
    apps.sort(descriptionSorter);
    apps.forEach((app: IApplication) => {
      if (app.children && app.children.length > 0) {
        this.recursiveAppSort(app.children);
      }
    });
  }
}
