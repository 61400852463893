import { Component } from '../component';
import { AddFavorite } from './add-favorite';
import addFavoriteTemplate from './favorite-dialog.html';
/**
 * @ngInject
 */
export function addFavoriteDirective(): ng.IDirective {
  'use strict';
  return Component.createDirective(AddFavorite, {
    restrict: 'E',
    templateUrl: addFavoriteTemplate,
    scope: {
      close: '&',
      dismiss: '&',
      resolve: '&',
    },
  });
}
