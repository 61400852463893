import { INotificationService } from './notification.service.interface';
import { INotificationChannelMessage } from './notification.channel.message.interface';
import _ from 'lodash';

/**
 * Notification Service
 * @author Tobias Straller [Tobias.Straller@nttdata.com]
 */
export class NotificationService implements INotificationService {
  static CHANNEL: string = 'NotificationServiceChannel';
  static TOPIC_MESSAGE: string = 'NotificationServiceTopicMessage';

  channel: IChannelDefinition<INotificationChannelMessage>;

  private _translateService: angular.translate.ITranslateService;
  private _toastr: Toastr;

  /**
   *
   * @param $translate
   * @param postal
   * @constructor
   * @ngInject
   */
  constructor($translate: angular.translate.ITranslateService, postal: IPostal, toastr: Toastr) {
    this._translateService = $translate;
    this._toastr = toastr;
    this.channel = postal.channel(NotificationService.CHANNEL);
    this.channel.subscribe(NotificationService.TOPIC_MESSAGE, (message: INotificationChannelMessage) => {
      this.showMessage(message.type, message.message);
    });
  }

  /**
   * Show a success notification message
   * @param messageKey
   * @param messageParams params object for formatting translation messages
   * @param titleKey
   * @param titleParams params object for formatting translation messages
   */
  showSuccess(messageKey: string, messageParams?: any, titleKey?: string, titleParams?: any): void {
    this.showMessage('success', messageKey, messageParams, titleKey, titleParams);
  }

  /**
   * Show a warning notification message
   * @param messageKey
   * @param messageParams params object for formatting translation messages
   * @param titleKey
   * @param titleParams params object for formatting translation messages
   */
  showWarn(messageKey: string, messageParams?: any, titleKey?: string, titleParams?: any): void {
    this.showMessage('warning', messageKey, messageParams, titleKey, titleParams);
  }

  /**
   * Show an information message
   * @param messageKey
   * @param messageParams params object for formatting translation messages
   * @param titleKey
   * @param titleParams params object for formatting translation messages
   */
  showInfo(messageKey: string, messageParams?: any, titleKey?: string, titleParams?: any): void {
    this.showMessage('info', messageKey, messageParams, titleKey, titleParams);
  }

  /**
   * Show an error message
   * @param messageKey
   * @param messageParams params object for formatting translation messages
   * @param titleKey
   * @param titleParams params object for formatting translation messages
   */
  showError(messageKey: string, messageParams?: any, titleKey?: string, titleParams?: any): void {
    this.showMessage('error', messageKey, messageParams, titleKey, titleParams);
  }

  /**
   * Show a message
   * @param type
   * @param messageKey
   * @param messageParams params object for formatting translation messages
   * @param titleKey
   * @param titleParams params object for formatting translation messages
   * @param options custom options that override default behavior, such as duration
   */
  showMessage(
    type: string,
    messageKey: string,
    messageParams?: any,
    titleKey?: string,
    titleParams?: any,
    options?: ToastrOptions
  ): void {
    type = type || 'info';
    this._translateService(
      [messageKey, titleKey].filter(key => !!key),
      _.merge({}, messageParams, titleParams)
    ).then((translation: { [key: string]: string }) => {
      if (typeof this._toastr[type] === 'function') {
        this._toastr[type](translation[messageKey], translation[titleKey], options);
      }
    });
  }

  /**
   * Show an already translated message.
   *
   * @param type
   * @param message
   * @param title
   */
  showTranslatedMessage(type: string, message: string, title?: string): void {
    type = type || 'info';
    this._toastr[type](message, title);
  }
}
