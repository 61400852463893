import { IAnnouncement } from '../../announcements/announcements.utils';

export class BannersActionsService {
  private readonly DISMISSED_BANNERS = 'dismissedBanners';

  dismissBanner(event: CustomEvent, qNumber: string): void {
    const banner = event.detail;

    if (banner.type.toLowerCase() === 'info') {
      const qNumberBanners = `${qNumber}/${this.DISMISSED_BANNERS}`;
      const dismissedBanners = localStorage.getItem(qNumberBanners);

      if (!dismissedBanners) {
        localStorage.setItem(qNumberBanners, JSON.stringify([banner.id]));
        return;
      }

      try {
        const parsedData = JSON.parse(dismissedBanners);

        parsedData.push(banner.id);

        localStorage.setItem(qNumberBanners, JSON.stringify(parsedData));
      } catch (err) {
        localStorage.removeItem(qNumberBanners);
      }
    }
  }

  deleteBannersFromLocalStorage(
    dismissedBanners: string[],
    liveAnnouncements: IAnnouncement[],
    qNumberDismissedBanners: string
  ): void {
    const banners = dismissedBanners.filter(bann => liveAnnouncements.find(live => bann === live.id));
    localStorage.setItem(qNumberDismissedBanners, JSON.stringify(banners));
  }

  dismissedBanners(qNumberDismissedBanners: string): string[] {
    return localStorage.getItem(qNumberDismissedBanners)
      ? JSON.parse(localStorage.getItem(qNumberDismissedBanners))
      : [];
  }
}
