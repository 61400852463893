import { Component } from '../components/component';
import { LockedApp } from './app-blocked';
import blockedTemplate from './app-blocked.html';

/**
 * @returns {ng.IDirective}
 * @ngInject
 *
 */
export function lockedAppDirective(): ng.IDirective {
  'use strict';
  return Component.createDirective(LockedApp, {
    restrict: 'E',
    scope: {
      app: '=',
      message: '=',
    },
    templateUrl: blockedTemplate,
  });
}
