import { Widget } from '../widget';
import { WidgetService } from '../widget.service';
import { WorkplaceContextService } from '../../workplace/workplace.context.service';
import { WorkplaceApiService } from '../../workplace/workplace.api.service';
import { UserService } from '../../user/user.service';
import { IWidgetData } from '../widget.data.interface';
import { DeviceService } from '../../../util/device.service';
import { ITileOptions } from './tile.options.interface';
import { INotificationService } from '../../notification/notification.service.interface';
import { UrlHelper } from '../../../util/url.helper';
import { ImageService } from '../../fileHandling/image.service';
import { ActionConstants } from '../../actionLog/action-constants';
import { ActionLogService } from '../../actionLog/action-log.service';
import { getLinkTarget, getOsSpecificLink, openLink } from '../../../util/link-utils/link.open';
import { getClientOs, OS_TYPE } from '../../../util/link-utils/os.client';
import { ENUM_OFFICE_OPEN_MODES } from '../../officeUri/office-uri';
import { OfficeUriService } from '../../officeUri/office-uri.service';

/**
 * @author Tobias Straller [Tobias.Straller.bp@nttdata.com]
 */
export class Tile<T extends ITileOptions> extends Widget<T> {
  static ICON_DEFAULT_FILE: string = 'iwp-icon-gen_text_doc';
  static ICON_DEFAULT_WEBSITE: string = 'placeholder-link';
  static ICON_FOLDER: string = 'iwp-icon-zeb_folder';
  static ICON_POWERPOINT: string = 'iwp-icon-iwp_powerpoint';
  static ICON_WORD: string = 'iwp-icon-iwp_word';
  static ICON_EXCEL: string = 'iwp-icon-iwp_excel';
  static ICON_PDF: string = 'iwp-icon-iwp_pdf';

  private _deviceService: DeviceService;
  private _imageService: ImageService;
  private _officeUriService: OfficeUriService;

  /**
   *
   * @param $timeout
   * @param widgetService
   * @param workplaceApiService
   * @param workplaceContextService
   * @param userService
   * @param deviceService
   * @param officeUriService
   * @param notificationService
   * @param $translate
   * @param imageService
   * @param actionLogService
   * @ngInject
   */
  constructor(
    $timeout: ng.ITimeoutService,
    widgetService: WidgetService,
    workplaceApiService: WorkplaceApiService,
    workplaceContextService: WorkplaceContextService,
    userService: UserService,
    deviceService: DeviceService,
    notificationService: INotificationService,
    $translate: angular.translate.ITranslateService,
    imageService: ImageService,
    actionLogService: ActionLogService,
    officeUriService: OfficeUriService
  ) {
    super(
      $timeout,
      widgetService,
      workplaceApiService,
      workplaceContextService,
      userService,
      notificationService,
      $translate,
      actionLogService
    );

    this._deviceService = deviceService;
    this._imageService = imageService;
    this._officeUriService = officeUriService;
    if (this.definition && typeof this.definition.settings.showCaption === 'undefined') {
      this.definition.settings.showCaption = true;
    }
  }

  /**
   * Returns the default icon for the tile, depending on the url
   * @returns {any}
   */
  getDefaultIcon(): string {
    const url = this.definition.settings.url;
    const extension = UrlHelper.getFileExtensionFromUrl(url);
    if (extension) {
      const icon = this.getIconForExtension(extension);
      return icon ? icon : UrlHelper.isFileProtocol(url) ? Tile.ICON_DEFAULT_FILE : Tile.ICON_DEFAULT_WEBSITE;
    } else {
      return UrlHelper.isFileProtocol(url) ? Tile.ICON_FOLDER : Tile.ICON_DEFAULT_WEBSITE;
    }
  }

  /**
   * Returns the icon for the given file extension
   * @param extension
   */
  getIconForExtension(extension: string): string {
    if (extension.match(/xlsx*$/i)) {
      return Tile.ICON_EXCEL;
    }
    if (extension.match(/docx*$/i)) {
      return Tile.ICON_WORD;
    }
    if (extension.match(/pptx*$/i)) {
      return Tile.ICON_POWERPOINT;
    }
    if (extension.match(/pdf$/i)) {
      return Tile.ICON_PDF;
    }
    return null;
  }

  /**
   * @returns  with the following priority:
   *  - imgId (set by user)
   *  - backgroundImage (set by DB config)
   *  - backgroundImageUrl (set by app or config)
   *  - default icon
   */
  getBackgroundImage(): string {
    // #1 imgId - set by user
    if (this.definition.settings.imgId) {
      return this._imageService.getImage(this.definition.settings.imgId);
    }
    // #2 backgroundImage - set from DB config
    if (this.definition.settings.backgroundImage) {
      return this.definition.settings.backgroundImage[
        +(this._deviceService && this._deviceService !== null ? this._deviceService.devicePixelRatio > 1 : false)
      ];
    }
    // #3 backgroundImageUrl - set by app or config
    if (this.definition.settings.backgroundImageUrl) {
      return this.definition.settings.backgroundImageUrl;
    }
    return '';
  }

  get style(): any {
    const backImage = this.getBackgroundImage();
    if (backImage) {
      return {
        'background-color': this.definition.settings.backgroundColor,
        'background-position': this.definition.settings.backgroundPosition,
        'background-image': `url("${backImage}")`,
        'background-size': this.definition.settings.backgroundSize,
      };
    }
    return {};
  }

  get tileHref(): string {
    /**
     * Check for office links and convert them to office URI Schemes (eg. ms-word:ofe|u|https://..)
     */
    let officeLink = null;
    const officeFileExtension = this._officeUriService.checkOfficeURILink(this.definition.settings.url);
    if (officeFileExtension) {
      officeLink = this._officeUriService.createOfficeURILink(
        this.definition.settings.url,
        officeFileExtension,
        ENUM_OFFICE_OPEN_MODES.EDIT
      );
    }
    return officeLink ? officeLink : getOsSpecificLink(this.definition.settings.url) || '#';
  }

  get tileTarget(): string {
    return this.definition.settings.url ? getLinkTarget(this.definition.settings.url) : '_self';
  }

  /**
   * Handle click on tile
   */
  handleClick(ev: MouseEvent): void {
    if (getClientOs() === OS_TYPE.IOS) {
      this._notificationService.showMessage('info', 'mobile.open.vmwareBrowser.message', null, 'mobile.open.vmwareBrowser.title');
      openLink(this.definition.settings.url);
      ev.stopPropagation();
      ev.preventDefault();
    }
    this.actionLogService.logAction({
      category: ActionConstants.CATEGORY_DASHBOARD,
      action: ActionConstants.ACTION_FAVORITE_LAUNCH,
      actionInfo: this.definition.settings.url,
    });
    return;
  }

  /**
   *
   * @param el
   * @param data
   */
  renderChart(el: JQuery, data: IWidgetData): void {
    // nothing to do
  }

  /**
   *
   */
  refresh(): ng.IPromise<void> {
    // nothing to do
    return null;
  }

  /**
   * Render the tile.
   */
  render(): void {
    // nothing to do
  }

  destroy(): void {
    this._deviceService = null;
    this._imageService = null;
    super.destroy();
  }
}
