export class EventListenerManager {
  private static instance: EventListenerManager;

  private listeners: Map<string, EventListenerOrEventListenerObject>;
  private idCounter: number = 0;

  private constructor() {
    this.listeners = new Map<string, EventListenerOrEventListenerObject>();
  }

  public static getInstance(): EventListenerManager {
    if (!EventListenerManager.instance) {
      EventListenerManager.instance = new EventListenerManager();
    }

    return EventListenerManager.instance;
  }

  public addEventListener(target: EventTarget, eventType: string, handler: EventListenerOrEventListenerObject): void {
    if (!target) return;
    const key = this.getKey(target, eventType);
    if (!this.listeners.has(key)) {
      target.addEventListener(eventType, handler);
      this.listeners.set(key, handler);
    }
  }

  public removeEventListener(
    target: EventTarget,
    eventType: string,
    handler: EventListenerOrEventListenerObject
  ): void {
    const key = this.getKey(target, eventType);
    if (this.listeners.has(key)) {
      target.removeEventListener(eventType, handler);
      this.listeners.delete(key);
    }
  }

  private getKey(target: EventTarget, eventType: string): string {
    if (!(target as any)?.__eventManagerId) {
      (target as any).__eventManagerId = `__uniqueId__${++this.idCounter}`;
    }
    return `${eventType}_${(target as any).__eventManagerId}`;
  }
}
