import { Component } from '../component';
import { SearchField } from './search-field';
import searchFieldTemplate from './search-field.html';

/**
 * Wrapper directive for {@link SearchField}.
 *
 * @returns {ng.IDirective}
 *
 * @ngInject
 */
export function searchFieldDirective(): ng.IDirective {
  'use strict';

  return Component.createDirective(SearchField, {
    restrict: 'E',
    templateUrl: searchFieldTemplate,
    scope: {
      placeholder: '@',
      keyUpHandler: '&',
      focusHandler: '&',
      resetHandler: '&',
      autoFocused: '&',
      alwaysShowReset: '@',
      term: '=',
    },
  });
}
