import { Component } from '../component';
import { RoleContext } from './roleContext';
import roleContextTemplate from './roleContext.html';
/**
 * @ngInject
 */
export function roleContextDirective(): ng.IDirective {
  'use strict';
  return Component.createDirective(RoleContext, {
    restrict: 'E',
    scope: {
      role: '=',
      diffValues: '=',
      clickHandler: '&?',
      hint: '@',
    },
    templateUrl: roleContextTemplate,
  });
}
