import { IUserSettingsStorage } from '../workplace/user-settings-storage.interface';
import { IUserSettingsStoreable } from '../workplace/user-settings-storeable.interface';

export class TabHistoryStorage implements IUserSettingsStoreable {
  private _tabManagerId: string;
  private tabs: string[] = [];
  readonly settingsStorageKey: string = 'workplace';
  readonly maxTabsInHistory = 20;

  constructor(private storage: IUserSettingsStorage, tabManagerId: string) {
    this._tabManagerId = tabManagerId;
  }

  get tabManagerId(): string {
    return this._tabManagerId;
  }

  getSettingsStoragePath(): string[] {
    return ['tab-history', this.tabManagerId];
  }

  add(tabId: string): void {
    let tabs: string[] = this.getSavedTabs();

    if (this.getLast() === tabId) {
      return;
    }

    const tabHistoryExists = tabs.some(t => t == tabId);

    if (tabHistoryExists) {
      tabs = this.moveToLastPosition(tabs, tabId);
    } else {
      if (tabs.length === this.maxTabsInHistory) {
        tabs.shift();
      }

      tabs.push(tabId);
    }

    this.tabs = tabs;
  }

  copyHistory(sourceHistory: string[]): void {
    this.tabs = this.tabs.concat(sourceHistory);
  }

  remove(tabId: string): void {
    let tabs: string[] = this.getSavedTabs();

    tabs = tabs.filter(t => t !== tabId);
    this.tabs = tabs;
  }

  reset(): void {
    this.tabs = [];
    this.saveToStorage();
  }

  loadFromStorage(): void {
    this.tabs = this.storage.loadSettings(this) || [];
  }

  getSavedTabs(): string[] {
    return this.tabs || [];
  }

  getLast(): string {
    const tabs = this.getSavedTabs();

    return tabs[tabs.length - 1];
  }

  saveToStorage(): void {
    this.storage.saveSettings(this, this.tabs);
  }

  private moveToLastPosition(tabs: string[], tabId: string): string[] {
    tabs = tabs.filter(t => t !== tabId);

    tabs.push(tabId);

    return tabs;
  }
}
