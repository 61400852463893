import { Component } from '../component';
import { WidgetSizeGrid } from './size-grid';
import widgetSizeGridTemplate from './size-grid.html';

/**
 * Wrapper directive for {WidgetTable}
 * @returns {ng.IDirective}
 * @ngInject
 */
export function widgetSizeGridDirective(): ng.IDirective {
  'use strict';

  return Component.createDirective(WidgetSizeGrid, {
    restrict: 'E',
    templateUrl: widgetSizeGridTemplate,
    scope: {
      minRows: '=',
      minColumns: '=',
      maxRows: '=',
      maxColumns: '=',
      currentRow: '=',
      currentColumn: '=',
      selectSize: '&',
    },
  });
}
