import { TabLayout } from './tab.layout';
import { Component } from '../components/component';
/**
 * Wraps {@link Tabview} component into a directive.
 *
 * @author Tobias Straller [Tobias.Straller.bp@nttdata.com]
 *
 * @ngInject
 */
export function appTabLayoutDirective($compile: ng.ICompileService): ng.IDirective {
  'use strict';
  return Component.createDirective(TabLayout, {
    restrict: 'E',
    scope: {
      layout: '=',
    },
    template: `<div class="app-tab-layout" ng-class="::vm.customClass">
    <div class="layout-content"
         ng-if="vm.layout.children.length > 0"
         ng-repeat="item in vm.layout.children track by item.id"
         ng-style="$parent.vm.getStyleForLayout(item)">
        <div class="tab-layout-divider" ng-if="$index > 0" ng-class="$parent.vm.layout.direction"
             util-interact-draggable
             dd-axis="{{::$parent.vm.ddAxis}}"
             draggable-on-end="$parent.vm.dividerEndDrag(event)"
             draggable-on-move="$parent.vm.dividerMove(event)"
             draggable-on-down="$parent.vm.dividerDown(event)"
             draggable-on-up="$parent.vm.dividerUp(event)"></div>
        <app-tab-layout layout="item"></app-tab-layout>
    </div>
    <div class="layout-content" ng-if="vm.layout.children.length === 0">
        <app-components-tabview tabview-id="vm.layout.id" max-tabs="vm.maxTabs" min-tab-width="250"
                                left-titlebar-visible="vm.hasMenu('left-titlebar')"
                                right-titlebar-visible="vm.hasMenu('right-titlebar')"></app-components-tabview>
    </div>
</div>`,
  });
}
