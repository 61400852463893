'use strict';
import { AxiosError } from 'axios';
import { AppAdminUploadService } from './appAdminUpload.service';

export class AppAdminUploadComponent {
  /**
   * @ngInject
   */
  constructor(private appAdminUploadService: AppAdminUploadService) {}

  onSubmitFile(): void {
    document.querySelector('.error').textContent = '';
    document.querySelector('.success').textContent = '';

    const formElement = document.getElementById('inputCSV') as HTMLInputElement;
    const inputFile = formElement.files[0];

    const formDataInput = new FormData();
    formDataInput.append('file', inputFile);

    this.submitFile(formDataInput);
  }

  private submitFile(formDataInput: FormData): void {
    this.appAdminUploadService
      .postFile(formDataInput)
      .then((response: string) => {
        document.querySelector('.success').textContent = response;
      })
      .catch((error: AxiosError): void => {
        if (error.response.data) {
          document.querySelector('.error').textContent = error.response.data;
        }
      });
  }
}
