import { IFrameLayout, ILayoutFrameDescriptor } from './frame-layout.model.interface';
import { TabService } from '../tab/tab.service';
import { Icons } from '../app.icons';
import { ITabContentAppsLayout, TabContentType } from '../tab/tab.content.interface';
import { UserService } from '../user/user.service';
import { WorkplaceContextService } from '../workplace/workplace.context.service';
import { DeviceService } from '../../util/device.service';
import { IUser } from '../user/user.model.interface';
import { IWorkplaceProperty } from '../workplace/workplace-property.interface';
import { UrlHelper } from '../../util/url.helper';
import { ILanguage } from '../../util/language.model.interface';
import { ActionConstants } from '../actionLog/action-constants';
import { ActionLogService } from '../actionLog/action-log.service';
import { NotificationService } from '../notification/notification.service';
import { WorkplaceApiService } from '../workplace/workplace.api.service';
import { IActionLogEntry } from '../actionLog/action-log-entry.interface';
import JSData from 'js-data';
import _ from 'lodash';

export class FrameLayoutService {
  static CHANNEL_FRAME_LAYOUT: string = 'CHANNEL_FRAME_LAYOUT';
  static SHOW_FULLSCREEN_FRAME: string = 'SHOW_FULLSCREEN_FRAME';
  static SET_IFRAME_SIZE: string = 'SET_IFRAME_SIZE';
  static CACHE_LAYOUTS: string = 'CACHE_LAYOUTS';
  static TOPIC_FRAME_LAYOUT_CONTEXT_UPDATE: string = 'TOPIC_FRAME_LAYOUT_CONTEXT_UPDATE';

  channel: IChannelDefinition<any>;

  private store: JSData.DSResourceDefinition<IFrameLayout>;
  private tabService: TabService;
  private logService: ng.ILogService;
  private userService: UserService;
  private workplaceContextService: WorkplaceContextService;
  private deviceService: DeviceService;
  private language: ILanguage;
  private actionLogService: ActionLogService;
  private _queueService: ng.IQService;
  private _notificationService: NotificationService;
  private _apiService: WorkplaceApiService;

  /**
   * @ngInject
   */
  constructor(
    frameLayoutsStore: JSData.DSResourceDefinition<IFrameLayout>,
    tabService: TabService,
    $log: ng.ILogService,
    postal: IPostal,
    userService: UserService,
    workplaceContextService: WorkplaceContextService,
    actionLogService: ActionLogService,
    deviceService: DeviceService,
    language: ILanguage,
    notificationService: NotificationService,
    private $q: ng.IQService
  ) {
    this.store = frameLayoutsStore;
    this.tabService = tabService;
    this.userService = userService;
    this.workplaceContextService = workplaceContextService;
    this.actionLogService = actionLogService;
    this.deviceService = deviceService;
    this.logService = $log;
    this.language = language;
    this._notificationService = notificationService;
    this.channel = postal.channel(FrameLayoutService.CHANNEL_FRAME_LAYOUT);
    this._queueService = $q;
  }

  setApiService(apiService: WorkplaceApiService): void {
    this._apiService = apiService;
  }

  getLayouts(config?: JSData.DSAdapterOperationConfiguration, includeHiddenLayouts?: boolean): ng.IPromise<IFrameLayout[]> {
    const deferred: ng.IDeferred<IFrameLayout[]> = this._queueService.defer<IFrameLayout[]>();

    this.store
      .findAll(
        {
          lang: this.language.lang,
          deviceType: this.deviceService.device,
          demoMode: this.workplaceContextService.demoMode,
        },
        Object.assign({}, config)
      )
      .then((data: IFrameLayout[]) => {
        if (!includeHiddenLayouts) {
          data = data.filter((layout: IFrameLayout) => !(_.startsWith(layout.id, '_') || _.startsWith(layout.id, 'hidden_')));
        }
        deferred.resolve(data);
      })
      .catch(() => {
        deferred.reject();
      });

    return deferred.promise;
  }

  getLayoutById(id: string): IFrameLayout {
    return this.store.get(id);
  }

  async openAppsLayoutTab(
    layout: IFrameLayout,
    context?: { [key: string]: string | string[] },
    widgetContext?: { widgetId: string; path: string; params: any; hash: string },
    opener?: string
  ): Promise<void> {
    /** validate strongAuth permissions for opening the layout */
    const validateOpenLayout = await this._apiService.checkStrongAuthPermissions(layout);
    if (!validateOpenLayout) {
      this._notificationService.showError('oidc.permissions.denied');
      return;
    }
    let entry = <IActionLogEntry>{
      category: ActionConstants.CATEGORY_LAYOUT,
      action: ActionConstants.ACTION_LAYOUT_OPEN,
      actionInfo: layout.id,
    };
    if (opener) {
      entry.source = opener;
    }
    /** Open layout */
    this.actionLogService.logAction(entry);
    this.userService.getUser().then((user: IUser) => {
      if (this.tabService.getTabById(layout.id)) {
        this.tabService.selectTab(layout.id);
        if (context || widgetContext) {
          // we only want to update the frames' context if there is a new one
          this.channel.publish(FrameLayoutService.TOPIC_FRAME_LAYOUT_CONTEXT_UPDATE, {
            layout,
            context,
            widgetContext,
          });
        }
        return;
      }

      this.tabService.openTab({
        id: layout.id,
        title: layout.providedTitle ? layout.providedTitle : layout.description,
        iconCls: layout.iconCls === null ? Icons.DASHBOARD_DEFAULT : layout.iconCls,
        refreshable: layout.refreshEnabled,
        neverSelected: true,
        closeable: true,
        content: <ITabContentAppsLayout>{
          type: TabContentType.FRAME_LAYOUT_TEMPLATE,
          frameContext: context,
          widgetContext: widgetContext,
          frameLayout: layout,
          template: `<frame-layout layout="$parent.$parent.vm.content.content.frameLayout" context-params="$parent.$parent.vm.content.content.frameContext" widget-context="$parent.$parent.vm.content.content.widgetContext"></frame-layout>`,
        },
      });
    });
  }

  /**
   * Since this is used by deep links as well, we want to make sure we load all layouts first.
   * They will be loaded anyways, in order to be displayed on the dashboard section in the burger menu.
   * @param {string} id
   * @param {{[p: string]: string | string[]}} context
   */
  openLayoutById(
    id: string,
    context?: { [key: string]: string | string[] },
    widgetContext?: { widgetId: string; path: string; params: any; hash: string },
    opener?: string
  ): ng.IPromise<IFrameLayout> {
    return this.getLayouts(null, true).then(() => {
      const layout = this.store.get(id);
      if (!layout) {
        this.logService.log(`Opening frame layout with id of ${id} through a deep link failed because the layout was not found.`);
        this._notificationService.showError('layout.not.found');
        return;
      }
      this.openAppsLayoutTab(layout, context, widgetContext, opener);
      return layout;
    });
  }

  openLayoutInStrongAuthContext(
    layout: IFrameLayout,
    context?: { [key: string]: string | string[] },
    widgetContext?: { widgetId: string; path: string; params: any; hash: string }
  ): void {
    this.workplaceContextService.getProperty('workplace.origin').then((originSourceProp: IWorkplaceProperty) => {
      if (!originSourceProp) {
        return;
      }

      const originUrlKey: string = 'workplace.strong.authentification.' + originSourceProp.value + '.url';
      this.workplaceContextService.getProperty(originUrlKey).then((originUrlProp: IWorkplaceProperty) => {
        if (!originUrlProp) {
          return;
        }

        const id = _.uniqueId('' + new Date().getTime());
        let url = UrlHelper.getOriginFromUrl(originUrlProp.value) + '/iwp/#/layout/' + layout.id;
        if (widgetContext) {
          let path = widgetContext.path ? encodeURIComponent(encodeURIComponent(widgetContext.path)) : '';
          let params = UrlHelper.toQueryString(widgetContext.params) || '-';
          let hash = widgetContext.hash ? encodeURIComponent(encodeURIComponent(widgetContext.hash)) : '';
          url += `/widget/${widgetContext.widgetId}${path}/${params}${hash ? '/' + hash : ''}`;
        } else if (context) {
          url += '/' + UrlHelper.toQueryString(context);
        }
        window.open(
          UrlHelper.appendQueryString(url, {
            c: id,
            lang: this.language.lang,
          }),
          'workplace_strong_auth_' + id
        );
      });
    });
  }

  frameFullScreenToggle(widgetId: string, toggle: boolean): void {
    const layout = this.findLayoutByChildId(widgetId);
    if (!layout) {
      return;
    }
    this.channel.publish(FrameLayoutService.SHOW_FULLSCREEN_FRAME, {
      layoutId: layout.id,
      widgetId,
      toggle,
    });
  }

  setLayoutIFrameSize(widgetId: string, options: { width?: number; height?: number }): void {
    const layout = this.findLayoutByChildId(widgetId);
    if (!layout) {
      return;
    }
    this.channel.publish(FrameLayoutService.SET_IFRAME_SIZE, {
      layoutId: layout.id,
      widgetId,
      options,
    });
  }

  /**
   * If given layout is cached, sync it with the one from the backend.
   * @param {IFrameLayout} layout
   * @return {IFrameLayout} the cached version or the orginal, if there is no cached version
   */
  getSyncedLayout(layout: IFrameLayout): IFrameLayout {
    const fromBackend: IFrameLayout = this.getLayoutById(layout.id);
    if (!fromBackend || fromBackend === null) {
      return layout;
    }
    layout.strongAuth = fromBackend.strongAuth;
    return layout;
  }

  cacheLayout(): void {
    this.channel.publish(FrameLayoutService.CACHE_LAYOUTS);
  }

  /**
   * Searches the layout containing the given widgetId
   * @param widgetId
   */
  findLayoutByChildId(widgetId: string): IFrameLayout {
    return this.store
      .getAll()
      .find(
        (l: IFrameLayout) => -1 !== l.frames.findIndex((frm: ILayoutFrameDescriptor) => frm.widget && frm.widget.id === widgetId)
      );
  }
}
