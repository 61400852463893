import { PopupService } from '../../notification/popup.service';
import _ from 'lodash';
/**
 *
 * NgChange does not support <input type = 'file'...\>, the handler function will never be invoked.
 * This directive solves the problem by adding binding a handler to the regular javascript 'change' event.
 * The FileReader api is used to fetch the data content of the image file.
 * Assign the base 64 encoded data content
 * to the attached ng-model property. See file-input-change directive usage.
 *
 * Files not matching images of type png or jpeg will be rejected.
 * Files exceeding 3 MB in size will also be rejected.
 * Appropriate messages will be shown.
 *
 * @ngInject
 */
export function fileInputChangeDirective(popupService: PopupService): ng.IDirective {
  'use strict';
  return {
    restrict: 'A',
    require: 'ngModel',
    link($scope: angular.IScope, el: JQuery, attrs: ng.IAttributes, ngModel: angular.INgModelController): void {
      el.bind('change', (event: Event): void => {
        var eventTarget: any = event.target,
          file: File = eventTarget.files[0],
          reader: FileReader = new FileReader(),
          fileSizeAccepted: boolean = file.size / (1024 * 1024) <= 3,
          fileTypeAccepted: boolean =
            file.name &&
            file.name !== null &&
            (_.endsWith(file.name.toLowerCase(), '.png') ||
              _.endsWith(file.name.toLowerCase(), '.jpg') ||
              _.endsWith(file.name.toLowerCase(), '.jpeg') ||
              _.endsWith(file.name.toLowerCase(), '.jpe') ||
              _.endsWith(file.name.toLowerCase(), '.jfif') ||
              _.endsWith(file.name.toLowerCase(), '.gif'));

        if (!fileSizeAccepted || !fileTypeAccepted) {
          popupService.showConfirm({
            messageKey: 'dialogs.favorite.popup.file.error.message',
            titleKey: 'dialogs.favorite.popup.file.error.title',
            hideCancel: true,
          });
          return;
        }

        reader.onloadend = function (ev: ProgressEvent): void {
          ngModel.$setViewValue(reader.result);
          $scope.$apply();
        };
        reader.readAsDataURL(file);
      });
    },
  };
}
