'use strict';
import angular from 'angular';

/**
 * Handles image business logic.
 */
export class ImageService {
  private static ENDPOINT: string = './rest/widgets/images';
  private static FORM_DATA_FILE_PROPERTY: string = 'fileUpload';

  private _httpService: ng.IHttpService;
  private _qService: ng.IQService;

  /**
   * @ngInject
   */
  constructor($http: ng.IHttpService, $q: ng.IQService) {
    this._httpService = $http;
    this._qService = $q;
  }

  /**
   * Saves a newly loaded image to the server.
   * Returns a promisse resolving to the new image's Id.
   * @param blob
   */
  saveImage(blob: Blob): ng.IPromise<string> {
    const defered = this._qService.defer<string>();
    const formData: FormData = new FormData();
    formData.append(ImageService.FORM_DATA_FILE_PROPERTY, blob);

    this._httpService.get(`./rest/token/uploadImage`).then((res: angular.IHttpPromiseCallbackArg<string>) => {
      this._httpService
        .post(`${ImageService.ENDPOINT}`, formData, {
          transformRequest: angular.identity,
          headers: {
            'Content-Type': undefined,
            csrftoken: res.headers()['csrftoken'],
          },
        })
        .then((response: angular.IHttpPromiseCallbackArg<string>) => defered.resolve(response.data))
        .catch(() => defered.reject());
    });

    return defered.promise;
  }

  /**
   *
   * @param id
   * @returns a rest uri, that will resolve to an image with the given id.
   */
  getImage(id: string): string {
    return `${ImageService.ENDPOINT}/${id}`;
  }

  /**
   * Delete the image with the given id.
   * Trigger an event to notify interested parts.
   *
   * @param id
   * @returns {IPromise<void>}
   */
  deleteImage(id: string): ng.IPromise<void> {
    return this._httpService.delete(`${ImageService.ENDPOINT}/${id}`, {}).then((): void => {
      return;
    });
  }
}
