/**
 * Created by MARIAB on 16.11.2015.
 */
import { IUser } from '../user/user.model.interface';

/**
 * These are PQM imported tasks.
 */
export interface ITask {
  id: string;
  system: string;
  systemDescription: string[];
  taskResponsibleName: string;
  taskResponsibleDepartment: string;
  taskResponsibleEmail: string;
  taskDescription: string;
  taskTitle: string;
  taskApplication: string;
  taskApplicationDescription: string;
  indiziertAm: Date;
  readPermissions: string[];
  type: string;
  taskResponsible: string;
  taskDue: number;
  taskLink: string;
  termin: Date;
  dateDescription: Date;
  version: number;
  parentId: string;
  important: boolean;
  confidential: boolean;
}

/**
 * Data model for the tasks used by workplace's own task management app
 */
export interface IWorkplaceTask {
  id: string;
  taskTitle: string;
  taskDue: number;
  system: string;
  taskLink: string;
  description: string;
  responsible: IUser;
  priority: ITaskPriority;
  owner: IUser;
  creationDate: number;
  source: string;
  sortPos: number;
  lastModified: number;
  type: TaskTypes;
  status: ITaskStatus;
  deputies: IUser[];
  thumbnail: string;
  parentId: string;
}

/**
 * Task status representation
 */
export interface ITaskStatus {
  id: string;
  status: string;
  description: string;
  lang: string;
}

/**
 * Task priority representation
 */
export interface ITaskPriority {
  id: string;
  priority: string;
  description: string;
  lang: string;
}

export interface ITaskBusinessObject {
  type: string;
  number: string;
  disposable: boolean;
  creator: IUser;
}

export interface IBusinessObject {
  allowedSpecialChars: string;
  autoUppercase: boolean;
  description: string;
  iconCls: string;
  id: string;
  lang: string;
  linkMappings: any[];
  name: string[];
  userSelectable: boolean;
}

export interface IDiffType {
  backgroundColor: string;
  icon: string;
  id: string;
  name: string;
}

export enum TaskTypes {
  TASK,
  SUBTASK,
}

export const TASK_SYSTEMS = {
  GAMS: 'gams',
  GSS: 'gss',
  FLS2: 'fls2_afos',
  PQM: 'pqm',
  DBKR: 'dbkr',
  QMT: 'qmt',
  NEXTONE: 'workplace',
  JIRA: 'jira',
  JIRA_SECURE: 'jira_secure',
  CONTAINER_CALCULATION: 'containercalculation',
  PLAUSI_CHECK: 'plausicheck',
  RSML: 'rsml',
  IAS_AEM: 'ias_aem',
  IAS_FRG: 'ias_frg',
  IAS_PKH: 'ias_pkh',
  IAS_FLS: 'ias_fls',
  VDAM: 'vdam',
  DALI: 'dali',
  NAEL: 'nael',
  FALi: 'fali',
  FTM: 'ftm',
  EMT_MVPM: 'EMT_MVPM',
  CARE: 'care',
};

export interface ITaskSessionUpgrade {
  authLevel: number;
  appName: string;
  refresh?: boolean;
  path?: string;
}

export interface ITaskType {
  id: string;
  name: string;
  label: string;
  iconName: string;
  description: string;
}
