import { IMenuItemModel } from './menu.item.model';
import { UserService } from '../user/user.service';
import { Icons } from '../app.icons';
import { ILayoutServiceChannelEventMessage, LayoutService } from '../layout/layout.service';
import { ActionLogService } from '../actionLog/action-log.service';
import { ActionConstants } from '../actionLog/action-constants';

import { PopupService } from '../notification/popup.service';
import * as uiRouter from 'angular-ui-router';
import _ from 'lodash';
import { WorkplaceContextService } from '../workplace/workplace.context.service';

/**
 * MenuService.
 */
export class MenuService {
  static CHANNEL_SUBMENU: string = 'MENU_SERVICE_SUBMENU_CHANNEL';
  static TOPIC_SUBMENU_SHOW: string = 'MENU_SERVICE_TOPIC_SUBMENU_SHOW';
  static TOPIC_SUBMENU_HIDE: string = 'MENU_SERVICE_TOPIC_SUBMENU_HIDE';
  static TOPIC_SUBMENU_SHOWN: string = 'MENU_SERVICE_TOPIC_SUBMENU_SHOWN';

  activeSubMenuItem: IMenuItemModel;
  channelSubMenu: IChannelDefinition<IMenuServiceSubMenuMessage>;

  private _stateService: uiRouter.IStateService;
  private _deferedMenuItems: ng.IDeferred<IMenuItemModel[]>;
  private _layoutService: LayoutService;
  private _userService: UserService;
  private _actionLogService: ActionLogService;

  /**
   * @ngInject
   * @param menuItems
   */
  constructor(
    $state: uiRouter.IStateService,
    userService: UserService,
    layoutService: LayoutService,
    private readonly $q: ng.IQService,
    postal: IPostal,
    actionLogService: ActionLogService,
    private readonly workplaceContextService: WorkplaceContextService,
    popupService: PopupService,
    private readonly featureFlags: any
  ) {
    this._stateService = $state;
    this._layoutService = layoutService;
    this._deferedMenuItems = $q.defer<IMenuItemModel[]>();
    this.channelSubMenu = postal.channel(MenuService.CHANNEL_SUBMENU);
    this._userService = userService;
    this._actionLogService = actionLogService;

    const menuItems: IMenuItemModel[] = [
      {
        name: 'workplaceMenu',
        menuIds: ['left-titlebar'],
        width: 40,
      },
      {
        name: 'tasks',
        label: 'menu.item.tasks',
        template: `<app-components-task-task-info-item></app-components-task-task-info-item>`,
        menuClass: 'tasks',
        menuContent: {
          template: '<usertasks-directive></usertasks-directive>',
          visibleMenuId: null,
        },
        action: (menuItem: IMenuItemModel, menuId: string): void => {
          if (this.toggleSubMenu(menuItem, menuId)) {
            this._actionLogService.logAction({
              category: ActionConstants.CATEGORY_TASKS,
              action: ActionConstants.ACTION_TASK_MENU,
            });
          }
        },
        menuIds: ['right-titlebar'],
        targetAlign: { 'right-titlebar': 'bottom right' },
        align: { 'right-titlebar': 'top right' },
        width: 40,
      },
    ];

    this._deferedMenuItems.resolve(menuItems);

    this._layoutService.channelEvent.subscribe(
      LayoutService.TOPIC_EVENT_BODY_CLICK,
      (message: ILayoutServiceChannelEventMessage) => {
        /**
         * don't close the menu overlay if the clicked target is on it
         * we want to be able to perform operation on the menu itself
         */
        const overlayParent = $(message.event.target).closest('.menuOverlay-content');
        const addTaskModal = $(message.event.target).closest('.add-task-modal');
        if (!overlayParent[0] && !addTaskModal[0]) {
          this.hideActiveSubMenu();
        }
      }
    );

    this._userService.channel.subscribe(UserService.TOPIC_SELECTED_ROLE_CHANGED, () => this.hideActiveSubMenu());
  }

  /**
   * Gets the the items of a specific menu, using the id
   *
   * These old menus are being replaced by new menus created with Angular 13.
   * The new menus are displayed when a specific feature flag is on
   * The old menus are only displayed if the corresponding new menu feature flag is off
   *
   * Example:
   *
   * The user-info menu developed in Angular 13 uses a feature flag called 'settings-menu'
   * If this feature flag is ON, the new menu will be displayed, which means that the old user-info menu SHALL NOT be displayed
   * The old items are only displayed if the corresponding feature flag is off or doesn't exists
   * @param menuId
   * @returns {ng.IPromise<IMenuItemModel[]>} A list of menu items models that match the given menuId
   */
  getMenuItemsById(menuId: string): ng.IPromise<IMenuItemModel[]> {
    return this.$q
      .all([this._deferedMenuItems.promise, this.workplaceContextService.getFeatureFlags()])
      .then(([menuItems]: [IMenuItemModel[], any]) =>
        menuItems.filter((menuItem: IMenuItemModel) => {
          //Checks if it's in a submenu
          return _.isArray(menuItem.menuIds) && menuItem.menuIds.indexOf(menuId) !== -1;
        })
      );
  }

  /**
   * Gets the total width for the given menu items, considering their visibility in the responsive layout
   * @param menuId
   * @param menuItems
   * @param layoutMessage
   * @returns {number}
   */
  getMenuItemsWidth(
    menuId: string,
    menuItems: IMenuItemModel[],
    responsiveInfo: { xs: boolean; sm: boolean; md: boolean; lg: boolean }
  ): number {
    return this.filterVisibleMenuItems(menuId, menuItems, responsiveInfo).reduce(
      (previousValue: number, menuItem: IMenuItemModel): number => previousValue + menuItem.width,
      0
    );
  }

  /**
   * Filters menu items according to their visibility. Menu items can be displayed in multiple menus, but only in one menu at the same time (exclusive).
   * Responsive layout information might change the menu, the item is displayed in.
   *
   * For example if a menu item has defined the following cls and menuIds:
   * <pre>
   *     cls: { 'right-titlebar': 'hidden-xs hidden-sm' }
   *     menuIds: ['right-titlebar', 'navigationbar']
   * </pre>
   * It will be displayed in the 'right-titlebar' menu as default, because of the order of menuIds. In case we are in a 'xs' or 'sm' layout, the menu item
   * would be hidden for 'right-titlebar'. Then it will be displayed in the next menu, which is 'navigationbar'.
   *
   * @param menuId
   * @param menuItems
   * @param responsiveInfo
   */
  filterVisibleMenuItems(
    menuId: string,
    menuItems: IMenuItemModel[],
    responsiveInfo: { xs: boolean; sm: boolean; md: boolean; lg: boolean }
  ): IMenuItemModel[] {
    return menuItems.filter((menuItem: IMenuItemModel) => {
      const visibleMenuId = menuItem.menuIds.find((id: string) =>
        this.getMenuItemVisibilityForMenu(id, menuItem, responsiveInfo)
      );
      return menuId === visibleMenuId;
    });
  }

  /**
   * Get the visibility of a menuItem for a given menu
   * @param menuId
   * @param menuItem
   * @param responsiveInfo
   * @returns {boolean}
   */
  getMenuItemVisibilityForMenu(
    menuId: string,
    menuItem: IMenuItemModel,
    responsiveInfo: { xs: boolean; sm: boolean; md: boolean; lg: boolean }
  ): boolean {
    return Object.keys(responsiveInfo).reduce(
      (previousValue: boolean, currentValue: string): boolean =>
        previousValue &&
        (!responsiveInfo[currentValue] ||
          !(menuItem.cls && menuItem.cls[menuId] && menuItem.cls[menuId].indexOf(`hidden-${currentValue}`) > -1)),
      true
    );
  }

  /**
   * Toggle the submenu for the given menu item
   * @param menuItem
   * @returns {boolean} true => menu was shown, false => menu was hidden
   */
  toggleSubMenu(menuItem: IMenuItemModel, menuId: string): boolean {
    if (!menuItem) {
      console.warn(`Menu Service -> toggleSubmenu: the given menuItem is undefined. Aborting toggle.`);
      return;
    }
    const visibleMenuId = menuItem.menuContent.visibleMenuId;
    if (visibleMenuId) {
      this.hideSubMenu(menuItem);
      return false;
    }
    if (visibleMenuId !== menuId) {
      this.showSubMenu(menuItem, menuId);
      return true;
    }
  }

  /**
   * Show a submenu for the given menu item
   * @param menuItem
   */
  showSubMenu(menuItem: IMenuItemModel, menuId: string): void {
    if (this.hasActiveSubMenu()) {
      this.hideSubMenu(this.activeSubMenuItem);
    }
    if (this.getMenuItemVisibilityForMenu('right-titlebar', menuItem, this._layoutService.responsiveInfo)) {
      this._layoutService.collapseSidebar('navigationbar');
    }
    menuItem.menuContent.visibleMenuId = menuId;
    this.activeSubMenuItem = menuItem;

    this.channelSubMenu.publish(MenuService.TOPIC_SUBMENU_SHOW, {
      menuItem: menuItem,
      menuId: menuId,
    });
  }

  /**
   * Hide the submenu for a given menu item
   * @param menuItem
   */
  hideSubMenu(menuItem: IMenuItemModel): void {
    if (menuItem === this.activeSubMenuItem) {
      this.activeSubMenuItem = null;
    }
    this.channelSubMenu.publish(MenuService.TOPIC_SUBMENU_HIDE, {
      menuItem: menuItem,
      menuId: menuItem.menuContent.visibleMenuId,
    });
    menuItem.menuContent.visibleMenuId = null;
  }

  /**
   * Hide the currently active submenu
   */
  hideActiveSubMenu(): void {
    if (this.hasActiveSubMenu()) {
      this.hideSubMenu(this.activeSubMenuItem);
    }
  }

  /**
   * Checks whether there is a active submenu item
   */
  hasActiveSubMenu(): boolean {
    return typeof this.activeSubMenuItem !== 'undefined' && this.activeSubMenuItem !== null;
  }

  /**
   * Signal the fact that the active submenu showing is rendered and visible.
   */
  publishMenuShown(): void {
    this.channelSubMenu.publish(MenuService.TOPIC_SUBMENU_SHOWN, {
      menuItem: this.activeSubMenuItem,
      menuId: this.activeSubMenuItem.menuContent.visibleMenuId,
    });
  }
}

/**
 * Describes messages published on the MenuService's channel for Topic TOPIC_SUB_MENU
 */
export interface IMenuServiceSubMenuMessage {
  menuItem: IMenuItemModel;
  menuId: string;
}
