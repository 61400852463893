import { Component } from '../component';
import { DASH_NAME_INPUT_LINE_BREAK_REGEX, ENTER_KEY_CODE } from './restrictedText.utils';

('use strict');

export class RestrictedTextComponent extends Component {
  onInputFocus: (callbackParams: { isFocused: boolean }) => void;
  maxLineLimitReached: boolean;
  isEnterKey: boolean;
  required: boolean;
  errorText: string;
  maxLength: number;
  maxLines: number;
  numberOfLines: number;
  textModel: string;
  resetActive: boolean;
  disableFocus: boolean;
  placeholder?: string;
  titleInput?: string;

  private dashRegExp: RegExp = new RegExp(DASH_NAME_INPUT_LINE_BREAK_REGEX);

  /**
   * @ngInject()
   */
  constructor() {
    super();
  }

  updateRestriction($event: KeyboardEvent): void {
    $event.stopPropagation();
    if ($event.key !== ENTER_KEY_CODE || !this.textModel) {
      this.isEnterKey = false;
      this.maxLineLimitReached = false;
      return;
    }
    this.isEnterKey = true;
    this.numberOfLines = (this.textModel.match(this.dashRegExp) || []).length + 1;
    this.maxLineLimitReached = this.numberOfLines >= this.maxLines;
    if (this.maxLineLimitReached) {
      $event.preventDefault();
    }
  }

  resetTextarea(): void {
    this.textModel = null;
    this.maxLineLimitReached = false;
  }
}
