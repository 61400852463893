import { IWidgetDescriptor } from './widget.descriptor.model';
import JSData from 'js-data';
import _ from 'lodash';

/**
 * @author Tobias Straller [Tobias.Straller.bp@nttdata.com]
 *
 * @ngInject
 */
export function widgetDefinitionStoreFactory(DS: JSData.DS): JSData.DSResourceDefinition<IWidgetDescriptor<any>> {
  'use strict';

  /**
   * THIS STORE SHOULD NOT BE USED ANYMORE
   * we have to test if ADMIN UI would be affected by removing it
   */
  const convertSettingsProperties: Function = function (data: IWidgetDescriptor<any>): void {
    data.customSettings = _.isString(data.customSettings) ? data.customSettings : JSON.stringify(data.customSettings);
    data.defaultSettings = _.isString(data.defaultSettings) ? data.defaultSettings : JSON.stringify(data.defaultSettings);
    data.settings = _.isString(data.settings) ? data.settings : JSON.stringify(data.settings);
  };

  const widgetDefinition = DS.defineResource<IWidgetDescriptor<any>>({
    name: 'widgetDefinition',
    idAttribute: 'id',
    endpoint: './rest/widgets',
    /**
     * Convert server side custom settings and default settings
     *
     * @param resource
     * @param data
     */
    afterInject: function (
      resource: JSData.DSResourceDefinition<any>,
      data: IWidgetDescriptor<any>[] | IWidgetDescriptor<any>
    ): void {
      let custSet: any;
      let defSet: any;

      if (!data) {
        return;
      }

      if (_.isArray(data)) {
        data.forEach((descr: IWidgetDescriptor<any>) => {
          custSet =
            descr.customSettings && _.isString(descr.customSettings) ? JSON.parse(descr.customSettings) : descr.customSettings;
          defSet =
            descr.defaultSettings && _.isString(descr.defaultSettings)
              ? JSON.parse(descr.defaultSettings)
              : descr.defaultSettings;
          descr.settings = _.merge({}, custSet, defSet);
          descr.customSettings = custSet;
          descr.defaultSettings = defSet;
        });
        return;
      }

      custSet =
        (<IWidgetDescriptor<any>>data).customSettings && _.isString((<IWidgetDescriptor<any>>data).customSettings)
          ? JSON.parse((<IWidgetDescriptor<any>>data).customSettings)
          : (<IWidgetDescriptor<any>>data).customSettings;
      defSet =
        (<IWidgetDescriptor<any>>data).defaultSettings && _.isString((<IWidgetDescriptor<any>>data).defaultSettings)
          ? JSON.parse((<IWidgetDescriptor<any>>data).defaultSettings)
          : (<IWidgetDescriptor<any>>data).defaultSettings;

      (<IWidgetDescriptor<any>>data).settings = _.merge({}, defSet, custSet);
      (<IWidgetDescriptor<any>>data).customSettings = custSet;
      (<IWidgetDescriptor<any>>data).defaultSettings = defSet;
    },
    /**
     * Server needs to recieve custom settings and default settings as string, because they are saved as is,
     *
     * @param resource
     * @param data
     * @param cb
     */
    beforeCreate: function (resource: JSData.DSResourceDefinition<any>, data: IWidgetDescriptor<any>, cb: Function): void {
      convertSettingsProperties(data);
      cb(null, data);
    },
    /**
     * Server needs to recieve custom settings and default settings as string, because they are saved as is,
     * as CLOB in the DB
     *
     * @param resource
     * @param data
     */
    beforeUpdate: function (resource: JSData.DSResourceDefinition<any>, data: IWidgetDescriptor<any>, cb: Function): void {
      convertSettingsProperties(data);
      cb(null, data);
    },
    watchChanges: false,
  });

  return widgetDefinition;
}
