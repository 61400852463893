import { IAttributes } from 'angular';

/**
 * Wrapper directive for {@link clickOutside}.
 *
 * @returns {ng.IDirective}
 *
 * @ngInject
 */
export function clickOutsideDirective($timeout: ng.ITimeoutService, $document): ng.IDirective {
  'use strict';

  return {
    restrict: 'A',
    scope: {
      onClickedOutside: '&',
    },
    link: function (scope: any, element: JQLite, attrs: IAttributes): void {
      let onClick = function (event) {
        let isChild = $(element).has(event.target).length > 0;
        let isSelf = element[0] === event.target;
        let isInside = isChild || isSelf;
        if (!isInside) {
          scope.$apply(() => {
            scope.onClickedOutside();
          });
        }
      };
      attrs.$observe('clickOutside', function (newValue): void {
        if (newValue === 'true') {
          $document.bind('click', onClick);
        } else if (newValue === 'false') {
          $document.unbind('click', onClick);
        }
      });
      scope.$on('$destroy', () => $document.unbind('click', onClick));
    },
  };
}
