import { Component } from '../component';

/**
 * Depiction of an action item.
 */
('use strict');
export class ActionItem extends Component {
  iconCls: string;
  textKey: string;
  htmlContent: string;
  href: string;
}
