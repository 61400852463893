export class Icons {
  static HELP: string = 'iwp-icon-gen_help';
  static APP_DEFAULT: string = 'iwp-icon-default_app';
  static DASHBOARD_DEFAULT: string = 'iwp-icon-iwp_dashboard';
  static DASHBOARD_ADD: string = 'iwp-icon-add-circle';
  static APP_GROUP: string = 'iwp-icon-zeb_folder';
  static EMPLOYEE_DEFAULT: string = 'iwp-icon-gen_user';
  static FAVORITE_DEFAULT: string = 'iwp-icon-gen_link';
  static MESSAGES_DEFAULT: string = 'iwp-icon-gen_email';
  static MESSAGES_INFO: string = 'iwp-icon-gen_info';
  static MESSAGES_WARN: string = 'iwp-icon-gen_warning';
  static CLOSE: string = 'iwp-icon-gen_close';
  static LOGOUT: string = 'iwp-icon-gen_logout';
}
