import { Component } from '../components/component';
import { ITab } from '../tab/tab';
import { ITabContent } from '../tab/tab.content.interface';

export class LayoutSplit extends Component {
  canSplit: (params: { direction: string; index: number }) => boolean;
  onSplit: (params: {
    transfer: { tab: ITab<ITabContent>; tabview: string };
    params: { direction: string; index: number };
  }) => void;
  timeoutService: ng.ITimeoutService;

  /**
   * @ngInject
   */
  constructor($timeout: ng.ITimeoutService) {
    super();
    this.timeoutService = $timeout;
  }

  /**
   * Returns whether a split is possible
   * @param tab
   */
  canSplitMask(direction: string, index: number): boolean {
    return this.canSplit({ direction, index });
  }

  /**
   * Tab has been dragged on a split mask.
   * @param transfer
   */
  onSplitMaskDrop(transfer: { tab: ITab<ITabContent>; tabview: string }, params: { direction: string; index: number }): void {
    this.timeoutService(() => {
      this.onSplit({ transfer, params });
    });
  }

  destroy(): void {
    this.timeoutService = null;
  }
}
