export type ToastNotification = {
  toastType: string;
  messageKey?: string;
  messageParams?: any;
  titleKey?: string;
  titleParams?: any;
  options?: any;
  type?: string;
  message?: string;
  title?: string;
};

export enum ToastType {
  INFO = 'info',
  MESSAGE = 'message',
  TRANSLATED_MESSAGE = 'translatedMessage',
  SUCCESS = 'success',
  WARN = 'warn',
  ERROR = 'error',
}
