import { BaseDialog } from './base-dialog';
import { WorkplaceNativeDeviceService } from '../../workplace/workplace-native-device.service';

export class DownloadDialog extends BaseDialog {
  /**
   * @ngInject
   * @param workplaceNativeDeviceService
   */
  constructor(private workplaceNativeDeviceService: WorkplaceNativeDeviceService) {
    super();
  }

  get filesInProgess(): { [key: string]: number } {
    return this.workplaceNativeDeviceService.filesInProgress;
  }
}
