import { IFrameLayout } from './frame-layout.model.interface';
import JSData from 'js-data';

/**
 * Store for frame layouts.
 *
 * @ngInject
 */
export function frameLayoutStoreFactory(DS: JSData.DS): JSData.DSResourceDefinition<IFrameLayout> {
  'use strict';

  return DS.defineResource<IFrameLayout>({
    name: 'appsLayouts',
    endpoint: './rest/layouts',
    watchChanges: false,
  });
}
