import {
  ALL_OFFICE_EXTENSIONS,
  ALL_OFFICE_PREFIXES,
  ENUM_OFFICE_OPEN_MODES,
  OFFICE_OPEN_EDIT_MODE,
  OFFICE_OPEN_VIEW_MODE,
} from './office-uri';

('use strict');
export class OfficeUriService {
  private office_extensions: any;
  private office_prefixes: any;
  private office_open_modes: any;
  private office_view_mode: any;
  private office_edit_mode: any;

  /**
   * @ngInject
   */
  constructor() {
    this.office_extensions = ALL_OFFICE_EXTENSIONS;
    this.office_prefixes = ALL_OFFICE_PREFIXES;
    this.office_open_modes = ENUM_OFFICE_OPEN_MODES;
    this.office_view_mode = OFFICE_OPEN_VIEW_MODE;
    this.office_edit_mode = OFFICE_OPEN_EDIT_MODE;
  }

  /**
   * Check if link is of OFFICE kind (eg. docx, xls, pptm)
   * returns the extension if it's an office link or null otherwise
   * @param link
   */
  checkOfficeURILink(link: string): string {
    if (link) {
      const link_segments = link.split('.');
      const extension = link_segments && link_segments.length > 0 ? link_segments[link_segments.length - 1] : null;
      return extension && this.office_extensions.includes(extension) ? extension : null;
    }
    return null;
  }

  /**
   * Create office link
   * @param link
   * @param extension
   * @param open_mode
   */
  createOfficeURILink(link: string, extension: string, open_mode: string): string {
    return link && extension && this.office_extensions.includes(extension)
      ? link.startsWith(this.office_prefixes[extension])
        ? link
        : `${this.office_prefixes[extension]}:${
            open_mode === this.office_open_modes.EDIT ? this.office_edit_mode : this.office_view_mode
          }${link.replace(/\\/g, '/')}`
      : null;
  }

  /**
   * Open office link in new tab
   * @param link
   */
  openOfficeURILink(link: string): void {
    window.open(link);
  }
}
