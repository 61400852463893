import { Component } from '../component';
import { TabviewContent } from './tabview.content';
import tabviewContentTemplate from './tabview.content.html';
/**
 * Wraps {@link Tabview} component into a directive.
 *
 * @author Tobias Straller [Tobias.Straller.bp@nttdata.com]
 *
 * @ngInject
 */
export function tabviewContentDirective(): ng.IDirective {
  'use strict';
  return Component.createDirective(TabviewContent, {
    restrict: 'E',
    scope: {
      content: '=',
    },
    templateUrl: tabviewContentTemplate,
  });
}
