import { IDashboard, IDashboardCategory } from './dashboard.model.interface';
import JSData from 'js-data';
import _ from 'lodash';

import { IWidgetDescriptor } from '../widget/widget.descriptor.model';
/**
 * Creates the dashboard store.
 * There is currently no backend to store dashboards, therefore we are inlining .json files which contain the definitions for the specific dashboards.
 * Once the backend has been created, the definition files can be removed.
 *
 * @author Tobias Straller [Tobias.Straller@nttdata.com]
 *
 * @ngInject
 */
export function dashboardStoreFactory(DS: JSData.DS): JSData.DSResourceDefinition<IDashboard> {
  'use strict';

  var dashboardResource = DS.defineResource<IDashboard>({
    name: 'dashboard',
    idAttribute: 'name',
    endpoint: './rest/v2/dashboards',
    watchChanges: false,
    beforeInject: function (resource: JSData.DSResourceDefinition<any>, data: IDashboard[] | IDashboard): void {
      /**
       * MWP-8143 - Dashboard & widgets performance optimization
       * Dashboard initial structure was modified.
       * Name is not sent anymore, but is mandatory for IApplication which IDashboard extends
       * Therefore we copy it from the id field (let's see if this work or we need a bigger change)
       */
      [].concat(data).forEach((dashboard: IDashboard) => {
        /** import fields that are used in FE, but don't come from BE anymore */
        dashboard.name = dashboard.id;
        dashboard.parentName = dashboard.parentId;
        dashboard.description = !dashboard.description && dashboard.title ? dashboard.title : dashboard.description;
        /**
         * used to verify if the dashboard is in initialState or not
         * initialState = false, means details are updated and dashboard is ready for other operations
         * a dashboard in initial state doesn't have categories yet
         */
        dashboard.initialState = !dashboard.categories;
      });
    },
    afterInject: function (resource: JSData.DSResourceDefinition<any>, data: IDashboard[] | IDashboard): void {
      /**
       * MWP-8143 - Dashboard & widgets performance optimization
       * Move widget afterInject to dashboard store, since we don't have a widget store anymore
       * Convert server side custom settings and default settings
       */
      if (!data) {
        return;
      }

      if (_.isArray(data)) {
        /** data is array of dashboards */
        const storedDashboards = data.filter((d: IDashboard) => !d.initialState);
        if (storedDashboards && storedDashboards.length > 0) {
          [].concat(storedDashboards).forEach((d: IDashboard) => {
            d.categories.forEach((categ: IDashboardCategory) => {
              categ.widgets.map((widget: IWidgetDescriptor<any>) => {
                const custSet =
                  widget.customSettings && _.isString(widget.customSettings)
                    ? JSON.parse(widget.customSettings)
                    : widget.customSettings;
                const defSet =
                  widget.defaultSettings && _.isString(widget.defaultSettings)
                    ? JSON.parse(widget.defaultSettings)
                    : widget.defaultSettings;
                widget.settings = _.merge({}, defSet, custSet);
                widget.customSettings = custSet;
                widget.defaultSettings = defSet;
                return widget;
              });
            });
          });
        }
        return;
      } else {
        /** data is a single dashboard */
        if (!data.initialState) {
          data.categories.forEach((categ: IDashboardCategory) => {
            categ.widgets.map((widget: IWidgetDescriptor<any>) => {
              const custSet =
                widget.customSettings && _.isString(widget.customSettings)
                  ? JSON.parse(widget.customSettings)
                  : widget.customSettings;
              const defSet =
                widget.defaultSettings && _.isString(widget.defaultSettings)
                  ? JSON.parse(widget.defaultSettings)
                  : widget.defaultSettings;
              widget.settings = _.merge({}, defSet, custSet);
              widget.customSettings = custSet;
              widget.defaultSettings = defSet;
              return widget;
            });
          });
        }
      }
    },
  });
  return dashboardResource;
}
