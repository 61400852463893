import angular from 'angular';
/**
 * Basic functionality needed by custom components.
 * Supports creating angular js directives from components.
 */
export class Component {
  componentOnRender: (params: { instance: Component }) => void;

  /**
   * Creates a directive configuration object.
   * The scope is bound to the component instance.
   * The controller scope is made available as <tt>vm</tt>.
   *
   * @param component
   * @param config
   * @returns {any}
   */
  static createDirective(clazz: Function, config: ng.IDirective): ng.IDirective {
    const directive = Object.assign(
      {},
      {
        controller: clazz,
        controllerAs: 'vm',
        bindToController: true,
        replace: true,
        link: function link(scope: angular.IScope, el: JQuery, attr: angular.IAttributes, component: Component): void {
          component.link.apply(this, arguments);
        },
      },
      config
    );
    directive.scope = Object.assign({}, directive.scope, {
      componentOnRender: '&',
    });
    return directive;
  }

  static renderComponentTo<T extends Component>(
    scope: ng.IScope,
    componentType: new (...args: any[]) => T,
    templateUrl: string,
    targetEl: JQuery
  ): ng.IPromise<T> {
    var injector: angular.auto.IInjectorService = angular.element(targetEl).injector();
    var $controller = injector.get<ng.IControllerService>('$controller');
    var $http = injector.get<ng.IHttpService>('$http');
    var $compile = injector.get<ng.ICompileService>('$compile');
    var $templateCache = injector.get<ng.ITemplateCacheService>('$templateCache');

    return $http.get(templateUrl, { cache: $templateCache }).then((response: ng.IHttpPromiseCallbackArg<string>): T => {
      var component = $controller(componentType, { $scope: scope });
      scope['vm'] = component;
      var el = $(response.data).appendTo(targetEl);
      $compile(el)(scope);
      component.onRenderComponent(el, scope);
      scope.$on('$destroy', () => {
        component.destroy(el);
        el.remove();
      });
      return component;
    });
  }

  /**
   * Creates a configuration object for a recursive directive.
   *
   * @param clazz
   * @param config
   * @param $compile
   * @returns {any}
   */
  static createRecursiveDirective(clazz: Function, config: ng.IDirective, $compile: ng.ICompileService): ng.IDirective {
    return Object.assign(
      {
        compile(el: JQuery<HTMLElement>, link: any): ng.IDirectivePrePost {
          // Break the recursion loop by removing the contents
          var contents = el.contents().remove();
          var compiledContents;
          return {
            pre: link && link.pre ? link.pre : null,
            /**
             * Compiles and re-adds the contents
             */
            post: function (scope: ng.IScope, element: JQuery, attr: ng.IAttributes, component: Component): void {
              // Compile the contents
              if (!compiledContents) {
                compiledContents = $compile(contents.text());
              }
              // Re-add the compiled contents to the element
              compiledContents(scope, function (clone: JQuery): void {
                element.append(clone);
              });

              // Call the post-linking function, if any
              if (link && link.post) {
                link.post.apply(null, arguments);
              }
              component.link.apply(this, arguments);
            },
          };
        },
      },
      Component.createDirective(clazz, config)
    );
  }

  /**
   * Setup your component. Attributes are available.
   */
  setupComponent(scope: ng.IScope): void {
    // empty implementation
  }

  /**
   * Component has been rendered. Element is available
   * @param el
   */
  onRenderComponent(el: JQuery, scope: ng.IScope): void {
    // empty implementation
  }

  /**
   * Destroy component
   */
  destroy(el: JQuery): void {
    // empty implementation
  }

  /**
   * Implements the link function used for directives
   *
   * @param scope
   * @param el
   * @param attr
   * @param component
   */
  link(scope: angular.IScope, el: JQuery, attr: angular.IAttributes, component: Component): void {
    component.setupComponent(scope);
    component.onRenderComponent(el, scope);
    if (typeof component.componentOnRender !== 'undefined') {
      component.componentOnRender({ instance: component });
    }
    // make sure our component is being destroyed
    scope.$on('$destroy', () => {
      component.destroy(el);
    });
  }
}
