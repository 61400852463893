import { BaseDialog } from './base-dialog';
import { IUser } from '../../user/user.model.interface';
import { ISharingSearchSource } from '../../favorite/search-source.model.interface';
import { DATE_FORMAT, DATE_FORMAT_PLACEHOLDERS, ILanguage } from '../../../util/language.model.interface';
import { ISharingChip } from '../../favorite/favorite-chip.model.interface';
import { User } from '../../user/user.model';
import { EmployeeService } from '../../employee/employee.service';
import { UserService } from '../../user/user.service';
import { INewTaskData, TasksService } from '../../task/tasks.service';
import { NotificationService } from '../../notification/notification.service';

export class AddTaskMobile extends BaseDialog {
  selectedTaskResponsible: IUser = null;
  selectedTaskDate: Date = null;
  selectedTaskTitle: string = null;
  selectedTaskAttachment: any = null;
  searchResponible: boolean = false;
  searchSource: ISharingSearchSource = null;
  searchTerm: string = '';
  taskDateOpen: boolean = false;
  placeholder: string;
  format: string;
  dateOptions: any = {
    showWeeks: false,
    startingDay: 1,
    closeOnDateSelection: true,
  };
  titleAutoFocus: boolean = false;

  private UNASIGNED: IUser = {
    uid: null,
    name: '',
    surname: '',
    userId: 'NONE',
    email: null,
    phone: null,
    external: false,
    strongAuth: null,
    department: null,
  };

  private _translateService: angular.translate.ITranslateService;
  private _timeout: ng.ITimeoutService;
  private _employeeService: EmployeeService;
  private _userService: UserService;
  private _tasksService: TasksService;
  private _notificationService: NotificationService;
  private _user: User;

  /**
   * @ngInject
   */
  constructor(
    $translate: angular.translate.ITranslateService,
    private language: ILanguage,
    $timeout: ng.ITimeoutService,
    employeeService: EmployeeService,
    tasksService: TasksService,
    userService: UserService,
    notificationService: NotificationService
  ) {
    super();
    this._translateService = $translate;
    this._timeout = $timeout;
    this._employeeService = employeeService;
    this._userService = userService;
    this._tasksService = tasksService;
    this._notificationService = notificationService;
  }

  $onInit() {
    this.placeholder = DATE_FORMAT_PLACEHOLDERS[this.language.locale.toLowerCase()] || '';
    this.format = DATE_FORMAT[this.language.locale.toLowerCase()] || '';

    this._translateService(['sidebar.addTask.user.unasigned']).then((t: { [key: string]: string }) => {
      this.UNASIGNED.name = t['sidebar.addTask.user.unasigned'];
    });
    this._userService.getUser().then((user: User) => {
      this._user = user;
      this.selectedTaskResponsible = user;
      this.searchTerm = this.selectedTaskResponsible ? this.getLabelForUser(this.selectedTaskResponsible) : '';
    });

    this.initTaskResponsible();
    this.titleAutoFocus = true;
  }

  // Title
  clearTaskTitle(event: MouseEvent): void {
    event.stopPropagation();
    this.selectedTaskTitle = null;
  }

  // Date
  openTaskDatepicker(): void {
    this.taskDateOpen = true;
  }

  clearTaskDatepicker(event: MouseEvent): void {
    event.stopPropagation();
    this.selectedTaskDate = null;
    this.taskDateOpen = false;
  }

  // Attachment
  removeAttachment(event: MouseEvent): void {
    event.stopPropagation();
    this.resetAttachment();
  }

  resetAttachment(): void {
    this.selectedTaskAttachment = null;
    // clears the upload element so we can add the same file twice if we removed it
    (<HTMLInputElement>document.getElementById('add-task-attachment')).value = null;
  }

  onUploadAttachment(event: any): void {
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    if (file && file.size > 3145728) {
      this._notificationService.showError('notification.max.file.size');
      return;
    }
    this.selectedTaskAttachment = file;
  }

  // Responsible
  enableSearchResponsible(event: MouseEvent): void {
    event.stopPropagation();
    this.searchResponible = true;
    this.doSearchResponsible();
  }

  doSearchResponsible(): void {
    this.searchSource.searchFunction(this.searchTerm);
  }

  initTaskResponsible(): void {
    this.searchSource = {
      iconCls: 'iwp-icon-gen_user',
      text: 'dialogs.favorite.share.search.for.person.text',
      placeholder: 'dialogs.favorite.share.person.search.placeholder',
      searchFunction: this.searchUsers.bind(this),
      createChip: (): ng.IPromise<ISharingChip> => {
        return;
      },
      updateSharedObject: (): void => {
        return;
      },
      tableConfig: {
        headers: [
          {
            title: 'dialogs.favorite.share.search.table.column.header.name',
            dataField: (user: IUser): string => this.getLabelForUser(user),
            dataIconCls: (user: IUser): string => (user.qaccount ? 'iwp-icon-gen_user_add' : 'iwp-icon-gen_user_c'),
          },
        ],
        data: [],
        disableCondition: this.hasQNumber.bind(this),
        tooManyUsersMessage: 'sidebar.addTask.responsible.tooManyResults',
        nbDisplayedResults: 5,
      },
    };
  }

  searchUsers(term: string, scope: string): void {
    if (!term || term === '') {
      this._timeout(() => (this.searchSource.tableConfig.data = null), 0);
      return;
    }

    if (term.length < 3) {
      return;
    }
    this._employeeService.findEmployees(
      {
        searchTerm: term,
      },
      (users: IUser[]) => {
        this.searchSource.tableConfig.data = [];
        users.forEach((user: IUser) => this.searchSource.tableConfig.data.push(user));
      }
    );
  }

  resetSearch(): void {
    this.searchTerm = this.selectedTaskResponsible ? this.getLabelForUser(this.selectedTaskResponsible) : '';
    this.searchResponible = false;
    this.searchSource.tableConfig.data = [];
  }

  handleEntryClicked(entry: any): void {
    if (this.searchSource.tableConfig.disableCondition(entry)) {
      return;
    }
    this.selectedTaskResponsible = entry;
    this.searchResponible = false;
    this.searchSource.tableConfig.data = [];
    this.searchTerm = this.selectedTaskResponsible ? this.getLabelForUser(this.selectedTaskResponsible) : '';
  }

  showRemoveResponsibleButton(): boolean {
    return !this.searchResponible && this.selectedTaskResponsible.userId !== 'NONE';
  }

  removeResponsible(event: any): void {
    event.stopPropagation();
    this.selectedTaskResponsible = this.UNASIGNED;
    this.searchTerm = this.selectedTaskResponsible ? this.getLabelForUser(this.selectedTaskResponsible) : '';
  }

  // Tasks buttons
  saveTaskAdd(event: MouseEvent, reset: boolean): void {
    event.stopPropagation();
    this.titleAutoFocus = false;
    const taskObject: INewTaskData = {
      title: this.selectedTaskTitle,
      date: this.selectedTaskDate,
      responsible: this.selectedTaskResponsible,
      attachment: this.selectedTaskAttachment,
    };

    this._tasksService.createTask(taskObject).then((response: any) => {
      this.resetTaskForm();
      // If reset is true, modal stays open and form is reset for next task
      if (reset) {
        this.titleAutoFocus = true;
      } else {
        super.cancel();
      }
    });
  }

  resetTaskForm(): void {
    this.selectedTaskTitle = null;
    this.selectedTaskDate = null;
    this.selectedTaskResponsible = this._user;
    this.searchSource.tableConfig.data = [];
    this.searchTerm = this.selectedTaskResponsible ? this.getLabelForUser(this.selectedTaskResponsible) : '';
    this.searchResponible = false;
    this.resetAttachment();
  }

  onKeyDown(event: any): void {
    if (event.key === 'Escape') {
      this.resetTaskForm();
      super.cancel();
      return;
    }

    if (event.key !== 'Enter' || !this.selectedTaskTitle) {
      return;
    }
    this.saveTaskAdd(event, false);
  }

  getLabelForUser(user: IUser): string {
    if (!user) {
      return '';
    }
    if (this.getLabelForUser[user.userId]) {
      return this.getLabelForUser[user.userId];
    }

    const fullName = `${user.name} ${user.surname}`.trim();
    if (user.external) {
      const dep = this._translateService.instant('sidebar.deputizer.external.department', { department: user.department });
      this.getLabelForUser[user.userId] = `${fullName}, ${dep}`;
      return `${fullName}, ${dep}`;
    }

    this.getLabelForUser[user.userId] = user.department ? `${fullName}, ${user.department}` : `${fullName}`;
    return user.department ? `${fullName}, ${user.department}` : `${fullName}`;
  }

  // Private functions
  private hasQNumber(user: IUser): boolean {
    return !(user && user !== null && User.prototype.hasQNumber.call(user));
  }
}
