'use strict';

export const DASH_NAME_DB_LINE_BREAK = '\n';
export const DASH_NAME_HTML_LINE_BREAK = '<br/>';
export const DASH_NAME_INPUT_LINE_BREAK_REGEX = /[(\n|\r)]/g;
export const ENTER_KEY_CODE = 'Enter';

export function htmlToDbLineBreak(name: string): string {
  'use strict';
  return name ? name.replace(DASH_NAME_HTML_LINE_BREAK, DASH_NAME_DB_LINE_BREAK) : '';
}

export function inputToDbLineBreak(name: string): string {
  'use strict';
  return name ? name.replace(DASH_NAME_INPUT_LINE_BREAK_REGEX, DASH_NAME_DB_LINE_BREAK) : '';
}

export function dbToHtmlLineBreak(name: string): string {
  'use strict';
  return name ? name.replace(DASH_NAME_DB_LINE_BREAK, DASH_NAME_HTML_LINE_BREAK) : '';
}

export function isNameMultiline(name: string): boolean {
  'use strict';
  return new RegExp(/[(\n|\r)]|(<br\/?>)/).test(name);
}
