import { ShareType } from '../../components/dialogs/share-dialog-config.interface';
import { MobileService } from '../../mobile/mobile.service';
import { NotificationService } from '../../notification/notification.service';
import { PopupService } from '../../notification/popup.service';
import { ToastNotification, ToastType } from '../../notification/toast-notification.model';
import { TabService } from '../../tab/tab.service';
import { MwpStoreService } from '../../web-components/mwp-store/mwp-store.service';
import { IWidgetDescriptor } from '../../widget/widget.descriptor.model';
import { WidgetService } from '../../widget/widget.service';
import { IUserSettingsStoreable } from '../../workplace/user-settings-storeable.interface';
import { IWorkplaceProperty } from '../../workplace/workplace-property.interface';
import { WorkplaceContextService } from '../../workplace/workplace.context.service';
import { IDashboard } from '../dashboard.model.interface';
import { DashboardService, IDashboardServiceMessage } from '../dashboard.service';
import { StoreItemTypeEnum } from '../../web-components/mwp-store/mwp-store-type';
import { APP_CATALOG } from '../../apps/application.model.interface';
import { AppsService } from '../../apps/apps.service';
import { ITab } from '../../tab/tab';
import { ITabContent } from '../../tab/tab.content.interface';

export class DashboardActionsService implements IUserSettingsStoreable {
  private static CHANNEL: string = 'DashboardActionsServiceChannel';
  private static DASHBOARD_CHANNEL: string = 'DashboardServiceChannel';
  private static DASHBOARD_REPLACED: string = 'DashboardServiceDashboardReplaced';
  private static DASHBOARD_DELETED: string = 'DashboardServiceDashboardDeleted';
  readonly settingsStorageKey = 'workplace';
  private channel: IChannelDefinition<IDashboardServiceMessage>;
  private dashboardChannel: IChannelDefinition<IDashboardServiceMessage>;

  /**
   * @ngInject
   */
  constructor(
    private readonly dashboardService: DashboardService,
    private readonly notificationService: NotificationService,
    private readonly popupService: PopupService,
    private readonly widgetService: WidgetService,
    private readonly tabService: TabService,
    private readonly mwpStoreService: MwpStoreService,
    private readonly mobileService: MobileService,
    private readonly workplaceContextService: WorkplaceContextService,
    private readonly appsService: AppsService,
    postal: IPostal
  ) {
    this.channel = postal.channel(DashboardActionsService.CHANNEL);
    this.dashboardChannel = postal.channel(DashboardActionsService.DASHBOARD_CHANNEL);
  }

  getSettingsStoragePath(): string[] {
    return ['dashboards'];
  }

  /**
   * Fetches the parent dashboard from the cache.
   * Removes the parent dashboard from the tab's history and from the recently opened list.
   * Replaces the current tabs content with the cloned dashboard information.
   */
  refreshDashboard(
    parentDashboard: { name: string },
    clonedDashboard: { name: string; title: string; parentName: string; sharedBy: string }
  ): void {
    if (!parentDashboard || !clonedDashboard) {
      return;
    }

    this.dashboardService
      .getTabServiceObject(clonedDashboard as unknown as IDashboard)
      .then((tabServiceObject: ITab<ITabContent>) => {
        this.tabService.replaceTab(parentDashboard.name, tabServiceObject);
        this.tabService.removeFromTabsHistory(parentDashboard.name);
        this.notificationService.showSuccess('dashboard.clone.toast.message.success', {
          dashboardTitle: clonedDashboard.title,
        });
      });
  }

  /**
   * Fetches the original system dashboard and the cloned dashboard from the cache.
   * Removes the cloned dashboard from the tab's history and from the recently opened list.
   * Replaces the current tabs content.
   */
  resetDashboard(parentDashboardId: string, deletedDashboardId: string, deletedDashboardTitle: string): void {
    if (!parentDashboardId || !deletedDashboardId) {
      return;
    }
    const parentDashboard = this.dashboardService.findDashboardInCache(parentDashboardId);
    if (!parentDashboard) {
      return;
    }
    this.dashboardService.getTabServiceObject(parentDashboard).then((tabServiceObject: ITab<ITabContent>) => {
      this.tabService.replaceTab(deletedDashboardId, tabServiceObject);
      this.tabService.removeFromTabsHistory(deletedDashboardId);
      this.dashboardService.removeFromRecentlyOpened(deletedDashboardId);
      this.notificationService.showSuccess('dialogs.dashboard.reset.success', { name: deletedDashboardTitle });
    });
  }

  openShareDialog(dashboardId: string, dashboardTitle: string): void {
    this.popupService
      .showModalWindow('shareDashboardDialog', {
        titleKey: 'dialogs.dashboard.share.title',
        okButtonKey: 'dialogs.share.buttons.ok.label',
        cancelButtonKey: 'dialogs.share.buttons.cancel.label',
        id: dashboardId,
        title: dashboardTitle,
        shareType: ShareType.DASHBOARD,
      })
      .then((result: number) => {
        if (result > 0) {
          this.notificationService.showInfo('dialogs.share.success', { name: dashboardTitle });
        } else {
          this.notificationService.showWarn('dialogs.share.unshare', { name: dashboardTitle });
        }
        this.dashboardService.refreshDashboard(dashboardId);
      });
  }

  shareTile(item) {
    const { id, name } = item;

    this.popupService
      .showModalWindow('shareDialog', {
        id,
        titleKey: 'dialogs.item.share.link.title',
        okButtonKey: 'dialogs.share.buttons.ok.label',
        cancelButtonKey: 'dialogs.share.buttons.cancel.label',
        shareType: ShareType.WIDGET,
        title: name,
      })
      .then((result: number) => {
        if (result > 0) {
          this.notificationService.showInfo('dialogs.share.success', { name });
        } else {
          this.notificationService.showWarn('dialogs.share.unshare', { name });
        }
      });
  }

  createLinkHandler(dashboardName: string): void {
    this.popupService
      .showModalWindow('appModalsAddFavorite', {
        id: dashboardName,
      })
      .then((data: IWidgetDescriptor<any>) => {
        this.widgetService.createFavoriteLink(data, dashboardName);
      });
  }

  openDashboardHandler = (dashboardName: string) => {
    this.mobileService.closeMobileAppsMenu();
    this.dashboardService.openDashboardByName(dashboardName);
  };

  openWidgetStoreHandler(storeItemType?: StoreItemTypeEnum): void {
    this.mwpStoreService.openMwpStore(StoreItemTypeEnum.WIDGET);
  }

  addAppHandler(): void {
    this.mwpStoreService.openMwpStore(StoreItemTypeEnum.APPLICATION);
  }

  openDashboardMenu() {
    const dashboardEl: HTMLElement & {
      [p: string]: any;
    } = document.getElementById('dashboards-menu-desktop');
    dashboardEl.openMenu = true;
  }

  createTemporaryDashboardAndOpen(dashboardId: string, parentId: string, title: string): Promise<void> {
    let strongAuth = 1000;
    if (parentId) {
      const parentDashboard = this.dashboardService.findDashboardInCache(parentId);
      strongAuth = parentDashboard?.strongAuth || 1000;
    }

    const tempDashboard = {
      title,
      name: dashboardId,
      id: dashboardId,
      strongAuth,
    };
    return this.dashboardService.openDashboard(tempDashboard as IDashboard);
  }

  closeCurrentTab(dashboardName: string): void {
    const currentTab = this.tabService.getTabById(dashboardName);

    this.tabService.closeTab(currentTab.id);
    this.tabService.removeFromTabsHistory(currentTab.id);
  }

  handleNotification(customEvent: CustomEvent): void {
    const notification: ToastNotification = customEvent.detail;
    const { toastType, messageKey, messageParams, titleKey, titleParams, options, type, message, title } = notification;

    switch (toastType) {
      case ToastType.INFO: {
        this.notificationService.showInfo(messageKey, messageParams, titleKey, titleParams);
        break;
      }
      case ToastType.MESSAGE: {
        this.notificationService.showMessage(type, messageKey, messageParams, titleKey, titleParams, options);
        break;
      }
      case ToastType.TRANSLATED_MESSAGE: {
        this.notificationService.showTranslatedMessage(type, message, title);
        break;
      }
      case ToastType.SUCCESS: {
        this.notificationService.showSuccess(messageKey, messageParams, titleKey, titleParams);
        break;
      }
      case ToastType.WARN: {
        this.notificationService.showWarn(messageKey, messageParams, titleKey, titleParams);
        break;
      }
      case ToastType.ERROR: {
        this.notificationService.showError(messageKey, messageParams, titleKey, titleParams);
        break;
      }
    }
  }
}
