import { IAugmentedJQuery } from 'angular';

/**
 * Wrapper directive for {@link autoFocus}.
 *
 * @returns {ng.IDirective}
 *
 * @ngInject
 */
export function autoFocusDirective($timeout: ng.ITimeoutService): ng.IDirective {
  'use strict';

  return {
    restrict: 'A',
    link: function (scope: ng.IScope, element: IAugmentedJQuery, attrs: any): void {
      attrs.$observe('autoFocus', function (newValue: boolean): void {
        if (newValue) {
          $timeout(function (): void {
            element.focus();
          });
        }
      });
    },
  };
}
