import { INotificationService } from './notification.service.interface';
import { NotificationService } from './notification.service';

/**
 * Created by mariab on 04.12.2015.
 */

export class NotificationProvider {
  private _toastr: Toastr;

  /**
   * @ngInject
   */
  constructor(toastr: Toastr) {
    this._toastr = toastr;
  }

  setNotificationOptions(options: ToastrOptions): void {
    this._toastr.options = options;
  }

  /**
   * @ngInject
   */
  $get($translate: angular.translate.ITranslateService, postal: IPostal): INotificationService {
    return new NotificationService($translate, postal, this._toastr);
  }
}
