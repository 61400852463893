import { SortableController } from './sortable.controller';
import _ from 'lodash';
/**
 *
 * @returns {ng.IDirective}
 * @ngInject
 */
export function sortableDirective(): ng.IDirective {
  'use strict';
  return {
    restrict: 'A',
    scope: {
      sortableId: '@?',
      ddGroup: '@',
      ddAxis: '@',
      onSort: '&',
      onStart: '&',
      onEnd: '&',
      onDropEnter: '&',
      onDropLeave: '&',
    },
    controller: SortableController,
    controllerAs: 'vm',
    bindToController: true,
    link<T>($scope: ng.IScope, el: JQuery, attr: ng.IAttributes, vm: SortableController<T>): void {
      vm.initialize(el);
      vm.onStart = <(params: { transfer: T }) => void>_.wrap(vm.onStart, (fn: Function, params: { transfer: T }) => {
        fn(params);
        $scope.$apply();
      });
      vm.onEnd = <(params: { transfer: T }) => void>_.wrap(vm.onEnd, (fn: Function, params: { transfer: T }) => {
        fn(params);
        $scope.$apply();
      });
      if (typeof vm.onDropEnter === 'function') {
        vm.onDropEnter = <(params: { transfer: T }) => void>_.wrap(vm.onDropEnter, (fn: Function, params: { transfer: T }) => {
          fn(params);
          $scope.$apply();
        });
      }
      if (typeof vm.onDropEnter === 'function') {
        vm.onDropLeave = <(params: { transfer: T }) => void>_.wrap(vm.onDropLeave, (fn: Function, params: { transfer: T }) => {
          fn(params);
          $scope.$apply();
        });
      }
      el.on('$destroy', () => {
        vm.destroy();
      });
    },
  };
}
