import { IDiffValue, IRole, IUser } from './user.model.interface';
import _ from 'lodash';

/**
 * Interface definition for role
 *
 * @author Tobias Straller [Tobias.Straller.bp@nttdata.com]
 */
export class Role implements IRole {
  public static DIFF_VALUE_MODULEORG: string = 'moduleOrg';
  public static DIFF_VALUE_PLORG: string = 'plOrg';
  public static DIFF_VALUE_DERIVATIVE: string = 'derivative';

  roleId: string;
  roleDescription: string;
  sortPos: number;
  tenantAdmin: boolean;
  responsible: boolean;
  diffValues: IDiffValue[];
  substitute: boolean;
  substitutedUser: IUser;

  private arrayMap: { [key: string]: string[] };

  /**
   * @ngInject
   */
  constructor(iRole: IRole, substitute: boolean = false, substitutedUser: IUser = null) {
    this.roleId = iRole.roleId;
    this.roleDescription = iRole.roleDescription;
    this.tenantAdmin = iRole.tenantAdmin;
    this.sortPos = iRole.sortPos;
    this.responsible = iRole.responsible;
    this.diffValues = iRole.diffValues || [];
    this.arrayMap = {};
    this.substitute = substitute;
    this.substitutedUser = substitutedUser;
  }

  getDiffValues(type: string, cache: boolean = true): string[] {
    const array: string[] = this.arrayMap[type];
    return _.isArray(array) ? array : this.assembleList(type, cache);
  }

  private assembleList(type: string, cache: boolean = true): string[] {
    const array = [];
    this.diffValues.forEach((diffValue: IDiffValue) => {
      if (diffValue.type === type && !_.isEmpty(diffValue.value)) {
        array.push(diffValue.value.value);
      }
    });
    if (cache) {
      this.arrayMap[type] = array;
    }
    return array;
  }
}
