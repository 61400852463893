'use strict';

import { UrlHelper } from '../util/url.helper';
import { TokenResult } from './workplace/token-result.interface';
import { AuthTokenService } from './auth/auth-token.service';

// 25 minutes in milliseconds
const tokenRenewalPollingTime = 1500000;
let renewalInterval: any;
// first timeout is the timeout to refresh the token, second one is to start the token renewal polling
let renewalTimeouts = [];
let dateTokenRefreshed = new Date();
const accessCodeKey = 'code';
const deepLinkUrlKey = 'deepLinkUrl';
const authURLs = {
  startLogin: './rest/auth/start',
  getAccessCode: './rest/auth/cb',
  renew: './rest/auth/refresh',
  revoke: './rest/auth/revoke',
};
const confirmationMessage =
  'Your session has been timed out. Unsaved changes might be lost! Click on cancel to stay on the page.';
export let tokenResult: TokenResult;

function isLocalEnv(): boolean {
  return (
    window.location.href.includes('https://localhost:3000') || window.location.href.includes('http://localhost:3000')
  );
}

// Method runs outside angular context
// to ensure user is authorized and token is set
export async function authConfig(): Promise<void> {
  if (isLocalEnv()) {
    tokenResult = {
      access_token: localStorage.getItem('access_token'),
    } as TokenResult;
    return Promise.resolve();
  }

  if (tokenResult?.access_token) {
    return;
  }

  const queryParams = UrlHelper.fromQueryString(location.search);

  const accessCode = queryParams[accessCodeKey] as string;

  if (accessCode) {
    const token = await fetchToken(accessCode);

    if (token) {
      tokenResult = token;
      setTokenSessionStorage();
      handleTokenAfterLogin();
    }
    return;
  }

  await doLogin();
}

export async function fetchToken(accessCode: string): Promise<TokenResult | ''> {
  try {
    const response = await fetch(`${authURLs.getAccessCode}?code=${accessCode}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    return response.json();
  } catch (err) {
    console.error(err);
    return '';
  }
}

document.addEventListener('visibilitychange', () => {
  if (document.hidden) {
    return;
  }

  if (renewalInterval) {
    clearInterval(renewalInterval);
  }

  if (renewalTimeouts?.length) {
    clearTimeout(renewalTimeouts[0]);
    clearTimeout(renewalTimeouts[1]);
  }

  const timePassed = new Date().getTime() - dateTokenRefreshed.getTime();
  if (timePassed >= tokenRenewalPollingTime) {
    refreshToken();
    startTokenRenewalPolling();
  } else {
    const timeLeft = tokenRenewalPollingTime - timePassed;
    renewalTimeouts[0] = setTimeout(refreshToken, timeLeft);
    renewalTimeouts[1] = setTimeout(startTokenRenewalPolling, timeLeft);
  }
});

export async function getEndSessionUrl(): Promise<{ logoutURL: string }> {
  try {
    const response = await fetch(authURLs.revoke, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${tokenResult.access_token}`,
      },
      body: tokenResult.id_token,
    });

    const json = await response.json();

    removeTokens();

    return json;
  } catch (err) {
    console.error(err);
  }
}

export function getToken(): string {
  if (isLocalEnv()) {
    return localStorage.getItem('access_token');
  }
  return tokenResult.access_token;
}

function removeTokens(): void {
  tokenResult = {} as TokenResult;
  AuthTokenService.getInstance().removeToken();
}

function handleTokenAfterLogin(): void {
  dateTokenRefreshed = new Date();
  removeAccessTokenFromURL();
  startTokenRenewalPolling();
  restoreDeepLink();
}

function removeAccessTokenFromURL(): void {
  const newUrl = getUrlWithoutAccessCode();
  window.history.pushState({ path: newUrl }, '', newUrl);
}

async function doLogin(checkUserConfirmation = false): Promise<void> {
  saveDeepLink();
  let hasUserConfirmedLogin = true;

  if (checkUserConfirmation) {
    hasUserConfirmedLogin = window.confirm(confirmationMessage);
  } else {
    hasUserConfirmedLogin = true;
  }

  if (!hasUserConfirmedLogin) {
    return;
  }

  // wait for document to load, otherwise navigation fails in iOS app
  if (document.readyState === 'loading') {
    return new Promise<void>(() => {
      const checkReadyState = setInterval(
        function (url: string): void {
          if (document.readyState === 'complete') {
            clearInterval(checkReadyState);
            // promise is not resolved as we redirect
            window.location.href = url;
          }
        }.bind(this, authURLs.startLogin),
        100
      );
    });
  } else {
    window.location.href = authURLs.startLogin;
  }
}

export function saveToken(tokenResultResponse: TokenResult): void {
  tokenResult = tokenResultResponse;
  setTokenSessionStorage();
}

function startTokenRenewalPolling(): void {
  renewalInterval = setInterval(async () => {
    try {
      await refreshToken();
    } catch (error) {
      await doLogin(true);
    }
  }, tokenRenewalPollingTime);
}

async function refreshToken(): Promise<void> {
  const res: TokenResult = await getRefreshedToken(true);

  tokenResult = {
    ...tokenResult,
    access_token: res.access_token,
    id_token: res.id_token,
    expires_in: res.expires_in,
    scope: res.scope,
    token_type: res.token_type,
  };
  dateTokenRefreshed = new Date();
  setTokenSessionStorage();
}

async function getRefreshedToken(checkUserConfirmation = false): Promise<TokenResult> {
  const response = await fetch(authURLs.renew, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      authorization: `Bearer ${tokenResult.access_token}`,
    },
    body: tokenResult.refresh_token,
  });

  if (response.status !== 200) {
    await doLogin(checkUserConfirmation);
    return;
  }

  return response.json();
}

function getUrlWithoutAccessCode(): string {
  const { protocol, host, pathname, hash } = window.location;

  return `${protocol}//${host}${pathname.endsWith('/') ? pathname : pathname + '/'}${hash}`;
}

function setTokenSessionStorage() {
  AuthTokenService.getInstance().setToken(tokenResult.access_token);
}

function saveDeepLink(): void {
  const url = window.location.href;
  sessionStorage.setItem(deepLinkUrlKey, url);
}

function restoreDeepLink(): void {
  const deepLink = sessionStorage.getItem(deepLinkUrlKey);
  if (deepLink) {
    sessionStorage.removeItem(deepLinkUrlKey);
    window.history.pushState({ path: deepLink }, '', deepLink);
  }
}
