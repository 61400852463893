import { getClientOs, OS_TYPE } from './os.client';

const mailToRegex = 'mailto:(?=.)(?:[^\\s])+@(?=.)(?:[^\\s])+\\.(?=.)(?:[^\\s])+';
const imRegex = 'im:<sip:(?=.)(?:[^\\s])+@(?=.)(?:[^\\s])+\\.(?=.)(?:[^\\s])+>';
const telRegex = 'tel:[0-9]+(?=.)(?:[^\\s])';
const VALIDATION_REGEX = {
  // pattern should also allow hostnames (have no .tld)
  URL: '^((http[s]?://(?=.)(?:[^\\s])+\\.{0,1}(?=.)(?:[^\\s])+))$',
  EXTERNAL_APP: '^(' + mailToRegex + '|' + imRegex + '|' + telRegex + ')$',
  FILE: '^file:\\\\(.+)|file://(.+)|smb://(.+)',
  BOXER: '^boxer://(.+)',
  ONENOTE: '^((onenote:(?=.)(?:[^\\s])+\\.{0,1}(?=.)(?:[^\\s])+))$',
};

enum LINK_TYPE {
  URL,
  EXTERNAL_APP,
  FILE,
  INVALID,
}

function getLinkType(text: string): LINK_TYPE {
  if (
    new RegExp(VALIDATION_REGEX.URL).test(text) ||
    new RegExp(VALIDATION_REGEX.BOXER).test(text) ||
    new RegExp(VALIDATION_REGEX.ONENOTE).test(text)
  ) {
    return LINK_TYPE.URL;
  }
  if (new RegExp(VALIDATION_REGEX.EXTERNAL_APP).test(text)) {
    return LINK_TYPE.EXTERNAL_APP;
  }
  if (new RegExp(VALIDATION_REGEX.FILE).test(text)) {
    return LINK_TYPE.FILE;
  }
  return LINK_TYPE.INVALID;
}

function convertLinkType(link: string, clientOs: OS_TYPE = getClientOs()): string {
  const linkType = getLinkType(link);
  switch (linkType) {
    case LINK_TYPE.FILE:
      if (clientOs === OS_TYPE.NOT_DETECTED) {
        return link;
      }
      if (clientOs === OS_TYPE.WINDOWS) {
        return link.replace(new RegExp(/^(smb:\/{2,})/), 'file:\\\\').replace(new RegExp(/\//g), '\\');
      }
      if (clientOs === OS_TYPE.IOS) {
        return link.replace(/^http:/, 'awb:').replace(/^https:/, 'awbs:');
      }
      if (clientOs === OS_TYPE.MAC) {
        return link
          .replace(new RegExp(/^(file:\\{2,})/), 'smb://')
          .replace(new RegExp(/^(file:\/{2,})/), 'smb://')
          .replace(new RegExp(/\\+/g), '/');
      }
      return link;
    case LINK_TYPE.URL:
      if (clientOs === OS_TYPE.IOS) {
        return link.replace(/^http:/, 'awb:').replace(/^https:/, 'awbs:');
      }
      return link;
    case LINK_TYPE.INVALID:
    case LINK_TYPE.EXTERNAL_APP:
      return link;
    default:
      return link;
  }
}

export { LINK_TYPE, VALIDATION_REGEX, getLinkType, convertLinkType };
