import { IUser } from './user.model.interface';
import { User } from './user.model';
import JSData from 'js-data';

/**
 * @author Tobias Straller [Tobias.Straller.bp@nttdata.com]
 *
 * @ngInject
 */
export function userStoreFactory(DS: JSData.DS): JSData.DSResourceDefinition<IUser> {
  'use strict';

  return DS.defineResource<IUser>(<JSData.DSResourceDefinitionConfiguration>(<unknown>{
    name: 'user',
    useClass: User,
    computed: {
      id(): string {
        return 'user';
      },
    },
    endpoint: './rest',
    watchChanges: false,
  }));
}
