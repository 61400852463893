import { Component } from '../component';

('use strict');

/**
 * Chip component.
 */
export class Chip extends Component {
  editable: boolean;
  clickHandler: Function;
  text: string;
  typeIconCls: string;

  /**
   *
   * @ngInject
   */
  constructor() {
    super();
  }
}
