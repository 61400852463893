/**
 * Describes the response from a widget data service.
 *
 * @author Tobias Straller [Tobias.Straller.bp@nttdata.com]
 */
export interface IWidgetData {
  info?: any;
  /**
   * Check the widget's column definition to find out about the items
   */
  data: any[];
}

/**
 * Interface for OData Services
 */
export interface IOData {
  d: { results: any[] };
}

export class WidgetDefinitions {
  static TILE: string = 'tile';
  static FAVORITE: string = 'favorite';
  static SEARCH_FAVORITE: string = 'search-favorite';
  static SEARCH_FAVORITE_NS: string = 'search-favorite-ns';
  static LOCKED: string = 'locked';
  static DASHBOARD_LINK: string = 'dashboard-link';
  static FRAME_LAYOUT: string = 'frame-layout';
}
