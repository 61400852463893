import { Component } from '../component';
import { UserService } from '../../user/user.service';
import { IUser } from '../../user/user.model.interface';
import { MenuService } from '../../menu/menu.service';
import { PopupService } from '../../notification/popup.service';
import { DeviceService } from '../../../util/device.service';
import { AppsService } from '../../apps/apps.service';
import { ActionLogService } from '../../actionLog/action-log.service';
import { ActionConstants } from '../../actionLog/action-constants';
import { DashboardService } from '../../dashboard/dashboard.service';
import { IApplication } from '../../apps/application.model.interface';
import { Role } from '../../user/role.model';
import { LayoutService } from '../../layout/layout.service';
import { IUserSetting, IUserSettingOption } from '../../userSetting/user-setting.model.interface';
import { UserSettingService } from '../../userSetting/user-setting.service';

/**
 * User info directive
 *
 * @author Tobias Straller [Tobias.Straller.bp@nttdata.com]
 */
export class Userinfo extends Component {
  user: IUser;
  userImage: string;
  roleManager: IApplication;
  settingsApp: IApplication;
  deputyApp: IApplication;

  private _userService: UserService;
  private _userSettingService: UserSettingService;
  private _menuService: MenuService;
  private _popupService: PopupService;
  private _deviceService: DeviceService;
  private _appsService: AppsService;
  private _translateService: angular.translate.ITranslateService;
  private _actionLogService: ActionLogService;
  private _dashboardService: DashboardService;
  private _layoutService: LayoutService;

  /**
   *
   * @param userService
   * @ngInject
   */
  constructor(
    userService: UserService,
    userSettingService: UserSettingService,
    menuService: MenuService,
    popupService: PopupService,
    deviceService: DeviceService,
    appsService: AppsService,
    $translate: angular.translate.ITranslateService,
    actionLogService: ActionLogService,
    layoutService: LayoutService,
    dashboardService: DashboardService
  ) {
    super();
    this._userService = userService;
    this._userSettingService = userSettingService;
    this._layoutService = layoutService;
    this._menuService = menuService;
    this._popupService = popupService;
    this._deviceService = deviceService;
    this._appsService = appsService;
    this._translateService = $translate;
    this._actionLogService = actionLogService;
    this._userService.getUser().then((user: IUser) => (this.user = user));
    this.loadUserImg();
    this._dashboardService = dashboardService;
    this._appsService.getApp('role-manager').then((app: IApplication) => {
      this.roleManager = app;
    });
    this._appsService.getApp('user-settings').then((app: IApplication) => {
      this.settingsApp = app;
    });
    this._appsService.getApp('app-deputy').then((app: IApplication) => {
      this.deputyApp = app;
    });
  }

  /**
   * Get selected user role proxy
   */
  selectedRole(): Role {
    return this.user ? this.user.getSelectedRole() : null;
  }

  /**
   * check if user got any role
   */
  hasRoles(): boolean {
    if (this.user.roles.length > 0) {
      return true;
    }
    var substitudedUsers = this.user.substitutedUsers;
    for (var substitudedUser of substitudedUsers) {
      if (substitudedUser.substitutedRoles.length > 0) {
        return true;
      }
    }
    return false;
  }

  /**
   * Select the user role
   * @param role
   */
  selectRole(role: Role): void {
    const selectedRole = this.selectedRole();
    if (selectedRole && selectedRole.roleId === role.roleId) {
      return;
    }

    this.user.selectRole(role);
    this._actionLogService.logAction({
      category: ActionConstants.CATEGORY_ROLES,
      action: ActionConstants.ACTION_ROLE_MENU,
      actionInfo: 'select role',
    });
    this._userService.selectRole(role);
  }

  /**
   * Shows logout confirmation dialog.
   */
  showLogoutConfirmation(): void {
    this._popupService.showLogoutConfirmationDialog();
  }

  /**
   * Will display the Role Manager app
   */
  showRoleManagerApp(): void {
    this._appsService.openAppByName('role-manager').then(() => {
      this._menuService.hideActiveSubMenu();
      this._layoutService.collapseSidebar('navigationbar');
    });
  }

  /**
   * Will display the user settings app
   */
  openUserSettings(): void {
    this._appsService.openAppByName('user-settings').then(() => {
      this._menuService.hideActiveSubMenu();
      this._layoutService.collapseSidebar('navigationbar');
    });
  }

  /**
   * Will display the deputy app
   */
  showDeputyApp(): void {
    this._appsService.openAppByName('app-deputy').then(() => {
      this._menuService.hideActiveSubMenu();
      this._layoutService.collapseSidebar('navigationbar');
    });
  }

  isUserDeleted(user: IUser): boolean {
    return user && user.deleted;
  }

  /**
   * Stop click event propagation
   * @param $event
   */
  stopClick($event: JQueryEventObject): boolean {
    $event.stopPropagation();
    return false;
  }

  destroy(): void {
    this._userService = null;
    this._menuService = null;
    this._popupService = null;
    this._deviceService = null;
    this._appsService = null;
    this._translateService = null;
  }

  private loadUserImg(): void {
    this._userSettingService.getSettingByDescription('profileImage').then((setting: IUserSetting) => {
      if (setting) {
        this.userImage = setting.optionsList.find(
          (option: IUserSettingOption) => option.description === 'profileImageSet'
        ).values[0];
      }
    });
  }
}
