'use strict';
import { ISharingSearchSource } from '../../favorite/search-source.model.interface';
import { EmployeeService } from '../../employee/employee.service';
import { GroupService } from '../../groups/group.service';
import { IGroup } from '../../groups/group.model.interface';
import { IChip } from '../chip/chip.model.interface';
import { NotificationService } from '../../notification/notification.service';
import { SharingService } from '../../sharing/sharing.service';
import { IUser } from '../../user/user.model.interface';
import { BaseDialog } from './base-dialog';
import { ISharingChip } from '../../favorite/favorite-chip.model.interface';
import { UserService } from '../../user/user.service';
import { User } from '../../user/user.model';
import { IShareDialogConfig, IShareObject } from './share-dialog-config.interface';
import { ILanguage } from '../../../util/language.model.interface';
import _ from 'lodash';

/**
 * Dialog for sharing a entity.
 */
export class ShareDashboardDialog extends BaseDialog {
  private static MAX_DISPLAYED_GROUPS: number = 10;
  private static MAX_DISPLAYED_EMPLOYEES: number = 10;
  private static SCOPE_EDIT: string = 'edit';
  private static SCOPE_VIEW: string = 'view';

  chips: { [key: string]: IChip[] };
  /**
   * The search sources being used.
   */
  searchSources: { [key: string]: ISharingSearchSource[] };
  /**
   * The currently active search source.
   */
  selectedSource: { [key: string]: ISharingSearchSource };
  /**
   * The search term being used in the search field.
   */
  searchTerm: { [key: string]: string };
  config: IShareDialogConfig;
  configView: IShareObject;
  configEdit: IShareObject;

  private ENDPOINT: string = './rest/users/deputy';
  private _employeeService: EmployeeService;
  private _groupService: GroupService;
  private _notificationService: NotificationService;
  private _sharingService: SharingService;
  private _sharedObject: IShareObject[];
  private _translateService: angular.translate.ITranslateService;
  private _externalText: string;
  private _accountDeletedText: string;
  private _userService: UserService;
  private _qService: ng.IQService;
  private _user: User;
  private _translatedDescriptions: { [key: string]: string };
  private _timeout: ng.ITimeoutService;
  private _httpService: ng.IHttpService;
  private _language: ILanguage;

  /**
   * @ngInject
   */
  constructor(
    employeeService: EmployeeService,
    groupService: GroupService,
    notificationService: NotificationService,
    sharingService: SharingService,
    $translate: angular.translate.ITranslateService,
    userService: UserService,
    $q: ng.IQService,
    $timeout: ng.ITimeoutService,
    $http: ng.IHttpService,
    language: ILanguage
  ) {
    super();
    this._employeeService = employeeService;
    this._groupService = groupService;
    this._notificationService = notificationService;
    this._sharingService = sharingService;
    this._translateService = $translate;
    this.chips = { [ShareDashboardDialog.SCOPE_EDIT]: [], [ShareDashboardDialog.SCOPE_VIEW]: [] };
    this.searchTerm = { [ShareDashboardDialog.SCOPE_EDIT]: '', [ShareDashboardDialog.SCOPE_VIEW]: '' };
    this.searchSources = { [ShareDashboardDialog.SCOPE_EDIT]: [], [ShareDashboardDialog.SCOPE_VIEW]: [] };
    this.selectedSource = {};
    this._userService = userService;
    this._qService = $q;
    this._translatedDescriptions = {};
    this._timeout = $timeout;
    this._httpService = $http;
    this._language = language;
  }

  $onInit() {
    this.config = this.resolve().config;

    this._translateService(['omnisearch.employee.external', 'tooltip.user.account.deleted']).then(
      (t: { [key: string]: string }) => {
        this._externalText = t['omnisearch.employee.external'];
        this._accountDeletedText = t['tooltip.user.account.deleted'];
      }
    );
    this._userService.getUser().then((user: User) => (this._user = user));

    /**
     * configure search sources for users/groups
     */
    this.searchSources[ShareDashboardDialog.SCOPE_EDIT].push(
      {
        iconCls: 'iwp-icon-gen_user',
        text: 'dialogs.favorite.share.search.for.person.text',
        placeholder: 'dialogs.favorite.share.person.search.placeholder',
        searchFunction: this.searchUsers.bind(this),
        createChip: this.createUserChip.bind(this),
        updateSharedObject: (user: IUser, sharedObject: IShareObject[], add: boolean = true, scope: string): void => {
          const so = sharedObject.find((s: IShareObject) => s.scope === scope);
          if (add) {
            delete user.id;
            if (so && so !== null) {
              so.users.push(user);
            }
            return;
          }
          so.users = _.pull(so.users, user);
        },
        tableConfig: {
          headers: [
            {
              title: 'dialogs.favorite.share.search.table.column.header.name',
              dataField: (user: IUser): string => user.name + ' ' + user.surname,
              dataIconCls: (user: IUser): string => (user.qaccount ? 'iwp-icon-gen_user_add' : 'iwp-icon-gen_user_c'),
            },
            {
              title: 'dialogs.favorite.share.search.table.column.header.department',
              dataField: 'department',
            },
            {
              title: 'dialogs.favorite.share.search.table.column.header.email',
              dataField: 'email',
            },
          ],
          data: [],
          disableCondition: this.hasQNumber.bind(this),
          tooManyUsersMessage: 'dialogs.favorite.share.search.tooManyResults',
          nbDisplayedResults: ShareDashboardDialog.MAX_DISPLAYED_EMPLOYEES,
        },
      },
      {
        iconCls: 'iwp-icon-groupadmin',
        text: 'dialogs.favorite.share.search.for.team.text',
        placeholder: 'dialogs.favorite.share.team.search.placeholder',
        searchFunction: this.searchGroups.bind(this),
        createChip: this.createGroupChip.bind(this),
        updateSharedObject: (group: IGroup, sharedObject: IShareObject[], add: boolean = true, scope: string): void => {
          const so = sharedObject.find((s: IShareObject) => s.scope === scope);
          if (add) {
            if (so && so !== null) {
              so.groups.push(group);
            }
            return;
          }
          so.groups = _.pull(so.groups, group);
        },
        tableConfig: {
          headers: [
            {
              title: 'dialogs.favorite.share.search.table.column.header.name',
              dataField: 'name',
              dataIconCls: 'iwp-icon-groupadmin_group_add',
            },
            {
              title: 'dialogs.favorite.share.search.table.column.header.creator',
              dataField: (group: IGroup): string => {
                return this._translatedDescriptions[group.id];
              },
            },
          ],
          data: [],
          disableCondition: (groups: IGroup): boolean => false,
          tooManyUsersMessage: 'dialogs.favorite.share.search.tooManyResults',
          nbDisplayedResults: ShareDashboardDialog.MAX_DISPLAYED_GROUPS,
        },
      }
    );
    this.searchSources[ShareDashboardDialog.SCOPE_VIEW].push(
      {
        iconCls: 'iwp-icon-gen_user',
        text: 'dialogs.favorite.share.search.for.person.text',
        placeholder: 'dialogs.favorite.share.person.search.placeholder',
        searchFunction: this.searchUsers.bind(this),
        createChip: this.createUserChip.bind(this),
        updateSharedObject: (user: IUser, sharedObject: IShareObject[], add: boolean = true, scope: string): void => {
          const so = sharedObject.find((s: IShareObject) => s.scope === scope);
          if (add) {
            delete user.id;
            if (so && so !== null) {
              so.users.push(user);
            }
            return;
          }
          so.users = _.pull(so.users, user);
        },
        tableConfig: {
          headers: [
            {
              title: 'dialogs.favorite.share.search.table.column.header.name',
              dataField: (user: IUser): string => {
                return user.name + ' ' + user.surname;
              },
              dataIconCls: (user: IUser): string => (user.qaccount ? 'iwp-icon-gen_user_add' : 'iwp-icon-gen_user_c'),
            },
            {
              title: 'dialogs.favorite.share.search.table.column.header.department',
              dataField: 'department',
            },
            {
              title: 'dialogs.favorite.share.search.table.column.header.email',
              dataField: 'email',
            },
          ],
          data: [],
          disableCondition: this.hasQNumber.bind(this),
          tooManyUsersMessage: 'dialogs.favorite.share.search.tooManyResults',
          nbDisplayedResults: ShareDashboardDialog.MAX_DISPLAYED_EMPLOYEES,
        },
      },
      {
        iconCls: 'iwp-icon-groupadmin',
        text: 'dialogs.favorite.share.search.for.team.text',
        placeholder: 'dialogs.favorite.share.team.search.placeholder',
        searchFunction: this.searchGroups.bind(this),
        createChip: this.createGroupChip.bind(this),
        updateSharedObject: (group: IGroup, sharedObject: IShareObject[], add: boolean = true, scope: string): void => {
          const so = sharedObject.find((s: IShareObject) => s.scope === scope);
          if (add) {
            if (so && so !== null) {
              so.groups.push(group);
            }
            return;
          }
          so.groups = _.pull(so.groups, group);
        },
        tableConfig: {
          headers: [
            {
              title: 'dialogs.favorite.share.search.table.column.header.name',
              dataField: 'name',
              dataIconCls: 'iwp-icon-groupadmin_group_add',
            },
            {
              title: 'dialogs.favorite.share.search.table.column.header.creator',
              dataField: (group: IGroup): string => this._translatedDescriptions[group.id],
            },
          ],
          data: [],
          disableCondition: (): boolean => false,
          tooManyUsersMessage: 'dialogs.favorite.share.search.tooManyResults',
          nbDisplayedResults: ShareDashboardDialog.MAX_DISPLAYED_GROUPS,
        },
      }
    );
    /**
     * default selected source is the first one
     * @type {ISharingSearchSource}
     */
    this.selectedSource[ShareDashboardDialog.SCOPE_EDIT] = this.searchSources[ShareDashboardDialog.SCOPE_EDIT][0];
    this.selectedSource[ShareDashboardDialog.SCOPE_VIEW] = this.searchSources[ShareDashboardDialog.SCOPE_VIEW][0];

    /**
     * fetch and create chips for already shared with users/groups
     */
    this._sharingService.getSharedUsers(this.config.id, this.config.shareType).then((sharingObject: IShareObject[]) => {
      this._sharedObject = sharingObject;
      this.configView = sharingObject.find((c: IShareObject) => c.scope === ShareDashboardDialog.SCOPE_VIEW || c.scope === null);
      this.configEdit = sharingObject.find((c: IShareObject) => c.scope === ShareDashboardDialog.SCOPE_EDIT);
      this._sharedObject.forEach((so: IShareObject) => (so.title = this.config.title));
      if (this._sharedObject) {
        this._sharedObject.forEach((so: IShareObject) => {
          if (_.isArray(so.users)) {
            so.users.forEach((user: IUser) =>
              this.createUserChip(user).then((chip: ISharingChip) => this.chips[so.scope].push(chip))
            );
          }

          if (_.isArray(so.groups)) {
            so.groups.forEach((group: IGroup) =>
              this.createGroupChip(group).then((chip: ISharingChip) => this.chips[so.scope].push(chip))
            );
          }
        });
      }
    });
  }

  /**
   * Remove chip from the 'Share with: ' section.
   * @param chip
   * @param scope
   */
  removeChip(chip: ISharingChip, scope: string): void {
    this.chips[scope] = _.pull(this.chips[scope], chip);
    this.searchSources[scope].forEach((source: ISharingSearchSource) =>
      source.updateSharedObject(chip.entry, this._sharedObject, false, scope)
    );
  }

  /**
   * Reset the current search results
   * Updated the selected search source users/team a.s.o.
   * Trigger a new search, on the new source. Let each source's search function do its magic and decisions
   * @param source
   * @param scope
   */
  updateSelectedSource(source: ISharingSearchSource, scope: string): void {
    this.selectedSource[scope] = source;
    this.doSearch(scope);
  }

  /**
   * Trigger search for users/teams. Only one type of search is triggered, based on the
   * currently selected source
   * @param scope
   */
  doSearch(scope: string): void {
    this.selectedSource[scope].searchFunction(this.searchTerm[scope], scope);
  }

  /**
   * Get deputies as search suggestions
   * @param scope
   */
  getSearchSuggestions(scope: string): void {
    if (this.searchTerm[scope].length === 0) {
      this._httpService
        .get(this.ENDPOINT, {
          params: {
            lang: this._language.lang,
            role: this._userService.selectedUserRole.roleId,
          },
        })
        .then((response: ng.IHttpPromiseCallbackArg<IUser[]>) => {
          this.selectedSource[scope].tableConfig.data = [];
          response.data.forEach((user: IUser) => this.selectedSource[scope].tableConfig.data.push(user));
        });
    }
  }

  /**
   * Searches for users. (Backend call)
   *
   * @param term
   * @param scope
   */
  searchUsers(term: string, scope: string): void {
    if (!term || term === '') {
      this._timeout(() => (this.selectedSource[scope].tableConfig.data = null), 0);
      return;
    }

    if (term.length < 3) {
      return;
    }
    this._employeeService.findEmployees(
      {
        searchTerm: term,
      },
      (users: IUser[]) => {
        this.selectedSource[scope].tableConfig.data = [];
        users.forEach((user: IUser) => this.selectedSource[scope].tableConfig.data.push(user));
      }
    );
  }

  /**
   * Searches for groups. (Backend call)
   *
   * @param term
   * @param scope
   */
  searchGroups(term: string, scope: string): void {
    this._translatedDescriptions = {};
    this._groupService.findGroups(term, ShareDashboardDialog.MAX_DISPLAYED_GROUPS, (groups: IGroup[]) => {
      const promises: ng.IPromise<string>[] = [];
      groups.forEach((group: IGroup) => promises.push(this.getUserDescription(group.owner)));
      this._qService.all(promises).then((descriptions: string[]) => {
        descriptions.forEach((description: string, idx: number) => (this._translatedDescriptions[groups[idx].id] = description));
        this.selectedSource[scope].tableConfig.data = [];
        groups.forEach((group: IGroup) => this.selectedSource[scope].tableConfig.data.push(group));
      });
    });
  }

  /**
   * Reset current search (empty the table).
   * @param scope
   */
  resetSearch(scope: string): void {
    this.searchTerm[scope] = '';
    this.selectedSource[scope].tableConfig.data = null;
  }

  /**
   * Entry in table has been clicked. Add a new chip to the 'Search with:' section.
   *
   * @param idx
   * @param entry
   * @param scope
   */
  handleEntryClicked(idx: number, entry: any, scope: string): void {
    if (this.selectedSource[scope].tableConfig.disableCondition(entry)) {
      return;
    }

    this.selectedSource[scope].createChip(entry).then((chip: ISharingChip) => {
      /**
       * Don't add self to the sharing list
       */
      if (chip.id === this._user.userId) {
        this._notificationService.showInfo('dialogs.favorite.share.search.table.add.item.self');
        return;
      }
      /**
       * Don't add duplicates.
       * A duplicate chip is a chip with the same text.
       */
      if (!_.isUndefined(this.chips[scope].find((ch: ISharingChip) => ch.id === chip.id))) {
        this._notificationService.showInfo('dialogs.favorite.share.search.table.add.item.duplicate');
        return;
      }

      /**
       * update the chips - visually added chip
       */
      this.chips[scope].push(chip);
      /**
       * update the shared favorite object
       */
      this.selectedSource[scope].updateSharedObject(entry, this._sharedObject, true, scope);
    });
  }

  /**
   * Ok button handler.
   */
  ok(): void {
    this._sharingService.setSharedUsers(this._sharedObject).then(() =>
      this.close({
        $value: this.chips[ShareDashboardDialog.SCOPE_VIEW].length + this.chips[ShareDashboardDialog.SCOPE_EDIT].length,
      })
    );
  }

  destroy(el: JQuery): void {
    this._employeeService = null;
    this._groupService = null;
    this._notificationService = null;
    this._sharingService = null;
    this._translateService = null;
    this._userService = null;
    this._qService = null;
    this._timeout = null;
  }

  private createUserChip(entry: IUser): ng.IPromise<ISharingChip> {
    const deletedClass = entry && entry.deleted ? ' deleted' : '';
    return this.getUserDescription(entry).then((text: string) => ({
      title: entry && entry.deleted ? this._accountDeletedText : '',
      text,
      iconCls: `iwp-icon-gen_user${deletedClass}`,
      entry,
      id: entry.userId,
    }));
  }

  private createGroupChip(entry: IGroup): ng.IPromise<ISharingChip> {
    return this._qService.resolve({
      text: entry.name,
      iconCls: 'iwp-icon-groupadmin',
      entry,
      id: entry.id,
    });
  }

  private getUserDescription(entry: IUser): ng.IPromise<string> {
    const fullName = `${entry.name} ${entry.surname}`.trim();
    return this._translateService(['omnisearch.employee.external', 'omnisearch.employee.inOrderOf.short'], entry).then(
      (messages: any) =>
        entry.external
          ? `${fullName}, ${messages['omnisearch.employee.external']}, ${messages['omnisearch.employee.inOrderOf.short']}`
          : `${fullName}, ${entry.department}`
    );
  }

  private hasQNumber(user: IUser): boolean {
    return !(user && user !== null && User.prototype.hasQNumber.call(user));
  }
}
