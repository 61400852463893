/**
 * Service for task activities.
 */
import { ITaskActivity } from './task-activity.model.interface';
import { ILanguage } from '../../util/language.model.interface';
import JSData from 'js-data';
import { IHttpPromiseCallbackArg } from 'angular';

export interface ITaskActivityServiceMessage {
  activities: ITaskActivity[];
  addedActivity?: ITaskActivity;
}

export class TaskActivityService {
  static CHANNEL_ACTIVITIES: string = 'TasksActivityServiceChannelActivities';
  static TOPIC_ACTIVITIES_UPDATE: string = 'TasksActivityServiceActivityUpdate';

  taskActivity: ITaskActivity[];
  getActivity: (start: number, callback: (activities: ITaskActivity[]) => void) => void;
  channel: IChannelDefinition<ITaskActivityServiceMessage>;

  private _taskActivity: JSData.DSResourceDefinition<ITaskActivity>;
  private _httpService: ng.IHttpService;
  private _language: ILanguage;
  private _interval: ng.IIntervalService;
  private _pollingInterval: number;

  /**
   * @ngInject
   */
  constructor(
    taskActivity: JSData.DSResourceDefinition<ITaskActivity>,
    $interval: ng.IIntervalService,
    pollingInterval: number,
    debounce: any,
    $http: ng.IHttpService,
    language: ILanguage,
    postal: IPostal
  ) {
    this._taskActivity = taskActivity;
    this._httpService = $http;
    this._language = language;
    this._interval = $interval;
    this._pollingInterval = pollingInterval;
    this.channel = postal.channel<ITaskActivityServiceMessage>(TaskActivityService.CHANNEL_ACTIVITIES);
    this.getActivity = debounce(this._getActivity.bind(this), 500, true);
  }

  /**
   * Will be called by the polling provider.
   * Shows a message if new tasks have been created
   * for the user and updates the TasksStore if
   * necessary
   */
  startPolling(): void {
    this._getActivity(null, 25, (activities: ITaskActivity[]) => {
      let aL: number = activities.length;

      this._interval(() => {
        this._getActivity(null, 25, (newActivities: ITaskActivity[]) => {
          if (aL !== newActivities.length) {
            console.log('updated activities');
            aL = newActivities.length;
            this.channel.publish(TaskActivityService.TOPIC_ACTIVITIES_UPDATE, {
              activities: this.taskActivity,
            });
          }
        });
      }, this._pollingInterval);
    });
  }

  /**
   * Get activities, last 25 if not specified
   * @param start
   * @param showLast
   * @param callback
   * @private
   */
  _getActivity(start: number, showLast: number, callback: (activities: ITaskActivity[]) => void): void {
    this._httpService({
      url: './rest/tasks/activity',
      method: 'get',
      params: {
        from: start,
        showLast,
      },
    }).then((promise: IHttpPromiseCallbackArg<ITaskActivity[]>) => {
      this.taskActivity = promise.data;
      callback(this.taskActivity);
    });
  }

  /**
   * Get activities by group, last 25 if not specified
   * @param start
   * @param showLast
   * @param groupId
   * @param callback
   * @private
   */
  _getActivityByGroup(start: number, showLast: number, groupId: string, callback: (activities: ITaskActivity[]) => void): void {
    this._httpService({
      url: `./rest/tasks/activity/group`,
      method: 'get',
      params: {
        from: start,
        showLast,
        groupId,
      },
    }).then((promise: IHttpPromiseCallbackArg<ITaskActivity[]>) => {
      this.taskActivity = promise.data;
      callback(this.taskActivity);
    });
  }
}
