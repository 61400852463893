import { IApplication } from '../apps/application.model.interface';
import { IUser } from '../user/user.model.interface';
import { IFrameLayout } from '../frameLayout/frame-layout.model.interface';

/**
 * Interface describing tab content
 *
 * @author Tobias Straller [Tobias.Straller.bp@nttdata.com]
 */
export interface ITabContent {
  type: TabContentType;
  app?: IApplication;
}

export enum TabContentType {
  IFRAME,
  TEMPLATE,
  FRAME_LAYOUT_TEMPLATE,
}

export interface ITabContentIFrame extends ITabContent {
  app?: IApplication;
  containsPdf?: boolean;
  frameLayout?: IFrameLayout;
}

export interface ITabContentTemplate extends ITabContent {
  template?: string;
  dashboardName?: string;
  dashboardParentName?: string;
  containsDashboard?: boolean;
  dashboardSharedBy?: IUser;
}

export interface ITabContentAppsLayout extends ITabContent {
  frameLayout?: IFrameLayout;
  template?: string;
}
