import { Component } from '../component';
import { AppDialog } from './app-dialog';
import appDialogTemplate from './app-dialog.html';
/**
 * Wrapper directive for {AcceptFavoriteDialog}
 * @returns {{}}
 * @ngInject
 */
export function appDialogDirective(): ng.IDirective {
  'use strict';

  return Component.createDirective(AppDialog, {
    restrict: 'E',
    templateUrl: appDialogTemplate,
    scope: {
      close: '&',
      dismiss: '&',
      resolve: '&',
    },
  });
}
