import { ITab } from './tab';
import { IErrorService } from '../errors/error.service.interface';
import { IFactory } from '../../util/factory.interface';
import { Tab } from './tab';
import { ITabContent } from './tab.content.interface';
import { NotificationService } from '../notification/notification.service';
import _ from 'lodash';

('use strict');

/**
 * Helper to manage a collection of tabs.
 *
 * @author Tobias Straller [Tobias.Straller.bp@nttdata.com]
 */
export class TabManager {
  public static MAX_TABS: number = 20;

  id: string;
  maxTabs: number;
  tabs: ITab<ITabContent>[];
  private _selectedTab: ITab<ITabContent>;
  private _errorService: IErrorService;
  private _notificationService: NotificationService;

  /**
   * @constructor
   * @param id
   * @param config
   * @param errorService
   * @ngInject
   */
  constructor(
    id: string,
    config: any = {
      maxTabs: Number.POSITIVE_INFINITY,
    },
    ...args: any[]
  ) {
    this.id = id;
    this.maxTabs = config.maxTabs;
    this.tabs = [];
    this.selectedTab = null;
  }

  /**
   * Factory for creating tab manager instances
   * @param errorService
   * @ngInject
   */
  static factory(errorService: IErrorService, notificationService: NotificationService): IFactory<TabManager> {
    return {
      createInstance(id: string, config: any): TabManager {
        var tm = new TabManager(id, config);
        tm._errorService = errorService;
        tm._notificationService = notificationService;
        return tm;
      },
    };
  }

  /**
   * Set the selected tab
   * @param value
   */
  set selectedTab(value: ITab<ITabContent>) {
    this._selectedTab = value;
  }

  /**
   * Get selected tab
   * @returns {Tab}
   */
  get selectedTab(): ITab<ITabContent> {
    return this._selectedTab;
  }

  /**
   * Add a tab
   * @param tab
   * @throws {Error} when number of tabs is exceeding tablimit
   */
  addTab(tab: ITab<ITabContent>, index: number = null): void {
    if (index === null) {
      index = this.tabs.length;
    }

    if (this.tabs.length < this.maxTabs) {
      this.tabs.splice(index, 0, new Tab(tab));
      return;
    }

    this._errorService.throwUserInputError(new Error('error.userInput.maxTabs'), { maxTabs: TabManager.MAX_TABS });
    this._notificationService.showError('notification.max.tabs.reached');
  }

  /**
   * Returns a tab by its id
   * @param tabId
   * @returns {Tab} null if tab was not found
   */
  getTabById(tabId: string): ITab<ITabContent> {
    const index = this.tabs.findIndex((tab: ITab<ITabContent>) => {
      return tabId === tab.id;
    });
    if (index > -1) {
      return this.tabs[index];
    }
    return null;
  }

  /**
   * Removes a tab by index
   * @param index
   */
  removeTabByIndex(index: number): void {
    if (index > -1) {
      this.tabs.splice(index, 1);
    }
  }

  /**
   * Remove a tab by its id
   * @param tabId
   */
  removeTabById(tabId: string): void {
    const index = this.tabs.findIndex((tab: ITab<ITabContent>) => tabId === tab.id);
    return this.removeTabByIndex(index);
  }

  /**
   * Removes a tab instance from the current manager
   * @param tab
   */
  removeTab(tab: ITab<ITabContent>): void {
    return this.removeTabById(tab.id);
  }

  /**
   * removes the tab that matches the given tabId and replaces it with the given tab.
   * @param {string} oldTabId
   * @param {ITab<ITabContent>} newTab
   */
  replaceTab(tabId: string, tab: ITab<ITabContent>): void {
    const index = this.tabs.findIndex((t: ITab<ITabContent>) => t.id === tabId);
    if (index === -1) {
      return;
    }
    this.removeTabByIndex(index);
    this.addTab(tab, index);
  }

  /**
   *
   * @param tabid
   * @param index
   */
  moveTab(tabId: string, index: number): void {
    var currentIndex = this.tabs.findIndex((tab: ITab<ITabContent>) => {
      return tabId === tab.id;
    });
    if (currentIndex > -1) {
      var tab = this.tabs.splice(currentIndex, 1)[0];
      this.tabs.splice(index, 0, tab);
    }
  }

  /**
   *
   * @param tabId
   */
  moveTabToFront(tabId: string): void {
    return this.moveTab(tabId, 0);
  }

  /**
   *
   * @param tabId
   */
  moveTabToBack(tabId: string): void {
    return this.moveTab(tabId, this.tabs.length);
  }

  /**
   * Set selected tab by given id
   * @param tabId
   */
  selectTabById(tabId: string): void {
    const tab = this.getTabById(tabId);
    this.tabs.forEach((tab: ITab<ITabContent>) => {
      tab.active = false;
    });
    tab.neverSelected = false;
    tab.active = true;
    tab.loaded = true;
    this.selectedTab = tab;
  }

  /**
   * Select the default tab.
   */
  selectDefault(): void {
    const tab = _.last(this.tabs);
    if (tab) {
      this.selectTabById(tab.id);
    }
  }
}
