export interface ILanguage {
  locale: string;
  lang: string;
}

export const DATE_FORMAT_PLACEHOLDERS = {
  'de-de': 'TT.MM.JJJJ',
  'en-gb': 'DD/MM/YYYY',
  'en-us': 'MM/DD/YYYY',
  'es-es': 'D/M/YYYY',
  'es-mx': 'DD/MM/YYYY',
  'hu-hu': 'NN.HH.EEEE',
};

export const DATE_FORMAT = {
  'de-de': 'dd.MM.yyyy',
  'en-gb': 'dd/MM/yyyy',
  'en-us': 'MM/dd/yyyy',
  'es-es': 'shortDate',
  'es-mx': 'dd/MM/yyyy',
  'hu-hu': 'dd.MM.yyyy',
};
