'use strict';
import { Component } from '../component';
import { ICroppedCanvasData } from '../../fileHandling/image.model.interface';
import Cropper from 'cropperjs';
import _ from 'lodash';

export const CROP_IMAGE_HEIGHT = 108;
export const CROP_IMAGE_WIDTH_1 = 140;
export const CROP_IMAGE_WIDTH_2 = 294;
export const CROP_ASPECT_RATIO_1 = +(CROP_IMAGE_WIDTH_1 / CROP_IMAGE_HEIGHT).toFixed(2);
export const CROP_ASPECT_RATIO_2 = +(CROP_IMAGE_WIDTH_2 / CROP_IMAGE_HEIGHT).toFixed(2);

/**
 * Image cropper component
 */
export class ImageCropper extends Component {
  /**
   * Original image source. Where we cut from.
   */
  imgSrc: any;
  /**
   * Two way data binding.
   * Use this property in your tag to tap yourself into the cropping results.
   */
  croppedCanvasData: ICroppedCanvasData;

  private _cropper: any;
  private _el: JQuery;
  private _scope: ng.IScope;

  /**
   *
   * @ngInject
   */
  constructor() {
    super();
  }

  onRenderComponent(el: JQuery, scope: ng.IScope): void {
    var jImg: JQuery = el.find('img'),
      img: HTMLImageElement = jImg ? <HTMLImageElement>jImg.get(0) : null;

    this._scope = scope;
    if (null == img) {
      return;
    }
    this._el = el;
    jImg.on('load', () => {
      this._cropper = new Cropper(img, {
        aspectRatio: CROP_ASPECT_RATIO_1,
        autoCropArea: 0.5,
        dragMode: 'move',
        toggleDragModeOnDblclick: false,
        minCropBoxWidth: CROP_IMAGE_WIDTH_1,
        minCropBoxHeight: CROP_IMAGE_HEIGHT,
      });
    });

    jImg.on('cropend', this.setCroppedImageData.bind(this));
    jImg.on('ready', this.setCroppedImageData.bind(this));
    jImg.on('zoom', _.debounce(this.setCroppedImageData.bind(this), 100, { trailing: true }));
  }

  /**
   * Toggle unselecting of currently selected button.
   *
   * @param $event
   * @param mode
   */
  selectCropMode(mode: number): void {
    let aspectRatio = mode === 1 ? CROP_ASPECT_RATIO_1 : CROP_ASPECT_RATIO_2;

    const active = this._el.find('.cropMode.active');
    const inactive = this._el.find('.cropMode.inactive');

    active.removeClass('active').addClass('inactive');
    inactive.removeClass('inactive').addClass('active');

    this._cropper.setAspectRatio(aspectRatio);
    this._cropper.options.minCropBoxWidth = CROP_ASPECT_RATIO_1 === aspectRatio ? CROP_IMAGE_WIDTH_1 : CROP_IMAGE_WIDTH_2;
    this._cropper.options.minCropBoxHeight = CROP_IMAGE_HEIGHT;

    /**
     * Update croppedImageData, if it exists.
     */
    if (this.croppedCanvasData) {
      this.croppedCanvasData.aspectRatio = aspectRatio;
    }
  }

  zoom(plus: boolean): void {
    this._cropper.zoom(plus ? 0.1 : -0.1);
  }

  destroy(el: JQuery): void {
    this._cropper.destroy();
    this._cropper = null;
    this._el = null;
    this._scope = null;
  }

  /**
   * Prepares cropped image data, with required information, to be sent to anyone needing it.
   */
  private setCroppedImageData(): void {
    var croppedCanvas = this._cropper.getCroppedCanvas({
      // save double the required size, for sharp image on retina displays
      width: (this._cropper.options.aspectRatio === CROP_ASPECT_RATIO_1 ? CROP_IMAGE_WIDTH_1 : CROP_IMAGE_WIDTH_2) * 2,
      height: CROP_IMAGE_HEIGHT * 2,
    });
    if (croppedCanvas.toBlob) {
      croppedCanvas.toBlob(this.createCropperData.bind(this));
      return;
    }

    /**
     * IE is well...IE. Take measures
     */
    this.createCropperData(croppedCanvas.msToBlob());
  }

  private createCropperData(blob: Blob): void {
    if (_.isUndefined(blob)) {
      return;
    }

    this.croppedCanvasData = {
      data: this._cropper
        .getCroppedCanvas({
          // save double the required size, for sharp image retina displays
          width: (this._cropper.options.aspectRatio === CROP_ASPECT_RATIO_1 ? CROP_IMAGE_WIDTH_1 : CROP_IMAGE_WIDTH_2) * 2,
          height: CROP_IMAGE_HEIGHT * 2,
        })
        .toDataURL(),
      croppedBlob: blob,
      aspectRatio: this._cropper.options.aspectRatio,
    };

    /**
     * Make sure the parent scope is updated immediately, if the user decides
     * to click accept right away, otherwise the default selection will not be
     * picked up as backgournd image for the widget.
     */
    this._scope.$apply();
  }
}
