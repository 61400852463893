import { WidgetDefinitions } from '../../app/widget/widget.data.interface';
import { Dashboard } from '../../app/dashboard/dashboard';
import { IDashboardDragTransfer, IDraggableHTMLElement } from '../../app/dashboard/dashboard-drag-transfer.interface';
import { DeviceService } from '../../util/device.service';
import { LayoutService } from '../../app/layout/layout.service';
import * as Interact from 'interactjs';

('use strict');

/**
 * @author Tobias Straller [Tobias.Straller.bp@nttdata.com]
 */
export class DraggableMenuItem {
  private _draggableItemData: any;
  private _beforeDrag: () => void;
  private _deviceService: DeviceService;
  private _layoutService: LayoutService;
  private _cloneEl: JQuery;
  private _el: JQuery;
  private _dragEl: JQuery;
  private _interactable: Interact.Interactable;

  constructor(
    draggableItemData: any,
    layoutService: LayoutService,
    deviceService: DeviceService,
    beforeDragHandler?: () => void
  ) {
    this._draggableItemData = draggableItemData;
    this._layoutService = layoutService;
    this._deviceService = deviceService;
    this._beforeDrag = beforeDragHandler;
  }

  /**
   * Prepare element for being draggable.
   * Only enable drag and drop for leaf items (directories should not be draggable)
   *
   * @param el
   */
  initElement(el: JQuery, scope: ng.IScope): void {
    if (this._deviceService.isMobile()) {
      return;
    }
    this._el = el;
    this._dragEl = el;
    // the following would enable drag on windows 10 touch devices, but it disables scrolling
    // this._dragEl.css({
    //   '-ms-touch-action': 'none',
    //   'touch-action': 'none'
    // });
    this._interactable = Interact.interact(this._dragEl.get(0)).draggable({
      onstart: this.onDraggableStart.bind(this, scope),
      onmove: this.onDraggableMove.bind(this),
      onend: this.onDraggableEnd.bind(this),
    });
  }

  /**
   * Remember to cleanup from Interactible library after the DOM element is destroyed
   *
   * @param el
   */
  cleanupElement(): void {
    if (this._interactable) {
      this._interactable.unset();
      this._interactable = null;
    }
  }

  /**
   * Called when dragging the item starts.
   * Creates a drag ghost and adds it to the viewport.
   *
   * @param event
   */
  private onDraggableStart(scope: ng.IScope, event: Interact.DragEvent): void {
    if (this._beforeDrag) {
      scope.$apply(() => {
        this._beforeDrag();
      });
    }
    /** transfer data */
    const transferData = {
      widgetDefinitionId: this._draggableItemData.widgetDefinitionId || WidgetDefinitions.FAVORITE,
      colspan: this._draggableItemData.colspan || 1,
      rowspan: this._draggableItemData.rowspan || 1,
      customSettings: this._draggableItemData.customSettings || { app: this._draggableItemData.name },
      linkId: (this._draggableItemData.widgetDefinitionId === WidgetDefinitions.TILE && this._draggableItemData.id) || null,
      widgetBreadCrumbs:
        this._draggableItemData.widgetBreadCrumbs && this._draggableItemData.widgetBreadCrumbs.length > 0
          ? this._draggableItemData.widgetBreadCrumbs[0]
          : null,
    };
    this._layoutService.publishDragStart();
    this._layoutService.setDragActive(true);
    this._dragEl.data('transfer', transferData);
    this._cloneEl = this._el.clone();
    this._el.parent().append(this._cloneEl);
    this._cloneEl.addClass('draggable-app-item-ghost dragging').appendTo('.viewport');
    this._cloneEl.css('transform', `translate(${event.pageX}px,${event.pageY}px)`);
    this._dragEl.addClass(Dashboard.DD_GROUP_DASHBOARD);
  }

  /**
   * Called when items is being dragged.
   * Shows the drag ghost relative to the mouse pointer.
   *
   * @param event
   */
  private onDraggableMove(event: Interact.DragEvent): void {
    if (this._cloneEl) {
      const x = (parseFloat(this._cloneEl.data('x')) || 0) + event.dx;
      const y = event.pageY + event.dy;
      this._cloneEl.css('transform', `translate(${x}px, ${y}px)`);
      this._cloneEl.data('x', x);
      this._cloneEl.data('y', y);
    }
  }

  /**
   * Destroy the drag clone when it is being dropped.
   *
   * @param event
   */
  private onDraggableEnd(event: Interact.DragEvent): void {
    this._layoutService.publishDragEnd();
    this._layoutService.setDragActive(false);
    this._cloneEl.remove();
    this._dragEl.removeClass(Dashboard.DD_GROUP_DASHBOARD);
    const draggableEl = <IDraggableHTMLElement<IDashboardDragTransfer>>event.target;
    if (draggableEl.placeholderEl) {
      draggableEl.removePlaceholder();
    }
    draggableEl.transferObject = null;
    draggableEl.dragEl = null;
  }
}
