import { IUserSettingsStorage } from './user-settings-storage.interface';
import { UserService } from '../user/user.service';
import { IUserSettingsStoreable } from './user-settings-storeable.interface';

export class LocalUserSettingsStorageService implements IUserSettingsStorage {
  private _userService: UserService;

  /**
   * @ngInject
   */
  constructor(userService: UserService) {
    this._userService = userService;
  }

  loadSettings(storeable: IUserSettingsStoreable): any {
    const path = this.getStoragePath(storeable);
    let serialized = localStorage.getItem(path);

    return serialized ? JSON.parse(serialized) : null;
  }

  saveSettings(storeable: IUserSettingsStoreable, settings: any): void {
    localStorage.setItem(this.getStoragePath(storeable), JSON.stringify(settings));
  }

  private getStoragePath(storeable: IUserSettingsStoreable): string {
    let userId = window['UserId'] && typeof window['UserId'] === 'string' ? window['UserId'] : null;
    userId =
      !userId && this._userService.user && this._userService.user.userId ? this._userService.user.userId : userId;
    if (!userId) {
      throw new Error('Missing userId. Settings cannot be saved/restored.');
    }

    if (!storeable.settingsStorageKey) {
      throw new Error('Missing storage key. Settings cannot be saved/restored.');
    }

    return `${storeable.settingsStorageKey}/${userId}/${storeable.getSettingsStoragePath().join('/')}`;
  }
}
