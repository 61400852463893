import { Component } from '../components/component';
import { AppsFrameComponent } from './apps.frame.component';
import frameComponentTemplate from './apps.frame.component.html';
/**
 * Wraps {@link AppsFrameComponent} component into a directive.
 *
 * @author Tobias Straller [Tobias.Straller.bp@nttdata.com]
 *
 * @ngInject
 */
export function appsFrameComponentDirective(): ng.IDirective {
  'use strict';
  return Component.createDirective(AppsFrameComponent, {
    restrict: 'E',
    scope: {
      frame: '=',
    },
    templateUrl: frameComponentTemplate,
  });
}
