import { UserService } from '../user/user.service';

export function getUser(userService: UserService): string {
  let userId = window['UserId'] && typeof window['UserId'] === 'string' ? window['UserId'] : null;

  userId = userId || userService.getUserNumber();
  if (!userId) {
    throw new Error('Missing userId. Settings cannot be saved/restored.');
  }
  return userId;
}
