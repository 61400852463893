/**
 *
 * @author Tobias Straller [Tobias.Straller.bp@nttdata.com]
 */
import { IWorkplaceContext } from '../app/workplace/workplace.context.interface';
import { renderFn, get } from 'micromustache';

export class UrlHelper {
  static HASH_REG_EXP: RegExp = /#(.*)$/;

  /**
   * Convert a parameter object into a url escaped query string.
   * @param params
   * @returns {string}
   */
  static toQueryString(params: { [key: string]: string | boolean | number | string[] | number[] | boolean[] }): string {
    return Object.keys(params)
      .sort()
      .map((key: string) => {
        var value = params[key];
        if (Array.isArray(value)) {
          if (value.length > 0) {
            return (<any[]>value)
              .filter((v: string | boolean | number) => {
                return typeof v !== 'undefined' && v !== null && v !== '';
              })
              .map((v: string | boolean | number) => {
                return `${key}=${encodeURIComponent('' + v)}`;
              })
              .join('&');
          } else {
            return '';
          }
        } else if (typeof value !== 'undefined' && value !== null && value !== '') {
          return `${key}=${encodeURIComponent('' + value)}`;
        } else {
          return '';
        }
      })
      .filter((part: string) => {
        return part !== '';
      })
      .join('&');
  }

  /**
   * Appends url parameters to an existing url.
   * Doesn't add duplicates, if the given params are already in the given url, they will be ignored.
   *
   * @param url
   * @param params
   * @returns {string} the url
   */
  static appendQueryString(
    url: string,
    params: { [key: string]: string | boolean | number | string[] | number[] | boolean[] }
  ): string {
    var queryString = UrlHelper.toQueryString(params);
    var split = url.split('#');
    var hash = split[1];
    url = split[0];
    if (url.indexOf(queryString) === -1) {
      url = url.indexOf('?') > -1 ? url + '&' + queryString : url + '?' + queryString;
    }
    return hash ? [url, hash].join('#') : url;
  }

  /**
   * Merge url parameters to an existing url
   * @param url
   * @param params
   * @returns {string} the url
   */
  static mergeQueryString(
    url: string,
    params: { [key: string]: string | boolean | number | string[] | number[] | boolean[] } | IWorkplaceContext
  ): string {
    var split = url.split('#');
    var fragment = split[1];
    url = split[0];
    var index = url.indexOf('?');
    var p: { [key: string]: string | string[] } = {};
    if (index > -1) {
      var queryString = url.slice(index + 1);
      p = UrlHelper.fromQueryString(queryString);
      url = url.slice(0, index);
    }
    if (fragment) {
      url = [url, fragment].join('#');
    }
    return UrlHelper.appendQueryString(url, Object.assign(p, params));
  }

  /**
   * Build a parameter object from a query string. The values are strings.
   * @param queryString
   * @returns {[key:string]:string|string[]}
   */
  static fromQueryString(queryString: string): { [key: string]: string | string[] } {
    var params: { [key: string]: string | string[] } = {};
    queryString = queryString.startsWith('?') ? queryString.substr(1) : queryString;
    queryString.split('&').forEach((keyValue: string) => {
      var split = keyValue.split('=');
      var key = split[0];
      var value = decodeURIComponent(split[1]);
      if (typeof params[key] !== 'undefined') {
        if (!Array.isArray(params[key])) {
          var v = params[key];
          params[key] = [];
          (<any[]>params[key]).push(v);
        }
        (<any[]>params[key]).push(value);
      } else {
        params[key] = value;
      }
    });
    return params;
  }

  /**
   * Removes the query string from the given url
   * @param url
   */
  static removeQueryString(url: string): string {
    return url.split('?')[0];
  }

  /**
   * Returns a parameter object from a url
   * @param url
   */
  static fromUrl(url: string): { [key: string]: string | string[] } {
    var queryString = url.split('?')[1];
    if (queryString) {
      return UrlHelper.fromQueryString(queryString.split('#')[0]);
    }
    return {};
  }

  /**
   * Replace the hash part of the url. If there is no hash part in the url, add it.
   * @param url
   * @param hash hash part of the url without the '#' character
   */
  static replaceHash(url: string, hash: string): string {
    if (!UrlHelper.HASH_REG_EXP.exec(url)) {
      return (url += '#' + hash);
    }
    return url.replace(UrlHelper.HASH_REG_EXP, '#' + hash);
  }

  /**
   * Returns the origin for a given absolute url.
   * Relative urls will return null
   * @param url
   * @returns {string} null -> for relative urls
   */
  static getOriginFromUrl(url: string): string {
    var match = /(http[s]*:\/\/.+?)($|[\/,#,\?,$]+.*)/i.exec(url);
    return match ? match[1] : null;
  }
  /**
   * Returns whether the url has a file protocol part
   * @param url
   * @returns {boolean}
   */
  static isFileProtocol(url: string): boolean {
    let protocol = UrlHelper.getProtocolFromUrl(url);
    return protocol === 'file' || protocol === 'smb';
  }

  /**
   * Returns the protocol part of the given url. If no protocol is specified, http is assumed.
   * @param url
   */
  static getProtocolFromUrl(url: string): string {
    var match = /^([a-z]+):(\/\/|\\\\)/i.exec(url);
    return match ? match[1] : 'http';
  }

  /**
   * Returns the file extension from a file url
   * @param url
   * @returns {string} the file extension if available. null if there is no file extension
   */
  static getFileExtensionFromUrl(url: string): string {
    var match = /.*?\.([a-z,\d]*)$/i.exec(url);
    return match ? match[1].toLowerCase() : null;
  }

  /**
   * Interpolates placeholders in URLs for object values, e.g.
   * /path?lang={{lang}} -> /path?lang=de
   *
   * You should provide default values.
   * If a property does not exist, the resulting string will be "undefined" e.g.
   * /{{foo}} -> /undefined
   *
   * @param url
   * @param properties
   */
  static interpolateUrl(url: string, properties: any): string {
    return renderFn(url, UrlHelper.getPropForInterpolateUrl, properties);
  }

  private static getPropForInterpolateUrl(varName: string, scope): string {
    return encodeURIComponent(get(scope, varName));
  }
}
