import { Component } from '../component';
import _ from 'lodash';

('use strict');

export class WidgetSizeGrid extends Component {
  /**
   * Min row selection for widget
   * Set by attribute
   */
  minRows: number;
  /**
   * Min column selection for widget
   * Set by attribute
   */
  minColumns: number;
  /**
   * Max row selection for widget
   * Set by attribute
   */
  maxRows: number;
  /**
   * Max column selection for widget
   * Set by attribute
   */
  maxColumns: number;

  /**
   * current widget width
   */
  currentRow: number;

  /**
   * current widget height
   */
  currentColumn: number;

  /**
   * @output - handle widget size selection
   */
  selectSize: (message: { row: number; column: number }) => void;

  protected rows: number[] = [1, 2, 3, 4, 5];
  protected columns: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  protected throttledUpdate: (row: number, column: number) => void;

  private attemptRow: number;
  private attemptColumn: number;
  private el: JQuery;

  /**
   * @ngInject
   */
  constructor() {
    super();
    this.throttledUpdate = _.throttle(this.updatePosition, 20);
  }

  onRenderComponent(el: JQuery, scope: ng.IScope): void {
    this.el = el;
  }

  handleCellClick(): void {
    if (this.isValid(this.attemptRow, this.attemptColumn)) {
      this.currentRow = this.attemptRow;
      this.currentColumn = this.attemptColumn;
      this.selectSize({
        row: this.currentRow,
        column: this.currentColumn,
      });
    } else {
      return;
    }
  }

  handleMouseLeave(): void {
    this.updatePosition(this.currentRow, this.currentColumn);
  }

  showBackgroundClass(row: number, column: number): boolean {
    return this.currentRow >= row && this.currentColumn >= column;
  }

  /**
   * @param {number} row
   * @param {number} column
   */
  updatePosition(row: number, column: number): void {
    this.attemptRow = row;
    this.attemptColumn = column;
    var cells: JQuery = this.el.find('.col-md-1');
    var isValid: boolean = this.isValid(row, column);
    var i: number, n: number;

    /**
     * Reset cell classes before adding new ones for the current mouse selection.
     */
    cells.removeClass('selectionTopMarker selectionLeftMarker selectionBottomMarker selectionRightMarker');
    cells.removeClass(
      'selectionTopMarkerInvalid selectionLeftMarkerInvalid selectionBottomMarkerInvalid selectionRightMarkerInvalid'
    );

    for (i = 0; i < column; i++) {
      jQuery(cells.get(i)).addClass(isValid ? 'selectionTopMarker' : 'selectionTopMarkerInvalid');
    }

    for (i = (row - 1) * 9, n = (row - 1) * 9 + column; i < n; i++) {
      jQuery(cells.get(i)).addClass(isValid ? 'selectionBottomMarker' : 'selectionBottomMarkerInvalid');
    }

    for (i = 0, n = row * 9; i < n; i += 9) {
      jQuery(cells.get(i)).addClass(isValid ? 'selectionLeftMarker' : 'selectionLeftMarkerInvalid');
    }

    for (i = column - 1, n = (row - 1) * 9 + column; i < n; i += 9) {
      jQuery(cells.get(i)).addClass(isValid ? 'selectionRightMarker' : 'selectionRightMarkerInvalid');
    }
  }

  isValid(row: number, column: number): boolean {
    var minRows: number = this.minRows ? this.minRows : 1;
    var maxRows: number = this.maxRows ? this.maxRows : 5;
    var minColumns: number = this.minColumns ? this.minColumns : 1;
    var maxColumns: number = this.maxColumns ? this.maxColumns : 9;
    return row >= minRows && column >= minColumns && row <= maxRows && column <= maxColumns;
  }

  isInBounds(row: number, column: number): boolean {
    var maxRows: number = this.maxRows ? this.maxRows : 5;
    var maxColumns: number = this.maxColumns ? this.maxColumns : 9;
    return row <= maxRows && column <= maxColumns;
  }
}
