import { AbstractMenuItem } from './menu.item';
import { IDashboard } from '../../dashboard/dashboard.model.interface';
import { UserService } from '../../user/user.service';
import { WorkplaceContextService } from '../../workplace/workplace.context.service';
import { LayoutService } from '../../layout/layout.service';
import { Icons } from '../../app.icons';
import { DraggableMenuItem } from '../../../util/interact/draggable-menu-item';
import { DeviceService } from '../../../util/device.service';
import { WidgetDefinitions } from '../../widget/widget.data.interface';
import { User } from '../../user/user.model';
import popOverTemplateUrl from '../../sharing/share.owner.legend.html';
import _ from 'lodash';

('use strict');

export class DashboardMenuItem extends AbstractMenuItem<IDashboard> {
  tooltipModel: any;

  private _draggableMenuItem: DraggableMenuItem;
  private userService: UserService;
  private isOwner: boolean;
  private popOverTemplateUrl: string = popOverTemplateUrl;

  /**
   * @ngInject
   */
  constructor(
    private layoutService: LayoutService,
    private deviceService: DeviceService,
    workplaceContextService: WorkplaceContextService,
    userService: UserService
  ) {
    super(layoutService, workplaceContextService);
    this.userService = userService;
    this.popOverTemplateUrl = popOverTemplateUrl;
  }

  $onInit() {
    this.isOwner = this.userService.user ? this.data.owner && this.userService.user.userId === this.data.owner.userId : false;
    this.tooltipModel = {
      owner: this.isOwner,
      sharedBy: this.data.sharedBy,
      isDashboard: true,
    };
    const customSettings = {
      name: this.data.name,
      locked: this.data.locked || false,
      type: WidgetDefinitions.DASHBOARD_LINK,
      parentName: this.data.parentName,
    };
    this._draggableMenuItem = new DraggableMenuItem(
      {
        customSettings: customSettings,
        widgetDefinitionId: WidgetDefinitions.DASHBOARD_LINK,
      },
      this.layoutService,
      this.deviceService,
      this._layoutService.collapseSidebar.bind(this._layoutService, 'navigationbar')
    );
  }

  get iconCls(): string {
    if (this.data.iconCls) {
      return this.data.iconCls;
    }

    if (this.isGroup) {
      return Icons.APP_GROUP;
    }

    return Icons.DASHBOARD_DEFAULT;
  }

  /**
   * check the dashboard owner
   */
  get owner(): boolean {
    return this.isOwner;
  }

  get dashboardDescription(): string {
    return this.data && this.data.description ? _.escape(this.data.description) : '';
  }

  /**
   * Check if user has QNumber.
   * @returns {boolean}
   */
  userHasQNumber(): boolean {
    const user: User = <User>this.userService.user;
    return user && user.hasQNumber();
  }

  onRenderComponent(el: JQuery, scope: ng.IScope): void {
    if (this.userHasQNumber()) {
      this._draggableMenuItem.initElement($(el), scope);
    }
  }

  destroy(el: JQuery): void {
    super.destroy(el);
    this._draggableMenuItem.cleanupElement();
    this._draggableMenuItem = null;
  }
}
