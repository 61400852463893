'use strict';
import { BaseDialog } from './base-dialog';
import { IDashboard } from '../../dashboard/dashboard.model.interface';
import { DashboardService } from '../../dashboard/dashboard.service';
import { AppsService } from '../../apps/apps.service';
import { IApplication } from '../../apps/application.model.interface';
import { NotificationService } from '../../notification/notification.service';
import { RestrictionsService } from '../../restrictions/restrictions.service';
import { descriptionSorter } from '../../../util/description-sorter';

export class AcceptFavoriteDialog extends BaseDialog {
  config: any;
  dashboards: IDashboard[];

  private dashboardsService: DashboardService;
  private selectedDashboard: IDashboard;
  private appsService: AppsService;
  private notificationService: NotificationService;
  private restrictionsService: RestrictionsService;

  /**
   * Add shared favorite to own dashboard component.
   * @ngInject
   */
  constructor(
    dashboardService: DashboardService,
    appsService: AppsService,
    notificationService: NotificationService,
    restrictionsService: RestrictionsService
  ) {
    super();
    this.dashboardsService = dashboardService;
    this.appsService = appsService;
    this.notificationService = notificationService;
    this.restrictionsService = restrictionsService;
  }

  $onInit() {
    this.dashboardsService.getDashboards().then((dashboards: IDashboard[]) => {
      const userDashboards = dashboards.filter((dash: IDashboard) => {
        return this.dashboardsService.isOwnDashboard(dash) || !!dash.system;
      });
      this.dashboards = userDashboards.sort(descriptionSorter);
      this.selectedDashboard = this.dashboards[0];
    });
    this.config = this.resolve().config;
  }

  get labelTranslationData(): any {
    return {
      name:
        this.config.widget.settings && this.config.widget.settings.title
          ? this.config.widget.settings.title
          : this.config.widget.title
          ? this.config.widget.title
          : this.config.title,
    };
  }

  ok(): void {
    if (this.config.widget && this.config.widget.customSettings && this.config.widget.customSettings.app) {
      this.appsService
        .getApp(this.config.widget.customSettings.app)
        // the user doesn't have access to the app. show an error message.
        .catch(() =>
          this.appsService
            .fetchSingleApp(this.config.widget.customSettings.app)
            .then((app: IApplication) => {
              if (app.locked || !this.restrictionsService.isEmpty(app.restrictions)) {
                this.notificationService.showError('notification.sharing.search.favorite.cannot.be.accepted', {
                  description: app.description,
                });
              }
            })
            .catch((reason: any) => console.log('reason', reason))
            .finally(() => this.dismiss())
        )
        .finally(() => this.close({ $value: this.selectedDashboard }));
      return;
    }

    this.close({ $value: this.selectedDashboard });
  }

  updateSelectedDashboard(dashboard: IDashboard): void {
    this.selectedDashboard = dashboard;
  }

  destroy(el: JQuery): void {
    this.dashboardsService = null;
    this.appsService = null;
    this.notificationService = null;
    this.restrictionsService = null;
  }
}
