import { FrameLayoutService } from './frame-layout.service';
import { IFrameLayout, ILayoutFrameDescriptor } from './frame-layout.model.interface';
import { IFrameLayoutAdapter, IFrameLayoutSplitter } from './frame-layout.adapters';
import { FrameLayout } from './frame-layout';
import { IAppsFrame } from '../apps/apps.frame.interface';
import _ from 'lodash';
import * as Interact from 'interactjs';

('use strict');

export class CrossSplitAdapter implements IFrameLayoutAdapter {
  isSplitterDragging: boolean;
  splitters: IFrameLayoutSplitter[];

  private parentEl: JQuery;
  private frameLayoutService: FrameLayoutService;
  private layout: IFrameLayout;
  private frameStyles: any[];
  private qService: ng.IQService;

  /**
   * @ngInject
   * @param {JQuery} parentEl
   * @param {FrameLayoutService} frameLayoutService
   */
  constructor(parentEl: JQuery, frameLayoutService: FrameLayoutService, layout: IFrameLayout, q: ng.IQService) {
    this.parentEl = parentEl;
    this.frameLayoutService = frameLayoutService;
    this.qService = q;
    this.layout = layout;
    this.splitters = [
      {
        limits: [0, this.parentEl.width()],
        axis: 'x',
        cls: FrameLayout.VERTICAL_SPLITTER_CLASS,
        draggable: false,
        getSplitterStyle: (fullScreen: boolean): any => {
          const st: any = { display: fullScreen ? 'none' : 'block' };
          if (this.boundingBoxes && this.boundingBoxes.length === 4) {
            st.left = this.splitters[0].pos !== 0 ? this.splitters[0].pos : this.boundingBoxes[1].left - 3;
          }
          return st;
        },
        pos: 0,
        posPercent: 0,
        splitterEndDrag: (event: Interact.DragEvent, frames: IAppsFrame[]): void => {
          if (!this.splitters[0].draggable) {
            return;
          }
          this.isSplitterDragging = false;
          // recalculate frame bounding boxes
          this.handleDividerEndDrag(frames);
        },
        splitterDragMove: (event: Interact.DragEvent): void => {
          if (!this.splitters[0].draggable) {
            return;
          }
          // set new horizontal splitter position
          const offset = this.parentEl.offset();
          if (
            event.pageX < offset.left ||
            event.pageX < this.splitters[0].limits[0] ||
            event.pageX > this.splitters[0].limits[1]
          ) {
            return;
          }
          this.splitters[0].pos = event.pageX - offset.left;
          this.splitters[0].posPercent = (event.pageX - offset.left) / this.parentEl.width();
        },
        splitterDragDown: (event: Interact.DragEvent, frames: ILayoutFrameDescriptor[]): void => {
          if (!this.splitters[0].draggable) {
            return;
          }
          this.isSplitterDragging = true;
          this.setXSplitterLimits(frames);
        },
        splitterDragUp: (): void => {
          this.isSplitterDragging = false;
        },
      },
      {
        limits: [40, this.parentEl.height()],
        axis: 'y',
        cls: FrameLayout.HORIZONTAL_SPLITTER_CLASS,
        draggable: false,
        getSplitterStyle: (fullScreen: boolean): any => {
          let st: any = { display: fullScreen ? 'none' : 'block' };
          if (this.boundingBoxes && this.boundingBoxes.length === 4) {
            st.top = this.splitters[1].pos !== 0 ? this.splitters[1].pos : this.boundingBoxes[3].top - 41;
          }
          return st;
        },
        pos: 0,
        posPercent: 0,
        splitterEndDrag: (event: Interact.DragEvent, frames: IAppsFrame[]): void => {
          if (!this.splitters[1].draggable) {
            return;
          }
          this.isSplitterDragging = false;
          // recalculate frame bounding boxes
          this.handleDividerEndDrag(frames);
        },
        splitterDragMove: (event: Interact.DragEvent): void => {
          if (!this.splitters[1].draggable) {
            return;
          }
          // set new vertical splitter position
          const offset = this.parentEl.offset();
          if (
            event.pageY < offset.top ||
            event.pageY < this.splitters[1].limits[0] ||
            event.pageY > this.splitters[1].limits[1]
          ) {
            return;
          }
          this.splitters[1].pos = event.pageY - offset.top;
          this.splitters[1].posPercent = this.splitters[1].pos / this.parentEl.height();
        },
        splitterDragDown: (event: Interact.DragEvent, frames: ILayoutFrameDescriptor[]): void => {
          if (!this.splitters[1].draggable) {
            return;
          }
          this.isSplitterDragging = true;
          this.setYSplitterLimits(frames);
        },
        splitterDragUp: (): void => {
          this.isSplitterDragging = false;
        },
      },
    ];
  }

  get boundingBoxes(): any[] {
    return this.frameStyles;
  }

  get fullScreenStyle(): any {
    return {
      top: 40,
      width: '100%',
      height: '100%',
    };
  }

  reset(): void {
    this.splitters.forEach((splitter: IFrameLayoutSplitter) => {
      splitter.pos = 0;
      splitter.posPercent = 0;
    });
  }

  resizeHandler(frames: IAppsFrame[], fullScreenFrame: IAppsFrame): void {
    if (fullScreenFrame !== null) {
      return;
    }
    this.splitHandler();
    this.handleDividerEndDrag(frames);
  }

  splitHandler(): void {
    if (this.splitters[0].draggable) {
      this.splitters[0].pos = this.splitters[0].posPercent * this.parentEl.width();
    }
    if (this.splitters[1].draggable) {
      this.splitters[1].pos = this.splitters[1].posPercent * this.parentEl.height();
    }
  }

  /**
   * Calculate each frame's position based on the frame's descriptor information. Frames are absolutely positioned.
   */
  buildFrameStyles(): void {
    if (!this.layout.frames || this.layout.frames.length !== 4) {
      return;
    }
    const width = this.parentEl.width();
    const height = this.parentEl.height();

    const leftFrame = this.layout.frames[0];
    const rightFrame = this.layout.frames[1];
    const leftMinSize = leftFrame.minWidth <= 1 ? Math.round(leftFrame.minWidth * width) : leftFrame.minWidth;
    const rightMinSize = rightFrame.minWidth <= 1 ? Math.round(rightFrame.minWidth * width) : rightFrame.minWidth;
    let leftWidth = leftFrame.width <= 1 ? Math.round(leftFrame.width * width) : leftFrame.width;
    let rightWidth = rightFrame.width <= 1 ? Math.round(rightFrame.width * width) : rightFrame.width;

    const topFrame = this.layout.frames[0];
    const bottomFrame = this.layout.frames[2];
    const topMinSize = topFrame.minHeight <= 1 ? Math.round(topFrame.minHeight * height) : topFrame.minHeight;
    const bottomMinSize =
      bottomFrame.minHeight <= 1 ? Math.round(bottomFrame.minHeight * height) : bottomFrame.minHeight;
    let topHeight = topFrame.height <= 1 ? Math.round(topFrame.height * height) : topFrame.height;
    let bottomHeight = bottomFrame.height <= 1 ? Math.round(bottomFrame.height * height) : bottomFrame.height;

    this.splitters[0].draggable =
      (leftWidth >= leftMinSize || rightWidth >= rightMinSize) && !(leftMinSize + rightMinSize >= width);
    leftWidth = leftWidth < leftMinSize ? leftMinSize : leftWidth;
    rightWidth = rightWidth < rightMinSize ? rightMinSize : rightWidth;

    this.splitters[1].draggable =
      (topHeight >= leftMinSize || bottomHeight >= rightMinSize) && !(leftMinSize + rightMinSize >= height);
    topHeight = topHeight < topMinSize ? topMinSize : topHeight;
    bottomHeight = bottomHeight < bottomMinSize ? bottomMinSize : bottomHeight;

    // if there is a value set for the first frame, the second frames values are ignored.
    this.assembleOriginalStyles(
      leftWidth !== 0 ? leftWidth : width - rightWidth,
      topHeight !== 0 ? topHeight : height - bottomHeight
    );
  }

  resizeIFrames(
    frames: IAppsFrame[],
    options: { width?: number; height?: number },
    sourceFrameIdx: number
  ): ng.IPromise<void> {
    const deferred = this.qService.defer<void>();
    const offsetX = this.parentEl.offset().left;
    const offsetY = this.parentEl.offset().top;
    let splitTarget: any;
    if (_.isNumber(options.width) && this.splitters[0].draggable) {
      // invert width, since requesting iFrame is on the right
      if (sourceFrameIdx === 1 || sourceFrameIdx === 3) {
        options.width = this.parentEl.width() - options.width;
      }
      this.setXSplitterLimits(this.layout.frames);
      /** convert to pixels, a width between [0,1) means percent */
      const targetWidth = options.width <= 1 ? options.width * this.parentEl.width() : options.width;
      /** constraint resizing to the calculated limits of the splitter */
      const calculatedWidth =
        targetWidth >= this.splitters[0].limits[0] - offsetX
          ? targetWidth <= this.splitters[0].limits[1] - offsetX
            ? targetWidth + offsetX
            : this.splitters[0].limits[1]
          : this.splitters[0].limits[0];
      splitTarget = Object.assign({ left: calculatedWidth - offsetX });
    }
    if (_.isNumber(options.height) && this.splitters[1].draggable) {
      // invert height, since requesting iFrame is on the right
      if (sourceFrameIdx === 2 || sourceFrameIdx === 3) {
        options.height = this.parentEl.height() - options.height;
      }
      this.setYSplitterLimits(this.layout.frames);
      /** convert to pixels, a height between [0,1) means percent */
      const targetHeight = options.height <= 1 ? options.height * this.parentEl.height() : options.height;
      /** constraint resizing to the calculated limits of the splitter */
      const calculatedHeight =
        targetHeight >= this.splitters[1].limits[0] - offsetY
          ? targetHeight <= this.splitters[1].limits[1] - offsetY
            ? targetHeight + offsetY
            : this.splitters[1].limits[1]
          : this.splitters[1].limits[0];
      splitTarget = splitTarget
        ? Object.assign({}, splitTarget, { top: calculatedHeight - offsetY })
        : Object.assign({ top: calculatedHeight - offsetY });
    }

    // nothing to do
    if (!splitTarget) {
      deferred.reject();
    } else {
      const promises: ng.IPromise<void>[] = [];
      jQuery('.splitter').each((idx: number, elem: Element) => {
        if (splitTarget.left && idx === 0) {
          let defX = this.qService.defer<void>();
          promises.push(defX.promise);
          jQuery(elem).animate({ left: splitTarget.left }, 250, () => {
            this.splitters[0].pos = splitTarget.left - 3;
            this.splitters[0].posPercent = (splitTarget.left - 3) / this.parentEl.width();
            defX.resolve();
          });
        }
        if (splitTarget.top && idx === 1) {
          let defY = this.qService.defer<void>();
          promises.push(defY.promise);
          jQuery(elem).animate({ top: splitTarget.top }, 250, () => {
            this.splitters[1].pos = splitTarget.top - 3;
            this.splitters[1].posPercent = (splitTarget.top - 3) / this.parentEl.height();
            defY.resolve();
          });
        }
      });

      Promise.all(promises).then(() => {
        this.handleDividerEndDrag(frames);
        deferred.resolve();
      });
    }
    return deferred.promise;
  }

  private handleDividerEndDrag(frames: IAppsFrame[]): IFrameLayout {
    const offset = this.parentEl.offset();
    const width = this.parentEl.width();
    const height = this.parentEl.height();

    frames.forEach((f: IAppsFrame, index: number) => {
      switch (index) {
        case 0:
          f.boundingBox = Object.assign({}, f.boundingBox, {
            left: offset.left,
            width: this.splitters[0].pos,
            top: offset.top,
            height: this.splitters[1].pos,
          });
          break;
        case 1:
          f.boundingBox = Object.assign({}, f.boundingBox, {
            left: offset.left + this.splitters[0].pos + 3,
            width: width - this.splitters[0].pos,
            top: offset.top,
            height: this.splitters[1].pos,
          });
          break;
        case 2:
          f.boundingBox = Object.assign({}, f.boundingBox, {
            left: offset.left,
            width: this.splitters[0].pos,
            top: offset.top + this.splitters[1].pos - 3,
            height: height - this.splitters[1].pos,
          });
          break;
        case 3:
          f.boundingBox = Object.assign({}, f.boundingBox, {
            left: offset.left + this.splitters[0].pos + 3,
            width: width - this.splitters[0].pos,
            top: offset.top + this.splitters[1].pos - 3,
            height: height - this.splitters[1].pos,
          });
          break;
        default:
          break;
      }

      // keep bounding boxes in sync
      if (this.boundingBoxes && this.boundingBoxes.length >= index) {
        this.boundingBoxes[index] = Object.assign({}, this.boundingBoxes[index], f.boundingBox);
      }

      // also update the frame descriptor in the layout object, since it is going to be cached,
      const frm = this.layout.frames.find((desc: ILayoutFrameDescriptor) => desc.widget.id === f.id);
      if (frm) {
        frm.width = +f.boundingBox.width / width;
        frm.height = +f.boundingBox.height / height;
      }
    });

    this.frameLayoutService.cacheLayout();
    return this.layout;
  }

  private assembleOriginalStyles(sizeWInPx: number, sizeHInPx: number): void {
    const offset = this.parentEl.offset();
    const width = this.parentEl.width();
    const height = this.parentEl.height();
    this.frameStyles = [
      {
        top: offset.top,
        /**
         * know how much to the right based on the previos frame width,
         */
        left: offset.left,
        width: sizeWInPx,
        height: sizeHInPx,
      },
      {
        top: offset.top,
        /**
         * know how much to the right based on the previos frame width,
         */
        left: offset.left + sizeWInPx,
        width: width - sizeWInPx,
        height: sizeHInPx,
      },
      {
        top: offset.top + sizeHInPx,
        bottom: 0,
        /**
         * know how much to the right based on the previos frame width,
         */
        left: offset.left,
        width: sizeWInPx,
        height: height - sizeHInPx,
      },
      {
        top: offset.top + sizeHInPx,
        bottom: 0,
        /**
         * know how much to the right based on the previos frame width,
         */
        left: offset.left + sizeWInPx,
        width: width - sizeWInPx,
        height: height - sizeHInPx,
      },
    ];
    this.splitters[0].pos = sizeWInPx - 3;
    this.splitters[0].posPercent = (sizeWInPx - 3) / width;
    this.splitters[1].pos = sizeHInPx;
    this.splitters[1].posPercent = sizeHInPx / height;
  }

  /** calculate X splitter limits */
  private setXSplitterLimits(frames: ILayoutFrameDescriptor[]): void {
    const offset = this.parentEl.offset();
    const width = this.parentEl.width();
    const minLSizePx = frames[0].minWidth <= 1 ? Math.round(frames[0].minWidth * width) : frames[0].minWidth;
    const minRSizePx = frames[1].minWidth <= 1 ? Math.round(frames[1].minWidth * width) : frames[1].minWidth;
    this.splitters[0].limits = [offset.left + minLSizePx, offset.left + width - minRSizePx];
  }

  /** calculate Y splitter limits */
  private setYSplitterLimits(frames: ILayoutFrameDescriptor[]): void {
    const offset = this.parentEl.offset();
    const height = this.parentEl.height();
    const minLSizePx = frames[0].minHeight <= 1 ? Math.round(frames[0].minHeight * height) : frames[0].minHeight;
    const minRSizePx = frames[2].minHeight <= 1 ? Math.round(frames[2].minHeight * height) : frames[2].minHeight;
    this.splitters[1].limits = [offset.top + minLSizePx, height - minRSizePx + offset.top];
  }
}
