import { MenuOverlay } from './menu.overlay';
import { Component } from '../components/component';
import menuOverlayTemplate from './menu.overlay.html';

/**
 * Wraps {@link MenuOverlay} component into a directive.
 *
 * @author Tobias Straller [Tobias.Straller.bp@nttdata.com]
 *
 * @ngInject
 */
export function menuOverlayDirective(): ng.IDirective {
  'use strict';
  return Component.createDirective(MenuOverlay, {
    restrict: 'E',
    scope: {
      customClass: '@',
    },
    transclude: true,
    templateUrl: menuOverlayTemplate,
  });
}
