import { Component } from '../component';

('use strict');
import { DialogConstants } from './dialog-constants.model';

/**
 * Base dialog model class, to be extended by the classes tied to each modal component.
 */
export abstract class BaseDialog extends Component {
  /**
   * Set by scope attribute, from the uibModalDialog
   */
  close: Function;
  /**
   * Set by scope attribute, from the uibModalDialog
   */
  dismiss: Function;
  /**
   * Set by scope attribute, from the uibModalDialog
   */
  resolve: Function;

  /**
   * @ngInject
   */
  constructor() {
    super();
  }

  /**
   * Ok button handler.
   */
  ok(): void {
    this.close({ $value: DialogConstants.OK });
  }

  /**
   * Cancel button handler.
   */
  cancel(): void {
    this.dismiss({ $value: DialogConstants.CANCEL });
  }

  /**
   * Called when a key is pressed while the confirm dialog is active.
   * Calls @ok() function if ENTER has been pressed.
   */
  protected handleKeyUp(event: KeyboardEvent, ...args: any[]): void {
    if (event.keyCode === 13) {
      this.ok();
    }
  }
}
