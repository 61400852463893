import {
  ApolloClient,
  ApolloQueryResult,
  createHttpLink,
  FetchPolicy,
  FetchResult,
  gql,
  InMemoryCache,
} from '@apollo/client';
import { WorkplaceContextService } from './workplace.context.service';
import { IWorkplaceProperty } from './workplace-property.interface';
import { NormalizedCacheObject } from '@apollo/client/cache/inmemory/types';
import { OperationVariables, TypedDocumentNode } from '@apollo/client/core/types';
import { setContext } from '@apollo/client/link/context';
import { tokenResult } from '../app.auth';

import { MutationOptions, Observable, ObservableQuery, QueryOptions, split } from '@apollo/client/core';
import { DocumentNode, getOperationAST } from 'graphql';
import { SSELink } from './sse-link';

export class GraphQLService {
  private _log: ng.ILogService;
  private _apolloClient: ApolloClient<NormalizedCacheObject>;
  private readonly workplaceContextService: WorkplaceContextService;
  private readonly tokenKey = 'accessToken';
  private readonly graphQLEndpointKey = 'workplace.graphql.endpoint';

  /**
   * @ngInject
   */
  constructor($log: ng.ILogService, workplaceContextService: WorkplaceContextService) {
    this._log = $log;
    this.workplaceContextService = workplaceContextService;
    this.setupApolloClient();
  }

  /**
   * Removes results from cache for giving query name
   * @param queryName
   * @returns true if cache was not empty
   */
  removeQueryFromCache(queryName: string): boolean {
    return this._apolloClient.cache.evict({ id: 'ROOT_QUERY', fieldName: queryName });
  }

  /**
   * Execute query using apollo client.
   * @param query
   * @param options
   * @returns {Promise<ApolloQueryResult>}
   */
  query<T>(
    query: string | DocumentNode | TypedDocumentNode<T, OperationVariables>,
    options: Omit<QueryOptions<OperationVariables, T>, 'query'>
  ): Promise<ApolloQueryResult<T>> {
    let queryTyped: DocumentNode | TypedDocumentNode<T, OperationVariables>;

    if (typeof query === 'string') {
      queryTyped = gql`
        ${query}
      `;
    } else {
      queryTyped = query as unknown as DocumentNode | TypedDocumentNode<T, OperationVariables>;
    }

    return this._apolloClient.query({
      query: queryTyped,
      ...options,
    });
  }

  /**
   * Execute query using apollo client.
   * @param query
   * @param variables
   * @returns {ObservableQuery}
   */
  watchQuery<T>(
    query: string,
    variables?: OperationVariables,
    fetchPolicy?: FetchPolicy,
    pollInterval?: number,
    context?: any
  ): ObservableQuery<T> {
    return this._apolloClient.watchQuery({
      query: gql`
        ${query}
      `,
      variables,
      fetchPolicy,
      context,
      pollInterval,
    });
  }

  /**
   * Execute mutation using apollo client.
   * @param mutation
   * @param options
   * @returns {Promise<ApolloQueryResult>}
   */
  mutation<T>(
    mutation: string | DocumentNode | TypedDocumentNode<T, OperationVariables>,
    options: Omit<MutationOptions<T>, 'mutation'>
  ): Promise<FetchResult<T>> {
    let mutationTyped: DocumentNode | TypedDocumentNode<T, OperationVariables>;

    if (typeof mutation === 'string') {
      mutationTyped = gql`
        ${mutation}
      `;
    } else {
      mutationTyped = mutation as unknown as DocumentNode | TypedDocumentNode<T, OperationVariables>;
    }

    return this._apolloClient.mutate({
      mutation: mutationTyped,
      ...options,
    });
  }

  /**
   * Execute subscription using apollo client.
   * @param subscription
   * @param variables
   * @returns {Observable<ApolloQueryResult>}
   */
  subscribe<T>(subscription: string, variables?: OperationVariables): Observable<FetchResult<T>> {
    return this._apolloClient?.subscribe({
      query: gql`
        ${subscription}
      `,
      variables,
    });
  }

  /**
   * Sets the initial configuration for the Apollo client.
   * Always sends the session token as a JWT.
   * @private
   */
  private setupApolloClient() {
    this.workplaceContextService.getProperty(this.graphQLEndpointKey).then((property: IWorkplaceProperty) => {
      try {
        const authLink = setContext((_, { headers }) => {
          const token = tokenResult.access_token;

          return {
            headers: {
              ...headers,
              authorization: token ? `Bearer ${token}` : '',
            },
          };
        });

        const uri = property.value;

        const httpLink = createHttpLink({
          uri,
        });

        const sseLink = new SSELink({
          uri: '/graphql-sse',
        });

        const link = split(
          ({ query, operationName }) => {
            const definition = getOperationAST(query, operationName);

            return definition?.kind === 'OperationDefinition' && definition.operation === 'subscription';
          },
          sseLink,
          httpLink
        );

        this._apolloClient = new ApolloClient({
          cache: new InMemoryCache(),
          link: authLink.concat(link),
          connectToDevTools: true,
        });
      } catch (e) {
        this._log.error(`ApolloService -> Error initializing apollo client: ${e.message}`);
      }
    });
  }
}
