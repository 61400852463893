import { Component } from '../component';
import { TasksService } from '../../task/tasks.service';
import { ITask } from '../../task/tasks.model.interface';
import { ILanguage } from '../../../util/language.model.interface';

/**
 * Component for a tasks info item.
 */
export class TaskInfoItem extends Component {
  public appLocale: string;

  private _tasksService: TasksService;
  private _postal: IPostal;

  /**
   * @ngInject
   * @param tasksService
   * @param deviceService
   * @param postal
   */
  constructor(tasksService: TasksService, postal: IPostal, language: ILanguage) {
    super();
    this._tasksService = tasksService;
    this._postal = postal;
    this.appLocale = language.locale;
  }

  /**
   *
   * @returns {ITask[]}
   */
  get tasks(): ITask[] {
    if (this._tasksService) {
      return this._tasksService.tasks;
    }
    return [];
  }

  get showNbTasks(): boolean {
    return this._tasksService.tasksLoaded && !this._tasksService.comError;
  }

  get showUserTasksError(): boolean {
    return this._tasksService.comError;
  }

  /**
   * Destroy the component
   */
  destroy(): void {
    this._tasksService = null;
  }
}
