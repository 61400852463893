import { FrameLayoutService } from './frame-layout.service';
import { IFrameLayout, ILayoutFrameDescriptor } from './frame-layout.model.interface';
import { IAppsFrame } from '../apps/apps.frame.interface';
import { FrameLayout } from './frame-layout';
import { IFrameLayoutAdapter, IFrameLayoutSplitter } from './frame-layout.adapters';
import _ from 'lodash';
import * as Interact from 'interactjs';

('use strict');

export class SimpleXAxisAdapter implements IFrameLayoutAdapter {
  splitters: IFrameLayoutSplitter[];
  isSplitterDragging: boolean;

  private parentEl: JQuery;
  private frameLayoutService: FrameLayoutService;
  private layout: IFrameLayout;
  private frameStyles: any[];
  private qService: ng.IQService;

  /**
   * @ngInject
   * @param {JQuery} parentEl
   * @param {FrameLayoutService} frameLayoutService
   */
  constructor(parentEl: JQuery, frameLayoutService: FrameLayoutService, layout: IFrameLayout, q: ng.IQService) {
    this.parentEl = parentEl;
    this.frameLayoutService = frameLayoutService;
    this.qService = q;
    this.layout = layout;
    this.splitters = [
      {
        limits: [0, this.parentEl.width()],
        axis: 'x',
        cls: FrameLayout.VERTICAL_SPLITTER_CLASS,
        getSplitterStyle: this.getSplitterStyle.bind(this),
        pos: 0,
        posPercent: 0,
        draggable: false,
        splitterEndDrag: (event: Interact.DragEvent, frames: IAppsFrame[]): void => {
          if (!this.splitters[0].draggable) {
            return;
          }
          this.isSplitterDragging = false;
          this.handleDividerEndDrag(frames);
        },
        splitterDragMove: (event: Interact.DragEvent): void => {
          if (!this.splitters[0].draggable) {
            return;
          }
          const offset = this.parentEl.offset();
          if (
            event.pageX < offset.left ||
            event.pageX < this.splitters[0].limits[0] ||
            event.pageX > this.splitters[0].limits[1]
          ) {
            return;
          }

          this.splitters[0].pos = event.pageX - offset.left;
          this.splitters[0].posPercent = (event.pageX - offset.left) / this.parentEl.width();
        },
        splitterDragDown: (event: Interact.DragEvent, frames: ILayoutFrameDescriptor[]): void => {
          if (!this.splitters[0].draggable) {
            return;
          }
          this.isSplitterDragging = true;
          this.setSplitterLimits(frames);
        },
        splitterDragUp: (): void => {
          this.isSplitterDragging = false;
        },
      },
    ];
  }

  get boundingBoxes(): any[] {
    return this.frameStyles;
  }

  get fullScreenStyle(): any {
    return {
      top: 40,
      width: '100%',
    };
  }

  reset(): void {
    this.splitters[0].pos = 0;
    this.splitters[0].posPercent = 0;
  }

  resizeHandler(frames: IAppsFrame[], fullScreenFrame: IAppsFrame): void {
    if (!this.splitters[0].draggable || fullScreenFrame !== null) {
      return;
    }
    this.splitHandler();
    this.handleDividerEndDrag(frames);
  }

  splitHandler(): void {
    this.splitters[0].pos = this.splitters[0].posPercent * this.parentEl.width();
  }

  /**
   * Calculate each frame's position based on the frame's descriptor information. Frames are absolutely positioned.
   */
  buildFrameStyles(): void {
    if (!this.layout.frames || this.layout.frames.length !== 2) {
      return;
    }
    const width = this.parentEl.width();
    const leftFrame = this.layout.frames[0];
    const rightFrame = this.layout.frames[1];
    const leftMinSize = leftFrame.minWidth <= 1 ? Math.round(leftFrame.minWidth * width) : leftFrame.minWidth;
    const rightMinSize = rightFrame.minWidth <= 1 ? Math.round(rightFrame.minWidth * width) : rightFrame.minWidth;
    let leftWidth = leftFrame.width <= 1 ? Math.round(leftFrame.width * width) : leftFrame.width;
    let rightWidth = rightFrame.width <= 1 ? Math.round(rightFrame.width * width) : rightFrame.width;

    leftWidth = !leftWidth || leftWidth < leftMinSize ? leftMinSize : leftWidth;
    rightWidth = !rightWidth || rightWidth < rightMinSize ? rightMinSize : rightWidth;
    this.splitters[0].draggable =
      (leftWidth >= leftMinSize || rightWidth >= rightMinSize) && !(leftMinSize + rightMinSize >= width);

    // if there is a value set for the first frame, the second frames values are ignored.
    this.assembleOriginalStyles(leftWidth ? leftWidth : width - rightWidth);
  }

  resizeIFrames(
    frames: IAppsFrame[],
    options: { width?: number; height?: number },
    sourceFrameIdx: number
  ): ng.IPromise<void> {
    const deferred = this.qService.defer<void>();
    if (!_.isNumber(options.width)) {
      console.log('Given target dimensions are not valid. Please specify a width');
      deferred.reject();
      return deferred.promise;
    }
    if (!this.splitters[0].draggable) {
      console.log('The layout is not resizable.');
      deferred.reject();
      return deferred.promise;
    }

    // invert width, since requesting iFrame is on the right
    if (sourceFrameIdx === 1) {
      options.width = this.parentEl.width() - options.width;
    }
    this.setSplitterLimits(this.layout.frames);
    /** convert to pixels, a width between [0,1) means percent */
    const targetWidth = options.width <= 1 ? options.width * this.parentEl.width() : options.width;
    const offsetX = this.parentEl.offset().left;
    /** constraint resizing to the calculated limits of the splitter */
    const calculatedWidth =
      targetWidth >= this.splitters[0].limits[0] - offsetX
        ? targetWidth <= this.splitters[0].limits[1] - offsetX
          ? targetWidth + offsetX
          : this.splitters[0].limits[1]
        : this.splitters[0].limits[0];

    jQuery('.splitter').animate(
      {
        left: calculatedWidth - 3 - offsetX,
      },
      250,
      () => {
        this.splitters[0].pos = calculatedWidth - 3;
        this.splitters[0].posPercent = (calculatedWidth - 3) / this.parentEl.width();
        this.handleDividerEndDrag(frames);
        deferred.resolve();
      }
    );
    return deferred.promise;
  }

  private handleDividerEndDrag(frames: IAppsFrame[]): IFrameLayout {
    const offset = this.parentEl.offset();
    const width = this.parentEl.width();

    frames.forEach((f: IAppsFrame, index: number) => {
      if (index === 0) {
        f.boundingBox = Object.assign({}, f.boundingBox, {
          left: offset.left,
          width: this.splitters[0].pos,
        });
      } else {
        f.boundingBox = Object.assign({}, f.boundingBox, {
          left: offset.left + this.splitters[0].pos + 3,
          width: width - this.splitters[0].pos,
        });
      }

      // keep bounding boxes in sync
      if (this.boundingBoxes && this.boundingBoxes.length >= index) {
        this.boundingBoxes[index] = Object.assign({}, this.boundingBoxes[index], f.boundingBox);
      }

      // also update the frame descriptor in the layout object, since it is going to be cached
      const frm = this.layout.frames.find((desc: ILayoutFrameDescriptor) => desc.widget.id === f.id);
      if (frm) {
        frm.width = +f.boundingBox.width / width;
      }
    });

    this.frameLayoutService.cacheLayout();
    return this.layout;
  }

  private getSplitterStyle(fullScreen: boolean): any {
    const st: any = { display: fullScreen ? 'none' : 'block' };
    if (this.boundingBoxes && this.boundingBoxes.length === 2) {
      st.left = this.splitters[0].pos !== 0 ? this.splitters[0].pos : this.boundingBoxes[1].left - 3;
    }
    return st;
  }

  private assembleOriginalStyles(sizeInPx: number): void {
    const offset = this.parentEl.offset();
    const width = this.parentEl.width();
    this.frameStyles = [
      {
        top: offset.top,
        bottom: 0,
        /**
         * know how much to the right based on the previos frame width,
         */
        left: offset.left,
        width: sizeInPx,
      },
      {
        top: offset.top,
        bottom: 0,
        /**
         * know how much to the right based on the previos frame width,
         */
        left: offset.left + sizeInPx,
        width: width - sizeInPx,
      },
    ];
    this.splitters[0].pos = sizeInPx - 3;
    this.splitters[0].posPercent = (sizeInPx - 3) / width;
  }

  /** Calculates the limits for the splitter */
  private setSplitterLimits(frames: ILayoutFrameDescriptor[]): void {
    const offset = this.parentEl.offset();
    const width = this.parentEl.width();
    const minLSizePx = frames[0].minWidth <= 1 ? Math.round(frames[0].minWidth * width) : frames[0].minWidth;
    const minRSizePx = frames[1].minWidth <= 1 ? Math.round(frames[1].minWidth * width) : frames[1].minWidth;
    this.splitters[0].limits = [offset.left + minLSizePx, offset.left + width - minRSizePx];
  }
}
