import { SortableController } from './sortable.controller';
/**
 *
 * @returns {ng.IDirective}
 * @ngInject
 */
export function sortableDraggableDirective($parse: ng.IParseService): ng.IDirective {
  'use strict';

  return {
    require: '^utilInteractSortable',
    restrict: 'A',
    link($scope: ng.IScope, el: JQuery, attr: ng.IAttributes, vm: SortableController<any>): void {
      const transfer = $parse(attr['transfer'])($scope);
      const allowDrag = $parse(attr['allowDrag'])($scope);
      let interactable;
      const watcher = $scope.$watch(attr['ddEnabled'], (enabled: boolean) => {
        if (interactable && !enabled) {
          vm.removeDraggable(interactable, el);
          interactable = null;
        } else if (typeof allowDrag === 'undefined' || !!allowDrag) {
          interactable = vm.addDraggable(el, transfer);
        }
      });
      el.on('$destroy', () => {
        if (interactable) {
          vm.removeDraggable(interactable, el);
          watcher();
        }
      });
    },
  };
}
