import { IGroup, IGroupParticipant } from './group.model.interface';
import { NotificationService } from '../notification/notification.service';
import IHttpPromiseCallbackArg = angular.IHttpPromiseCallbackArg;
import { ILanguage } from '../../util/language.model.interface';
import JSData from 'js-data';

/**
 * Service for group operations.
 */
export class GroupService {
  findGroups: (searchTerm: string, maxSize: number, callback: (employees: IGroup[]) => void) => void;

  findGroupsNoDebounce: (searchTerm: string, maxSize: number, callback: (employees: IGroup[]) => void) => void;

  getGroupParticipants: (groupId: string, callback: (participants: IGroupParticipant[]) => void) => void;

  private _groups: IGroup[];
  private _qService: ng.IQService;
  private _groupStore: JSData.DSResourceDefinition<IGroup>;
  private _notificationService: NotificationService;
  private _httpService: ng.IHttpService;
  private _language: ILanguage;

  /**
   * @ngInject
   * @param groupStore
   * @param $q
   * @param debounce
   * @param notificationService
   * @param $http
   * @param language
   */
  constructor(
    groupStore: JSData.DSResourceDefinition<IGroup>,
    $q: ng.IQService,
    debounce: any,
    notificationService: NotificationService,
    $http: ng.IHttpService,
    language: ILanguage
  ) {
    this._groups = [];
    this._qService = $q;
    this._notificationService = notificationService;
    this._groupStore = groupStore;
    this._httpService = $http;
    this._language = language;
    this.findGroups = debounce(this._findGroups, 500, false, true);
    this.findGroupsNoDebounce = this._findGroups;
    this.getGroupParticipants = debounce(this._getGroupParticipants, 500, false, true);
  }

  get groups(): IGroup[] {
    return this._groups;
  }

  private _findGroups(searchTerm: string, maxSize: number, callback: (employees: IGroup[]) => void): void {
    this._groupStore.ejectAll({});
    let params: any = {
      filterTerm: searchTerm,
    };
    if (maxSize) {
      params.maxSize = maxSize;
    }
    this._groupStore
      .findAll(params, <JSData.DSAdapterOperationConfiguration>{
        bypassCache: true,
        noCache: true,
        errorNotification: {
          '400': false,
        },
      })
      .then((groups: IGroup[]) => {
        this._groups = [...groups];
        callback(this._groups);
      })
      .catch((rejection: any) => {
        if (rejection.status === 400) {
          this._notificationService.showInfo('error.http.status.400');
        }
      });
  }

  private _getGroupParticipants(groupId: string, callback: (participants: IGroupParticipant[]) => void): void {
    this._httpService({
      url: `./rest/teams/${groupId}/participants/overview/`,
      method: 'get',
      params: {
        lang: this._language.lang,
      },
    }).then((promise: IHttpPromiseCallbackArg<IGroupParticipant[]>) => {
      callback(promise.data);
    });
  }
}
