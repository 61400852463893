import { Component } from '../component';
import { Fullscreen } from './fullscreen';
import fullscreenToggleTemplate from './fullscreen.html';
/**
 * A fullscreen toggle - emulates F11 key behavior
 */
export function fullscreenToggleDirective(): ng.IDirective {
  'use strict';
  return Component.createDirective(Fullscreen, {
    restrict: 'E',
    templateUrl: fullscreenToggleTemplate,
  });
}
