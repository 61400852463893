import { Component } from '../component';
import { Role } from '../../user/role.model';
import _ from 'lodash';
import { IDiffValue, IDiffValueObject } from '../../user/user.model.interface';

/**
 * Displays information about the current module context (derivatives or productline organisations) of the user's role.
 *
 * @author Tobias Straller [Tobias.Straller.bp@nttdata.com]
 */
export class RoleContext extends Component {
  static DIFF_VALUE_TOTAL_LIMIT: number = 100;
  static DIFF_VALUE_MAX: number = 100;

  _role: Role;
  _diffValues: IDiffValue[];
  diffTypes: any[];

  /**
   * Set by attribute
   */
  clickHandler: (params: { event: JQueryEventObject }) => void;

  /**
   * Hint to display on hover
   * Set by attribute
   */
  hint: string;

  diffTypeHint: string;

  /**
   * @ngInject
   */
  constructor() {
    super();
  }

  /**
   * Set by attribute
   * @param role
   */
  set role(role: Role) {
    this._role = role;
    this.createDiffTypes();
  }

  get role(): Role {
    return this._role;
  }

  set diffValues(diffValues: IDiffValue[]) {
    this._diffValues = diffValues;
    this.createDiffTypes();
  }

  get diffValues(): IDiffValue[] {
    return this._diffValues;
  }

  createDiffTypes(): void {
    this.diffTypes = Object.assign(
      {},
      this.role ? this.getDiffTypes(this.role.diffValues) : {},
      this.diffValues ? this.getDiffTypes(this.diffValues) : {}
    );
  }

  getDiffTypes(diffValues: IDiffValue[] = []): any {
    let totalLength = 0;
    let foundTypes = [];
    let diffTypes = diffValues
      .filter((value: IDiffValue) => {
        if (!foundTypes.includes(value.type)) {
          foundTypes.push(value.type);
          return true;
        }
        return false;
      })
      .map((diffType: IDiffValue) => {
        let values = diffValues
          .filter((value: IDiffValue) => {
            return value.type === diffType.type;
          })
          .map((value: IDiffValue) => {
            return value.value;
          });
        totalLength += diffType.type.length;
        totalLength += values.map((v: IDiffValueObject) => v.displayName).join(', ').length;
        return {
          type: diffType.type,
          name: diffType.name,
          values: values,
        };
      });
    if (totalLength > RoleContext.DIFF_VALUE_TOTAL_LIMIT) {
      diffTypes.forEach((item: any) => {
        if (item.values.length > RoleContext.DIFF_VALUE_MAX) {
          item.values = item.values.splice(0, RoleContext.DIFF_VALUE_MAX);
          item.values.push('...');
        }
      });
    }
    diffTypes.sort((a: any, b: any) => {
      return a.name.localeCompare(b.name);
    });

    this.calculateDiffTypeHint();
    return diffTypes;
  }

  get classes(): string {
    var classes = [];
    if (this.clickable && this.diffTypes.length) {
      classes.push('clickable');
    }
    return classes.join(' ');
  }

  get clickable(): boolean {
    return typeof this.clickHandler === 'function';
  }

  handleClick(event: JQueryEventObject): void {
    if (typeof this.clickHandler === 'function') {
      this.clickHandler({ event: event });
    }
  }

  private calculateDiffTypeHint(): void {
    this.diffTypeHint = (this.diffTypes && _.isArray(this.diffTypes) ? this.diffTypes : [])
      .reduce<string>((acc: string, val: any) => {
        const diffTypeName = val.name && _.isString(val.name) ? val.name : '';
        const diffTypeVal = (val.values && _.isArray(val.values) ? val.values : [])
          .map((v: IDiffValueObject) => v.displayName)
          .join(', ');
        return `${acc}${diffTypeName}: ${diffTypeVal} | `;
      }, '')
      .trim()
      .slice(0, -1);
  }
}
