import angular from 'angular';
import './layout/layout.html';
import * as uiRouter from 'angular-ui-router';
import { getToken } from './app.auth';

declare var navigator: any;

/**
 * Run on document ready.
 */
export function bootstrapApp(): void {
  'use strict';

  var language = navigator.language || navigator.browserLanguage,
    device =
      screen.width > 992 ||
      (navigator.platform && navigator.platform !== null && navigator.platform.indexOf('Win') !== -1)
        ? 1
        : 2,
    match,
    pl = /\+/g, // Regex for replacing addition symbol with a space
    search = /([^&=]+)=?([^&]*)/g,
    decode = function (s: string): string {
      return decodeURIComponent(s.replace(pl, ' '));
    },
    query = window.location.search === '' ? window.location.hash.substring(3) : window.location.search.substring(1),
    urlParams = {};

  while ((match = search.exec(query))) {
    urlParams[decode(match[1])] = decode(match[2]);
  }

  jQuery.ajax({
    type: 'POST',
    dataType: 'text',
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
    url:
      './rest/workplace/init?bl=' +
      language +
      '&deviceType=' +
      device +
      ((urlParams['demoMode'] ? '&demoMode=true' : '') + (urlParams['lang'] ? '&lang=' + urlParams['lang'] : '')),
    success: (response: any, textStatus, request): void => {
      window['Locale'] = response || 'en-US';
      window['UserId'] = request.getResponseHeader('X-User-Id');
      angular.bootstrap(document, ['app']);
    },
    error: (): void => {
      console.log('Error while calling /workplace/init');
      window['Locale'] = navigator.language || navigator.browserLanguage || 'en-US';
      let injector = angular.bootstrap(document, ['app']);
      (<uiRouter.IStateService>injector.get('$state')).go('error');
    },
  });
}
