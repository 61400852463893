import { UserInputError } from './user.input.error';
import { IErrorService } from './error.service.interface';

('use strict');

/**
 * Service to throw translated errors.
 * We cannot throw the errors directly because the translation service is not available in the exception handler (circular dependency).
 *
 * @author Tobias Straller [Tobias.Straller.bp@nttdata.com]
 */
export class ErrorService implements IErrorService {
  static NOTIFICATION_TYPE_WARNING: string = 'notification.type.warning';

  private _translate: angular.translate.ITranslateService;

  /**
   * @ngInject
   */
  constructor($translate: angular.translate.ITranslateService) {
    this._translate = $translate;
  }

  /**
   * Handle custom errors. As default we throw the error.
   * When using the default angular behavior (using log service) the source mapping fails.
   *
   * @param logService
   * @param toastr
   * @param error
   */
  static handleError(logService: ng.ILogService, toastr: Toastr, error: Error): void {
    if (!error) {
      logService.error('Unknown Error.');
      return;
    }

    if (error instanceof UserInputError) {
      toastr.warning(error.message, (<UserInputError>error).notificationType);
    } else {
      logService.error(error.message || error);
      if (error.name) {
        logService.debug('error name: ' + error.name);
      }
      if (error.stack) {
        logService.debug('error stack: ' + error.stack);
      }
    }
  }

  /**
   * Factory method for the exception handler.
   * Cannot inject $translate service due to circular dependencies
   *
   * @param errorService
   * @returns {function(Error): void}
   *
   * @ngInject
   */
  static exceptionHandlerFactory($log: ng.ILogService, toastr: Toastr): Function {
    return ErrorService.handleError.bind(this, $log, toastr);
  }

  /**
   * Throw a translated {@link UserInputError}.
   *
   * @param error
   * @param params
   * @throws {UserInputError}
   */
  throwUserInputError(error: Error, params?: any): ng.IPromise<void> {
    return this._translate([error.message, ErrorService.NOTIFICATION_TYPE_WARNING], params).then(
      (translation: { [key: string]: string }) => {
        throw new UserInputError(
          error,
          translation[error.message],
          translation[ErrorService.NOTIFICATION_TYPE_WARNING]
        );
      }
    );
  }
}
