import { Component } from '../component';
import { DropDownMenu } from './dropdown-menu';
import dropdownMenuTemplate from './dropdown-menu.html';

/**
 * Wrapper directive for a dropdown menu.
 *
 * @ngInject
 */
export function dropdownMenuDirective(): ng.IDirective {
  'use strict';
  return Component.createDirective(DropDownMenu, {
    restrict: 'E',
    transclude: true,
    scope: {
      ariaLabelledby: '@',
    },
    templateUrl: dropdownMenuTemplate,
  });
}
