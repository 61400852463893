import { IApplication } from '../../apps/application.model.interface';
import { LayoutService } from '../../layout/layout.service';
import { WorkplaceContextService } from '../../workplace/workplace.context.service';
import { Icons } from '../../app.icons';
import { DraggableMenuItem } from '../../../util/interact/draggable-menu-item';
import { DeviceService } from '../../../util/device.service';
import { AbstractMenuItem } from './menu.item';
import { WidgetDefinitions } from '../../widget/widget.data.interface';
import { UserService } from '../../user/user.service';
import { User } from '../../user/user.model';
('use strict');

/**
 * @author Tobias Straller [Tobias.Straller.bp@nttdata.com]
 */
export class AppsMenuItem extends AbstractMenuItem<IApplication> {
  private _draggableMenuItem: DraggableMenuItem;
  private _userService: UserService;

  /**
   * @ngInject
   */
  constructor(
    private layoutService: LayoutService,
    workplaceContextService: WorkplaceContextService,
    private deviceService: DeviceService,
    userService: UserService
  ) {
    super(layoutService, workplaceContextService);
    this._userService = userService;
  }

  $onInit() {
    this._draggableMenuItem = new DraggableMenuItem(
      {
        customSettings: { app: this.data.name },
        widgetDefinitionId: WidgetDefinitions.FAVORITE,
      },
      this.layoutService,
      this.deviceService,
      this._layoutService.collapseSidebar.bind(this._layoutService, 'navigationbar')
    );
  }

  /**
   * Returns the icon class for the app
   * @returns {string}
   */
  get iconCls(): string {
    if (this.data.iconCls) {
      return this.data.iconCls;
    }
    if (this.isGroup) {
      return Icons.APP_GROUP;
    }
    // if (this.isDashboard) {
    //   return Icons.DASHBOARD_DEFAULT;
    // }
    return Icons.APP_DEFAULT;
  }

  /**
   * Check if user has QNumber.
   * @returns {boolean}
   */
  userHasQNumber(): boolean {
    const user: User = <User>this._userService.user;
    return user && user.hasQNumber();
  }

  /**
   * Prepare element for being draggable.
   * Only enable drag and drop for leaf items (directories should not be draggable)
   * Because an app without subdirectories is actually a ul with a single li item
   * we need to drag the ul itself
   * @param el
   */
  onRenderComponent(el: JQuery, scope: ng.IScope): void {
    if (!this.isGroup && this.userHasQNumber()) {
      this._draggableMenuItem.initElement($(el), scope);
    }
  }

  destroy(el: JQuery): void {
    super.destroy(el);
    this._draggableMenuItem.cleanupElement();
    this._draggableMenuItem = null;
  }
}
