import { Component } from '../component';
import { ITab } from '../../tab/tab';
import { ITabContent } from '../../tab/tab.content.interface';
import { TabContentType } from '../../tab/tab.content.interface';
import { ITabContentTemplate } from '../../tab/tab.content.interface';
import { TabService, ITabServiceAppSupportMessage } from '../../tab/tab.service';
import { SupportOverlay } from '../../support/support-overlay';

/**
 * Component for displaying tab content
 *
 * @author Tobias Straller [Tobias.Straller.bp@nttdata.com]
 */
export class TabviewContent<T extends ITabContent> extends Component {
  /**
   * Set by attribute.
   */
  content: ITab<T>;

  private _tabService: TabService;
  private _subscriptionAppSupport: ISubscriptionDefinition<ITabServiceAppSupportMessage>;
  private _supportOverlay: SupportOverlay;
  private _timeoutService: ng.ITimeoutService;
  private _timeout: ng.IPromise<void>;

  /**
   * @ngInject
   */
  constructor(private $templateCache: ng.ITemplateCacheService, tabService: TabService, $timeout: ng.ITimeoutService) {
    super();
    this._tabService = tabService;
    this._timeoutService = $timeout;
  }

  $onInit() {
    if (this.hasTemplate) {
      const { content } = this.content;
      // add the template string to the template cache to be referenced in ngInclude binding
      this.$templateCache.put(this.content.id, (<ITabContentTemplate>content).template);
    }
  }

  /**
   * Whether the content has a template
   */
  get hasTemplate(): boolean {
    if (
      this.content &&
      (this.content.content.type === TabContentType.TEMPLATE ||
        this.content.content.type === TabContentType.FRAME_LAYOUT_TEMPLATE)
    ) {
      return typeof (<ITabContentTemplate>this.content.content).template !== 'undefined';
    }
    return false;
  }

  /**
   * Custom onRenderComponent
   * @param el
   */
  onRenderComponent(el: JQuery, scope: ng.IScope): void {
    this._subscriptionAppSupport = this._tabService.channelAppSupport.subscribe(
      TabService.TOPIC_APP_SUPPORT,
      (message: ITabServiceAppSupportMessage) => {
        if (message.tabId === this.content.id && !this._supportOverlay) {
          const componentScope = scope.$new();
          this._timeoutService.cancel(this._timeout);
          Component.renderComponentTo(
            componentScope,
            SupportOverlay,
            '/app/support/support-overlay.html',
            el.find('.__overlayContainer').first()
          ).then((supportOverlay: SupportOverlay) => {
            this._supportOverlay = supportOverlay;
            supportOverlay.supportConfig = message.supportConfig;
            supportOverlay.show();
            const subscription = supportOverlay.channel.subscribe(SupportOverlay.TOPIC_HIDE, () => {
              subscription.unsubscribe();
              if (!supportOverlay) {
                return;
              }
              supportOverlay.el.off('mouseenter.timer');
              supportOverlay.el.off('mouseleave.timer');
              componentScope.$destroy();
              this._supportOverlay = null;
            });
            supportOverlay.el.on('mouseenter.timer', () => {
              this._timeoutService.cancel(this._timeout);
            });
            supportOverlay.el.on('mouseleave.timer', () => {
              this.createSupportOverlayTimer(message.timeout);
            });
            this.createSupportOverlayTimer(message.timeout);
          });
        }
      }
    );
  }

  /**
   * Destroy the component
   */
  destroy(el: JQuery): void {
    this.content = null;
    this._subscriptionAppSupport.unsubscribe();
    if (this._supportOverlay) {
      this._supportOverlay.destroy();
    }
  }

  /**
   * Create a timer for the support overlay
   */
  private createSupportOverlayTimer(timeout: number): void {
    if (timeout) {
      this._timeout = this._timeoutService(() => {
        if (this._supportOverlay) {
          this._supportOverlay.hide();
        }
      }, timeout * 1000);
    }
  }
}
