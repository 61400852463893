import { BaseDialog } from './base-dialog';

export class AuthFlowProgressDialog extends BaseDialog {
  /**
   * @ngInject
   * @param workplaceNativeDeviceService
   */
  constructor() {
    super();
  }
}
