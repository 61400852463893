'use strict';
import { LogoutConfirmationDialog } from './logout-confirmation-dialog';
import { UserSettingService } from '../../userSetting/user-setting.service';
import { WorkplaceApiService } from '../../workplace/workplace.api.service';
import { SettingService } from '../../setting/setting.service';
import { SessionDataService } from '../../sessionData/session-data.service';
import { AuthService } from '../../auth/AuthService';

/**
 * Session expired dialog. We will need some different handling that logout, but until we know what it is, it will just do the same as logout.
 */
export class SessionExpiredDialog extends LogoutConfirmationDialog {
  private httpService: ng.IHttpService;

  /**
   * @ngInject
   */
  constructor(
    sessionDataService: SessionDataService,
    settingService: SettingService,
    workplaceApiService: WorkplaceApiService,
    userSettingService: UserSettingService,
    authService: AuthService
  ) {
    super(sessionDataService, settingService, workplaceApiService, userSettingService, authService);
  }

  async ok() {
    await this.authService.logout(true);
  }

  destroy(el: JQuery): void {
    super.destroy(el);
  }
}
