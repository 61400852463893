import { IAnalyticsFeatureConfig } from '@myworkplace/api';
import { BaseTracker } from './baseTracker';

export class TrackerOffMock extends BaseTracker {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  initFeatures(_featuresConfigs: Map<string, IAnalyticsFeatureConfig>): void {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  trackFeature(_featureName: string, _step?: number): void {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  resetFeature(_featureName: string): void {}
}
