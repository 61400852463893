import { Component } from '../components/component';
import { FrameLayoutMenuItem } from './frame-layout-menu-item';
import frameLayoutMenuItemTemplate from './frame-layout-menu-item.html';

/**
 * Frame layout menu item directive.
 *
 * @return {angular.IDirective}
 */
export function frameLayoutMenuItemDirective(): ng.IDirective {
  'use strict';
  return Component.createDirective(FrameLayoutMenuItem, {
    restrict: 'E',
    scope: {
      layout: '=',
    },
    templateUrl: frameLayoutMenuItemTemplate,
  });
}
