import { Component } from '../component';
import { TaskInfoItem } from './task.info.item';
import taskInfoItemTemplate from './task.info.item.html';
/**
 * Wraps {@link TaskInfoItem} component into a directive.
 *
 * @author Tobias Straller [Tobias.Straller.bp@nttdata.com]
 *
 * @ngInject
 */
export function taskInfoItemDirective(): ng.IDirective {
  'use strict';
  return Component.createDirective(TaskInfoItem, {
    restrict: 'E',
    scope: {},
    templateUrl: taskInfoItemTemplate,
  });
}
