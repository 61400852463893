import { IAnalyticsFeatureConfig } from '@myworkplace/api';
import { BaseTracker } from './baseTracker';

export class TrackerMock extends BaseTracker {
  initFeatures(featuresConfigs: Map<string, IAnalyticsFeatureConfig>): void {
    console.log('TrackerMock - initFeatures: ', featuresConfigs);
  }

  trackFeature(featureName: string, step?: number): void {
    console.log('TrackerMock - trackFeature: ', featureName, step);
  }

  resetFeature(featureName: string): void {
    console.log('TrackerMock - resetFeature: ', featureName);
  }
}
