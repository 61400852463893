/**
 * Directive to render a template, optional with a viewmodel.
 *
 * @returns {ng.IDirective}
 * @ngInject
 *
 */
export function utilTemplateDirective($compile: ng.ICompileService, $templateCache: ng.ITemplateCacheService): ng.IDirective {
  'use strict';

  function renderTemplate(template: string, $scope: any, el: JQuery): void {
    if (template) {
      var clone = $compile(template)($scope);
      el.replaceWith(clone);
      $scope.$on('$destroy', () => {
        clone.remove();
      });
    }
  }

  return {
    scope: {
      templateUrl: '@',
      template: '=',
      vm: '=',
    },
    restrict: 'E',
    link($scope: any, el: JQuery, attr: any): void {
      if (attr.template) {
        $scope.$watch('template', function (template: string): void {
          renderTemplate(template, $scope, el);
        });
      }
      if (attr.templateUrl) {
        renderTemplate($templateCache.get<string>(attr.templateUrl), $scope, el);
      }
    },
  };
}
