import { IPopupOptions } from '../../notification/popup.model.interface';

('use strict');
import { BaseDialog } from './base-dialog';
import _ from 'lodash';

/**
 * Confirmation Dialog.
 * It is also used as a warning dialog, with its cancel button hidden. @See dashboard.ts -> onDropCallback
 */
export class ConfirmDialog extends BaseDialog {
  options: IPopupOptions;

  private config: any;

  /**
   * @constructor
   * @ngInject
   */
  constructor() {
    super();
  }

  $onInit() {
    this.config = this.resolve();
    this.options = this.config && this.config.options ? this.config.options : null;
  }

  /**
   * Gets the Ok button key. If none is given through IPopupOptions at invocation time, the default one is returned.
   * Key is translated -> @see confirm.html
   *
   * @returns {string}
   */
  get okButtonKey(): string {
    return this.options !== null && this.options.okButtonKey ? this.options.okButtonKey : 'notification.popup.button.ok';
  }

  /**
   * Gets the Cancel button key. If none is given through IPopupOptions at invocation time, the default one is returned.
   * Key is translated -> @see confirm.html
   *
   * @returns {string}
   */
  get cancelButtonKey(): string {
    return this.options !== null && this.options.cancelButtonKey
      ? this.options.cancelButtonKey
      : 'notification.popup.button.cancel';
  }

  /**
   * Shows cancel button if config.option.hideCancel is not defined or is set to false
   */
  showCancel(): boolean {
    return _.isUndefined(this.options.hideCancel) || !this.options.hideCancel;
  }
}
