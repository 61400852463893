import { ISetting } from './setting.model.interface';
import { SettingType } from './setting.model.interface';
import JSData from 'js-data';
import _ from 'lodash';
/**
 * Service for settings
 */
export class SettingService {
  static DEFAULT_SETTINGS: ISetting<any>[] = [
    {
      id: 'navigationbarExpanded',
      type: SettingType.BOOLEAN,
      value: false,
    },
    {
      id: 'selectedRole',
      type: SettingType.OBJECT,
      value: {},
    },
    {
      id: 'navigationbarSidebar',
      type: SettingType.BOOLEAN,
      value: false,
      label: 'setting.navigationbarSidebar',
      configurable: false,
    },
    {
      id: 'minimizeSidebar',
      label: 'setting.minimizeSidebar',
      type: SettingType.BOOLEAN,
      value: false,
      configurable: false,
      dependsOn: ['navigationbarSidebar'],
    },
    {
      id: 'guidedTourOnStartup',
      type: SettingType.BOOLEAN,
      value: true,
    },
    {
      id: 'dashboardState',
      type: SettingType.OBJECT,
      value: {},
    },
  ];

  private _settings: ISetting<any>[];
  private _settingsStore: JSData.DSResourceDefinition<ISetting<any>>;

  /**
   *
   * @param settingsStore
   * @param $q
   * @ngInject
   */
  constructor(settingsStore: JSData.DSResourceDefinition<ISetting<any>>) {
    this._settingsStore = settingsStore;
    this._settings = _.cloneDeep(SettingService.DEFAULT_SETTINGS);
  }

  /**
   * Get all workplace settings
   *
   * @returns {IPromise<ISetting<any>[]>}
   */
  getSettings(): ISetting<any>[] {
    return _.clone(this._settings);
  }

  /**
   * Get a specific setting
   * @param id
   */
  getSetting(id: string): JSData.JSDataPromise<ISetting<any>> {
    return this._settingsStore
      .findAll()
      .then((storedSettings: ISetting<any>[]) => {
        const find = storedSettings.find((setting: ISetting<any>) => setting.id === id);
        return find ? find : this.getDefaultSettings(id);
      })
      .catch(() => this.getDefaultSettings(id));
  }

  /**
   * Save a setting
   * @param setting
   */
  updateSetting(setting: ISetting<any>): void {
    this._settingsStore.update(setting.id, setting);
  }

  /**
   * Gets the setting from the default settings array
   * @param id
   * @returns {ISetting<any>}
   */
  private getDefaultSettings(id: string): ISetting<any> {
    return this._settings.find((setting: ISetting<any>) => setting.id === id);
  }
}
