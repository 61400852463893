/**
 * Custom error class for workplace api calls.
 *
 * @author Tobias Straller [Tobias.Straller@bmw.de]
 */
export class WorkplaceApiError extends Error {
  static NAME: string = 'WorkplaceApiError';
  stack: any;
  name: string;
  code: number;

  /**
   * Create a new error object from the given error.
   * The stack is created upon calling new Error(), that is why we have to pass an error instance.
   *
   * @constructor
   * @param error
   * @param code
   * @ngInject
   */
  constructor(error: any, code: number) {
    super(error.message);
    this.name = WorkplaceApiError.NAME;
    this.message = error.message;
    this.stack = error.stack;
    this.code = code;
  }

  /**
   * Override toString for logging correctly
   *
   * @returns {string}
   */
  toString(): string {
    return `${this.name}: ${this.message}`;
  }
}
