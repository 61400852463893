import { Component } from '../component';
import { PaginationComponent } from './pagination';
import paginationTemplate from './pagination.html';
/**
 * Wrapper directive for our custom paginatin directive.
 *
 * @ngInject
 */
export function paginationDirective(): ng.IDirective {
  'use strict';

  return Component.createDirective(PaginationComponent, {
    restrict: 'E',
    templateUrl: paginationTemplate,
    scope: {
      maxSize: '=',
      currentPage: '=',
      totalItems: '=',
      itemsPerPage: '=',
      pageUpdateHandler: '&',
    },
  });
}
