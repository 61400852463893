import { Component } from '../component';
import { DownloadDialog } from './download-dialog';
import downloadDialogTemplate from './download-dialog.html';
/**
 * Wrapper directive for {AcceptFavoriteDialog}
 * @returns {{}}
 * @ngInject
 */
export function downloadDialogDirective(): ng.IDirective {
  'use strict';

  return Component.createDirective(DownloadDialog, {
    restrict: 'E',
    templateUrl: downloadDialogTemplate,
    scope: {
      close: '&',
      dismiss: '&',
      resolve: '&',
    },
  });
}
