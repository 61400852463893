import JSData from 'js-data';
import { ISetting } from './setting.model.interface';
/**
 * Factory for the setting resource definition.
 *
 * @author Tobias Straller [Tobias.Straller.bp@nttdata.com]
 *
 * @ngInject
 */
export function settingStoreFactory(localStore: JSData.DS): JSData.DSResourceDefinition<ISetting<any>> {
  'use strict';

  return localStore.defineResource<ISetting<any>>({
    name: 'setting',
  });
}
