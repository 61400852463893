import { Component } from '../component';
import _ from 'lodash';

('use strict');

/**
 * A search field with a placeholder, a close button and a search icon
 * Uses throttle to prevent too many key triggers.
 */
export class SearchField extends Component {
  placeholder: string;
  term: string;
  autoFocused: boolean = false;
  alwaysShowReset: boolean = false;
  throttledSearch: Function;
  keyUpHandler: Function;
  focusHandler: Function;
  resetHandler: Function;
  searchFocus: boolean;

  /**
   * @ngInject
   */
  constructor() {
    super();

    this.throttledSearch = _.debounce(this.triggerHandler.bind(this), 150, {
      trailing: true,
    });
  }

  reset(event: MouseEvent): void {
    event.stopPropagation();
    this.term = null;
    this.resetHandler();
  }

  triggerHandler(): void {
    this.keyUpHandler();
  }

  triggerFocusHandler(): void {
    this.focusHandler();
  }
}
