/**
 * Defines several constants used by the action logs.
 */
export class ActionConstants {
  static SOURCE_WORKPLACE: string = 'workplace';

  static CATEGORY_APPS: string = 'apps';
  static CATEGORY_GENERAL: string = 'general';
  static CATEGORY_HELP: string = 'help';
  static CATEGORY_DASHBOARD: string = 'dashboard';
  static CATEGORY_MULTILINK: string = 'multilink';
  static CATEGORY_LAYOUT: string = 'layout';
  static CATEGORY_MESSAGES: string = 'messages';
  static CATEGORY_TASKS: string = 'tasks';
  static CATEGORY_ROLES: string = 'roles';
  static CATEGORY_API: string = 'api';

  static ACTION_APPS_LAUNCH_DEEPLINK: string = 'launch-deeplink-app';
  static ACTION_APPS_LAUNCH_SESSION_RESTORE: string = 'launch-session-app';
  static ACTION_APPS_LAUNCH_NAVIGATON_MENU: string = 'launch-navigationMenu';
  static ACTION_APPS_LAUNCH_SEARCH: string = 'launch-search';
  static ACTION_APPS_START: string = 'start-app';
  static ACTION_DASHBOARD_LAUNCH_DEEPLINK: string = 'launch-deeplink-dashboard';
  static ACTION_DASHBOARD_LAUNCH_SESSION_RESTORE: string = 'launch-session-dashboard';
  static ACTION_SPLIT_SCREEN: string = 'splitscreen';
  static ACTION_FULLSCREEN: string = 'fullscreen';
  static ACTION_HELP_MENU: string = 'helpMenu';
  static ACTION_GUIDED_TOUR: string = 'guidedTour';
  static ACTION_ROLE_MENU: string = 'roleMenu';
  static ACTION_TASK_MENU: string = 'taskMenu';
  static ACTION_MESSAGES_MENU: string = 'messagesMenu';
  static ACTION_WIDGET_CREATE: string = 'widget-create';
  static ACTION_FAVORITE_LAUNCH: string = 'favorite-launch';
  static ACTION_WIDGET_CHART_LINK: string = 'widget-chart-link';
  static ACTION_WIDGET_CONTROL: string = 'widget-control';
  static ACTION_API_OPEN_APP: string = 'openApp';
  static ACTION_DASHBOARDS_OPEN_DASHBOARD: string = 'openDashboard';
  static ACTION_DASHBOARD_START: string = 'start-dashboard';
  static ACTION_LAYOUT_OPEN: string = 'openLayout';
  static ACTION_LAYOUT_OPEN_DEEPLINK: string = 'launch-deeplink-layout';
  static ACTION_MULTILINK_OPEN_URL: string = 'open-url';
}
