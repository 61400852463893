'use strict';

/**
 * Custom error class for user inputs.
 *
 * @author Tobias Straller [Tobias.Straller.bp@nttdata.com]
 */
export class UserInputError extends Error {
  static NAME: string = 'UserInputError';
  stack: any;
  name: string;
  notificationType: string;

  /**
   * Create a new error object from the given error.
   * The stack is created upon calling new Error(), that is why we have to pass an error instance.
   *
   * @constructor
   * @param message a translated message
   * @param error original error containing stack trace info
   * @ngInject
   */
  constructor(error: any, message: string, notificationType: string) {
    super(message);
    this.name = UserInputError.NAME;
    this.message = message;
    this.stack = error.stack;
    this.notificationType = notificationType;
  }

  /**
   * Override toString for logging correctly
   *
   * @returns {string}
   */
  toString(): string {
    return `${this.name}: ${this.message}`;
  }
}
