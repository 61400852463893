import { Component } from '../component';

/**
 * Inlinge editing component
 *
 * @author Wilhelm Müller [Wilhelm.Mueller@nttdata.com]
 */
export class InlineEdit extends Component {
  /**
   * set by attribute
   */
  required: boolean;
  disable: boolean;
  onChange: (params: { value: any }) => void;
  maxTitleLength: string;
  editing: boolean;
  dirty: boolean = false;
  defaultValue: string;
  isNew: boolean;

  private _value: string;
  private el: JQuery;
  private timeout: ng.ITimeoutService;
  private timer: ng.IPromise<void>;

  /**
   * @ngInject
   */
  constructor($timeout: ng.ITimeoutService) {
    super();
    this.timeout = $timeout;
  }

  get val(): any {
    return this._value;
  }

  set val(v: any) {
    if (v !== this._value) {
      this._value = v;
      this.dirty = true;
    }
  }

  /**
   * Do stuff when the input element loses focus
   * send http PUT to save the data and update the dashboard
   */
  registerOnBlur(event?: JQueryEventObject): boolean {
    this.timeout(() => {
      this.editing = false;
    }, 200);

    if (this.dirty) {
      if (this._value === '') {
        this._value = this.defaultValue;
        this.onChange({ value: this._value });
        this.dirty = false;
      } else {
        this.onChange({ value: this._value });
        this.dirty = false;
      }
    }
    return false;
  }

  onRenderComponent(el: JQuery): void {
    this.el = el;
    if (this.editing) {
      this.timer = this.timeout(() => {
        this.focusElement();
      });
    }
  }

  destroy(): void {
    this.el = null;
    if (this.timer) {
      this.timeout.cancel(this.timer);
    }
  }

  saveWithEnter($event: JQueryEventObject): void {
    if ($event.keyCode === 13) {
      this.registerOnBlur();
    }
    if ($event.keyCode === 27) {
      this.cancelEdit();
    }
  }

  cancelEdit(): void {
    this.editing = false;
  }

  /**
   * Start the editting process for the input element
   * @param value
   */
  edit($event: JQueryEventObject): boolean {
    if (this.disable) {
      return;
    }
    this.editing = true;
    $event.stopPropagation();
    this.timer = this.timeout(() => {
      this.focusElement();
    });
    return false;
  }

  focusElement(): void {
    var input = this.el.find('input').focus();
    if (this.isNew) {
      input.select();
      this.isNew = false;
    }
  }
}
