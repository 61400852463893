import { ITaskActivity } from './task-activity.model.interface';
import JSData from 'js-data';

/**
 * @ngInject
 */
export function taskActivityFactory(DS: JSData.DS): JSData.DSResourceDefinition<ITaskActivity> {
  'use strict';

  return DS.defineResource<ITaskActivity>({
    name: 'taskActivity',
    idAttribute: 'name',
    endpoint: './rest/tasks/activity',
    watchChanges: false,
  });
}
