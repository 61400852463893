import { INotificationService } from '../notification/notification.service.interface';
import { ILanguage } from '../../util/language.model.interface';
import { AppsService } from '../apps/apps.service';
import { TaskActivityService } from './task-activity.service';
import { ITaskActivity } from './task-activity.model.interface';
import JSData from 'js-data';

export class ActivitiesPolling {
  private _pollingInterval: number;

  /** @ngInject */
  constructor() {
    this._pollingInterval = 0;
  }

  setPollingInterval(pollingInterval: number): void {
    this._pollingInterval = pollingInterval;
  }

  /** @ngInject */
  $get(
    taskActivity: JSData.DSResourceDefinition<ITaskActivity>,
    $translate: angular.translate.ITranslateService,
    $interval: ng.IIntervalService,
    notificationService: INotificationService,
    language: ILanguage,
    appsService: AppsService,
    $http: ng.IHttpService,
    postal: IPostal,
    $q: ng.IQService,
    debounce: any
  ): TaskActivityService {
    return new TaskActivityService(taskActivity, $interval, this._pollingInterval, debounce, $http, language, postal);
  }
}
