import { Component } from '../components/component';
import { FrameLayout } from './frame-layout';
import frameLayoutTemplate from './frame-layout.html';

export function frameLayoutDirective(): ng.IDirective {
  'use strict';
  return Component.createDirective(FrameLayout, {
    restrict: 'E',
    scope: {
      layout: '=',
      contextParams: '=',
      widgetContext: '=',
    },
    templateUrl: frameLayoutTemplate,
  });
}
