'use strict';
import { FormDialog } from './form-dialog';
import { IRestrictedTextModel } from '../restrictedText/restrictedText.model';

/**
 * Model class tied to Add Dashboard modal dialog.
 */
export class AddDashboardDialog extends FormDialog {
  title: string;

  linkInputModel: IRestrictedTextModel = {
    placeholder: '',
    maxLength: 30,
    maxLines: 2,
    required: true,
    maxLinesErrorText: '',
    titleInput: '',
  };

  private _translationService: angular.translate.ITranslateService;

  /**
   * @ngInject
   * @param $translate
   */
  constructor($translate: angular.translate.ITranslateService) {
    super();
    this._translationService = $translate;
    this._translationService([
      'dialogs.dashboard.error.name.required',
      'dialogs.dashboard.error.name.maxlength',
      'dialogs.dashboard.add.input.placeholder',
      'dialogs.dashboard.title.input',
    ]).then((translations: { [key: string]: string }) => {
      this.linkInputModel.placeholder = translations['dialogs.dashboard.add.input.placeholder'];
      this.linkInputModel.titleInput = translations['dialogs.dashboard.title.input'];
      this.tooltipTranslations['title'] = {
        required: translations['dialogs.dashboard.error.name.required'],
        maxLength: translations['dialogs.dashboard.error.name.maxlength'],
      };
    });
  }

  /**
   * Decides whether the name error tooltip should be shown or not.
   * Also sets the error message.
   *
   * @param error
   * @param touched
   * @returns {boolean}
   */
  showTooltip(control: angular.INgModelController): boolean {
    if (!control.$touched) {
      return false;
    }

    if (control.$error.required) {
      return true;
    }

    if (control.$error.maxlength) {
      return true;
    }

    return false;
  }

  /**
   * Compute the tooltip text
   *
   * @param control
   * @returns {any}
   */
  getTooltipText(control: angular.INgModelController): string {
    if (!control.$touched) {
      return '';
    }

    if (control.$error.required) {
      return this.tooltipTranslations[control.$name]['required'];
    }

    if (control.$error.maxlength) {
      return this.tooltipTranslations[control.$name]['maxLength'];
    }

    return '';
  }

  submit(formCtrl: angular.IFormController): void {
    if (formCtrl.$invalid) {
      this.validateBeforeSubmit(formCtrl);
      return;
    }

    this.close({
      $value: this.title,
    });
  }

  /**
   * Destroy the component.
   *
   * @param el
   */
  destroy(el: JQuery): void {
    this._translationService = null;
  }
}
