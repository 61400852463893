import { IUser } from './user.model.interface';
import { User } from './user.model';
import { INotificationService } from '../notification/notification.service.interface';
import { SettingService } from '../setting/setting.service';
import { ISetting } from '../setting/setting.model.interface';
import { IUserService } from './user.service.interface';
import { Role } from './role.model';
import { ILanguage } from '../../util/language.model.interface';
import JSData from 'js-data';
import { WorkplaceContextService } from '../workplace/workplace.context.service';
import IPromise = angular.IPromise;
import { isInternet } from '../../util/host.utils';

/**
 * Service providing the user object for the workplace
 */
export class UserService implements IUserService {
  static CHANNEL: string = 'UserServiceChannel';
  static CHANNEL_DEPUTY_UPDATED: string = 'UserServiceChannel';
  static TOPIC_SELECTED_ROLE_CHANGED: string = 'UserServiceTopicRoleChanged';
  static TOPIC_ROLES_UPDATED: string = 'TopicRolesUpdated';
  static DEPUTY_UPDATED = 'UserServiceDeputyChanged';

  /**
   * Channel for user service messages
   */
  channel: IChannelDefinition<IUserServiceMessage>;
  channelDeputyUpdated: IChannelDefinition<IUserServiceDeputyUpdatedMessage>;

  private _user: User;
  private _qService: ng.IQService;
  private _userStore: JSData.DSResourceDefinition<IUser>;
  private _translateService: angular.translate.ITranslateService;
  private _notificationService: INotificationService;
  private _settingService: SettingService;
  private _postal: IPostal;
  private _httpService: ng.IHttpService;
  private _selectedRoleSetting: ISetting<Role>;
  private _logService: ng.ILogService;
  private _language: ILanguage;
  private _workplaceContextService: WorkplaceContextService;

  /**
   * @ngInject
   */
  constructor(
    $q: ng.IQService,
    userStore: JSData.DSResourceDefinition<IUser>,
    $translate: angular.translate.ITranslateService,
    postal: IPostal,
    $http: ng.IHttpService,
    notificationService: INotificationService,
    settingService: SettingService,
    $log: ng.ILogService,
    language: ILanguage
  ) {
    this._qService = $q;
    this._userStore = userStore;
    this._translateService = $translate;
    this._settingService = settingService;
    this._postal = postal;
    this._httpService = $http;
    this.channel = postal.channel(UserService.CHANNEL);
    this.channelDeputyUpdated = postal.channel(UserService.CHANNEL_DEPUTY_UPDATED);
    this._notificationService = notificationService;
    this._settingService
      .getSetting('selectedRole')
      .then((setting: ISetting<Role>) => (this._selectedRoleSetting = setting));
    this._logService = $log;
    this._language = language;
  }

  setWorkplaceContextService(workplaceContextService: WorkplaceContextService): void {
    this._workplaceContextService = workplaceContextService;
  }

  get user(): IUser {
    return this._user;
  }

  /**
   * The currently selected user role
   */
  get selectedUserRole(): Role {
    return this._user ? this._user.getSelectedRole() : null;
  }

  getUserNumber(): string {
    return isInternet() ? this.user?.cnumber : this.user?.userId;
  }

  /** Updates user's strongAuth - used after session upgrade */
  updateUserStrongAuth(value: number): IPromise<number> {
    return this.getUser(true).then((user: IUser) => {
      if (user && user.authLevel >= value) {
        this._workplaceContextService.publishUpdatedAuthLevel(user.authLevel, value);
      }
      return user.authLevel;
    });
  }

  /**
   * Set a user role as selected
   * @param role
   */
  selectRole(role: Role): void {
    const found = this._user.roles ? this._user.roles.find((r: Role) => r.roleId === role.roleId) : null;
    if (found) {
      this._selectedRoleSetting.value = found;
      this._settingService.updateSetting(this._selectedRoleSetting);
      this.channel.publish(UserService.TOPIC_SELECTED_ROLE_CHANGED, {
        user: this._user,
        selectedRole: this.selectedUserRole,
      });
    }
  }

  /**
   * Get the user
   * @returns {IPromise<IUser>}
   */
  getUser(bypassCache: boolean = false): ng.IPromise<User> {
    const deferred = this._qService.defer<User>();
    if (this._user && !bypassCache) {
      deferred.resolve(this._user);
    } else {
      this._userStore
        .find('users/info', <JSData.DSAdapterOperationConfiguration>{
          params: {
            lang: this._language.lang,
          },
          noCache: true,
        })
        .then((data: IUser) => {
          if (!this._user || bypassCache) {
            const user = new User(data);
            /**
             * Check for the selected role, set first one if nothing found
             * @type {Role}
             */
            const currentRole = this.getCurrentRole(user);
            user.selectedRole = currentRole ? currentRole : user.roles[0];
            this._user = user;
            this._logService.info(`UserService -> getUser: strongAuth=${this._user.authLevel}`);
          }
          deferred.resolve(this._user);
        })
        .catch(() => deferred.reject());
    }
    return deferred.promise;
  }

  /**
   * Method used when responsibilities changed
   */
  refreshUserInfo(modfiedRoleId: string): void {
    this._userStore
      .find('users', <JSData.DSAdapterOperationConfiguration>{
        params: {
          lang: this._language.lang,
        },
        noCache: true,
      })
      .then((data: IUser) => {
        const user = new User(data);
        const currentRole = this.getCurrentRole(user);
        user.selectedRole = currentRole ? currentRole : user.roles && user.roles.length !== 0 ? user.roles[0] : null;
        this._user = user;
        this._logService.info(`UserService -> getUser: strongAuth=${this._user.authLevel}`);
        // TODO: don't trigger refresh if modified role is given and doesn't match currently selected role. - issue: if this event is not triggered role switching will not work
        this.channel.publish(UserService.TOPIC_ROLES_UPDATED, {
          user: this._user,
          selectedRole: this.selectedUserRole,
        });
      });
  }

  getUserDeputies(): ng.IPromise<IUser[]> {
    return this._httpService({
      method: 'GET',
      url: './rest/users/deputizedBy/active',
    })
      .then((result: any) => {
        if (!result) {
          return [];
        }
        return result.data;
      })
      .catch((message: any) => {
        console.log('User deputies error: ', message);
        return [];
      });
  }

  updateDeputy(deputy: IUser, enabled: boolean): void {
    this.channelDeputyUpdated.publish(UserService.DEPUTY_UPDATED, { deputy, enabled });
  }

  private getCurrentRole(user: IUser): Role {
    return user.roles
      ? user.roles.find(
          (role: Role) =>
            role.roleId === this._selectedRoleSetting.value.roleId &&
            role.substitute === this._selectedRoleSetting.value.substitute
        )
      : null;
  }
}

/**
 * Describes messages send on the user service channel
 */
export interface IUserServiceMessage {
  user: IUser;
  selectedRole: Role;
}

export interface IUserServiceDeputyUpdatedMessage {
  deputy: IUser;
  enabled: boolean;
}

export interface IUserServiceAuthUpdatedMessage {
  authLevel: number;
  strongAuthService: string;
}
