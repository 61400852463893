import { Component } from '../component';
import { InlineEdit } from './inline-edit';
import inlineEditTemplate from './inline-edit.html';
/**
 * @ngInject
 */
export function inlineEditDirective(): ng.IDirective {
  'use strict';
  return Component.createDirective(InlineEdit, {
    restrict: 'E',
    scope: {
      disable: '=',
      onChange: '&',
      val: '=',
      maxTitleLength: '=',
      editing: '=',
      defaultValue: '=',
      isNew: '=',
    },
    templateUrl: inlineEditTemplate,
  });
}
