import { Component } from '../component';
import { LayoutService } from '../../layout/layout.service';
import { TabService } from '../../tab/tab.service';
import { IDocument, IElement } from '../../../util/types/augmented.model.interface';
import { ActionConstants } from '../../actionLog/action-constants';
import { ActionLogService } from '../../actionLog/action-log.service';
import { TrackingService } from '../../feature-tracking/tracking.service';
import { EXIT_FULL_SCREEN, FULL_SCREEN } from '../analytics/feature-analytics-conf-layout';

/**
 * Component for toggling browser full screen mode.
 */
export class Fullscreen extends Component {
  private _tabService: TabService;
  private _layoutService: LayoutService;
  private _actionLogService: ActionLogService;

  /**
   * @ngInject
   */
  constructor(
    tabService: TabService,
    layoutService: LayoutService,
    actionLogService: ActionLogService,
    private trackingService: TrackingService
  ) {
    super();
    this._tabService = tabService;
    this._layoutService = layoutService;
    this._actionLogService = actionLogService;
  }

  showToggle(): boolean {
    return !this._layoutService.mobileLayout;
  }

  /**
   * Checks to see if we're in full screen mode already
   * Browser proof, yet again.
   *
   * @returns {boolean}
   */
  isFullscreen(): boolean {
    return (
      (typeof document.fullscreenElement !== 'undefined' && document.fullscreenElement !== null) ||
      (typeof (<IDocument>document).webkitFullscreenElement !== 'undefined' &&
        (<IDocument>document).webkitFullscreenElement !== null) ||
      (typeof (<IDocument>document).mozFullScreenElement !== 'undefined' &&
        (<IDocument>document).mozFullScreenElement !== null) ||
      (typeof (<IDocument>document).msFullscreenElement !== 'undefined' &&
        (<IDocument>document).msFullscreenElement !== null)
    );
  }

  /**
   * Toggles fullscreen mode on or off.
   * Unfortunately, not all browsers obey the standard 'requestFullscreen' yet, so we need to be browser proof...
   *
   * @param value
   */
  toggleFullscreen(value: boolean): void {
    if (!this.isFullscreen()) {
      this.trackingService.getTracker().trackFeature(FULL_SCREEN, 1);

      this._actionLogService.logAction({
        category: ActionConstants.CATEGORY_GENERAL,
        action: ActionConstants.ACTION_FULLSCREEN,
      });
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
        return;
      }
      if ((<IElement>document.documentElement).webkitRequestFullscreen) {
        (<IElement>document.documentElement).webkitRequestFullscreen();
        return;
      }
      if ((<IElement>document.documentElement).mozRequestFullScreen) {
        (<IElement>document.documentElement).mozRequestFullScreen();
        return;
      }
      if ((<IElement>document.documentElement).msRequestFullscreen) {
        (<IElement>document.documentElement).msRequestFullscreen();
        return;
      }
    }

    this.trackingService.getTracker().trackFeature(EXIT_FULL_SCREEN, 1);

    if (document.exitFullscreen) {
      document.exitFullscreen();
      return;
    }

    if ((<IDocument>document).webkitExitFullscreen) {
      (<IDocument>document).webkitExitFullscreen();
      return;
    }

    if ((<IDocument>document).mozCancelFullScreen) {
      (<IDocument>document).mozCancelFullScreen();
      return;
    }

    if ((<IDocument>document).msExitFullscreen) {
      (<IDocument>document).msExitFullscreen();
      return;
    }
  }

  /**
   * Destroy
   */
  destroy(): void {
    this._tabService = null;
    this._layoutService = null;
  }
}
