'use strict';
import { WorkplaceContextService } from '../workplace/workplace.context.service';
import { IWorkplaceProperty } from '../workplace/workplace-property.interface';
import { IRestrictions } from './restrictions.model.interface';

export class RestrictionsService {
  private translateService: angular.translate.ITranslateService;
  private workplaceContextService: WorkplaceContextService;
  private missingBRole: string;
  private missingPRole: string;
  private originIntranetOnly: string;
  private originInternetOnly: string;
  private strongAuthMandatory: string;
  private origin: string;

  /**
   * @ngInject
   */
  constructor($translate: angular.translate.ITranslateService, workplaceContextService: WorkplaceContextService) {
    this.translateService = $translate;
    this.workplaceContextService = workplaceContextService;
  }

  getRestrictionText(restrictions: IRestrictions): ng.IPromise<string> {
    return this.translateService(
      [
        'widget.restrictions.business.role',
        'widget.restrictions.portal.role',
        'widget.restrictions.origin.intranet',
        'widget.restrictions.origin.internet',
        'widget.restrictions.string.auth',
      ],
      {
        businessRoles: restrictions.businessRoles ? restrictions.businessRoles.join(', ') : '',
        portalRoles: restrictions.portalRoles ? restrictions.portalRoles.join(', ') : '',
      }
    ).then((translations: any) => {
      this.missingBRole = translations['widget.restrictions.business.role'];
      this.missingPRole = translations['widget.restrictions.portal.role'];
      this.originIntranetOnly = translations['widget.restrictions.origin.intranet'];
      this.originInternetOnly = translations['widget.restrictions.origin.internet'];
      this.strongAuthMandatory = translations['widget.restrictions.string.auth'];

      return this.workplaceContextService.getProperty('workplace.origin').then((origin: IWorkplaceProperty) => {
        this.origin = origin.value;
        if (!restrictions) {
          return '';
        }

        var tooltipText = '';
        if (restrictions.businessRoles && restrictions.businessRoles.length > 0) {
          tooltipText += '<div>' + this.missingBRole + '</div>';
        }
        if (restrictions.portalRoles && restrictions.portalRoles.length > 0) {
          tooltipText += '<div>' + this.missingPRole + '</div>';
        }
        if (restrictions.origin && restrictions.origin.length > 0) {
          if (restrictions.origin.indexOf('internet') && this.origin === 'internet') {
            tooltipText += '<div>' + this.originInternetOnly + '</div>';
          }
          if (restrictions.origin.indexOf('intranet') && this.origin === 'intranet') {
            tooltipText += '<div>' + this.originIntranetOnly + '</div>';
          }
        }
        if (restrictions.strongAuth && restrictions.strongAuth.length > 0) {
          tooltipText += '<div>' + this.strongAuthMandatory + '</div>';
        }
        return tooltipText;
      });
    });
  }

  /**
   * Wheter or not a restriction object has any specific restrictions inside it.
   * @param {IRestrictions} restriction
   */
  isEmpty(restriction: IRestrictions): boolean {
    if (!restriction) {
      return true;
    }

    if (
      (restriction.origin && restriction.origin.length > 0) ||
      (restriction.businessRoles && restriction.businessRoles.length > 0) ||
      (restriction.portalRoles && restriction.portalRoles.length > 0) ||
      (restriction.strongAuth && restriction.strongAuth.length > 0) ||
      (restriction.cooperation && restriction.cooperation.length > 0)
    ) {
      return false;
    }

    return true;
  }
}
