import angular from 'angular';

/**
 *
 * Attribute directive that makes sure the angular bootstrap ui element Modal is always centered on the page.
 *
 * Created by mariab on 14.01.2016.
 *
 * @ngInject
 */

export function popupCenterDirective($window: Window): ng.IDirective {
  'use strict';

  return {
    restrict: 'A',
    link(scope: angular.IScope, el: JQuery): void {
      var modal = el.closest('.modal'),
        dialog = modal.find('.modal-dialog'),
        resizeFunction = (): void => {
          modal.css('display', 'block');

          // Dividing by two centers the modal exactly, but dividing by three
          // or four works better for larger screens.
          dialog.css('margin-top', Math.max(0, ($(window).height() - dialog.height()) / 2));
        };

      /**
       * call it on init, so the popup centers when it is opened
       */
      resizeFunction();

      /**
       * call it on browser resize
       */
      angular.element($window).bind('resize', (): void => {
        resizeFunction();
      });
    },
  };
}
