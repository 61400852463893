import { Component } from '../component';
import { LayoutService } from '../../layout/layout.service';
import { ILayoutServiceChannelEventMessage } from '../../layout/layout.service';

('use strict');

/**
 * Sidebar component
 *
 * @author Tobias Straller [Tobias.Straller.bp@nttdata.com]
 *
 */
export class Sidebar extends Component {
  /**
   * Set by attribute.
   */
  _autoClose: boolean;
  /**
   * Set by attribute
   */
  _expanded: boolean;
  private _customClass: string;
  private _layoutService: LayoutService;
  private _timeoutService: ng.ITimeoutService;
  private _subscriptionEvent: ISubscriptionDefinition<ILayoutServiceChannelEventMessage>;
  private _id: string;

  /**
   * @param {ILayoutService} layoutService
   * @constructor
   *
   * @ngInject
   */
  constructor($attrs: any, layoutService: LayoutService, $timeout: ng.ITimeoutService) {
    super();
    this._timeoutService = $timeout;
    this._layoutService = layoutService;
  }

  /**
   * Returns the expanded state of the component
   * @returns {boolean}
   */
  get expanded(): boolean {
    if (this.id && this._layoutService.sidebars[this.id]) {
      return this._layoutService.sidebars[this.id].expanded;
    }
    return false;
  }

  /**
   * Set expanded state of the component
   */
  set expanded(value: boolean) {
    if (this._layoutService) {
      this._layoutService.mergeSidebarConfig(this.id, {
        expanded: value,
      });
    }
  }

  /**
   * When auto-close is true, a click outside the sidebar will close it.
   * Default is false.
   */
  get autoClose(): boolean {
    return this._autoClose || false;
  }

  /**
   * Returns custom css classes separated by space
   *
   * @returns {string}
   */
  get customClass(): string {
    return (this._customClass + (this.expanded ? ' expanded' : '')).trim();
  }

  /**
   * Set the custom class
   *
   * @param clazz
   */
  set customClass(clazz: string) {
    this._customClass = clazz;
  }

  set id(value: string) {
    this._id = value;
  }

  get id(): string {
    return this._id;
  }

  /**
   * Handle body click message from layout service
   */
  layoutServiceEventBodyClickMessageCallback(message: ILayoutServiceChannelEventMessage): void {
    if (this.autoClose) {
      this._layoutService.collapseSidebar(this.id);
    }
  }

  /**
   * Setup the component
   */
  setupComponent(scope: ng.IScope): void {
    this._layoutService.addSidebar(this.id, { expanded: false });
    if (!this._layoutService.mobileLayout && this._expanded) {
      this._layoutService.expandSidebar(this.id);
    }
    this._subscriptionEvent = this._layoutService.channelEvent.subscribe(
      LayoutService.TOPIC_EVENT_BODY_CLICK,
      (message: ILayoutServiceChannelEventMessage) => {
        this._timeoutService(this.layoutServiceEventBodyClickMessageCallback.bind(this, message));
      }
    );
  }

  /**
   * Stop click event, so sidebar does not automatically close
   * @param $event
   */
  sidebarClickHandler($event: ng.IAngularEvent): void {
    $event.stopPropagation();
  }

  /**
   * Destroy the component
   */
  destroy(): void {
    this._subscriptionEvent.unsubscribe();
    this._layoutService = null;
    this._timeoutService = null;
  }
}
