import _ from 'lodash';
/**
 * Created by mariab on 05.01.2016.
 */

/**
 * Directive for throttling of succesive multiple clicks on an element
 *
 * @param $parse
 * @returns {ng.IDirective}
 * @ngInject
 *
 */
export function throttleclickDirective($parse: ng.IParseService): ng.IDirective {
  'use strict';

  return {
    restrict: 'A',
    compile: function (el: JQuery, attr: any): ng.IDirectivePrePost {
      var fn = $parse(attr.utilThrottleClick);
      return {
        post: function link(scope: ng.IScope, el: JQuery): void {
          el.on(
            'click',
            _.throttle(
              function (): void {
                var callback = function (): void {
                  fn(scope);
                };
                scope.$apply(callback);
              },
              2000,
              { trailing: true }
            )
          );
        },
      };
    },
  };
}
