import { IIFrameComponent } from '../components/iframe-component';
import { IDndCoordinates, IDragObject } from './interframe-drag-manager.interface';
import { IInterframeDragManagerServiceMessage } from './interframe-drag-manager.service';
import { WidgetDefinitions } from '../widget/widget.data.interface';
import { DashboardService } from '../dashboard/dashboard.service';
import { TabManager } from '../tab/tab.manager';
import { ITab } from '../tab/tab';
import { ITabContent, ITabContentTemplate, TabContentType } from '../tab/tab.content.interface';
import { TabService } from '../tab/tab.service';
import { WidgetService } from '../widget/widget.service';

/**
 * Service for handling dragging and dropping between app/widget iframes.
 */
export class IframeDragToWorkplaceService {
  static IFRAME_DND_TO_WORKPLACE_CHANNEL: string = 'IframeDnDToWorkplaceChannel';
  static IFRAME_DND_TO_WORKPLACE_START: string = 'IframeDnDToWorkplaceStart';
  static IFRAME_DND_TO_WORKPLACE_END: string = 'IframeDnDToWorkplaceEnd';

  channel: IChannelDefinition<IInterframeDragManagerServiceMessage>;

  _dashboardService: DashboardService;
  _tabService: TabService;
  _widgetService: WidgetService;

  private dragObject: IDragObject;
  private jquery: JQueryStatic;
  private iFrameDragProxy: any;
  private acceptDrop: boolean = false;
  private log: ng.ILogService;

  /**
   * @ngInject
   * @param jQuery
   * @param $log
   * @param postal
   */
  constructor(
    jQuery: JQueryStatic,
    $log: ng.ILogService,
    postal: IPostal,
    dashboardService: DashboardService,
    tabService: TabService,
    widgetService: WidgetService
  ) {
    this.jquery = jQuery;
    this.log = $log;
    this.channel = postal.channel(IframeDragToWorkplaceService.IFRAME_DND_TO_WORKPLACE_CHANNEL);
    this._dashboardService = dashboardService;
    this._tabService = tabService;
    this._widgetService = widgetService;
  }

  /**
   * Updates drag proxy coordinates.
   * @param frame
   * @param position
   */
  setDragCoordinates(frame: IIFrameComponent, position: IDndCoordinates): void {
    if (!this.isDragActive()) {
      return;
    }
    this.updateDragProxy(frame, position);
    this.dragObject.position = position;
    this.setIframeDragProxyPosition(frame, position);
  }

  /**
   * Creates and sets the drag proxy.
   *
   * @param frame
   * @param connections
   * @param dragObject
   */
  setDragObject(frame: IIFrameComponent, dragObject: IDragObject): void {
    /**
     * if drag Object is a not valid Object, dragging is canceled.
     */
    if (!dragObject) {
      this.endDrag();
      return;
    }

    /**
     * A drag is already active destroy the old proxy and create a new one
     */
    if (this.dragObject && this.iFrameDragProxy) {
      this.iFrameDragProxy.remove();
    }

    this.dragObject = dragObject;

    /**
     * create drag proxy object
     * @type {JQuery}
     */
    this.iFrameDragProxy = this.jquery('<div></div>');
    this.iFrameDragProxy
      .css({
        position: 'absolute',
        color: '#888',
        backgroundColor: '#fff',
        border: '1px solid #ddd',
        padding: '6px',
        zIndex: 1,
      })
      .html(this.dropRejectedTag);

    /**
     * set the initial position of the drag proxy
     */
    this.setIframeDragProxyPosition(frame, dragObject.position);
    this.jquery('body').append(this.iFrameDragProxy);
    this.channel.publish(IframeDragToWorkplaceService.IFRAME_DND_TO_WORKPLACE_START, {
      targetFrameId: frame.getId(),
      dragObject: dragObject,
    });
  }

  endDrag(): void {
    if (this.acceptDrop) {
      this.addAppToDashboard();
    }
    if (this.iFrameDragProxy) {
      this.iFrameDragProxy.remove();
    }
    this.channel.publish(IframeDragToWorkplaceService.IFRAME_DND_TO_WORKPLACE_END, {
      targetFrameId: null,
      dragObject: this.dragObject.data,
    });
  }

  private isDragActive(): boolean {
    return this.dragObject && this.dragObject !== null;
  }

  private setIframeDragProxyPosition(frame: IIFrameComponent, position: IDndCoordinates): void {
    if (this.iFrameDragProxy) {
      this.iFrameDragProxy.css({
        top: frame === null ? position.y - 10 : frame.getIframePosition().y + position.y + 30,
        left: frame === null ? position.x + 10 : frame.getIframePosition().x + position.x + 20,
        right: 'auto',
        bottom: 'auto',
      });
    }
  }

  private get dragAcceptedTag(): string {
    return `<span style="font-size: 16px; position: absolute; color: #009000;" class="iwp-icon-gen_ok"></span> 
            <span style="padding-left: 20px;">${this.dragObject ? this.dragObject.dragText : ''}</span>`;
  }

  private get dropRejectedTag(): string {
    return `<span style="font-size: 16px; position: absolute; color: #CC3333;" class="iwp-icon-gen_error"></span>
            <span style="padding-left: 20px;">${this.dragObject ? this.dragObject.dragText : ''}</span>`;
  }

  private updateDragProxy(frame: IIFrameComponent, position: IDndCoordinates): void {
    const frameRectangle = frame['el'].get(0).getBoundingClientRect();
    if (!(position.x > 0 && position.x < frameRectangle.width && position.y > 0 && position.y < frameRectangle.height)) {
      this.acceptDrop = true;
      this.iFrameDragProxy.html(this.dragAcceptedTag);
    } else {
      this.acceptDrop = false;
      this.iFrameDragProxy.html(this.dropRejectedTag);
    }
  }

  private async addAppToDashboard(): Promise<any> {
    const widgetDesc = {
      widgetDefinitionId: WidgetDefinitions.FAVORITE,
      colspan: this.dragObject.data.colspan || 1,
      rowspan: this.dragObject.data.rowspan || 1,
      hidden: false,
      customSettings: {
        app: this.dragObject.data.id,
        url: this.dragObject.data.url || '',
      },
      widgetContextMenuOpen: false,
    };

    if (this.dragObject.data && this.dragObject.data.backgroundImageUrl && this.dragObject.data.backgroundImageUrl.trim()) {
      widgetDesc.customSettings['backgroundImageUrl'] = this.dragObject.data.backgroundImageUrl;
    }
    if (this.dragObject.data && this.dragObject.data.iconCls && this.dragObject.data.iconCls.trim()) {
      widgetDesc.customSettings['iconCls'] = this.dragObject.data.iconCls;
    }

    const tabmanagers = this._tabService.getTabManagers();
    let activeDashboardName = '';
    Object.keys(tabmanagers)
      .map((k: string) => tabmanagers[k])
      .some((tabMng: TabManager) =>
        tabMng.tabs.some((t: ITab<ITabContent>) => {
          if (
            t.active &&
            t.content &&
            t.content.type === TabContentType.TEMPLATE &&
            (<ITabContentTemplate>t.content).containsDashboard
          ) {
            activeDashboardName = (<ITabContentTemplate>t.content).dashboardName;
            return true;
          } else {
            return false;
          }
        })
      );

    if (!activeDashboardName) {
      return;
    }

    /**
     * Get dashboard from BE
     * This will ensure we have the dashboard in store for the link creation
     */
    const dash = await this._dashboardService.getDashboard(activeDashboardName);
    if (!dash) {
      throw {
        type: 'dashboards',
        message: 'No matching dashboard found',
      };
    }

    try {
      /** create favorite link on last category of the dashboard */
      return this._widgetService.createFavoriteLink(widgetDesc, dash.name);
    } catch (reason) {
      throw {
        type: 'server',
        message: reason && reason !== null && reason.message !== null ? reason.message : 'Unknown error.',
      };
    }
  }
}
