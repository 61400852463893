import { Component } from '../component';
import { LogoutConfirmationDialog } from './logout-confirmation-dialog';
import logoutConfirmationDialogTemplate from './logout-confirmation-dialog.html';

/**
 * Directive wrapper for logout confirmation dialog.
 *
 * @returns {ng.IDirective}
 * @ngInject
 */
export function logoutConfirmationDialogDirective(): ng.IDirective {
  'use strict';
  return Component.createDirective(LogoutConfirmationDialog, {
    restrict: 'E',
    templateUrl: logoutConfirmationDialogTemplate,
    scope: {
      close: '&',
      dismiss: '&',
      resolve: '&',
    },
  });
}
