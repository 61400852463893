'use strict';
import { BaseDialog } from './base-dialog';
import { IAppsFrame } from '../../apps/apps.frame.interface';
import { WorkplaceApiService } from '../../workplace/workplace.api.service';

/**
 * Dialog for displaying apps
 */
export class AppDialog extends BaseDialog {
  frame: IAppsFrame;
  private config: any;
  private workplaceApiService: WorkplaceApiService;
  private waitForClose: boolean = false;

  /**
   * @constructor
   * @ngInject
   */
  constructor(workplaceApiService: WorkplaceApiService) {
    super();
    this.workplaceApiService = workplaceApiService;
  }

  $onInit() {
    this.config = this.resolve();
    this.frame = this.config.config.frame;
  }

  onRenderComponent(el: JQuery, scope: ng.IScope): void {
    super.onRenderComponent(el, scope);
    scope.$on('modal.closing', (event: ng.IAngularEvent) => {
      if (!this.waitForClose) {
        event.preventDefault();
        this.waitForClose = true;
        this.workplaceApiService
          .waitForAppClose(this.frame.id)
          .then(() => {
            this.workplaceApiService.notifyClose(this.frame.id);
          })
          .then(() => {
            this.frame = null;
            this.dismiss();
          })
          .finally(() => {
            this.waitForClose = false;
          });
      }
    });
  }

  destroy(el: JQuery): void {
    super.destroy(el);
    this.frame = null;
    this.workplaceApiService = null;
  }
}
