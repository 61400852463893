import { Component } from '../components/component';
import { Layout } from '../layout/layout.model';
import { LayoutService } from '../layout/layout.service';
import { TabManager } from './tab.manager';
import * as Interact from 'interactjs';

/**
 * Tab layout is based on the tree model {@link Layout}. Every layout node can have child layouts,
 * therefore the tab layout can be nested. A leaf node ususally contains a {@link Tabview}.
 *
 * @author Tobias Straller [Tobias.Straller@nttdata.com]
 */
export class TabLayout extends Component {
  /**
   * Minimum size for columns (grid padding is 15px)
   * @type {number}
   */
  static MIN_SIZE_COL: number = 520;
  /**
   * Minimum size for rows (height of a tab header + grid padding)
   * @type {number}
   */
  static MIN_SIZE_ROW: number = 55;

  layout: Layout;
  maxTabs: number = TabManager.MAX_TABS;

  private _layoutService: LayoutService;
  private _el: JQuery;

  /**
   * @ngInject
   */
  constructor(layoutService: LayoutService) {
    super();
    this._layoutService = layoutService;
  }

  /**
   * Indicate that we want to move the divider on mousedown
   * @param event
   */
  dividerDown(event: Interact.DragEvent): void {
    this._layoutService.isLayoutDividerDragging = true;
  }

  /**
   * Indicate that we want to stop moving the divider on mouseup
   * @param event
   */
  dividerUp(event: Interact.DragEvent): void {
    this._layoutService.isLayoutDividerDragging = false;
  }

  /**
   * Divider has been moved. Adjust the aspect ratio of the layout.
   * @param event
   */
  dividerMove(event: Interact.DragEvent): void {
    const offset = this._el.offset();
    const width = this._el.width();
    const height = this._el.height();
    let pos;
    let aspectRatio;
    if (this.ddAxis === 'x') {
      pos = event.pageX - offset.left;
      if (pos >= TabLayout.MIN_SIZE_COL && pos <= width - TabLayout.MIN_SIZE_COL) {
        aspectRatio = [+(pos / width).toFixed(2), +((width - pos) / width).toFixed(2)];
        this._layoutService.updateLayoutAspectRatio(this.layout, aspectRatio);
      }
    } else {
      pos = event.pageY - offset.top;
      if (pos >= TabLayout.MIN_SIZE_ROW && pos <= height - TabLayout.MIN_SIZE_ROW) {
        aspectRatio = [+(pos / height).toFixed(2), +((height - pos) / height).toFixed(2)];
        this._layoutService.updateLayoutAspectRatio(this.layout, aspectRatio);
      }
    }
  }

  /**
   * Stopped dragging the divider.
   * @param event
   */
  dividerEndDrag(event: Interact.DragEvent): void {
    this._layoutService.isLayoutDividerDragging = false;
  }

  /**
   * Custom class to be used in the template
   * @returns {string}
   */
  get customClass(): string {
    return this.layout.direction ? 'layout-' + this.layout.direction : '';
  }

  /**
   * The drag axis for this component depends on the split direction.
   * @returns {string}
   */
  get ddAxis(): string {
    return this.layout.direction === 'row' ? 'x' : 'y';
  }

  /**
   * Style for the layout content.
   * @param index
   * @returns {{flex-grow: number}}
   */
  getStyleForContent(index: number): any {
    if (this.layout.direction) {
      return {
        'flex-grow': +(this.layout.aspectRatio[index] / this.layout.aspectRatio[0]).toFixed(2),
      };
    }
  }

  getStyleForLayout(layout: Layout): any {
    return layout
      ? {
          top: `${100 * layout.dimensions.top}%`,
          left: `${100 * layout.dimensions.left}%`,
          right: `${100 - (100 * layout.dimensions.left + 100 * layout.dimensions.width)}%`,
          bottom: `${100 - (100 * layout.dimensions.top + 100 * layout.dimensions.height)}%`,
          'margin-left':
            (<Layout>layout.parent).direction === 'row' && layout.parent && layout.parent.children.indexOf(layout) > 0
              ? '12px'
              : 0,
        }
      : {};
  }

  /**
   * Whether a menu is visible in the current layout
   * @param menuId
   */
  hasMenu(menuId: string): boolean {
    const layout: Layout = <Layout>this.layout.parent;
    const index = layout.children.indexOf(this.layout);
    if (layout.direction) {
      if (layout.direction === 'row') {
        if (menuId === 'left-titlebar') {
          return index === 0;
        }
        if (menuId === 'right-titlebar') {
          return index === 1;
        }
      }
      return index === 0;
    }
    return true;
  }

  onRenderComponent(el: JQuery): void {
    this._el = el;
  }

  destroy(): void {
    this._layoutService = null;
    this._el = null;
  }
}
