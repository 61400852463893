import { LayoutService } from './layout.service';
import { ILocalStorareWrapper } from '../sessionData/local-storage-wrapper.factory';
import { SessionRestoreProperties } from '../sessionData/session-data.model.interface';
import { INode, Node } from '../../util/tree/node';
import { Layout } from './layout.model';
import * as uiRouter from 'angular-ui-router';
import _ from 'lodash';
import { IUserSettingsStorage } from '../workplace/user-settings-storage.interface';
import { UserSettingService } from '../userSetting/user-setting.service';
import { DeviceService } from '../../util/device.service';

export class LayoutProvider {
  public static INITIAL_LAYOUT: INode = <INode>{
    id: 'root',
    dimensions: {
      width: 1,
      height: 1,
      top: 0,
      left: 0,
    },
    children: [
      <INode>{
        id: 'main',
        dimensions: {
          width: 1,
          height: 1,
          top: 0,
          left: 0,
        },
      },
    ],
  };

  /**
   * @ngInject
   */
  constructor() {
    // nothing
  }

  /**
   * @ngInject
   */
  $get(
    postal: IPostal,
    jQuery: JQueryStatic,
    $animate: angular.animate.IAnimateService,
    $q: ng.IQService,
    $timeout: ng.ITimeoutService,
    $state: uiRouter.IStateService,
    localStorageFactory: ILocalStorareWrapper,
    userSettingsStorageService: IUserSettingsStorage,
    userSettingService: UserSettingService,
    deviceService: DeviceService
  ): LayoutService {
    const service = new LayoutService(
      postal,
      jQuery,
      $animate,
      $q,
      $timeout,
      $state,
      userSettingsStorageService,
      userSettingService,
      deviceService
    );

    // Migrate legacy user settings. TODO: Remove this block after settings migration has been done
    if (!service.layoutLoaded) {
      const rawLegacyLayout =
        localStorageFactory.getProperty(SessionRestoreProperties.LAYOUT) || _.cloneDeep(LayoutProvider.INITIAL_LAYOUT);
      const legacyLayout = Node.deserialize(Layout, rawLegacyLayout);
      service.layout = legacyLayout;
      service.saveSettings();
    }

    return service;
  }
}
