import { Widget } from '../widget';
import { Tile } from './tile';
import tileWidgetTemplate from './tile.html';
/**
 * @author Tobias Straller [Tobias.Straller.bp@nttdata.com]
 *
 * @returns {ng.IDirective}
 * @ngInject
 *
 */
export function tileWidgetDirective(): ng.IDirective {
  'use strict';
  return Widget.createWidgetDirective(Tile, {
    restrict: 'E',
    templateUrl: tileWidgetTemplate,
  });
}
