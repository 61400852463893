import { DeviceService } from '../../util/device.service';
import { LayoutService } from '../layout/layout.service';

export class MobileService {
  /**
   * @ngInject
   */
  constructor(private readonly deviceService: DeviceService, private readonly layoutService: LayoutService) {}

  closeMobileAppsMenu(): void {
    if (this.deviceService.isMobile && this.layoutService.getSidebarConfig('navigationbar').expanded) {
      this.layoutService.toggleSidebar('navigationbar');
    }
  }
}
