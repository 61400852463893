import { Component } from '../component';
import { MaximizeWidgetDialog } from './maximize-widget-dialog';
import maximizeWidgetDialogTemplate from './maximize-widget-dialog.html';
/**
 * Wrapper directive for {MaximizeWidgetDialog}
 * @ngInject
 */
export function maximizeWidgetDialogDirective(): ng.IDirective {
  'use strict';

  return Component.createDirective(MaximizeWidgetDialog, {
    restrict: 'E',
    templateUrl: maximizeWidgetDialogTemplate,
    scope: {
      close: '&',
      dismiss: '&',
      resolve: '&',
    },
  });
}
