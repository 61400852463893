import { JiraPopupMessage } from './jira-message';

export const JIRA_SECURE_LOGIN_ENDPOINT = `/iwp/rest/jira/secure/auth/authorize`;

export const JIRA_LOGIN_ENDPOINT = `/iwp/rest/jira/auth/authorize`;

export const POPUP_STATUS_CHECK_INTERVAL = 500;

export const JIRA_LOGIN_FALLBACK: JiraPopupMessage = {
  code: 500,
  event: 'jira-login',
};
