import { FetchPolicy, ObservableQuery } from '@apollo/client';
import {
  AnnouncementStatus,
  getAnnouncementsForAppQuery,
  getAnnouncementStatus,
  IAnnouncement,
  IAnnouncementBanner,
} from './announcements.utils';
import { GraphQLService } from '../workplace/graphql.service';
import { BannersActionsService } from '../apps/apps-actions/banners-actions.service';
import { ILanguage } from '../../util/language.model.interface';
import { DISMISS_BANNER } from '../apps/application.model.interface';
import { UserService } from '../user/user.service';

export class AnnouncementService {
  readonly ANNOUNCEMENTS_POLLING = 300000;
  readonly DISMISSED_BANNERS = 'dismissedBanners';
  private announcementStatusTimers = new Map<string, ReturnType<typeof setTimeout>>();

  // Map with frameId as key and list of id of announcements with timers active as value
  private frameAnnouncementTimers = new Map<string, string[]>();

  // Map with frameId as key and announcements query subscription as value
  private frameAnnouncementSub = new Map<string, ZenObservable.Subscription>();

  /**
   * @ngInject
   */
  constructor(
    private readonly graphqlService: GraphQLService,
    private bannersActionsService: BannersActionsService,
    private language: ILanguage,
    private readonly userService: UserService
  ) {}

  setupAnnouncementsBanners(frameId: string, appId: string) {
    this.retryOperation(this.loadAnnouncementsBanner, 300, 3, frameId, appId);
  }

  loadAnnouncementsBanner = async (frameId: string, appId: string): Promise<void> => {
    await import('announcementsBanner');

    const announcementsBannerEl: Element & {
      [key: string]: any;
    } = document.getElementById(`announcement-${frameId}`);

    if (!announcementsBannerEl) return;

    announcementsBannerEl.lang = this.language.lang;

    const qNumber = (await this.userService.getUser()).userId;
    const qNumberDismissedBanners = `${qNumber}/${this.DISMISSED_BANNERS}`;

    announcementsBannerEl?.addEventListener(DISMISS_BANNER, (event: CustomEvent) =>
      this.bannersActionsService.dismissBanner(event, qNumber)
    );

    const announcementsSub = this.fetchAnnouncementsByAppId(appId, 'no-cache', this.ANNOUNCEMENTS_POLLING).subscribe(
      ann => {
        const announcements: IAnnouncement[] = ann.data.getAnnouncementsForApps[0].announcements;
        if (!announcements) return;

        const { banners, liveAnnouncements, scheduledAnnouncements } = this.handleAnnouncements(
          announcements,
          qNumberDismissedBanners
        );

        announcementsBannerEl.banners = banners;
        this.announcementStatusChanges(announcementsBannerEl, scheduledAnnouncements, AnnouncementStatus.SCHEDULED);
        this.announcementStatusChanges(announcementsBannerEl, liveAnnouncements, AnnouncementStatus.LIVE);
      }
    );

    this.frameAnnouncementSub.set(frameId, announcementsSub);
  };

  fetchAnnouncementsByAppId(
    appId: string,
    fetchPolicy: FetchPolicy,
    pollInterval: number,
    status?: AnnouncementStatus
  ): ObservableQuery {
    try {
      return this.graphqlService.watchQuery<{
        getAnnouncementsForApps: { announcements: IAnnouncement[] }[];
      }>(getAnnouncementsForAppQuery, { appIds: [appId], status }, fetchPolicy, pollInterval);
    } catch (err) {
      console.error('Unexpected error when fetching announcements by app id.', err);
    }
  }

  handleAnnouncements(
    announcements: IAnnouncement[],
    qNumberDismissedBanners: string
  ): {
    banners: IAnnouncementBanner[];
    liveAnnouncements: IAnnouncement[];
    scheduledAnnouncements: IAnnouncement[];
  } {
    const dismissedBanners = this.bannersActionsService.dismissedBanners(qNumberDismissedBanners);

    let banners: IAnnouncementBanner[] = [],
      scheduledAnnouncements: IAnnouncement[] = [],
      liveAnnouncements: IAnnouncement[] = [];

    announcements.forEach(announcement =>
      this.filterAnnouncementByStatus(
        announcement,
        banners,
        liveAnnouncements,
        scheduledAnnouncements,
        dismissedBanners
      )
    );

    this.bannersActionsService.deleteBannersFromLocalStorage(
      dismissedBanners,
      liveAnnouncements,
      qNumberDismissedBanners
    );

    return { banners, liveAnnouncements, scheduledAnnouncements };
  }

  clearAnnouncementsSubscriptions(frameId: string): void {
    if (!this.frameAnnouncementTimers.get(frameId)) return;

    for (const announcementId of this.frameAnnouncementTimers.get(frameId)) {
      clearTimeout(this.announcementStatusTimers.get(announcementId));
      this.announcementStatusTimers.delete(announcementId);
    }

    this.frameAnnouncementTimers.delete(frameId);
    this.frameAnnouncementSub.get(frameId)?.unsubscribe();

    const announcementsBannerEl: Element & { [key: string]: any } = document.getElementById(frameId);
    announcementsBannerEl?.removeEventListener(DISMISS_BANNER, () => this.bannersActionsService.dismissBanner);
  }

  private filterAnnouncementByStatus(
    announcement: IAnnouncement,
    banners: IAnnouncementBanner[],
    liveAnnouncements: IAnnouncement[],
    scheduledAnnouncements: IAnnouncement[],
    dismissedBanners: string[]
  ): void {
    announcement.startDate = announcement.startDate && new Date(announcement.startDate);
    announcement.endDate = announcement.endDate && new Date(announcement.endDate);

    const announcementStatus = getAnnouncementStatus(announcement);

    if (AnnouncementStatus.LIVE === announcementStatus) {
      liveAnnouncements.push(announcement);
      if (announcement.method.banner && !dismissedBanners.includes(announcement.id)) {
        banners.push(this.createBannerObject(announcement));
      }
    }

    if (AnnouncementStatus.SCHEDULED === announcementStatus) scheduledAnnouncements.push(announcement);
  }

  private createBannerObject(announcement: IAnnouncement): IAnnouncementBanner {
    const { lang } = this.language;

    const message = announcement.description[lang]?.length
      ? announcement.description[lang]
      : announcement.description.en;
    const link = announcement.link[lang]?.length ? announcement.link[lang] : announcement.link.en;

    return { id: announcement.id, type: announcement.type, message, link };
  }

  // Check if in the next 24H an announcement will become Live or Ended
  private announcementStatusChanges = (
    announcementsBannerEl: Element & { [key: string]: any },
    announcements: IAnnouncement[],
    statusToCheck: AnnouncementStatus
  ): void => {
    announcements.forEach(announcement => {
      let timestamp: number;

      if (AnnouncementStatus.SCHEDULED === statusToCheck) timestamp = announcement.startDate.getTime();
      else if (!announcement.endDate) return;
      else timestamp = announcement.endDate.getTime();

      const msBetweenDates = Math.abs(timestamp - new Date().getTime());
      // 👇️ convert ms to hours                  min  sec   ms
      const hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);

      if (hoursBetweenDates > 24) return;

      const appId = announcementsBannerEl.id;
      if (this.frameAnnouncementTimers.get(appId)) {
        this.frameAnnouncementTimers.get(appId)?.push(announcement.id);
      } else {
        this.frameAnnouncementTimers.set(appId, [announcement.id]);
      }

      this.updateStatusTimers(announcementsBannerEl, announcement, statusToCheck, msBetweenDates);
    });
  };

  private updateStatusTimers(
    announcementsBannerEl: Element & { [key: string]: any },
    announcement: IAnnouncement,
    statusToCheck: AnnouncementStatus,
    msBetweenDates: number
  ) {
    this.announcementStatusTimers.set(
      announcement.id,
      setTimeout(() => {
        const banner = this.createBannerObject(announcement);

        // Scheduled will become Live or Live will become Ended
        if (AnnouncementStatus.SCHEDULED === statusToCheck) {
          announcementsBannerEl.banners.push(banner);
        } else {
          announcementsBannerEl.announcementsBannerEl = announcementsBannerEl.banners.filter(
            bann => banner.id !== bann.id
          );
        }
      }, msBetweenDates)
    );
  }

  private retryOperation = (operation, delay: number, retries: number, frameId: string, appId: string) => {
    const wait = (ms: number) => new Promise(r => setTimeout(r, ms));

    new Promise((resolve, reject) => {
      return operation(frameId, appId)
        .then(resolve)
        .catch(reason => {
          if (retries > 0) {
            return wait(delay)
              .then(this.retryOperation.bind(null, operation, delay, retries - 1))
              .then(resolve)
              .catch(reject);
          }
          return reject(reason);
        });
    });
  };
}
