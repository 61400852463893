'use strict';
import { getToken } from '../app.auth';

export function multiLinkUploadInit() {
  loadWidgetIds();
  let form = document.getElementById('fileUpload');

  form.addEventListener('submit', event => {
    event.preventDefault();
    getCSRFToken();
  });
}

function loadWidgetIds() {
  let xhttp = new XMLHttpRequest();
  let token = getToken();
  xhttp.onreadystatechange = function () {
    jQuery('#appLoader').remove();
    if (xhttp.readyState === 4 && xhttp.status === 200) {
      let jsonArray = JSON.parse(xhttp.response),
        sel = document.getElementById('widgetDefId') as any,
        opt;

      jsonArray.forEach(function (option) {
        opt = document.createElement('option');
        opt.text = option;
        opt.id = option;
        opt.value = option;
        sel.add(opt);
      });
    }
  };
  xhttp.open('GET', './rest/widgets/multiLinks', true);
  xhttp.setRequestHeader('authorization', 'Bearer ' + token);
  xhttp.send();
}

function getCSRFToken() {
  var XHR = new XMLHttpRequest();
  let opResult = document.getElementById('opResult');
  let token = getToken();

  // Define what happens on successful data submission
  XHR.onreadystatechange = () => {
    if (XHR.readyState === 4 && XHR.status === 200) {
      sendData(XHR.getResponseHeader('CSRFToken'));
      return;
    }
    if (XHR.status === 404) {
      opResult.innerHTML = 'Page not found';
      return;
    }
    opResult.innerHTML = XHR.responseText || XHR.statusText;
  };

  // Define what happens in case of error
  XHR.addEventListener('error', function () {
    opResult.innerHTML = 'Could not get CSRF Token.';
  });

  // Set up our request
  XHR.open('GET', `/iwp/rest/admin/services/uploadMultiLink`);
  XHR.setRequestHeader('authorization', 'Bearer ' + token);

  XHR.send();
}

function sendData(csrfToken): void {
  let XHR = new XMLHttpRequest();
  let form = document.getElementById('fileUpload') as unknown as HTMLFormElement;
  let opResult = document.getElementById('opResult');
  let token = getToken();

  // Bind the FormData object and the form element
  let FD = new FormData(form);

  // Define what happens on successful data submission
  XHR.onreadystatechange = function () {
    jQuery('#appLoader').remove();
    if (XHR.readyState === 4) {
      form.remove();
      let pre = document.createElement('pre');
      pre.style.overflowWrap = 'break-word';
      pre.style.whiteSpace = 'pre-wrap';
      pre.innerText = XHR.responseText;
      document.body.getElementsByClassName('view-container')[0].appendChild(pre);
      return;
    }

    opResult.innerHTML = XHR.responseText;
  };

  // Define what happens in case of error
  XHR.addEventListener('error', function () {
    opResult.innerHTML = 'Something went wrong when uploading the file.';
  });

  // Set up our request
  XHR.open('POST', `/iwp/rest/admin/services/uploadMultiLink`);
  XHR.setRequestHeader('CSRFToken', csrfToken);
  XHR.setRequestHeader('authorization', 'Bearer ' + token);

  // The data sent is what the user provided in the form
  XHR.send(FD);
}
