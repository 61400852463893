/**
 * Service for catching sidebar close event
 */
export class MenuOverlayService {
  static CHANNEL: string = 'MENU_OVERLAY_CHANNEL';
  static MENU_OVERLAY_CLOSED: string = 'MENU_OVERLAY_CLOSED';

  channel: IChannelDefinition<{}>;

  /**
   * @ngInject
   */
  constructor(postal: IPostal) {
    this.channel = postal.channel(MenuOverlayService.CHANNEL);
  }

  closedMenuOverlay(): void {
    this.channel.publish(MenuOverlayService.MENU_OVERLAY_CLOSED);
  }
}
