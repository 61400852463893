import { LayoutSplit } from './layout.split';
import { Component } from '../components/component';
import layoutSplitTemplate from './layout.split.html';

/**
 * @ngInject
 */
export function layoutSplitDirective(): ng.IDirective {
  'use strict';
  return Component.createDirective(LayoutSplit, {
    restrict: 'E',
    scope: {
      onSplit: '&',
      canSplit: '&',
    },
    templateUrl: layoutSplitTemplate,
  });
}
