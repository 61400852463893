import { ShareType } from '../../components/dialogs/share-dialog-config.interface';
import { NotificationService } from '../../notification/notification.service';
import { PopupService } from '../../notification/popup.service';
import { StoreItemTypeEnum } from './mwp-store-type';

export class MwpStoreService {
  private mwpStoreEl: Element & { [key: string]: any };

  /**
   * @ngInject
   */
  constructor(private readonly popupService: PopupService, private readonly notificationService: NotificationService) {}

  setMwpStoreEl(element: Element & { [key: string]: any }) {
    this.mwpStoreEl = element;
  }

  async openMwpStore(storeItemType?: StoreItemTypeEnum): Promise<void> {
    this.mwpStoreEl?.openSidePanel(storeItemType);
  }

  openStoreItemDetails(itemId: string, shareType: string): void {
    if (itemId) {
      this.mwpStoreEl.sharedItem = {
        id: itemId,
        type: shareType.split('-')[2],
      };
    }
    this.mwpStoreEl.openSidePanel();
  }

  openShareStoreItemDialog(item): void {
    const { id, type } = item;

    this.popupService
      .showModalWindow('shareStoreItemDialog', {
        id,
        titleKey: this.getTitleKey(type.toLowerCase()),
        okButtonKey: 'dialogs.share.buttons.ok.label',
        cancelButtonKey: 'dialogs.share.buttons.cancel.label',
        shareType: `${ShareType.STORE_ITEM}-${type.toUpperCase()}`,
      })
      .then((result: number) => {
        if (result > 0) {
          this.notificationService.showInfo('dialogs.share.success', { name: id });
        } else {
          this.notificationService.showWarn('dialogs.share.unshare', { name: id });
        }
      });
  }

  private getTitleKey(type: string): string {
    switch (type) {
      case ShareType.WIDGET:
        return 'dialogs.item.share.widget.title';
      case ShareType.APPLICATION:
        return 'dialogs.item.share.application.title';
      default:
        return 'dialogs.item.share.item.title';
    }
  }
}
