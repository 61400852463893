import { IEmployee } from './employee.model.interface';
import { NotificationService } from '../notification/notification.service';
import JSData from 'js-data';

/**
 * Service for managing employee contacts.
 *
 * @author Dragos Damian [Dragos.Damian@nttdata.ro] on 13.10.2016.
 */
export class EmployeeService {
  employees: IEmployee[];
  findEmployees: (searchConfig: IEmployeeSearchConfig, callback: (employees: IEmployee[]) => void) => void;

  private _employeeStore: JSData.DSResourceDefinition<IEmployee>;
  private _qService: ng.IQService;
  private _notificationService: NotificationService;

  /**
   *
   * @param employeeStore
   * @param $q
   * @param debounce
   * @param notificationService
   * @ngInject
   */
  constructor(
    employeeStore: JSData.DSResourceDefinition<IEmployee>,
    $q: ng.IQService,
    debounce: any,
    notificationService: NotificationService
  ) {
    this.employees = [];
    this._employeeStore = employeeStore;
    this._qService = $q;
    this._notificationService = notificationService;
    // Options: wait=500ms immediate=false, invokeApply=true
    this.findEmployees = debounce(this._findEmployees, 500, false, true);
  }

  /**
   * Get employees filtered by a search term.
   * @param searchTerm
   * @param callback
   * @returns {ng.IPromise<IEmployee[]>}
   */
  _findEmployees(searchConfig: IEmployeeSearchConfig, callback: (employees: IEmployee[]) => void): void {
    this._employeeStore.ejectAll({});
    this._employeeStore
      .findAll(
        {
          searchTerm: searchConfig.searchTerm,
          maxSize: searchConfig.maxSize || 10,
        },
        <JSData.DSAdapterOperationConfiguration>{
          bypassCache: true,
          noCache: true,
          errorNotification: {
            '400': false,
          },
        }
      )
      .then((employees: IEmployee[]) => {
        this.employees = employees;
        callback(this.employees);
      });
  }
}

export interface IEmployeeSearchConfig {
  // search key
  searchTerm: string;
  // the maximum number of returned results. will default to 10 if not specified
  maxSize?: number;
}
