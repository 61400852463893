import { BaseDialog } from './base-dialog';
import { IPopupOptions } from '../../notification/popup.model.interface';
import { DashboardService } from '../../dashboard/dashboard.service';
import { WidgetService } from '../../widget/widget.service';
import { TasksService } from '../../task/tasks.service';

export class MaximizeWidgetDialog extends BaseDialog {
  config: any;
  item: any;
  dashboard: any;
  dashboardName: any;
  dashboardContext: any;
  categoryId: any;
  options: IPopupOptions;

  private _dashboardService: DashboardService;
  private _widgetService: WidgetService;
  private _tasksService: TasksService;
  private _subscriptions: ISubscriptionDefinition<any>[] = [];
  /**
   * @ngInject
   */
  constructor(dashboardService: DashboardService, widgetService: WidgetService, tasksService: TasksService) {
    super();
    this._dashboardService = dashboardService;
    this._widgetService = widgetService;
    this._tasksService = tasksService;
  }

  $onInit() {
    this.config = this.resolve().config;

    if (this.config) {
      this.item = this.config.item ? this.config.item : null;
      this.dashboard = this.config.dashboard ? this.config.dashboard : null;
      this.dashboardContext = this.config.dashboardContext ? { ...this.config.dashboardContext } : null;
      this.options = this.config.options ? this.config.options : null;
      this.dashboardName = this.config.dashboardName ? this.config.dashboardName : null;
      this.categoryId = this.config.categoryId ? this.config.categoryId : null;
    }

    // Close maximize widget if task was open through deeplink
    this._subscriptions.push(this._tasksService.channel.subscribe(TasksService.TOPIC_TASKS_DEEPLINK, () => this.cancel()));

    // Close maximized widget by messaging
    this._subscriptions.push(this._widgetService.channel.subscribe(WidgetService.CLOSE_MAXIMIZED_WIDGET, () => this.cancel()));
  }

  destroy(): void {
    this._subscriptions.forEach((subscription: ISubscriptionDefinition<any>): void => subscription.unsubscribe());
  }
}
