import { NotificationService } from '../notification/notification.service';
import { FrameLayoutService } from '../frameLayout/frame-layout.service';
import { AppsService } from '../apps/apps.service';
import { DeviceService } from '../../util/device.service';
import { LayoutService } from '../layout/layout.service';
import { IDashboard } from '../dashboard/dashboard.model.interface';
import { DashboardService } from '../dashboard/dashboard.service';
import { IWorkplaceProperty } from '../workplace/workplace-property.interface';
import { APP_CATALOG, IApplication } from '../apps/application.model.interface';
import { MwpStoreService } from './mwp-store/mwp-store.service';
import { StoreItemTypeEnum } from './mwp-store/mwp-store-type';
import { WorkplaceApiService } from '../workplace/workplace.api.service';
import { openLink } from '../../util/link-utils/link.open';
import { ITab } from '../tab/tab';
import { ITabContent } from '../tab/tab.content.interface';
import { DASHBOARD_SHARING_DASHBOARD } from '../components/tabview/analytics/feature-analytics-conf';
import { ShareType } from '../components/dialogs/share-dialog-config.interface';
import { TrackingService } from '../feature-tracking/tracking.service';
import { PopupService } from '../notification/popup.service';

/**
 * A lot of our Angular micro-frontends (web-components) have he same handlers
 *
 * This service is used to share the same handlers between the web-components
 */
export class WebComponentSharedHandlersService {
  /**
   * @ngInject
   */
  constructor(
    private readonly notificationService: NotificationService,
    private readonly frameLayoutService: FrameLayoutService,
    private readonly appsService: AppsService,
    private readonly deviceService: DeviceService,
    private readonly layoutService: LayoutService,
    private readonly dashboardService: DashboardService,
    private readonly mwpStoreService: MwpStoreService,
    private readonly trackingService: TrackingService,
    private readonly popupService: PopupService
  ) {}

  /**
   * Handles the copy link event by copying the link to the clipboard and showing a success notification.
   *
   * @param {MouseEvent} event - The mouse event that triggered the handler. The event's detail property should contain the link to be copied.
   */
  copyLinkHandler(event: MouseEvent) {
    const messageKey = 'copyUrl.success';
    var dummy = document.createElement('textarea');
    document.body.appendChild(dummy);
    dummy.value = event.detail as unknown as string;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
    this.notificationService.showSuccess(messageKey);
  }

  /**
   * Handles the open app event by opening the app in a new window.
   *
   * @param {MouseEvent} event - The mouse event that triggered the handler. The event's detail property should contain the app name.
   */
  async openInNewWindowHandler(event: MouseEvent) {
    const application: IApplication = await this.appsService.getApp(event.detail as unknown as string);
    this.appsService.openAppInWindow(application);
  }

  /**
   * Handles the open app event by opening the app or a layout
   *
   * @param {MouseEvent} event - The mouse event that triggered the handler. The event's detail property should contain the app name.
   */
  openAppHandler(event: MouseEvent) {
    let name = typeof event.detail === 'string' ? event.detail : event.detail?.['name'];
    const isAppLayout = event.detail?.['isAppLayout'];

    if (!name) {
      console.warn('Invalid name when trying to open the application.');
      return;
    }

    if (isAppLayout) {
      return this.frameLayoutService.openLayoutById(name).then(layout => {
        const layoutMapper = {
          ...layout,
          name: layout.id,
          isAppLayout: true,
        };
        this.appsService.storeRecentlyOpenedApps(layoutMapper);
      });
    }

    this.closeMobileAppsMenu();
    return this.appsService.openAppByName(name);
  }

  /**
   * Opens the MWP Store in the application details page
   */
  openStoreAppDetailsHandler(event: MouseEvent) {
    this.mwpStoreService.openStoreItemDetails(event.detail.toString(), 'STORE-ITEM-APPLICATION');
  }

  /**
   * Opens the MWP Store in the default page
   */
  openMwpStoreHandler() {
    this.mwpStoreService.openMwpStore(StoreItemTypeEnum.APPLICATION);
  }

  /**
   * Opens the MWP Store in the widget details page
   */
  openStoreWidgetDetailsHandler(event: MouseEvent) {
    this.mwpStoreService.openStoreItemDetails(event.detail.toString(), 'STORE-ITEM-WIDGET');
  }

  /**
   * This is a temporary dashboard/placeholder for the "tabs" in the client.
   * The client passes only the "id" to the web-component dashboards.
   * Dashboards will do all the http graphql requests to retrieve the dashboard information.
   * Since it's not really a dashboard, strongAuth will rerun when actually changing to the tab.
   *
   * @private
   * @param {{ title: string, dashboardId: string }} payload
   * @memberof Tabview
   */
  openDashboardHandler = (payload: { title: string; dashboardId: string; strongAuth: number }) => {
    this.closeMobileAppsMenu();
    let tempDashboard = this.dashboardService.findDashboardInCache(payload.dashboardId);
    if (!tempDashboard) {
      tempDashboard = {
        title: payload.title,
        name: payload.dashboardId,
        id: payload.dashboardId,
        strongAuth: payload.strongAuth,
      } as IDashboard;
    }
    this.dashboardService.openDashboard(tempDashboard);
  };

  openLinkHandler(event: MouseEvent) {
    const url = event.detail as any;
    if (url) {
      openLink(url);
    }
  }

  /**
   * Opens the share dialog for the dashboard
   * @param tab
   */
  openShareDialogHandler(tab: ITab<ITabContent>): void {
    this.trackingService.getTracker().trackFeature(DASHBOARD_SHARING_DASHBOARD, 1);

    const name = tab.title;
    this.popupService
      .showModalWindow('shareDashboardDialog', {
        titleKey: 'dialogs.dashboard.share.title',
        okButtonKey: 'dialogs.share.buttons.ok.label',
        cancelButtonKey: 'dialogs.share.buttons.cancel.label',
        id: tab.id,
        title: name,
        shareType: ShareType.DASHBOARD,
      })
      .then((result: number) => {
        if (result > 0) {
          this.notificationService.showInfo('dialogs.share.success', { name });
        } else {
          this.notificationService.showWarn('dialogs.share.unshare', { name });
        }
        this.dashboardService.refreshDashboard(tab.id);
      });
  }

  shareItemHandler(event: MouseEvent) {
    const { detail: item } = event;
    this.mwpStoreService.openShareStoreItemDialog(item);
  }

  closeMobileAppsMenu(): void {
    if (this.deviceService.isMobile && this.layoutService.getSidebarConfig('navigationbar').expanded) {
      this.layoutService.toggleSidebar('navigationbar');
    }
  }
}
