import { Component } from '../component';
import { Navbarmenu } from './navbarmenu';
import navbarmenuTemplate from './navbarmenu.html';
import { IDirective } from 'angular';
/**
 * @ngInject
 */
export function navbarmenuDirective(): IDirective {
  'use strict';
  return Component.createDirective(Navbarmenu, {
    restrict: 'E',
    scope: {
      menuId: '@',
    },
    templateUrl: navbarmenuTemplate,
  });
}
