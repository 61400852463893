import { Component } from '../component';
import { DialogHeader } from './dialog-header';
import dialogHeaderTemplate from './dialog-header.html';

/**
 * Modal dialog header directive.
 *
 * @ngInject
 */
export function dialogHeaderDirective(): ng.IDirective {
  'use strict';
  return Component.createDirective(DialogHeader, {
    restrict: 'E',
    templateUrl: dialogHeaderTemplate,
    scope: {
      closeHandler: '&',
      dialogTitle: '@',
      closeable: '=?',
      icon: '@',
    },
  });
}
