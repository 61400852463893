import { ILanguage } from '../../util/language.model.interface';
import { getToken } from '../app.auth';
import axios, { AxiosResponse } from 'axios';

/**
 * Service providing the App Admin post file
 */
export class AppAdminUploadService {
  private URL = './rest/admin/apps/upload?lang=';

  /**
   * @ngInject
   */
  constructor(private language: ILanguage) {}

  postFile(input: FormData): Promise<string> {
    const headers = {
      'Content-Type': 'multipart/form-data; boundary=something',
      Authorization: 'Bearer ' + getToken(),
      Accept: '*/*',
    };

    return axios.post(this.URL + this.language.lang, input, { headers }).then((result: AxiosResponse) => result?.data);
  }
}
