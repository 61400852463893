import { Component } from '../component';

('use strict');
import { ILayoutServiceChannelResizeMessage, LayoutService } from '../../layout/layout.service';
/**
 * Pagination component.
 * Shows a list of button that trigger navigation on embracing component.
 */
export class PaginationComponent extends Component {
  maxSize: number;
  currentPage: number;
  totalItems: number;
  itemsPerPage: number;
  pageUpdateHandler: () => void;

  private visiblePages: number[];
  private totalNumberOfPages: number;
  private timourService: ng.ITimeoutService;
  private layoutService: LayoutService;
  private hidePages: boolean;
  private subscriptions: ISubscriptionDefinition<any>[];

  /**
   * @ngInject
   */
  constructor($timeout: ng.ITimeoutService, layoutService: LayoutService) {
    super();
    this.hidePages = false;
    this.currentPage = 1;
    this.visiblePages = [];
    this.timourService = $timeout;
    this.layoutService = layoutService;
    this.subscriptions = [];
    this.subscriptions.push(
      this.layoutService.channelResize.subscribe(LayoutService.TOPIC_RESIZE, (message: ILayoutServiceChannelResizeMessage) => {
        this.setHidePages(message.dimensionViewport.width);
      })
    );
  }

  /**
   * Override to set the maximum number of shown pages to 0 if
   * @param el
   * @param scope
   */
  onRenderComponent(el: JQuery, scope: ng.IScope): void {
    scope.$watch('vm.totalItems', () => {
      this.setTotalNumberOfPages();
    });

    this.setHidePages(jQuery(window).width());
  }

  /**
   * Recalculate the total number of pages when total items changes.
   * @param newValue
   */
  setTotalNumberOfPages(): void {
    this.totalNumberOfPages =
      Math.floor(this.totalItems / this.itemsPerPage) + (this.totalItems % this.itemsPerPage === 0 ? 0 : 1);
    if (this.totalNumberOfPages === 1) {
      return;
    }
    this.setVisiblePages();
  }

  /**
   * Previous or next has been clicked.
   * @param amount
   */
  updateCurrentPage(amount: number): void {
    if (this.currentPage === 1 && amount === -1) {
      return;
    }
    if (this.currentPage === this.totalNumberOfPages && amount === 1) {
      return;
    }
    this.currentPage += amount;
    this.updateViewAndNotify();
  }

  /**
   * A page number has been clicked.
   *
   * @param page
   */
  gotoPage(page: number): void {
    this.currentPage = page;
    this.updateViewAndNotify();
  }

  destroy(): void {
    this.timourService = null;
    this.layoutService = null;
    this.subscriptions.forEach((subscription: ISubscriptionDefinition<any>): void => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  /**
   * Only show previous and next buttons when the width of the display is smaller than 475
   *
   * @param width
   */
  private setHidePages(width: number): void {
    if (width < 475) {
      this.hidePages = true;
      return;
    }

    this.hidePages = false;
  }

  /**
   * Updates the visible pages
   * and calls interested thirds parties by invoking page update handler.
   */
  private updateViewAndNotify(): void {
    this.setVisiblePages();
    // give angular a chance to update two way data bound objects
    this.timourService(() => {
      this.pageUpdateHandler();
    });
  }

  /**
   * Calculate the range of shown pages
   */
  private setVisiblePages(): void {
    /**
     * Populate visible pages initially or move forward
     */
    if (this.visiblePages.length === 0 || this.currentPage > this.visiblePages[this.visiblePages.length - 1]) {
      var lastVisiblePage: number = this.currentPage + this.maxSize - 1;
      this.visiblePages = [];
      for (var i: number = this.currentPage; i <= lastVisiblePage; i++) {
        this.visiblePages.push(i);
      }
      return;
    }
    /**
     * Move backwards
     */
    if (this.currentPage < this.visiblePages[0]) {
      var firstVisiblePage: number = Math.max(this.currentPage - this.maxSize + 1, 1);
      this.visiblePages = [];
      for (var i: number = firstVisiblePage; i <= this.currentPage; i++) {
        this.visiblePages.push(i);
      }
      return;
    }
  }
}
