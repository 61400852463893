import { Component } from '../component';
import { Table } from './table';
import tableTemplate from './table.html';

export function tableDirective(): ng.IDirective {
  'use strict';
  return Component.createDirective(Table, {
    restrict: 'E',
    templateUrl: tableTemplate,
    scope: {
      tableConfig: '=',
      entryClickHandler: '&',
    },
  });
}
