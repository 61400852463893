import { Component } from '../component';
import { AddDashboardDialog } from './add-dashboard-dialog';
import dashboardDialogTemplate from './add-dashboard-dialog.html';

/**
 * @return {angular.IDirective}
 * @ngInject
 */
export function addDashboardDirective(): ng.IDirective {
  'use strict';
  return Component.createDirective(AddDashboardDialog, {
    restrict: 'E',
    templateUrl: dashboardDialogTemplate,
    scope: {
      close: '&',
      dismiss: '&',
      resolve: '&',
    },
  });
}
