import { Component } from '../component';
('use strict');
/**
 * Reusable bootstrap dropdown wrapper.
 */
export class DropDownMenu extends Component {
  ariaLabelledby: string;

  /**
   * @ngInject
   */
  constructor() {
    super();
  }
}
