import optiscroll from 'optiscroll';

/**
 * Wraps an Optiscroll scrollbar in an angular directive
 *
 * @returns {ng.IDirective}
 * @ngInject
 */
export function optiscrollDirective($timeout): ng.IDirective {
  'use strict';

  return {
    restrict: 'A',
    link(scope: angular.IScope, el: JQuery, attrs: any): void {
      const scrollbar = optiscroll(el.get(0), {});
      attrs.$observe('utilOptiscroll', function (scrollTop: boolean = false): void {
        if (scrollTop) {
          $timeout(function (): void {
            scrollbar.scrollTo(0, 0, 0);
          });
        }
      });
      attrs.$observe('utilUpdateOptiscroll', function (updateScroll: boolean = false): void {
        if (updateScroll) {
          $timeout(function (): void {
            scrollbar.update();
          });
        }
      });
      scope.$on('$destroy', () => scrollbar.destroy());
    },
  };
}
