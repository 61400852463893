/**
 * Filter to localize a number based on the locale
 * @param text
 * @param length
 * @returns {string}
 */
export function localizedNumberFilter(): any {
  'use strict';

  let numberToLocaleFilter: any = function (num: number, locale: string): string {
    if (!num || !locale) {
      return '0';
    }
    return num.toLocaleString(locale.replace('_', '-'));
  };
  numberToLocaleFilter.$stateful = true;

  return numberToLocaleFilter;
}
