import { Component } from '../component';
import { Chip } from './chip';
import chipTemplate from './chip.html';

/**
 * Chip directive wrapper.
 *
 * @returns {ng.IDirective}
 */
export function chipDirective(): ng.IDirective {
  'use strict';
  return Component.createDirective(Chip, {
    restrict: 'E',
    templateUrl: chipTemplate,
    scope: {
      editable: '=',
      clickHandler: '&',
      text: '@',
      typeIconCls: '@',
    },
  });
}
