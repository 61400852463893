import { Component } from '../component';
import { RestrictedTextComponent } from './restrictedText';
import restrictedTextTemplate from './restrictedText.html';

/**
 * Wrapper directive for our custom restricted text directive.
 *
 * @ngInject
 */
export function restrictedTextDirective(): ng.IDirective {
  'use strict';

  return Component.createDirective(RestrictedTextComponent, {
    restrict: 'E',
    templateUrl: restrictedTextTemplate,
    scope: {
      textModel: '=',
      required: '=',
      errorText: '@',
      maxLength: '=',
      maxLines: '=',
      onInputFocus: '&',
      resetActive: '=',
      disableFocus: '=',
      placeholder: '=',
      titleInput: '=',
    },
  });
}
