/**
 * Describes model for Settings
 *
 * @author Tobias Straller [Tobias.Straller.bp@nttdata.com]
 */
export interface ISetting<T> {
  id: string;
  type: SettingType;
  value: T;
  label?: string;
  configurable?: boolean;
  /**
   * List of setting ids that the current setting depends on.
   * The setting will be configurable when the values of the dependant settings evaluate to true.
   */
  dependsOn?: string[];
}

/**
 * Settings type
 */
export enum SettingType {
  BOOLEAN,
  STRING,
  NUMBER,
  OBJECT,
}
