import { Component } from '../component';
import { TabService } from '../../tab/tab.service';
import { LayoutService } from '../../layout/layout.service';
import { TrackingService } from '../../feature-tracking/tracking.service';
import { EXIT_SPLIT_CANVAS, SPLIT_HORIZONTAL, SPLIT_VERTICAL } from '../analytics/feature-analytics-conf-layout';

/**
 * Component for view toggle
 *
 * @author Tobias Straller [Tobias.Straller.bp@nttdata.com]
 */
export class ViewToggle extends Component {
  private _tabService: TabService;
  private _layoutService: LayoutService;

  /**
   * @ngInject
   */
  constructor(tabService: TabService, layoutService: LayoutService, private trackingService: TrackingService) {
    super();
    this._tabService = tabService;
    this._layoutService = layoutService;
  }

  /**
   * Split screen is possible when
   * <ul>
   *     <li>more than one tab</li>
   *     <li>not already in split mode</li>
   *     <li>not in mobile layout</li>
   * </ul>
   * @returns {boolean}
   */
  canSplit(): boolean {
    return (
      this._tabService.getTabManagerDefault().tabs.length > 1 &&
      !this._layoutService.mobileLayout &&
      this._layoutService.layout.leaves().length <= 1
    );
  }

  /**
   * Whether fullscreen is possible (only when currently in split).
   * @returns {boolean}
   */
  canJoin(): boolean {
    return this._layoutService.isSplitLayout();
  }

  /**
   * Splits the layout vertically and moves the currently selected tab to the left view
   */
  splitRight(): void {
    this._tabService.splitTabview({
      tabviewId: this._tabService.tabManagerDefault,
      tabId: this._tabService.getTabManagerDefault().selectedTab.id,
      direction: 'row',
      index: 1,
    });

    this.trackingService.getTracker().trackFeature(SPLIT_VERTICAL, 1);
  }

  /**
   * Splits the layout horizontally and moves the currently selected tab to the top view
   */
  splitBottom(): void {
    this._tabService.splitTabview({
      tabviewId: this._tabService.tabManagerDefault,
      tabId: this._tabService.getTabManagerDefault().selectedTab.id,
      direction: 'column',
      index: 1,
    });

    this.trackingService.getTracker().trackFeature(SPLIT_HORIZONTAL, 1);
  }

  /**
   * Joins 2 tabviews into one tabview
   */
  join(): void {
    const tabviews = this._layoutService.layout.leaves();
    if (tabviews.length > 1) {
      this._tabService.moveAllTabsFromTo(<string>tabviews[1].id, <string>tabviews[0].id);
      this._tabService.unregisterTabview(<string>tabviews[1].id);
      this._tabService.getTabManagerDefault().selectDefault();
    }

    this.trackingService.getTracker().trackFeature(EXIT_SPLIT_CANVAS, 1);
  }

  /**
   * Destroy
   */
  destroy(): void {
    this._tabService = null;
    this._layoutService = null;
  }
}
