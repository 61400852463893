import { DashboardMenuItem } from './dashboardmenu.item';
import { Component } from '../component';
/**
 * @ngInject
 *
 * @return {angular.IDirective}
 */
export function dashboardMenuItemDirective($compile: ng.ICompileService): ng.IDirective {
  'use strict';
  return Component.createDirective(DashboardMenuItem, {
    restrict: 'E',
    scope: {
      data: '=',
      itemClickHandler: '&',
    },
    template: `<li class="app-components-appsmenu-item"
    ng-class="::{group: vm.isGroup, external: vm.isExternalForNewWindow || vm.isExternalForNewTab, strongAuth: vm.isStrongAuth}">

  <a href=""
     ng-click="vm.onItemClick()"
     ng-class="{collapsed: vm.collapsed}"
     uib-tooltip="{{::vm.data.description}}" tooltip-placement="right"
     tooltip-enable="vm.tooltipEnabled"
     tooltip-append-to-body="true">

     <div class="item-content">
       <span class="sidebar-icon" ng-class="::vm.iconCls"></span>

       <span class="item-label" ng-bind-html="vm.dashboardDescription"></span>
     </div>

     <div class="item-actions">
        <i class="infoIcon infoIcon-sharedAndOwner iwp-icon-iwp_share_owner" ng-if="::(vm.data.sharedBy && vm.owner)"
           util-bootstrap-popover
           util-bootstrap-popover-view-model="::vm.tooltipModel"
           util-bootstrap-popover-template="::vm.popOverTemplateUrl"
           role="button"
           tabindex="0"
           data-toggle="popover"
           data-placement="bottom"
           data-trigger="hover"
        ></i>
        <i class="infoIcon infoIcon-shared iwp-icon-iwp_share_participant" ng-if="::(vm.data.sharedBy && !vm.owner)"
           util-bootstrap-popover
           util-bootstrap-popover-view-model="::vm.tooltipModel"
           util-bootstrap-popover-template="::vm.popOverTemplateUrl"
           role="button"
           tabindex="0"
           data-toggle="popover"
           data-placement="bottom"
           data-trigger="hover"
        ></i>
        <i class="infoIcon infoIcon-strongAuth iwp-icon-gen_token"
           ng-class="::{'moveLeft': vm.data.sharedBy}"
           ng-if="::vm.isStrongAuth"
           title="{{::'dashboardmenu.item.strongAuth' | translate }}"></i>
     </div>
  </a>
  <ul class="nav appsmenu-list" ng-if="::vm.isGroup" uib-collapse="vm.collapsed">
    <app-components-dashboard-menu-item ng-repeat="item in ::vm.data.children track by item.name"
                                        data="::item"
                                        item-click-handler="vm.onItemClick(app)"></app-components-dashboard-menu-item>
  </ul>
</li>`,
  });
}
