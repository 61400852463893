import _ from 'lodash';
declare var screen: any;

/**
 * Service to detect the current device class
 *
 * @author Tobias Straller [Tobias.Straller.bp@nttdata.com]
 */
export class DeviceService {
  // devices with size above the breakpoint are considered to be desktop devices
  static DEVICE_BREAKPOINT: number = 992;

  channel: IChannelDefinition<{}>;

  // Default is destop
  private _device: Devices = Devices.DESKTOP;

  // Device Pixel Ratio
  private _devicePixelRatio: number;

  // is the current browser internet explorer?
  private _ie: boolean;
  private _deviceDetector: any;
  private _$logService: ng.ILogService;
  private _ios: boolean = false;
  private _OS: any;

  static CHANNEL_DEVICE_UPDATE: string = 'DeviceServiceDeviceUpdateChannel';
  channelDeviceUpdate: IChannelDefinition<IDeviceServiceChannelDeviceUpdateMessage>;

  /**
   * @ngInject
   */
  constructor($log: ng.ILogService, deviceDetector: any, OS: any, private readonly postal: IPostal) {
    this._deviceDetector = deviceDetector;
    this._$logService = $log;
    this._ios = this.getIsIOS();
    this._OS = OS;
    this.channelDeviceUpdate = postal.channel(DeviceService.CHANNEL_DEVICE_UPDATE);
    this.detectDevice();
  }

  detectDevice() {
    var orientation = screen.orientation || screen.mozOrientation || screen.msOrientation;
    if (orientation && orientation.type) {
      orientation = orientation.type;
    }
    var secondary: boolean = orientation ? orientation.endsWith('secondary') : false;
    var portrait: boolean = orientation ? orientation.startsWith('portrait') : false;
    // on most handheld deices, screen.width is always constant
    var size: number = screen.width;
    // exception: if we are in 'portrait-secondary' orientation we have to inverse (e.g. Surface Pro)
    if (secondary && portrait) {
      size = screen.height;
    }
    this._device = size > DeviceService.DEVICE_BREAKPOINT ? Devices.DESKTOP : Devices.MOBILE;
    if (this._device === Devices.MOBILE && this._deviceDetector.os === this._OS.WINDOWS) {
      // For presentation on low resolution beamers: If OS is Windows but not Windows Phone always set device to desktop
      this._device = Devices.DESKTOP;
      this._$logService.info(
        `DeviceService: Screen size suggests mobile device, but OS is Windows. Overriding device to desktop.`
      );
    }
    this._$logService.info(
      `DeviceService: Detected device ${this.device === Devices.MOBILE ? 'MOBILE' : 'DESKTOP'}. Breakpoint: ${
        DeviceService.DEVICE_BREAKPOINT
      }`
    );
    this._devicePixelRatio = window && window.devicePixelRatio ? window.devicePixelRatio : 1;
    this._ios = this.getIsIOS();
  }

  /**
   * The device that has been detected upon application start.
   * @returns {Devices}
   */
  get device(): Devices {
    return this._device;
  }

  /**
   * Returns the device pixel ratio for the current device
   * @returns {number}
   */
  get devicePixelRatio(): number {
    return this._devicePixelRatio;
  }

  get isIOS(): boolean {
    return this._ios;
  }

  /**
   * Whether we have a mobile device
   * @returns {boolean}
   */
  isMobile(): boolean {
    return this._device === Devices.MOBILE;
  }

  /**
   * Whether the current browser is Internet Explorer
   */
  isInternetExplorer(): boolean {
    if (typeof this._ie === 'undefined') {
      if (
        !_.isUndefined(this._deviceDetector.browser) &&
        this._deviceDetector.browser !== null &&
        this._deviceDetector.browser.indexOf('ie') !== -1
      ) {
        this._ie = true;
      } else {
        this._ie = false;
      }
    }
    return this._ie;
  }

  private getIsIOS(): boolean {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window['MSStream'];
  }
}

export enum Devices {
  DESKTOP = 1,
  MOBILE = 2,
}

export interface IDeviceServiceChannelDeviceUpdateMessage {
  device: Devices;
}
