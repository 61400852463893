import { Tracker } from './tracker/tracker';
import { IAnalytics } from '@myworkplace/api';
import { BaseTracker } from './tracker/baseTracker';
import { TrackerMock } from './tracker/tracker.mock';
import { TrackerOffMock } from './tracker/tracker-off.mock';

export class TrackingService {
  private tracker!: BaseTracker;

  /**
   * Sets tracking service.
   * @param analytics analytics provided by the My workplace.
   * @param isProd if its production or not. If true, uses the my workplace API, otherwise returns a mocked analytics.
   */
  setTracker(isProd: boolean, analytics: IAnalytics): void {
    if (!isProd) {
      this.tracker = new TrackerMock();
      return;
    }
    if (this.isIntOrProdUrl()) {
      this.tracker = new Tracker(analytics);
    } else {
      this.tracker = new TrackerOffMock();
    }
  }

  /**
   * Returns the current instance of widget analytics tracker.
   */
  getTracker(): BaseTracker {
    return this.tracker;
  }

  isIntOrProdUrl(): boolean {
    const url = window.location.href;
    if (url.includes('-dev') || url.includes('-test') || url.includes('-edu')) {
      return false;
    }
    return true;
  }
}
