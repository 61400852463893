'use strict';
import { ISharingSearchSource } from '../../favorite/search-source.model.interface';
import { EmployeeService } from '../../employee/employee.service';
import { GroupService } from '../../groups/group.service';
import { IGroup } from '../../groups/group.model.interface';
import { IChip } from '../chip/chip.model.interface';
import { NotificationService } from '../../notification/notification.service';
import { SharingService } from '../../sharing/sharing.service';
import { IUser } from '../../user/user.model.interface';
import { BaseDialog } from './base-dialog';
import { ISharingChip } from '../../favorite/favorite-chip.model.interface';
import { UserService } from '../../user/user.service';
import { User } from '../../user/user.model';
import { IShareDialogConfig, IShareObject } from './share-dialog-config.interface';
import _ from 'lodash';

/**
 * Dialog for sharing a entity.
 */
export class ShareDialog extends BaseDialog {
  private static MAX_DISPLAYED_GROUPS: number = 10;
  private static MAX_DISPLAYED_EMPLOYEES: number = 10;

  chips: IChip[];
  /**
   * The search sources being used.
   */
  searchSources: ISharingSearchSource[];
  /**
   * The currently active search source.
   */
  selectedSource: ISharingSearchSource;
  /**
   * The search term being used in the search field.
   */
  searchTerm: string;
  config: IShareDialogConfig;

  private _employeeService: EmployeeService;
  private _groupService: GroupService;
  private _notificationService: NotificationService;
  private _sharingService: SharingService;
  private _sharedObject: IShareObject[];
  private _translateService: angular.translate.ITranslateService;
  private _externalText: string;
  private _accountDeletedText: string;
  private _userService: UserService;
  private _qService: ng.IQService;
  private _user: User;
  private _translatedDescriptions: { [key: string]: string };
  private _timeout: ng.ITimeoutService;

  /**
   * @ngInject
   */
  constructor(
    employeeService: EmployeeService,
    groupService: GroupService,
    notificationService: NotificationService,
    sharingService: SharingService,
    $translate: angular.translate.ITranslateService,
    userService: UserService,
    $q: ng.IQService,
    $timeout: ng.ITimeoutService
  ) {
    super();
    this._employeeService = employeeService;
    this._groupService = groupService;
    this._notificationService = notificationService;
    this._sharingService = sharingService;
    this._translateService = $translate;
    this.chips = [];
    this.searchSources = [];
    this._userService = userService;
    this._qService = $q;
    this._translatedDescriptions = {};
    this._timeout = $timeout;
  }

  $onInit() {
    this.config = this.resolve().config;
    this._translateService(['omnisearch.employee.external', 'tooltip.user.account.deleted']).then(
      (t: { [key: string]: string }) => {
        this._externalText = t['omnisearch.employee.external'];
        this._accountDeletedText = t['tooltip.user.account.deleted'];
      }
    );

    this._userService.getUser().then((user: User) => {
      this._user = user;
    });

    /**
     * configure search sources for users/groups
     */
    this.searchSources.push(
      {
        iconCls: 'iwp-icon-gen_user',
        text: 'dialogs.favorite.share.search.for.person.text',
        placeholder: 'dialogs.favorite.share.person.search.placeholder',
        searchFunction: this.searchUsers.bind(this),
        createChip: this.createUserChip.bind(this),
        updateSharedObject: (user: IUser, sharedObject: IShareObject[], add: boolean = true): void => {
          if (add) {
            delete user.id;
            sharedObject[0].users.push(user);
            return;
          }
          sharedObject[0].users = _.pull(sharedObject[0].users, user);
        },
        tableConfig: {
          headers: [
            {
              title: 'dialogs.favorite.share.search.table.column.header.name',
              dataField: (user: IUser): string => {
                return user.name + ' ' + user.surname;
              },
              dataIconCls: (user: IUser): string => (user.qaccount ? 'iwp-icon-gen_user_add' : 'iwp-icon-gen_user_c'),
            },
            {
              title: 'dialogs.favorite.share.search.table.column.header.department',
              dataField: 'department',
            },
            {
              title: 'dialogs.favorite.share.search.table.column.header.email',
              dataField: 'email',
            },
          ],
          data: [],
          disableCondition: (user: IUser): boolean => {
            return !(user && user !== null && User.prototype.hasQNumber.call(user));
          },
          tooManyUsersMessage: 'dialogs.favorite.share.search.tooManyResults',
          nbDisplayedResults: ShareDialog.MAX_DISPLAYED_EMPLOYEES,
        },
      },
      {
        iconCls: 'iwp-icon-groupadmin',
        text: 'dialogs.favorite.share.search.for.team.text',
        placeholder: 'dialogs.favorite.share.team.search.placeholder',
        searchFunction: this.searchGroups.bind(this),
        createChip: this.createGroupChip.bind(this),
        updateSharedObject: (group: IGroup, sharedObject: IShareObject[], add: boolean = true): void => {
          if (add) {
            sharedObject[0].groups.push(group);
            return;
          }
          sharedObject[0].groups = _.pull(sharedObject[0].groups, group);
        },
        tableConfig: {
          headers: [
            {
              title: 'dialogs.favorite.share.search.table.column.header.name',
              dataField: 'name',
              dataIconCls: 'iwp-icon-groupadmin_group_add',
            },
            {
              title: 'dialogs.favorite.share.search.table.column.header.creator',
              dataField: (group: IGroup): string => {
                return this._translatedDescriptions[group.id];
              },
            },
          ],
          data: [],
          disableCondition: (groups: IGroup): boolean => {
            return false;
          },
          tooManyUsersMessage: 'dialogs.favorite.share.search.tooManyResults',
          nbDisplayedResults: ShareDialog.MAX_DISPLAYED_GROUPS,
        },
      }
    );
    /**
     * default selected source is the first one
     * @type {ISharingSearchSource}
     */
    this.selectedSource = this.searchSources[0];

    /**
     * fetch and create chips for already shared with users/groups
     */
    this._sharingService.getSharedUsers(this.config.id, this.config.shareType).then((sharingObject: IShareObject[]) => {
      this._sharedObject = sharingObject;
      this._sharedObject[0].title = this.config.title;
      if (this._sharedObject) {
        if (_.isArray(this._sharedObject[0].users)) {
          this._sharedObject[0].users.forEach((user: IUser) => {
            this.createUserChip(user).then((chip: ISharingChip) => {
              this.chips.push(chip);
            });
          });
        }

        if (_.isArray(this._sharedObject[0].groups)) {
          this._sharedObject[0].groups.forEach((group: IGroup) => {
            this.createGroupChip(group).then((chip: ISharingChip) => {
              this.chips.push(chip);
            });
          });
        }
      }
    });
  }

  /**
   * Remove chip from the 'Share with: ' section.
   * @param chip
   */
  removeChip(chip: ISharingChip): void {
    this.chips = _.pull(this.chips, chip);
    this.searchSources.forEach((source: ISharingSearchSource) => {
      source.updateSharedObject(chip.entry, this._sharedObject, false, null);
    });
  }

  /**
   * Reset the current search results
   * Updated the selected search source users/team a.s.o.
   * Trigger a new search, on the new source. Let each source's search function do its magic and decisions
   * @param source
   */
  updateSelectedSource(source: ISharingSearchSource): void {
    this.selectedSource = source;
    this.doSearch();
  }

  /**
   * Trigger search for users/teams. Only one type of search is triggered, based on the
   * currently selected source
   */
  doSearch(): void {
    this.selectedSource.searchFunction(this.searchTerm);
  }

  /**
   * Searches for users. (Backend call)
   *
   * @param term
   */
  searchUsers(term: string): void {
    if (!term || term === '') {
      this._timeout(() => {
        this.selectedSource.tableConfig.data = null;
      }, 0);
      return;
    }

    if (term.length < 3) {
      return;
    }
    this._employeeService.findEmployees(
      {
        searchTerm: term,
      },
      (users: IUser[]) => {
        this.selectedSource.tableConfig.data = [];
        users.forEach((user: IUser): void => {
          this.selectedSource.tableConfig.data.push(user);
        });
      }
    );
  }

  /**
   * Searches for groups. (Backend call)
   *
   * @param term
   */
  searchGroups(term: string): void {
    this._translatedDescriptions = {};
    this._groupService.findGroups(term, ShareDialog.MAX_DISPLAYED_GROUPS, (groups: IGroup[]) => {
      var promises: ng.IPromise<string>[] = [];
      groups.forEach((group: IGroup) => {
        promises.push(this.getUserDescription(group.owner));
      });
      this._qService.all(promises).then((descriptions: string[]) => {
        descriptions.forEach((description: string, idx: number) => {
          this._translatedDescriptions[groups[idx].id] = description;
        });
        this.selectedSource.tableConfig.data = [];
        groups.forEach((group: IGroup): void => {
          this.selectedSource.tableConfig.data.push(group);
        });
      });
    });
  }

  /**
   * Reset current search (empty the table).
   */
  resetSearch(): void {
    this.searchTerm = '';
    this.selectedSource.tableConfig.data = null;
  }

  /**
   * Entry in table has been clicked. Add a new chip to the 'Search with:' section.
   *
   * @param idx
   * @param entry
   */
  handleEntryClicked(idx: number, entry: any): void {
    if (this.selectedSource.tableConfig.disableCondition(entry)) {
      return;
    }

    this.selectedSource.createChip(entry).then((chip: ISharingChip) => {
      /**
       * Don't add self to the sharing list
       */
      if (chip.id === this._user.userId) {
        this._notificationService.showInfo('dialogs.favorite.share.search.table.add.item.self');
        return;
      }
      /**
       * Don't add duplicates.
       * A duplicate chip is a chip with the same text.
       */
      if (
        !_.isUndefined(
          this.chips.find((ch: ISharingChip) => {
            return ch.id === chip.id;
          })
        )
      ) {
        this._notificationService.showInfo('dialogs.favorite.share.search.table.add.item.duplicate');
        return;
      }

      /**
       * update the chips - visually added chip
       */
      this.chips.push(chip);
      /**
       * update the shared favorite object
       */
      this.selectedSource.updateSharedObject(entry, this._sharedObject, true, null);
    });
  }

  /**
   * Ok button handler.
   */
  ok(): void {
    this._sharingService.setSharedUsers(this._sharedObject).then(() => {
      this.close({ $value: this.chips.length });
    });
  }

  destroy(el: JQuery): void {
    this._employeeService = null;
    this._groupService = null;
    this._notificationService = null;
    this._sharingService = null;
    this._translateService = null;
    this._userService = null;
    this._qService = null;
    this._timeout = null;
  }

  private createUserChip(entry: IUser): ng.IPromise<ISharingChip> {
    return this.getUserDescription(entry).then((text: string) => {
      const userDeletedClass = entry && entry.deleted ? ' deleted' : '';
      return {
        title: entry && entry.deleted ? this._accountDeletedText : '',
        text: text,
        iconCls: `iwp-icon-gen_user${userDeletedClass}`,
        entry: entry,
        id: entry.userId,
      };
    });
  }

  private createGroupChip(entry: IGroup): ng.IPromise<ISharingChip> {
    return this._qService.resolve({
      text: entry.name,
      iconCls: 'iwp-icon-groupadmin',
      entry: entry,
      id: entry.id,
    });
  }

  private getUserDescription(entry: IUser): ng.IPromise<string> {
    const fullName = `${entry.name} ${entry.surname}`.trim();
    return this._translateService(['omnisearch.employee.external', 'omnisearch.employee.inOrderOf.short'], entry).then(
      (messages: any) => {
        return entry.external
          ? `${fullName}, ${messages['omnisearch.employee.external']}, ${messages['omnisearch.employee.inOrderOf.short']}`
          : `${fullName}, ${entry.department}`;
      }
    );
  }
}
