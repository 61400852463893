import { FrameLayoutService } from './frame-layout.service';
import { IFrameLayout, ILayoutFrameDescriptor } from './frame-layout.model.interface';
import { IAppsFrame } from '../apps/apps.frame.interface';
import { FrameLayout } from './frame-layout';
import { IFrameLayoutAdapter, IFrameLayoutSplitter } from './frame-layout.adapters';
import _ from 'lodash';
import * as Interact from 'interactjs';

('use strict');

export class SimpleYAxisAdapter implements IFrameLayoutAdapter {
  splitters: IFrameLayoutSplitter[];
  isSplitterDragging: boolean;

  private parentEl: JQuery;
  private frameLayoutService: FrameLayoutService;
  private layout: IFrameLayout;
  private frameStyles: any[];
  private qService: ng.IQService;

  /**
   * @ngInject
   * @param {JQuery} parentEl
   * @param {FrameLayoutService} frameLayoutService
   */
  constructor(parentEl: JQuery, frameLayoutService: FrameLayoutService, layout: IFrameLayout, q: ng.IQService) {
    this.parentEl = parentEl;
    this.frameLayoutService = frameLayoutService;
    this.layout = layout;
    this.qService = q;
    this.splitters = [
      {
        limits: [40, this.parentEl.height()],
        axis: 'y',
        cls: FrameLayout.HORIZONTAL_SPLITTER_CLASS,
        getSplitterStyle: this.getSplitterStyle.bind(this),
        pos: 0,
        posPercent: 0,
        draggable: false,
        splitterEndDrag: (event: Interact.DragEvent, frames: IAppsFrame[]): void => {
          if (!this.splitters[0].draggable) {
            return;
          }
          this.isSplitterDragging = false;
          this.handleDividerEndDrag(frames);
        },
        splitterDragMove: (event: Interact.DragEvent): void => {
          if (!this.splitters[0].draggable) {
            return;
          }
          const offset = this.parentEl.offset();
          if (
            event.pageY < offset.top ||
            event.pageY < this.splitters[0].limits[0] ||
            event.pageY > this.splitters[0].limits[1]
          ) {
            return;
          }
          this.splitters[0].pos = event.pageY - offset.top;
          this.splitters[0].posPercent = this.splitters[0].pos / this.parentEl.height();
        },
        splitterDragDown: (event: Interact.DragEvent, frames: ILayoutFrameDescriptor[]): void => {
          if (!this.splitters[0].draggable) {
            return;
          }
          this.isSplitterDragging = true;
          this.setSplitterLimits(frames);
        },
        splitterDragUp: (): void => {
          this.isSplitterDragging = false;
        },
      },
    ];
  }

  get boundingBoxes(): any[] {
    return this.frameStyles;
  }

  get fullScreenStyle(): any {
    return {
      top: 40,
      height: '100%',
    };
  }

  reset(): void {
    this.splitters[0] = Object.assign({}, this.splitters[0], {
      pos: 0,
      posPercent: 0,
    });
    this.splitters[0].pos = 0;
    this.splitters[0].posPercent = 0;
  }

  resizeHandler(frames: IAppsFrame[], fullScreenFrame: IAppsFrame): void {
    if (!this.splitters[0].draggable || fullScreenFrame !== null) {
      return;
    }
    this.splitHandler();
    this.handleDividerEndDrag(frames);
  }

  splitHandler(): void {
    this.splitters[0].pos = this.splitters[0].posPercent * this.parentEl.height();
  }

  /**
   * Calculate each frame's position based on the frame's descriptor information. Frames are absolutely positioned.
   */
  buildFrameStyles(): void {
    if (!this.layout.frames || this.layout.frames.length !== 2) {
      return;
    }
    const height = this.parentEl.height();
    const leftFrame = this.layout.frames[0];
    const rightFrame = this.layout.frames[1];
    const leftMinSize = leftFrame.minHeight <= 1 ? Math.round(leftFrame.minHeight * height) : leftFrame.minHeight;
    const rightMinSize = rightFrame.minHeight <= 1 ? Math.round(rightFrame.minHeight * height) : rightFrame.minHeight;
    let leftHeight = leftFrame.height <= 1 ? Math.round(leftFrame.height * height) : leftFrame.height;
    let rightHeight = rightFrame.height <= 1 ? Math.round(rightFrame.height * height) : rightFrame.height;

    this.splitters[0].draggable =
      (leftHeight >= leftMinSize || rightHeight >= rightMinSize) && !(leftMinSize + rightMinSize >= height);
    leftHeight = leftHeight < leftMinSize ? leftMinSize : leftHeight;
    rightHeight = rightHeight < rightMinSize ? rightMinSize : rightHeight;

    // if there is a value set for the first frame, the second frames values are ignored.
    this.assembleOriginalStyles(leftHeight !== 0 ? leftHeight : height - rightHeight);
  }

  resizeIFrames(
    frames: IAppsFrame[],
    options: { width?: number; height?: number },
    sourceFrameIdx: number
  ): ng.IPromise<void> {
    const deferred = this.qService.defer<void>();
    if (!_.isNumber(options.height)) {
      console.log('Given target dimensions are not valid. Please specify a height');
      deferred.reject();
      return deferred.promise;
    }
    if (!this.splitters[0].draggable) {
      console.log('The layout is not resizable.');
      deferred.reject();
      return deferred.promise;
    }
    // invert height, since requesting iFrame is on the right
    if (sourceFrameIdx === 1) {
      options.height = this.parentEl.height() - options.height;
    }
    // this.isSplitterDragging = true;
    this.setSplitterLimits(this.layout.frames);
    /** convert to pixels, a height between [0,1) means percent */
    const targetHeight = options.height <= 1 ? options.height * this.parentEl.height() : options.height;
    const offsetY = this.parentEl.offset().top;
    /** constraint resizing to the calculated limits of the splitter */
    const calculatedHeight =
      targetHeight >= this.splitters[0].limits[0] - offsetY
        ? targetHeight <= this.splitters[0].limits[1] - offsetY
          ? targetHeight + offsetY
          : this.splitters[0].limits[1]
        : this.splitters[0].limits[0];

    jQuery('.splitter').animate(
      {
        top: calculatedHeight - 3 - offsetY,
      },
      250,
      () => {
        this.splitters[0].pos = calculatedHeight - 3;
        this.splitters[0].posPercent = (calculatedHeight - 3) / this.parentEl.height();
        this.handleDividerEndDrag(frames);
      }
    );
    return deferred.promise;
  }

  private handleDividerEndDrag(frames: IAppsFrame[]): IFrameLayout {
    const offset = this.parentEl.offset();
    const height = this.parentEl.height();

    frames.forEach((f: IAppsFrame, index: number) => {
      if (index === 0) {
        f.boundingBox = Object.assign({}, f.boundingBox, {
          top: offset.top,
          height: this.splitters[0].pos,
        });
      } else {
        f.boundingBox = Object.assign({}, f.boundingBox, {
          top: offset.top + this.splitters[0].pos - 3,
          height: height - this.splitters[0].pos,
        });
      }

      // keep bounding boxes in sync
      if (this.boundingBoxes && this.boundingBoxes.length >= index) {
        this.boundingBoxes[index] = Object.assign({}, this.boundingBoxes[index], f.boundingBox);
      }

      // also update the frame descriptor in the layout object, since it is going to be cached
      const frm = this.layout.frames.find((desc: ILayoutFrameDescriptor) => desc.widget.id === f.id);
      if (frm) {
        frm.height = +f.boundingBox.height / height;
      }
    });

    this.frameLayoutService.cacheLayout();
    return this.layout;
  }

  private getSplitterStyle(fullScreen: boolean): any {
    let st: any = { display: fullScreen ? 'none' : 'block' };
    if (this.boundingBoxes && this.boundingBoxes.length === 2) {
      st.top = this.splitters[0].pos !== 0 ? this.splitters[0].pos : this.boundingBoxes[1].top - 41;
    }
    return st;
  }

  private assembleOriginalStyles(sizeInPx: number): void {
    const offset = this.parentEl.offset();
    const height = this.parentEl.height();
    this.frameStyles = [
      {
        left: offset.left,
        right: 0,
        /**
         * know how much to the right based on the previos frame width,
         */
        top: offset.top,
        height: sizeInPx,
      },
      {
        left: offset.left,
        right: 0,
        /**
         * know how much to the right based on the previos frame width,
         */
        top: offset.top + sizeInPx,
        height: height - sizeInPx,
      },
    ];
    this.splitters[0].pos = sizeInPx;
    this.splitters[0].posPercent = sizeInPx / height;
  }

  private setSplitterLimits(frames: ILayoutFrameDescriptor[]): void {
    const offset = this.parentEl.offset();
    const height = this.parentEl.height();
    const minLSizePx = frames[0].minHeight <= 1 ? Math.round(frames[0].minHeight * height) : frames[0].minHeight;
    const minRSizePx = frames[1].minHeight <= 1 ? Math.round(frames[1].minHeight * height) : frames[1].minHeight;
    this.splitters[0].limits = [offset.top + minLSizePx, height - minRSizePx + offset.top];
  }
}
