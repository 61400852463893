/**
 * Filter to localize a date based on the locale
 * @param text
 * @param length
 * @returns {string}
 */
export function localizedDateFilter(): any {
  'use strict';

  let dateToLocaleFilter: any = function (timestamp: number, locale: string): string {
    if (!timestamp) {
      return '';
    }
    const dateOptions: Intl.DateTimeFormatOptions = {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    };
    return new Date(timestamp).toLocaleDateString(locale.replace('_', '-'), dateOptions);
  };
  dateToLocaleFilter.$stateful = true;

  return dateToLocaleFilter;
}
