import * as Interact from 'interactjs';
export class DraggableController {
  ddGroup: string;
  ddAxis: string;
  draggableOnStart: (params: { event: Interact.DragEvent }) => void;
  draggableOnEnd: (params: { event: Interact.DragEvent }) => void;
  draggableOnMove: (params: { event: Interact.DragEvent }) => void;
  draggableOnDown: (params: { event: Interact.PointerEvent }) => void;
  draggableOnUp: (params: { event: Interact.PointerEvent }) => void;
  draggableTranslate: boolean;

  private _interactable: Interact.Interactable;
  private _el: JQuery;

  initialize(el: JQuery): void {
    this._el = el;
    this._el.css({
      '-ms-touch-action': 'none',
      'touch-action': 'none',
    });
    this._interactable = Interact.interact(el.get(0))
      .draggable({
        onstart: this.onDraggableStart.bind(this),
        onend: this.onDraggableEnd.bind(this),
        onmove: this.onDraggableMove.bind(this),
      })
      .on('down', this.onDraggablePointerDown.bind(this))
      .on('up', this.onDraggablePointerUp.bind(this));
  }

  onDraggableStart(event: Interact.DragEvent): void {
    this._el.addClass('dragging');
    this.draggableOnStart({ event: event });
  }

  onDraggableEnd(event: Interact.DragEvent): void {
    this._el.removeClass('dragging');
    this.draggableOnEnd({ event: event });
    this._el.css('transform', '');
    this._el.removeData('x');
    this._el.removeData('y');
  }

  onDraggableMove(event: Interact.DragEvent): void {
    if (this.draggableTranslate) {
      const x = (parseFloat(this._el.data('x')) || 0) + event.dx;
      const y = parseFloat(this._el.data('y')) || 0;
      this._el.css('transform', `translate(${x}px, 0px)`);
      // update the position attributes
      this._el.data('x', x);
      this._el.data('y', y);
    }
    this.draggableOnMove({ event: event });
  }

  onDraggablePointerDown(event: Interact.PointerEvent): void {
    this.draggableOnDown({ event: event });
  }

  onDraggablePointerUp(event: Interact.PointerEvent): void {
    this.draggableOnUp({ event: event });
  }

  destroy(): void {
    this._el = null;
    this._interactable.unset();
    this._interactable = null;
  }
}
