import * as msal from '@azure/msal-browser';
import { PublicClientApplication } from '@azure/msal-browser/dist/app/PublicClientApplication';
import { WorkplaceContextService } from '../../workplace/workplace.context.service';
import { UserService } from '../../user/user.service';
import { AccountInfo } from '@azure/msal-common';

export class MicrosoftService {
  private readonly redirectEnvs = {
    dev: 'https://myworkplace-dev.apps.4wm-mwp.azure.cloud.bmw/ms_login',
    test: 'https://myworkplace-test.bmwgroup.net/ms_login',
    int: 'https://myworkplace-int.bmwgroup.net/ms_login',
    prod: 'https://myworkplace.bmwgroup.net/ms_login',
  };

  private msalInstance = {} as PublicClientApplication;

  private microsoftAccessToken: string;

  /**
   * @ngInject
   */
  constructor(private workplaceContextService: WorkplaceContextService, private userService: UserService) {}

  private async setMsalInstance(): Promise<void> {
    const { value: clientId } = await this.workplaceContextService.getProperty('ms.client.id');
    const { value: tenantId } = await this.workplaceContextService.getProperty('ms.tenant.id');
    const { value: env } = await this.workplaceContextService.getProperty('workplace.environment');

    const msalConfig = {
      auth: {
        clientId,
        authority: `https://login.microsoftonline.com/${tenantId}`,
        redirectUri: this.redirectEnvs[env],
      },
    };

    this.msalInstance = new msal.PublicClientApplication(msalConfig);
    return this.msalInstance.initialize();
  }

  async getMicrosoftToken(): Promise<string> {
    if (!Object.keys(this.msalInstance).length) {
      await this.setMsalInstance();
    }

    const account = this.getUserMsAccount();
    let response;

    const request = {
      scopes: ['User.Read'],
    };

    if (account) {
      request['account'] = account;
      try {
        response = await this.msalInstance.acquireTokenSilent(request);
      } catch (error) {
        console.error('error on silent login', error);
      }
    } else {
      try {
        response = await this.msalInstance.loginPopup(request);
      } catch (error) {
        console.log('error on login popup', error);
      }
    }

    if (response) {
      this.setAccessToken(response.accessToken);
      return response.accessToken;
    }

    return '';
  }

  async isUserLoggedIn(): Promise<boolean> {
    if (!Object.keys(this.msalInstance).length) {
      await this.setMsalInstance();
    }
    const account = this.getUserMsAccount();
    return !!account;
  }

  private getUserMsAccount(): AccountInfo {
    const user = this.userService.user;
    return this.msalInstance.getAccountByUsername(user.email);
  }

  private setAccessToken(token: string): void {
    this.microsoftAccessToken = token;
  }
}
