import _ from 'lodash';

/**
 * Url validator for form elements.
 *
 * @ngInject
 */

export function isuri(): ng.IDirective {
  'use strict';

  var uriRegEx: RegExp = /^(ftp|http|https|file):(\/\/|\\\\)(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?$/;

  return {
    require: 'ngModel',
    link: function (scope: angular.IScope, elm: JQuery, attrs: any, ctrl: angular.INgModelController): void {
      _.set(ctrl.$validators, 'isuri', (modelValue: string, viewValue: string): boolean => {
        if (ctrl.$isEmpty(modelValue)) {
          // consider empty models to be valid
          return true;
        }

        if (uriRegEx.test(viewValue)) {
          // it is valid
          return true;
        }

        // it is invalid
        return false;
      });
    },
  };
}
