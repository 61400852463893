import { IGroup } from './group.model.interface';
import JSData from 'js-data';

/**
 * Creates a groups store
 * @param DS
 *
 * @ngInject
 */
export function groupStoreFactory(DS: JSData.DS): JSData.DSResourceDefinition<IGroup> {
  'use strict';

  return DS.defineResource<IGroup>({
    name: 'groups',
    endpoint: './rest/groups',
    watchChanges: false,
  });
}
