import { SessionDataService } from '../sessionData/session-data.service';
import { IAppBehavior } from './app-behavior.model.interface';
/**
 * @ngInject
 */
export function appBehaviorFactory(
  $translate: angular.translate.ITranslateService,
  sessionDataService: SessionDataService
): IAppBehavior {
  'use strict';

  let doUnload = (): void => {
    sessionDataService.saveState();
  };

  return {
    isLogout: false,
    /**
     * Simple unload handler
     */
    addUnloadHandler(): void {
      window.addEventListener('unload', (event: BeforeUnloadEvent) => {
        doUnload();
      });
    },
    /**
     * Requires user's prompt
     */
    addUserPromptedUnloadHandler(): void {
      $translate('appbehavior.close.confirmation').then((message: string) => {
        window.addEventListener('beforeunload', (event: BeforeUnloadEvent) => {
          if (!this.isLogout) {
            (event || window.event).returnValue = message; // Gecko + IE
            return message; // Webkit, Safari, Chrome etc.
          }
        });
        window.addEventListener('unload', () => {
          doUnload();
        });
      });
    },
  };
}
