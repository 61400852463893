/**
 * Filter strips html tags from text
 * @param text
 * @param length
 * @returns {string}
 */
export function plainTextFilter(): Function {
  'use strict';
  return function (text: string, length: number): string {
    var result = ('' + text).replace(/<[^>]+>/gm, '');
    if (length && text.length > length) {
      return result.substring(0, length);
    }
    return result;
  };
}
