import { IRole, ISubstitutedUser } from './user.model.interface';
import { IUser } from './user.model.interface';
import { Role } from './role.model';
import _ from 'lodash';

/**
 * Implements the {@link IUser} Interface.
 * @author Tobias Straller [Tobias.Straller.bp@nttdata.com]
 */
export class User implements IUser {
  selectedRole: Role;

  uid: string;
  name: string;
  surname: string;
  userId: string;
  phone: string;
  email: string;
  roles: Role[];
  strongAuth: number;
  external: boolean;
  substitutedUsers: ISubstitutedUser[];
  department?: string;
  authLevel?: number;
  cnumber?: string;

  /**
   * @ngInject
   */
  constructor(data: any) {
    if (data) {
      this.uid = data.uid;
      this.name = data.name;
      this.surname = data.surname;
      this.userId = data.userId;
      this.email = data.email;
      this.phone = data.phone;
      this.roles = data.roles ? data.roles.map((iRole: IRole): Role => new Role(iRole)) : [];
      if (_.isArray(data.substitutedUsers)) {
        data.substitutedUsers.forEach(
          (substitutedUser: ISubstitutedUser) =>
            (substitutedUser.substitutedRoles = substitutedUser.substitutedRoles.map((iRole: IRole): Role => {
              this.roles.push(new Role(iRole, true, substitutedUser.userInfo));
              return new Role(iRole, true);
            }))
        );
        this.substitutedUsers = data.substitutedUsers;
      }

      this.external = data.external;
      this.strongAuth = data.strongAuth;
      this.selectedRole = data.currentRole;
      this.department = data.department ? data.department : '';
      this.authLevel = data.authLevel ? data.authLevel : 0;
      this.cnumber = data.cnumber;
    }
  }

  /**
   * Get the current role
   */
  get currentRole(): string {
    let selectedRole: Role;
    if ((selectedRole = this.getSelectedRole())) {
      return selectedRole.roleDescription;
    }
    return '';
  }

  getSelectedRole(): Role {
    /**
     * Reset to first role, if old one is not available
     */
    const currentRole = this.roles.find((role: Role): boolean => role === this.selectedRole);
    return currentRole ? currentRole : this.roles.length > 0 ? this.roles[0] : null;
  }

  selectRole(role: Role): void {
    this.selectedRole = role;
  }

  /**
   * Whether user has a qnumber
   * @returns {boolean}
   */
  hasQNumber(): boolean {
    return typeof this.userId !== 'undefined' && this.userId !== null && this.userId !== '';
  }
}
