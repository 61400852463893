import { Component } from '../component';
import { ImageCropper } from './cropper';
import cropperTemplate from './cropper.html';
/**
 * Cropper tool component.
 *
 * @ngInject
 */
export function cropperDirective(): ng.IDirective {
  'use strict';
  return Component.createDirective(ImageCropper, {
    restrict: 'E',
    scope: {
      imgSrc: '=',
      croppedCanvasData: '=',
    },
    templateUrl: cropperTemplate,
  });
}
