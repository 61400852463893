import { Component } from '../component';
('use strict');
import { ITableConfig, IHeader } from './table.model.interface';
import _ from 'lodash';

export class Table extends Component {
  tableConfig: ITableConfig;
  entryClickHandler: Function;

  /**
   * @ngInject
   */
  constructor() {
    super();
  }

  get tableData(): any[] {
    if (!_.isArray(this.tableConfig.data)) {
      return [];
    }

    if (!this.tableConfig.nbDisplayedResults || this.tableConfig.nbDisplayedResults > this.tableConfig.data.length) {
      return this.tableConfig.data;
    }
    return this.tableConfig.data.slice(0, this.tableConfig.nbDisplayedResults);
  }

  getCellValue(entry: any, header: IHeader): string {
    if (typeof header.dataField === 'function') {
      return header.dataField(entry);
    } else {
      return entry[header.dataField];
    }
  }

  getIconCls(entry: any, header: IHeader): string {
    if (typeof header.dataIconCls === 'function') {
      return header.dataIconCls(entry);
    } else {
      return header.dataIconCls;
    }
  }

  handleEntryClick(idx: number, entry: any): void {
    this.entryClickHandler({ idx: idx, entry: entry });
  }
}
