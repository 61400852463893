import { IPopupOptions } from './popup.model.interface';

/**
 * Service for displaying modal popups, as offered by the Modal component in angular bootstrap
 *
 * @author Bogdan Marian [bogdan.marian@nttdata.com]
 */
export class PopupService {
  static CHANNEL: string = 'POPUP_SERVICE_CHANNEL';
  static TOPIC_CONFIRM: string = 'POPUP_SERVICE_TOPIC_CONFIRM';

  channel: IChannelDefinition<IPopupServiceMessage>;

  private _uibModal: angular.ui.bootstrap.IModalService;
  private _q: ng.IQService;

  /**
   * @ngInject
   */
  constructor($uibModal: angular.ui.bootstrap.IModalService, $q: ng.IQService, postal: IPostal) {
    this._uibModal = $uibModal;
    this._q = $q;
    this.channel = postal.channel(PopupService.CHANNEL);
    this.channel.subscribe(PopupService.TOPIC_CONFIRM, (message: IPopupServiceMessage) => {
      this.showConfirm(message.options, message.directive);
    });
  }

  /**
   * Show a confirmation dialog. Using the confirmation dialog is the default option,
   * but callers could give in a different component.
   *
   * Given options are forwarded to the component.
   *
   * @param options - IPopupOptions for modal config
   * @param directive - string for confirmation dialog component, confirmation dialog by default.
   * @returns {IPromise<T>}
   */
  showConfirm(options: IPopupOptions, directive: string = 'confirmationDialog'): ng.IPromise<string> {
    const deferred = this._q.defer<string>();
    let uibModalInstance: angular.ui.bootstrap.IModalServiceInstance;
    uibModalInstance = this._uibModal.open({
      animation: true,
      backdrop: 'static',
      windowClass: directive + 'Modal',
      component: directive,
      resolve: {
        options: (): IPopupOptions => options,
      },
    });

    uibModalInstance.result.then(deferred.resolve, deferred.reject);

    return deferred.promise;
  }

  showLogoutConfirmationDialog(): ng.IPromise<string> {
    return this.showConfirm(
      {
        titleKey: 'logout.confirmation.title',
        messageKey: 'logout.confirmation.message',
        okButtonKey: 'logout.confirmation.button.ok',
        cancelButtonKey: 'logout.confirmation.button.cancel',
      },
      'logoutConfirmationDialog'
    );
  }

  /**
   * Will show a modal dialog window with the given directive as its child component.
   *
   * @param directive - the name of the directive to be rendered in the modal dialog.
   * @param config - optional configuration object used by the rendered component. This can be literally anything, we
   *                  cannot define a common interface for it, because every component might be using different info.
   * @param escapable - close modal when pressing scape
   * @param size - optional suffix of modal window class
   * @param backdrop - controls presence of a backdrop, can be true, false or 'static'(disables modal closing by click
   *                    on the backdrop)
   * @returns {IPromise<T>}
   */
  showModalWindow(
    directive: string,
    config?: any,
    escapable?: boolean,
    size?: string,
    backdrop?: boolean | string
  ): ng.IPromise<any> {
    const deferred = this._q.defer();
    let uibModalInstance: angular.ui.bootstrap.IModalServiceInstance;
    uibModalInstance = this.createModalWindow(directive, config, escapable, size, backdrop);
    uibModalInstance.result.then((res: any) => deferred.resolve(res)).catch(() => deferred.reject());
    return deferred.promise;
  }

  /**
   * Create a modal dialog instance
   * @see showModalWindow
   *
   * @param {string} directive
   * @param config
   * @param {boolean} escapable
   * @param {string} size
   * @param {true, false or string} backdrop
   * @returns {angular.IPromise<any>}
   */
  createModalWindow(
    directive: string,
    config: any = {},
    escapable?: boolean,
    size?: string,
    backdrop: boolean | string = 'static'
  ): angular.ui.bootstrap.IModalServiceInstance {
    let rendered = this._q.defer();
    config.rendered = rendered.promise;
    let instance = this._uibModal.open({
      size: size,
      animation: true,
      backdrop,
      keyboard: escapable,
      windowClass: directive + 'Modal',
      component: directive,
      resolve: {
        config: (): any => config,
      },
    });
    instance.rendered.then(rendered.resolve);
    return instance;
  }

  async initFileModal(modalId: string): Promise<Element> {
    const { modal } = await import('dashboards/open-files-modal');
    const linkModalEl = document.querySelector(`mwp-open-files-modal[wc-id="files-modal-${modalId}"]`) as Element & {
      [key: string]: any;
    };

    await modal.mount();

    return linkModalEl;
  }
}

/**
 * Interface for messages send over postal channel
 */
export interface IPopupServiceMessage {
  options: IPopupOptions;
  directive: string;
}
