import { Component } from '../component';
import { IApplication } from '../../apps/application.model.interface';
import { LayoutService } from '../../layout/layout.service';
import { WorkplaceContextService } from '../../workplace/workplace.context.service';
import { IWorkplaceContext } from '../../workplace/workplace.context.interface';

export abstract class AbstractMenuItem<T extends IApplication> extends Component {
  /**
   * Set by attribute
   */
  data: T;
  /**
   * Set by attribute
   */
  itemClickHandler: (message: { app: T }) => void;
  /**
   * True -> Groups item is collapsed
   */
  collapsed: boolean = true;

  protected _layoutService: LayoutService;
  private _strongAuth: number;

  /**
   *
   * @param tabService
   *
   * @ngInject
   */
  constructor(layoutService: LayoutService, workplaceContextService: WorkplaceContextService) {
    super();
    this._layoutService = layoutService;
    workplaceContextService.getAppContext('strongAuth').then((context: IWorkplaceContext) => {
      this._strongAuth = context.strongAuth;
    });
    /** auth level updated - update strongAuth */
    workplaceContextService.authLevelUpdated.subscribe(
      WorkplaceContextService.UPDATED_AUTH_LEVEL,
      ({ authLevel }) => (this._strongAuth = authLevel)
    );
  }

  abstract get iconCls(): string;

  /**
   * Whether the menu item has children
   * @returns {boolean}
   */
  get isGroup(): boolean {
    return this.data.children && this.data.children.length > 0;
  }

  /**
   * Whether the menu item represents an app with strong authentication
   */
  get isStrongAuth(): boolean {
    return this.data.strongAuth && this._strongAuth && this.data.strongAuth > this._strongAuth;
  }

  /**
   * Whether the app is opening external in a new window
   * @returns {boolean}
   */
  get isExternalForNewWindow(): boolean {
    return this.data.startMode === 'N';
  }

  /**
   * Whether the app is opening external in a new browser tab
   * @returns {boolean}
   */
  get isExternalForNewTab(): boolean {
    return this.data.startMode === 'B';
  }

  get tooltipEnabled(): boolean {
    return !this._layoutService.getSidebarConfig('navigationbar').expanded;
  }

  /**
   * Item or subitem has been clicked.
   * @param app only when subitem has been clicked
   */
  onItemClick(app?: T, $event?: ng.IAngularEvent): void {
    if (app) {
      // sub item has been clicked
      this.itemClickHandler({ app: app });
      return;
    }
    if (this.isGroup) {
      this.collapsed = !this.collapsed;
      return;
    }
    this.itemClickHandler({ app: this.data });
    if ($event) {
      $event.stopPropagation();
    }
  }

  destroy(el: JQuery): void {
    this._layoutService = null;
  }
}
