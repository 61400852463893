import { IRequestConfig } from 'angular';
import JSData from 'js-data';
import _ from 'lodash';
import { DeviceService } from '../../util/device.service';
import { ILanguage } from '../../util/language.model.interface';
import { APP_MY_PERMISSIONS, APP_TASKS_MANAGER, IApplication } from '../apps/application.model.interface';
import { AppsService } from '../apps/apps.service';
import { INotificationService } from '../notification/notification.service.interface';
import { IUser } from '../user/user.model.interface';
import { IUserServiceDeputyUpdatedMessage, UserService } from '../user/user.service';
import { IBusinessObject, IDiffType, ITask, ITaskType, IWorkplaceTask, TASK_SYSTEMS } from './tasks.model.interface';

/**
 * Created by MARIAB on 16.11.2015.
 */
export class TasksService {
  static CHANNEL_TASKS: string = 'TasksServiceChannelTasks';
  static TOPIC_TASKS_UPDATE: string = 'TasksServiceTasksUpdate';
  static TOPIC_TASKS_DEEPLINK: string = 'TasksServiceTasksDeeplink';
  static UPDATED_DEPUTIES: string = 'TasksServiceUpdatedDeputies';

  tasks: ITask[];
  sidebarTasksDeputy: IUser;
  tasksLoaded: boolean = false;
  comError: boolean = false;
  channel: IChannelDefinition<ITaskServiceMessage>;
  taskTypes: ITaskType[];

  private _userService: UserService;
  private _tasksStore: JSData.DSResourceDefinition<ITask>;
  private _translateService: angular.translate.ITranslateService;
  private _pollingInterval: number;
  private _interval: ng.IIntervalService;
  private _notificationService: INotificationService;
  private _language: ILanguage;
  private _appsService: AppsService;
  private _httpService: ng.IHttpService;
  private _qService: ng.IQService;
  private _deviceService: DeviceService;

  // using RxJs instead of the messaging channels
  private _businessObjects: IBusinessObject[];
  private _diffTypes: IDiffType[];

  /**
   * @ngInject
   */
  constructor(
    tasksStore: JSData.DSResourceDefinition<ITask>,
    $translate: angular.translate.ITranslateService,
    $interval: ng.IIntervalService,
    pollingInterval: number,
    notificationService: INotificationService,
    langauge: ILanguage,
    appsService: AppsService,
    $http: ng.IHttpService,
    postal: IPostal,
    $q: ng.IQService,
    debounce: any,
    userService: UserService,
    deviceService: DeviceService
  ) {
    this.tasks = [];
    this.taskTypes = [];
    this._userService = userService;
    this._userService.channelDeputyUpdated.subscribe(UserService.DEPUTY_UPDATED, this._updateDeputy.bind(this));
    this._appsService = appsService;
    this._tasksStore = tasksStore;
    this._translateService = $translate;
    this._pollingInterval = pollingInterval;
    this._httpService = $http;
    this._interval = $interval;
    this._notificationService = notificationService;
    this._language = langauge;
    this._deviceService = deviceService;
    this.channel = postal.channel<ITaskServiceMessage>(TasksService.CHANNEL_TASKS);
    this._qService = $q;
    this.updateTasks = debounce(this.updateTasks.bind(this), 500, true);
  }

  getBusinessObjectsLinkedToTasks() {
    return new Promise(resolve => {
      if (this._businessObjects) {
        resolve(this._businessObjects);
      }
      this._httpService
        .get('rest/workplace/businessobjects', {
          params: {
            lang: this._language.lang,
            locale: this._language.locale,
            deviceType: this._deviceService.device,
          },
        })
        .then((res: any) => {
          this._businessObjects = res.data;
          resolve(res.data);
        });
    });
  }

  getAllowedDiffTypes() {
    return new Promise(resolve => {
      if (this._diffTypes) {
        resolve(this._diffTypes);
      }
      this._httpService
        .get('rest/tasks/diffTypes/allowed', {
          params: {
            lang: this._language.lang,
          },
        })
        .then((res: any) => {
          this._diffTypes = res.data;
          resolve(res.data);
        });
    });
  }

  openTaskNextOne(taskId: string, queryParams: any = {}): void {
    queryParams = {
      ...queryParams,
      taskId,
    };
    this._appsService.openAppByName({ name: APP_TASKS_MANAGER, queryParams });
    return;
  }

  openTask(task: IWorkplaceTask | ITask, queryParams: any = {}, deputizedFor?: string): void {
    this.channel.publish(TasksService.TOPIC_TASKS_DEEPLINK);
    if (task.system === TASK_SYSTEMS.NEXTONE) {
      queryParams = {
        ...queryParams,
        taskId: task.parentId ? `${task.parentId}-${task.id}` : task.id,
        deputizedFor: deputizedFor ? deputizedFor : '',
      };
      this._appsService.openAppByName({ name: APP_TASKS_MANAGER, queryParams });
      return;
    }

    // for ias tasks open widget from layout with path and params, ex: /layout/ipdm/widget/iram/-/home?changeContextNumber=K1800023
    if (
      task.system === TASK_SYSTEMS.IAS_AEM ||
      task.system === TASK_SYSTEMS.IAS_FRG ||
      task.system === TASK_SYSTEMS.IAS_PKH ||
      task.system === TASK_SYSTEMS.IAS_FLS
    ) {
      const link = task.taskLink;

      window.location.href = `./#${link.replace('/iwp/#', '')}`;
      return;
    }

    if (
      [TASK_SYSTEMS.NAEL, TASK_SYSTEMS.VDAM, TASK_SYSTEMS.DALI, TASK_SYSTEMS.FALi, TASK_SYSTEMS.FTM].includes(
        task.system
      )
    ) {
      this._appsService.openAppByName({
        name: 'vimClient',
        path: '/',
        queryParams: {
          'hashOpenIssue;uid': task.id,
        },
        hash: '',
      });
      return;
    }

    if (task.system === TASK_SYSTEMS.CARE) {
      this.openApp(APP_MY_PERMISSIONS, task.taskLink);

      return;
    }

    this.openApp(task.system, task.taskLink);
  }

  private openApp(appName: string, taskLink: string): void {
    this._appsService
      .getApp(appName)
      .then((app: IApplication) => {
        const clone = _.cloneDeep(app);
        clone.url = taskLink ? taskLink : clone.url;
        if (clone.url) {
          this._appsService.openApp(clone);
        } else {
          this._notificationService.showError('open.task.invalid.url');
        }
      })
      .catch(() => {
        if (taskLink) {
          window.open(taskLink);
        }
      });
  }

  /**
   * Open Jira Task
   * @param task
   * @param queryParams
   * @param deputizedFor
   */
  openJiraTask(task: ITaskItem, queryParams: any = {}, deputizedFor?: string): void {
    this.channel.publish(TasksService.TOPIC_TASKS_DEEPLINK);
    if (task.system === TASK_SYSTEMS.JIRA || task.system === TASK_SYSTEMS.JIRA_SECURE) {
      queryParams = {
        ...queryParams,
        jiraId: task.id,
        deputizedFor: deputizedFor ? deputizedFor : '',
      };
      if (task.system === TASK_SYSTEMS.JIRA_SECURE) {
        queryParams.jiraSecure = true;
      }
      this._appsService.openAppByName({
        name: APP_TASKS_MANAGER,
        refreshUrl: true,
        queryParams,
      });
      return;
    }
  }

  /**
   * Create task
   */
  createTask(taskData: INewTaskData): ng.IPromise<string> {
    const defered = this._qService.defer<string>();
    const formData: FormData = new FormData();
    formData.append(
      'task',
      JSON.stringify({
        taskTitle: taskData.title,
        taskDue: taskData.date,
        taskLink: '/app/nextone/taskId=', // we need this for the email notification bugfix: MWP-5342
        responsible: taskData.responsible,
        requester: null,
        priority: null,
        deputyUsers: null,
        deputyGroups: null,
        sharedUsers: null,
        sharedGroups: null,
        businessObjects: null,
        tags: null,
      })
    );

    this._httpService({
      method: 'GET',
      url: 'rest/token/uploadAttachment',
    }).then((res: angular.IHttpPromiseCallbackArg<string>) => {
      const headers = {
        csrftoken: res.headers()['csrftoken'],
        'Content-Type': undefined,
        'As-Deputy-For': this.sidebarTasksDeputy ? this.sidebarTasksDeputy.userId : '',
      };

      if (taskData.attachment) {
        formData.append('fileUpload', taskData.attachment);
      }

      // transformResponse set to empty array, to avoid transformation to json from angularjs client
      // (https://github.com/angular/angular.js/issues/15897)
      this._httpService({
        method: 'POST',
        url: 'rest/tasks',
        data: formData,
        params: { lang: this._language.lang, source: 'workplace' },
        responseType: 'text',
        transformResponse: [],
        headers,
      })
        .then((response: angular.IHttpPromiseCallbackArg<string>) => {
          defered.resolve(response.data);
          // UPDATE TASKS
          const addedTask: ITaskItem = {
            id: response.data,
            system: TASK_SYSTEMS.NEXTONE,
            systemDescription: [],
            taskResponsibleName: `${taskData.responsible.name} ${taskData.responsible.surname}`,
            taskResponsibleDepartment: taskData.responsible.department,
            taskResponsibleEmail: taskData.responsible.email,
            taskDescription: null,
            taskTitle: taskData.title,
            taskApplication: null,
            taskApplicationDescription: null,
            indiziertAm: null,
            readPermissions: [],
            type: null,
            taskResponsible: null,
            taskDue: null,
            taskLink: null,
            termin: null,
            dateDescription: null,
            version: null,
            parentId: null,
            important: false,
            confidential: false,
            id_: response.data,
            system_: 'iwp-icon-nextone_task',
            title_: taskData.title,
            dueDate_: taskData.date ? taskData.date.getTime() : null,
          };
          this.channel.publish(TasksService.TOPIC_TASKS_UPDATE, {
            tasks: [],
            addedTask: addedTask,
          });
        })
        .catch(() => {
          defered.reject();
        });
    });
    return defered.promise;
  }

  /**
   * fromWidget - source of call is the task widget, don't need to update the counter and the tasks, just return results
   * asDeputyWidget - deputy set in widget
   * @returns {angular.IPromise<ITask[]>}
   */
  getTasks(fromWidget: boolean = false, asDeputyWidget: IUser = null): ng.IPromise<ITask[]> {
    return this._httpService(<IRequestConfig>{
      method: 'GET',
      url: './rest/tasks',
      params: {
        lang: this._language.lang,
        deviceType: this._deviceService.device,
      },
      headers: {
        'As-Deputy-For': fromWidget
          ? asDeputyWidget
            ? asDeputyWidget.userId
            : ''
          : this.sidebarTasksDeputy
          ? this.sidebarTasksDeputy.userId
          : '',
      },
      errorNotification: fromWidget,
    })
      .then((tasks: any) => {
        if (!fromWidget) {
          this.tasks = tasks.data;
          this.comError = false;
          this.tasksLoaded = true;
          return this.tasks;
        } else {
          return tasks.data;
        }
      })
      .catch(() => {
        this.comError = true;
        return [];
      });
  }

  async getTaskTypes(): Promise<ITaskType[]> {
    if (this.taskTypes.length) {
      return this.taskTypes;
    }

    return this._httpService(<IRequestConfig>{
      method: 'GET',
      url: './rest/user/settings/v2/taskTypes',
      params: {
        lang: this._language.lang,
      },
      errorNotification: false,
    })
      .then(taskTypes => {
        this.taskTypes = taskTypes.data as ITaskType[];
        return this.taskTypes;
      })
      .catch(() => {
        this.comError = true;
        return [];
      });
  }

  /**
   * Will be called by the polling provider.
   * Shows a message if new tasks have been created
   * for the user and updates the TasksStore if
   * necessary
   */
  startPolling(): void {
    this.getTasks().then((tasks: ITask[]) => {
      let tL: number = tasks.length;
      this._interval(() => {
        this.getTasks().then((newTasks: ITask[]) => {
          if (tL !== newTasks.length) {
            /**
             * The new tasks loaded notification must always be displayed.
             */
            this._notificationService.showInfo('tasks.message.newTasks');
            tL = newTasks.length;
            this.channel.publish(TasksService.TOPIC_TASKS_UPDATE, {
              tasks: this.tasks,
            });
          }
        });
      }, this._pollingInterval);
    });
  }

  /**
   * Creates and copies a workplace task/subtask deep link to the clipboard
   * @param {IWorkplaceTask | ITask} task
   * @param {boolean} justPath
   * @returns {string}
   */
  getWorkplaceTaskDeepLink(task: IWorkplaceTask | ITask, justPath: boolean = false): string {
    let extraParams = `${window.location.origin}${window.location.pathname}#/app/${APP_TASKS_MANAGER}/`;

    if (justPath === true) {
      return extraParams;
    }

    if (!task) {
      return null;
    }

    if (task.parentId) {
      return extraParams + 'taskId=' + task.parentId + '&subtaskId=' + task.id;
    }
    return extraParams + 'taskId=' + task.id;
  }

  getSystemIcon(system: string): string {
    return `iwp-${this.taskTypes.find(taskType => taskType.name === system).iconName}`;
  }

  setSidebarTasksDeputy(user: IUser): void {
    this.sidebarTasksDeputy = user;
  }

  getSidebarTaskDeputy(): IUser {
    return this.sidebarTasksDeputy;
  }

  publishDeputyUpdate(): void {
    this.channel.publish(TasksService.UPDATED_DEPUTIES);
  }

  updateTasks(): void {
    this.getTasks().then(() =>
      this.channel.publish(TasksService.TOPIC_TASKS_UPDATE, {
        tasks: this.tasks,
      })
    );
  }

  private _updateDeputy(message: IUserServiceDeputyUpdatedMessage) {
    const currentDeputy = this.getSidebarTaskDeputy();
    /** update deputy list */
    this.publishDeputyUpdate();
    /** verify if deputy is still valid */
    if (!currentDeputy) {
      /** have no current deputy, we don't need to do anything */
      return;
    }
    if (message.deputy && message.deputy.userId === currentDeputy.userId && !message.enabled) {
      /** deputy was disabled so we need to unset it and warn the user */
      this.setSidebarTasksDeputy(null);
      this._notificationService.showWarn('global.deputized.disabled');
    }
  }
}

export interface ITaskServiceMessage {
  tasks: ITask[];
  addedTask?: ITaskItem;
}

export interface ITaskItem extends ITask {
  id_?: string;
  id_sort?: number;
  system_?: string;
  title_?: string;
  status_?: string;
  status_sort?: string;
  dueDate_?: number;
  contextMenu?: boolean;
}

export interface ITaskColumn {
  id: string;
  name: string;
  sortField: string;
  asc: boolean;
  sortActive: boolean;
}

export interface ITaskDueDateFilter {
  start: Date;
  end: Date;
}

export interface ITaskFilters {
  systemFilters: ITaskFilterItem[];
  statusFilters?: ITaskFilterItem[];
  dueDateFilters: ITaskDueDateFilter;
  titleFilter?: string;
  idFilter?: string;
}

export interface ITaskFilterItem {
  name: string;
  isSelected: boolean;
  label: string;
  iconName: string;
  description: string;
}

export const SYSTEM_NAMES = {
  gams: 'taskSystems.gams',
  pqm: 'taskSystems.pqm',
  dbkr: 'taskSystems.dbkr',
  qmt: 'taskSystems.qmt',
  workplace: 'taskSystems.workplace',
  containercalculation: 'taskSystems.containercalculation',
  plausicheck: 'taskSystems.plausicheck',
  rsml: 'taskSystems.rsml',
  ias_aem: 'taskSystems.ias_aem',
  ias_frg: 'taskSystems.ias_frg',
  ias_pkh: 'taskSystems.ias_pkh',
  ias_fls: 'taskSystems.ias_fls',
  jira: 'taskSystems.jira',
  jira_secure: 'taskSystems.jira_secure',
  gss: 'taskSystems.gss',
  fls2: 'taskSystems.fls2',
  nael: 'taskSystems.nael',
  vdam: 'taskSystems.vdam',
  dali: 'taskSystems.dali',
  fali: 'taskSystems.fali',
  ftm: 'taskSystems.ftm',
  emt_mvpm: 'taskSystems.emt_mvpm',
};

export interface INewTaskData {
  title: string;
  date: Date;
  responsible: IUser;
  attachment: any;
}
