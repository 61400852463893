import { Component } from '../component';
import { ViewToggle } from './view.toggle';
import viewToggleTemplate from './view.toggle.html';
export function viewToggleDirective(): ng.IDirective {
  'use strict';

  return Component.createDirective(ViewToggle, {
    restrict: 'E',
    templateUrl: viewToggleTemplate,
  });
}
