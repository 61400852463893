import { Component } from '../component';
import { IMenuItemModel } from '../../menu/menu.item.model';
import { MenuOverlay } from '../../menu/menu.overlay';
import { MenuService } from '../../menu/menu.service';
import { DashboardService } from '../../dashboard/dashboard.service';
import menuOverlayTemplate from '../../menu/menu.overlay.html';
import _ from 'lodash';
import { TrackingService } from '../../feature-tracking/tracking.service';
import { OPEN_MY_TASKS } from '../task/analytics/feature-analytics-conf';

/**
 * @author Tobias Straller [Tobias.Straller.bp@nttdata.com]
 */
export class MenuItem extends Component {
  /**
   * Set by attribute
   */
  data: IMenuItemModel;

  /**
   * Set by attribute
   */
  menuId: string;

  /**
   * Whether the menu item is active
   */
  active: boolean;

  private _compileService: ng.ICompileService;
  private _httpService: ng.IHttpService;
  private _templateCache: ng.ITemplateCacheService;
  private _controllerService: ng.IControllerService;
  private _scope: any;
  private _el: JQuery;
  private _visibleUnsubscribe: Function;
  private _menuService: MenuService;
  private _dashboardService: DashboardService;
  private _hideOverlayThrottle: any;

  /**
   * @ngInject
   * @param $compile
   */
  constructor(
    $compile: ng.ICompileService,
    $http: ng.IHttpService,
    $templateCache: ng.ITemplateCacheService,
    $controller: ng.IControllerService,
    menuService: MenuService,
    dashboardService: DashboardService,
    private readonly trackingService: TrackingService
  ) {
    super();
    this._httpService = $http;
    this._templateCache = $templateCache;
    this._compileService = $compile;
    this._controllerService = $controller;
    this._menuService = menuService;
    this._dashboardService = dashboardService;
    this._hideOverlayThrottle = _.throttle(this._hideOverlay, 100);
  }

  /**
   * Item has been clicked
   */
  handleClick($event: ng.IAngularEvent): boolean {
    this.closeNotifications();
    this.trackingService.getTracker().trackFeature(OPEN_MY_TASKS, 1);
    this._dashboardService.publishWidgetContextMenuClose();
    this.data.action(this.data, this.menuId);
    $event.stopPropagation();
    return false;
  }

  private closeNotifications(): void {
    const navbarRightSideMenus = document.getElementById('navbar-right-menus-desktop');
    if (!navbarRightSideMenus) return;

    const shadowRoot = navbarRightSideMenus.shadowRoot;
    if (!shadowRoot) return;
    const notifications = shadowRoot.querySelector('mwp-notification');

    if (!notifications) return;
    const notificationsButton = notifications.querySelector('button');
    if (notificationsButton.getAttribute('selected') === 'true') {
      notificationsButton.click();
    }
  }

  /**
   * Get the css class for the current menu item
   */
  getCls(): string {
    const cls = [this.data.name];
    if (this.data.cls && this.data.cls[this.menuId]) {
      cls.push(this.data.cls[this.menuId]);
    }
    if (this.active) {
      cls.push('active');
    }
    return cls.join(' ');
  }

  onRenderComponent(el: JQuery, $scope: ng.IScope): void {
    const promise: ng.IPromise<MenuOverlay> = Component.renderComponentTo(
      $scope.$new(),
      MenuOverlay,
      menuOverlayTemplate,
      $('.overlay-container')
    );
    this._visibleUnsubscribe = $scope.$watch('vm.data.menuContent.visibleMenuId', (value: string) => {
      if (value === this.menuId) {
        promise.then((menuOverlay: MenuOverlay) => {
          menuOverlay.label = this.data.label;
          menuOverlay.menuContent = this.data.menuContent;
          menuOverlay.customClass = this.data.menuClass;
          let targetAlign = this.menuId === 'navigationbar' ? 'top right' : 'bottom left';
          if (this.data.targetAlign && this.data.targetAlign[this.menuId]) {
            targetAlign = this.data.targetAlign[this.menuId];
          }
          const align = this.data.align && this.data.align[this.menuId] ? this.data.align[this.menuId] : 'top left';
          menuOverlay.show(el, targetAlign, align).then(this._menuService.publishMenuShown.bind(this._menuService));
        });
        this.active = true;
      } else if (promise) {
        this._hideOverlay(promise);
      }
    });
    window.addEventListener('resize', this._hideOverlayThrottle.bind(this, promise));
  }

  onDestroy(): void {
    this._el = null;
    this._scope = null;
    this._httpService = null;
    this._templateCache = null;
    this._compileService = null;
    this._controllerService = null;
    this._menuService = null;
    if (typeof this._visibleUnsubscribe === 'function') {
      this._visibleUnsubscribe();
    }
  }

  private _hideOverlay(promise: ng.IPromise<MenuOverlay>): void {
    if (this.active) {
      promise.then((menuOverlay: MenuOverlay) => menuOverlay.hide());
      this.active = false;
    }
  }
}
