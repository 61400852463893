import { Widget } from '../widget';
import { Sus } from './sus';
import susWidgetTemplate from './sus.html';
/**
 *
 * @returns {ng.IDirective}
 * @ngInject
 *
 */
export function susWidgetDirective(): ng.IDirective {
  'use strict';
  return Widget.createWidgetDirective(Sus, {
    restrict: 'E',
    templateUrl: susWidgetTemplate,
  });
}
