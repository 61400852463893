import { Component } from '../component';
import { CordovaDialog } from './cordova-dialog';
import cordovaDialogTemplate from './cordova-dialog.html';
/**
 * Wrapper directive for {AcceptFavoriteDialog}
 * @returns {{}}
 * @ngInject
 */
export function cordovaDialogDirective(): ng.IDirective {
  'use strict';

  return Component.createDirective(CordovaDialog, {
    restrict: 'E',
    templateUrl: cordovaDialogTemplate,
    scope: {
      close: '&',
      dismiss: '&',
      resolve: '&',
    },
  });
}
