import { SupportOverlay } from './support-overlay';
import { Component } from '../components/component';
import appSupportOverlayTemplate from './support-overlay.html';

/**
 * Wraps {@link AppsMenu} component into a directive.
 *
 * @author Tobias Straller [Tobias.Straller.bp@nttdata.com]
 *
 * @ngInject
 */
export function appSupportOverlayDirective(): ng.IDirective {
  'use strict';
  return Component.createDirective(SupportOverlay, {
    restrict: 'E',
    scope: {},
    templateUrl: appSupportOverlayTemplate,
  });
}
