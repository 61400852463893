import { WidgetService } from '../../widget/widget.service';
import { DashboardService } from '../../dashboard/dashboard.service';
import { WidgetDefinitions } from '../../widget/widget.data.interface';
import { ICroppedCanvasData } from '../../fileHandling/image.model.interface';
import { IWidgetDescriptor } from '../../widget/widget.descriptor.model';
import { ImageService } from '../../fileHandling/image.service';
import { DeviceService } from '../../../util/device.service';
import { FormDialog } from './form-dialog';
import { getLinkType, LINK_TYPE } from '../../../util/link-utils/link.type';
import { CROP_ASPECT_RATIO_2 } from '../cropper/cropper';
import { IRestrictedTextModel } from '../restrictedText/restrictedText.model';
import { INgModelController } from 'angular';
import _ from 'lodash';

('use strict');
/**
 * Abstract model base class for favorite modal dialogs.
 */
export abstract class FavoriteDialog extends FormDialog {
  title: string;
  dataUrl: string;
  config: any;
  dialogTitle: string = 'dialogs.favorite.title';
  /**
   * Widget's default icon, as given by the Widget.ts getDefaultIcon method.
   */
  defaultIcon: string;
  /**
   * Cropped image data model
   */
  croppedCanvasData: ICroppedCanvasData = null;
  /**
   * Original image's base 64 encoded source.
   */
  image: string;

  loading: boolean = false;

  validDataUrl: boolean = false;

  restrictedTextFocused: boolean = false;

  linkInputModel: IRestrictedTextModel = {
    maxLength: 50,
    maxLines: 2,
    required: true,
    maxLinesErrorText: '',
  };

  protected _widgetService: WidgetService;
  protected _widgetDescriptor: IWidgetDescriptor<any>;
  protected _dashboardService: DashboardService;
  protected _translateService: angular.translate.ITranslateService;
  protected _imageService: ImageService;
  protected _deviceService: DeviceService;

  /**
   * @constructor
   * @ngInject
   */
  constructor(
    widgetService: WidgetService,
    dashboardService: DashboardService,
    $translate: angular.translate.ITranslateService,
    imageService: ImageService,
    deviceService: DeviceService
  ) {
    super();
    this._widgetService = widgetService;
    this._dashboardService = dashboardService;
    this._translateService = $translate;
    this._imageService = imageService;
    this._deviceService = deviceService;
  }

  $onInit() {
    this.config = this.resolve().config;

    if (this.config) {
      this._widgetDescriptor = _.cloneDeep(this._widgetService.getWidget(this.config.id));
      if (!this._widgetDescriptor || this._widgetDescriptor === null) {
        this._widgetDescriptor = Object.assign(
          {},
          {
            type: WidgetDefinitions.TILE,
            widgetDefinitionId: WidgetDefinitions.TILE,
            rowspan: 1,
            colspan: 1,
            newColspan: 2,
            newRowspan: 2,
            customSettings: {},
            hidden: false,
          }
        );
      }
      this.defaultIcon = this.config.defaultIcon || 'placeholder-link';
    }

    this._translateService([
      'dialogs.favorite.error.max.lines',
      'dialogs.favorite.error.name.required',
      'dialogs.favorite.error.name.maxlength',
      'dialogs.favorite.info.address.uri',
      'dialogs.favorite.info.address.nolocaluri',
      'dialogs.favorite.error.address.required',
      'dialogs.favorite.error.address.maxlength',
      'dialogs.favorite.error.address.uri',
      'dialogs.favorite.placeholder.name',
    ]).then((translations: { [key: string]: string }) => {
      this.linkInputModel.maxLinesErrorText = translations['dialogs.favorite.error.max.lines'];
      this.linkInputModel.placeholder = translations['dialogs.favorite.placeholder.name'];
      this.tooltipTranslations['title'] = {
        required: translations['dialogs.favorite.error.name.required'],
        maxLength: translations['dialogs.favorite.error.name.maxlength'],
      };
      this.tooltipTranslations['dataUrl'] = {
        touched: translations['dialogs.favorite.info.address.uri'],
        required:
          translations['dialogs.favorite.error.address.required'] +
          '\n\n' +
          translations['dialogs.favorite.info.address.uri'],
        maxLength: translations['dialogs.favorite.error.address.maxlength'],
        isuri:
          translations['dialogs.favorite.error.address.uri'] +
          '\n\n' +
          translations['dialogs.favorite.info.address.uri'],
        isnolocaluriwindows: translations['dialogs.favorite.info.address.nolocaluri'],
      };
    });
    this.validateUrl();
  }

  protected handleKeyUp(event: KeyboardEvent, formCtrl: angular.IFormController): void {
    if (!this.restrictedTextFocused && this.isFavoriteValid()) {
      super.handleKeyUp(event, formCtrl);
    }
  }
  /**
   * Decides whether the name error tooltip should be shown or not.
   * Also sets the error message.
   *
   * @param error
   * @param touched
   * @returns {boolean}
   */
  showTooltip(control: INgModelController): boolean {
    if (!control.$touched) {
      return false;
    }

    if (control.$error.required) {
      return true;
    }

    if (control.$error.maxlength) {
      return true;
    }

    return false;
  }

  /**
   * Compute the title tooltip text
   *
   * @param control
   * @returns {any}
   */
  getTooltipText(control: INgModelController): string {
    if (!control) {
      return;
    }
    if (!control.$touched) {
      return '';
    }

    if (control.$error.required) {
      return this.tooltipTranslations[control.$name]['required'];
    }

    if (control.$error.maxlength) {
      return this.tooltipTranslations[control.$name]['maxLength'];
    }

    return '';
  }

  validateUrl(): void {
    let linkType = getLinkType(this.dataUrl);
    this.validDataUrl = linkType === LINK_TYPE.FILE || linkType === LINK_TYPE.URL;
  }

  getAddressTooltipText(control: INgModelController): string {
    if (!control) {
      return;
    }

    if (control.$error.required) {
      return this.tooltipTranslations[control.$name]['required'];
    }

    if (control.$error.maxlength) {
      return this.tooltipTranslations[control.$name]['maxlength'];
    }

    if (control.$error.isuri) {
      return this.tooltipTranslations[control.$name]['isuri'];
    }

    if (control.$error.isnolocaluriwindows) {
      return this.tooltipTranslations[control.$name]['isnolocaluriwindows'];
    }
  }

  getAddressTooltipInfoText(control: INgModelController) {
    return this.tooltipTranslations[control.$name]['touched'];
  }

  /**
   * Called when a the user considers his cropping to be final
   */
  acceptCrop(): void {
    this.image = null;
  }

  /**
   * Called when a the user changes his mind and quits selecting a new wiget image background
   */
  cancelCrop(): void {
    this.croppedCanvasData = null;
    this.image = null;
  }

  /**
   * Resets the cropped widget background image to the default one.
   */
  resetToDefaultImage(): void {
    if (this._widgetDescriptor && this._widgetDescriptor.settings && this._widgetDescriptor.settings.imgId) {
      delete this._widgetDescriptor.settings.imgId;
      delete this._widgetDescriptor.customSettings.imgId;
      this._widgetDescriptor.rowspan = 1;
      this._widgetDescriptor.colspan = 1;
    }

    this.croppedCanvasData = null;
    this.image = null;
  }

  /**
   * Only show edit image part when editing favorites.
   * @type {boolean}
   */
  get showEditWidgetBackgroundImage(): boolean {
    return (
      this._widgetDescriptor && (this._widgetDescriptor.type === WidgetDefinitions.TILE || this.config.editWithoutURL)
    );
  }

  /**
   * Sometimes we want to hide this field -> when editing app favorites.
   * @type {boolean}
   */
  get showUrlField(): boolean {
    return true;
  }

  /**
   * Cropper is visible when there's a source for the cropperImage tag.
   *
   * @returns {boolean}
   */
  get cropperVisible(): boolean {
    return !_.isUndefined(this.image) && this.image !== null;
  }

  get widgetDescriptor(): IWidgetDescriptor<any> {
    return this._widgetDescriptor;
  }

  /**
   * Wheter the reset to default image button should be visible or not.
   * That is the case when either a crop has been performed or there's a saved
   * image in the db for the widget.
   */
  get resetToDefaultVisible(): boolean {
    return (
      (this.croppedCanvasData && this.croppedCanvasData.data) ||
      (this._widgetDescriptor.settings && this._widgetDescriptor.settings.imgId)
    );
  }

  /**
   * Saves the image first, through the image service, than updates the widget descriptor with
   * the new image's id and closes the dialog.
   */
  protected saveImgDataAndClose(): void {
    this.loading = true;
    /**
     * Delete the old image first, if present.
     * We will get orphans in the DB otherwise
     */
    if (this._widgetDescriptor.settings && this._widgetDescriptor.settings.imgId) {
      this._imageService.deleteImage(this._widgetDescriptor.settings.imgId).then(() => {
        this._widgetDescriptor.settings.imgId = null;
        this.saveImgDataAndClose();
      });
      return;
    }
    /**
     * save the newly selected image first and close the dialog with an updated descriptor instance
     */
    this._imageService.saveImage(this.croppedCanvasData.croppedBlob).then((imgId: string) => {
      this.loading = false;
      this._widgetDescriptor.customSettings.imgId = '' + imgId;
      this._widgetDescriptor.colspan = this.croppedCanvasData.aspectRatio === CROP_ASPECT_RATIO_2 ? 2 : 1;

      this.close({ $value: this._widgetDescriptor });
    });
  }

  onRestrictedTextFocus(isFocused: boolean): void {
    this.restrictedTextFocused = isFocused;
  }

  isFavoriteValid(): boolean {
    return true;
  }

  /**
   * Destroy the component.
   *
   * @param el
   */
  destroy(el: JQuery): void {
    this._widgetService = null;
    this._dashboardService = null;
    this._translateService = null;
    this._imageService = null;
    this._deviceService = null;
  }
}
