import { Component } from '../component';
('use strict');

/**
 * An item in a bootstrap dropdown menu.
 */
export class DropdownMenuItem extends Component {
  iconCls: string;
  key: string;
  isInternet: boolean;
  errorMsg: string;
  itemClick: Function;

  /**
   * @ngInject
   */
  constructor() {
    super();
  }
}
