import { Component } from '../component';
import { ShareDialog } from './share-dialog';
import shareDialogTemplate from './share-dialog.html';
/**
 * Directive wrapper for sharing a dialog.
 * @returns {ng.IDirective}
 * @ngInject
 */
export function shareDialogDirective(): ng.IDirective {
  'use strict';
  return Component.createDirective(ShareDialog, {
    restrict: 'E',
    templateUrl: shareDialogTemplate,
    scope: {
      close: '&',
      dismiss: '&',
      resolve: '&',
    },
  });
}
