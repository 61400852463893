import { Component } from '../component';
import { TitlebarSplit } from './titlebar.split';
import titlebarSplitTemplate from './titlebar.split.html';

/**
 * Wraps {@link Titlebar} component into a directive.
 *
 * @author Tobias Straller [Tobias.Straller.bp@nttdata.com]
 *
 * @ngInject
 */
export function titlebarSplitDirective(): ng.IDirective {
  'use strict';
  return Component.createDirective(TitlebarSplit, {
    restrict: 'E',
    scope: {
      menuId: '@',
    },
    templateUrl: titlebarSplitTemplate,
  });
}
