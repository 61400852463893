import { Component } from '../component';
import { IDashboard } from '../../dashboard/dashboard.model.interface';
import angular from 'angular';
import _ from 'lodash';

('use strict');

/**
 * A typeahead search field
 * Uses throttle to prevent too many key triggers.
 */

export class TypeaheadDropdown extends Component {
  items: any[];
  preselected: any;
  placeholder: string;
  autoFocused: boolean = false;
  alwaysShowReset: boolean = false;
  previewIcon: string;
  throttledSearch: Function;
  changeHandler: Function;
  focusHandler: Function;
  resetHandler: Function;
  disabled: Function;
  displayLabel: string;

  term: string;
  collapsedDropdown: boolean = true;
  selectedItem: any;
  filteredItems: any[] = [];

  private _timeout: ng.ITimeoutService;
  /**
   * @ngInject
   */
  constructor($timeout: ng.ITimeoutService) {
    super();
    this._timeout = $timeout;
  }

  $onInit() {
    if (this.items && this.items.length > 0) {
      this.filteredItems = [...this.items];
      const item = this.items.find((item: any) => item.id === this.preselected.id);
      this.selectedItem = item || this.items[0];
      this.changeHandler({ item: this.selectedItem });
    } else {
      this.filteredItems = [];
      this.selectedItem = null;
    }

    this.throttledSearch = _.debounce(this.triggerHandler.bind(this), 10, {
      trailing: true,
    });
  }

  /**
   * Basic input functions (reset / type / focus)
   * @param event
   */
  reset(event: MouseEvent): void {
    event.stopPropagation();
    this.resetDropdownOptions();
    this.resetHandler();
  }

  triggerHandler(): void {
    this.filterItems();
  }

  triggerFocusHandler(): void {
    this.focusHandler();
  }

  /**
   * Select item functionality
   * Select item; verify if item is selected; get selected item's name
   * @param item
   */
  selectItem(item: any): void {
    this.selectedItem = item;
    this.closeDropdown();
    this.changeHandler({ item });
  }

  isItemSelected(item: any): boolean {
    return item.id === this.selectedItem.id;
  }

  getSelectedItemName(): void {
    return this.selectedItem
      ? this.displayLabel && this.displayLabel in this.selectedItem
        ? this.selectedItem[this.displayLabel]
        : this.selectedItem.name
      : '';
  }

  /**
   * Dropdown events
   * Open / close / reset dropdown
   * @param event
   */
  openDropdown(event: MouseEvent): void {
    event.stopPropagation();
    if (this.disabled()) {
      return;
    }
    this.collapsedDropdown = false;
    this._timeout(() => {
      angular.element('.__term')[0].focus();
    });
  }

  closeDropdown(): void {
    this.collapsedDropdown = true;
    this.term = '';
    this.resetDropdownOptions();
  }

  resetDropdownOptions(): void {
    this.term = '';
    this.filterItems();
  }

  /**
   * Filter items
   * trigger detection
   */
  filterItems(): void {
    this.filteredItems = [
      ...this.items.filter((item: any) =>
        this.displayLabel && this.displayLabel in item
          ? item[this.displayLabel].toLowerCase().indexOf(this.term.toLowerCase()) !== -1
          : item.name.toLowerCase().indexOf(this.term.toLowerCase()) !== -1
      ),
    ];

    this._timeout(() => {
      const element = angular.element('.dropdown-items');
      if (element[0]) {
        element[0].click();
      }
    });
  }

  getDisplayName(item: IDashboard): string {
    return this.displayLabel && this.displayLabel in item ? item[this.displayLabel] : item.name;
  }
}
