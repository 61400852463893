import { ConfirmDialog } from './confirm';
import { Component } from '../component';
import confirmationDialogTemplate from './confirm.html';

/**
 * A modal, configurable, confirmation dialog.
 *
 * @ngInject
 */
export function confirmationDialog(): ng.IDirective {
  'use strict';
  return Component.createDirective(ConfirmDialog, {
    restrict: 'E',
    templateUrl: confirmationDialogTemplate,
    scope: {
      close: '&',
      dismiss: '&',
      resolve: '&',
    },
  });
}
