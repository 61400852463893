import { Component } from '../components/component';
import { IFrameLayout } from './frame-layout.model.interface';
import { FrameLayoutService } from './frame-layout.service';
import { LayoutService } from '../layout/layout.service';
import { DraggableMenuItem } from '../../util/interact/draggable-menu-item';
import { DeviceService } from '../../util/device.service';
import { WidgetDefinitions } from '../widget/widget.data.interface';
import { UserService } from '../user/user.service';
import { User } from '../user/user.model';

('use strict');

/**
 * Model class for frame layout menu items.
 */
export class FrameLayoutMenuItem extends Component {
  layout: IFrameLayout;

  private frameLayoutService: FrameLayoutService;
  private layoutService: LayoutService;
  private draggableMenuItem: DraggableMenuItem;
  private _userService: UserService;

  /**
   * @ngInject
   */
  constructor(
    frameLayoutService: FrameLayoutService,
    layoutService: LayoutService,
    private deviceService: DeviceService,
    userService: UserService
  ) {
    super();
    this.frameLayoutService = frameLayoutService;
    this.layoutService = layoutService;
    this._userService = userService;
  }

  $onInit() {
    const customSettings = {
      type: WidgetDefinitions.FRAME_LAYOUT,
      id: this.layout.id,
      locked: this.layout.locked,
    };
    this.draggableMenuItem = new DraggableMenuItem(
      {
        customSettings: customSettings,
        widgetDefinitionId: WidgetDefinitions.DASHBOARD_LINK,
      },
      this.layoutService,
      this.deviceService,
      this.layoutService.collapseSidebar.bind(this.layoutService, 'navigationbar')
    );
  }

  /**
   * Check if user has QNumber.
   * @returns {boolean}
   */
  userHasQNumber(): boolean {
    const user: User = <User>this._userService.user;
    return user && user.hasQNumber();
  }

  onRenderComponent(el: JQuery, scope: ng.IScope): void {
    if (this.userHasQNumber()) {
      this.draggableMenuItem.initElement($(el), scope);
    }
  }

  destroy(el: JQuery): void {
    super.destroy(el);
    this.draggableMenuItem.cleanupElement();
    this.draggableMenuItem = null;
    this.frameLayoutService = null;
    this.layoutService = null;
  }

  handleClick(): void {
    this.frameLayoutService.openAppsLayoutTab(this.layout);
    this.layoutService.collapseSidebar('navigationbar');
  }

  strongAuthRequired(): boolean {
    return (
      this.layout.strongAuth && this._userService.user.authLevel && this.layout.strongAuth > this._userService.user.authLevel
    );
  }
}
