import { Component } from '../component';
import { AcceptFavoriteDialog } from './accept-favorite-dialog';
import acceptFavoriteTemplate from './accept-favorite-dialog.html';
/**
 * Wrapper directive for {AcceptFavoriteDialog}
 * @returns {{}}
 * @ngInject
 */
export function acceptFavoriteDirective(): ng.IDirective {
  'use strict';

  return Component.createDirective(AcceptFavoriteDialog, {
    restrict: 'E',
    templateUrl: acceptFavoriteTemplate,
    scope: {
      close: '&',
      dismiss: '&',
      resolve: '&',
    },
  });
}
