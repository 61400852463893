import { LayoutService } from '../../layout/layout.service';
import { getToken } from '../../app.auth';
import { IAnalytics, PlatformAnalyticsFactory } from '@myworkplace/api';
import { ActionLogService } from '../../actionLog/action-log.service';
import { WorkplaceContextService } from '../../workplace/workplace.context.service';
import { EventListenerManager } from '../event-listener-manager';
import { UserService } from '../../user/user.service';
import { IUserSetting, IUserSettingOption } from '../../userSetting/user-setting.model.interface';
import { UserSettingService } from '../../userSetting/user-setting.service';
import { MwpStoreService } from '../mwp-store/mwp-store.service';

type MicroFrontend = HTMLElement & { getToken: () => string; analytics: IAnalytics; demoMode: string };
type NavigationComponentHandlers = { [key: string]: (event: any) => void };
type NavigationComponentsHandlers = { [key: string]: NavigationComponentHandlers };

export class NavigationComponentsService {
  private static readonly CREATE_ACTION_LOG_FUNC = 'createActionLog';
  private navigationHandlers: NavigationComponentsHandlers;

  protected appsMenuEl: MicroFrontend;
  protected dashboardsMenuEl: MicroFrontend;
  protected searchEl: MicroFrontend;
  protected navbarMenusEl: MicroFrontend;
  protected storeEl: MicroFrontend;

  /**
   * @ngInject
   */
  constructor(
    private readonly layoutService: LayoutService,
    private readonly actionLogService: ActionLogService,
    private readonly workplaceContextService: WorkplaceContextService,
    private readonly eventListenerManager: EventListenerManager,
    private readonly userService: UserService,
    private readonly userSettingService: UserSettingService,
    private readonly mwpStoreService: MwpStoreService
  ) {}

  /**
   * Import all navigation components and set their handlers
   */
  importAll(handlers: NavigationComponentsHandlers): void {
    this.navigationHandlers = handlers;
    this.importAppsMenu();
    this.importDashboardsMenu();
    this.importSearch();
    this.importNavbarMenus();
    this.importStore();
    this.importHeaderTabs();
    this.importIdCards();
    this.importDashboards();
  }

  async importSearch(): Promise<void> {
    await import('mwpSearch');
    this.searchEl = this.configureWebComponent('mwp-search-desktop', 'mwp-search-mobile');
    this.addHandlersToWebComponent(this.searchEl, 'mwpSearch');
  }

  async importAppsMenu(): Promise<void> {
    await import('appsMenu');
    this.appsMenuEl = this.configureWebComponent('apps-menu-desktop', 'apps-menu-mobile');
    this.addHandlersToWebComponent(this.appsMenuEl, 'appsMenu');
  }

  async importDashboardsMenu(): Promise<void> {
    await import('dashboardsMenu');
    this.dashboardsMenuEl = this.configureWebComponent('dashboards-menu-desktop', 'dashboards-menu-mobile');
    this.addHandlersToWebComponent(this.dashboardsMenuEl, 'dashboardsMenu');
  }

  async importNavbarMenus(): Promise<void> {
    await import('navbarRightSideMenus');
    this.navbarMenusEl = this.configureWebComponent('navbar-right-menus-desktop', 'navbar-right-menus-mobile');
    this.addHandlersToWebComponent(this.navbarMenusEl, 'navbarRightSideMenus');
    const user = await this.userService.getUser();
    if (user) {
      this.navbarMenusEl['user'] = user;
      const userImage = await this.getProfileImage();
      if (userImage) {
        this.navbarMenusEl['userImage'] = `./rest/user/settings/profileImage/${userImage}`;
      }
    }
  }

  async importStore(): Promise<void> {
    await import('mwpStore');
    this.storeEl = this.configureWebComponent('mwp-store-desktop');
    this.mwpStoreService.setMwpStoreEl(this.storeEl);
    this.addHandlersToWebComponent(this.storeEl, 'mwpStore');
  }

  async importHeaderTabs(): Promise<void> {
    await import('headerTabs');
    this.configureWebComponent('header-tabs');
  }

  async importIdCards(): Promise<void> {
    await import('idCard');
    const idCards = document.querySelectorAll('id-card');
    if (idCards) {
      idCards.forEach((element: MicroFrontend) => (element.getToken = getToken));
    }
  }

  async importDashboards(): Promise<void> {
    await import('dashboards');
  }

  closeOverlays(): void {
    this.appsMenuEl?.['closeSidePanel']();
    this.dashboardsMenuEl?.['closeSidePanel']();
    this.navbarMenusEl?.['closePopover']();
    this.navbarMenusEl?.['closeSidePanel']();
    this.searchEl?.['closeSidePanel']();
  }

  private addHandlersToWebComponent(element: MicroFrontend, name: string): void {
    const handlers = this.navigationHandlers[name];
    if (!handlers || !element) return;
    Object.keys(handlers).forEach((key: string) => {
      this.eventListenerManager.addEventListener(element, key, handlers[key]);
    });
  }

  /**
   * Configure a web component with the necessary properties, such as token, analytics and demo mode
   * (soon to be replaced by the shell input service in Angular)
   * Some components are duplicated for mobile and desktop, so we need to check which one to use
   */
  private configureWebComponent(desktopId: string, mobileId?: string): MicroFrontend {
    let element: MicroFrontend;
    if (this.layoutService.mobileLayout && mobileId) {
      element = document.getElementById(mobileId) as MicroFrontend;
    } else {
      element = document.getElementById(desktopId) as MicroFrontend;
    }

    if (!element) return;
    element.getToken = getToken;
    element.analytics = this.getPlatformAnalytics();
    element.demoMode = this.workplaceContextService.demoMode;
    return element;
  }

  private getPlatformAnalytics(): IAnalytics {
    return PlatformAnalyticsFactory({
      callApi: (name: string, ...params: any[]): Promise<any> => {
        if (name !== NavigationComponentsService.CREATE_ACTION_LOG_FUNC) {
          return Promise.reject('Wrong API function');
        }
        const logEntry = params[0];
        this.actionLogService.logAction(logEntry);
      },
    });
  }

  private async getProfileImage(): Promise<any> {
    const settings: IUserSetting = await this.userSettingService.getSettingByDescription('profileImage');
    if (!settings) return;
    return settings.optionsList.find((option: IUserSettingOption) => option.description === 'profileImageSet')
      .values[0];
  }
}
