export enum AnnouncementStatus {
  LIVE = 'LIVE',
  SCHEDULED = 'SCHEDULED',
  ENDED = 'ENDED',
  DELETED = 'DELETED',
}

export enum AnnouncementMutationType {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export enum AnnouncementBannerType {
  INFO = 'INFO',
  WARNING = 'WARNING',
  CRITICAL = 'CRITICAL',
}

export interface AnnouncementMethod {
  banner: boolean;
  badge: boolean;
}

export interface IAnnouncement {
  id?: string;
  name: string;
  type: AnnouncementBannerType;
  startDate: Date;
  endDate: Date | null;
  showFor: string[];
  method: AnnouncementMethod;
  description: {
    en: string;
    de: string;
    es: string;
  };
  link: {
    en: string;
    de: string;
    es: string;
  };
}

export interface IAnnouncementBanner {
  id: string;
  type: string;
  message: string;
  link?: string;
}

export interface AnnouncementMutation {
  action: AnnouncementMutationType;
  announcement: IAnnouncement;
}

interface AnnouncementDates {
  startDate: Date;
  endDate: Date | null;
}

export function getAnnouncementStatus(announcement: AnnouncementDates): AnnouncementStatus {
  const dateNow = Date.now();
  const startDate = announcement.startDate.getTime();
  const endDate = announcement.endDate?.getTime();

  if (startDate > dateNow) return AnnouncementStatus.SCHEDULED;

  if (endDate && endDate <= dateNow) return AnnouncementStatus.ENDED;

  return AnnouncementStatus.LIVE;
}

export const getAnnouncementsForAppQuery = `
query getAnnouncementsForApps($appIds: [String!]!, $status: announcementStatus) {
  getAnnouncementsForApps(appIds: $appIds, status: $status) {
    announcements {
      id
      name
      type
      showFor
      startDate
      endDate
      method {
        banner
        badge
      }
      link {
        en
        de
        es
      }
      description {
        en
        de
        es
      }
    }
  }
}
`;

export const getAnnouncementSubscription = `
  subscription newAnnouncement {
    getMutatedAnnouncement {
      action
      announcement {
        id
        name
        type
        startDate
        endDate
        showFor
        method {
          banner
          badge
        }
        description {
          en
          es
          de
        }
        link {
          en
          es
          de
        }
        timeZone
      }
    }
  }
`;
