import { ITask } from './tasks.model.interface';
import JSData from 'js-data';

/**
 *
 * @param DS
 * @returns {DSResourceDefinition<ITask>|(DSResourceDefinition<ITask>&TActions)}
 * @ngInject
 */
export function tasksStoreFactory(DS: JSData.DS): JSData.DSResourceDefinition<ITask> {
  'use strict';

  return DS.defineResource<ITask>({
    name: 'tasks',
    endpoint: './rest/tasks',
    watchChanges: false,
    idAttribute: 'composedId',
    computed: {
      composedId: [
        'id',
        'parentId',
        (id: string, parentId: string): string => {
          return parentId ? `${parentId}-${id}` : id;
        },
      ],
    },
  });
}
