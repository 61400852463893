import { IActionLogEntry } from './action-log-entry.interface';
import { DeviceService } from '../../util/device.service';
import { WorkplaceContextService } from '../workplace/workplace.context.service';
import { IWorkplaceProperty } from '../workplace/workplace-property.interface';
import { ActionConstants } from './action-constants';
import { UrlHelper } from '../../util/url.helper';
import { ILanguage } from '../../util/language.model.interface';
import { UserService } from '../user/user.service';
import { IWorkplaceContext } from '../workplace/workplace.context.interface';
import _ from 'lodash';

/**
 * Service used to log user actions. User actions are information used to evaluate usage of certain features in the workplace.
 *
 * author Tobias Straller [Tobias.Straller@nttdata.com]
 */
export class ActionLogService {
  queue: IActionLogEntry[];
  logEnabled: boolean = true;

  private deviceService: DeviceService;
  private userService: UserService;
  private workplaceContextService: WorkplaceContextService;
  private intervalService: ng.IIntervalService;
  private httpService: ng.IHttpService;
  private language: ILanguage;

  /**
   * @ngInject
   */
  constructor(
    deviceService: DeviceService,
    workplaceContextService: WorkplaceContextService,
    userService: UserService,
    $interval: ng.IIntervalService,
    $http: ng.IHttpService,
    language: ILanguage
  ) {
    this.queue = [];
    this.deviceService = deviceService;
    this.intervalService = $interval;
    this.workplaceContextService = workplaceContextService;
    this.userService = userService;
    this.httpService = $http;
    this.language = language;
    // flush the queue every 10mins
    this.intervalService(this.flushQueue.bind(this), 10 * 60 * 1000);
    // flush the queue when the window has been closed
    // $(window).on('unload', this.flushQueue.bind(this));
    this.workplaceContextService.getProperty('client.actions.log.enable').then((property: IWorkplaceProperty) => {
      if (property) {
        this.logEnabled = property.value === 'true';
      }
    });
  }

  /** n
   * Add an entry to the action log
   * @param entry
   */
  logAction(entry: IActionLogEntry): void {
    if (this.logEnabled) {
      this.workplaceContextService.getAppContext('businessRoles', 'role').then((context: IWorkplaceContext) => {
        this.queue.push(
          _.merge(
            {},
            {
              source: ActionConstants.SOURCE_WORKPLACE,
              selectedRole: context.role,
              businessRoles: context.businessRoles,
              deviceType: this.deviceService.device,
              screenResolution: window.screen.width + 'x' + window.screen.height,
              userAgent: navigator.userAgent,
              timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              timezoneOffset: new Date().getTimezoneOffset(),
              locale: navigator.language || this.language.locale,
            },
            entry
          )
        );
        this.flushQueue();
      });
    }
  }

  /**
   * Flush the queue
   */
  flushQueue(): void {
    if (this.queue.length) {
      var queue = this.queue;
      var url = UrlHelper.appendQueryString('./rest/workplace/v2/logs', {
        lang: this.language.lang,
      });
      this.queue = [];
      this.httpService.post(url, queue, <angular.IRequestShortcutConfig>{
        errorNotification: false,
      });
    }
  }
}
