/**
 * Use the IdCardService to open the Id-Card web-component.
 *
 * @author Fernando Moreira [Fernando.MA.Moreira@bmwgroup.com]
 */

export class IdCardService {
  private idCardEl: Element & { [key: string]: unknown };

  openIdCard(qNumber: string): void {
    this.idCardEl = document.querySelector('id-card');
    if (this.idCardEl) {
      this.idCardEl.userId = qNumber;
    }
  }
}
