import { IUserSetting } from './user-setting.model.interface';
import JSData from 'js-data';

/**
 * Creates the user settings store.
 *
 * @author Dragos Damian [Dragos.Damian@nttdata.com]
 *
 * @ngInject
 */

export function userSettingStoreFactory(DS: JSData.DS): JSData.DSResourceDefinition<IUserSetting> {
  'use strict';

  return DS.defineResource<IUserSetting>({
    name: 'userSetting',
    idAttribute: 'id',
    endpoint: './rest/user/settings',
    watchChanges: false,
  });
}
