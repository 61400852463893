import { INestedObject } from '../../util/tree/node';
import { IRestrictions } from '../restrictions/restrictions.model.interface';

export class StartMode {
  static WINDOW: string = 'N';
  static BROWSER_TAB: string = 'B';
  static MODAL_WINDOW: string = 'M';
  static DESKTOP: string = 'D';
  static ENDPOINT: string = 'E';
  static AUTH_FLOW: string = 'A';
  static SECURE_BROWSER: string = 'S';
}

export class ApplicationControls {
  static APP_SETTINGS: string = 'app-settings';
  static REFRESH: string = 'refresh';
}

export class ApplicationControlOptions {
  static OPEN_NEW_WINDOW: string = 'open-new-window';
  static SHOW_CONTEXT_HELP: string = 'help';
}

/**
 * @author Tobias Straller [Tobias.Straller.bp@nttdata.com]
 */
export interface IApplication extends INestedObject<IApplication> {
  /**
   * id of the application
   */
  name: string;
  id?: string;
  /**
   * translated title of the application
   */
  description: string;
  title?: string;
  iconCls: string;
  url?: string;
  helpUrl?: string;
  authFlowUrl?: string;
  parentName?: string;

  /**
   * Determines whether the app needs oauth2
   * authentication to launch
   * null - no special authentication needed
   * oauth - auth token needs to be present in the call headers
   */
  authMode?: string;
  /**
   * 0 - means no multiple windows are allowed
   * 1 - means multiple windows are allowed
   */
  multipleWindows?: number;
  /**
   * Determines whether the app needs strong
   * authentication through a third party portal
   */
  strongAuth?: number;
  /**
   * Determines whether the app is opened in a tab or in a new browser window.
   * Possible values: "B" for browser (new window) or "D" for default.
   */
  startMode?: string;
  /**
   * Url for the app favorite icon/image
   */
  iconUrl?: string;
  /**
   * Rowspan for app favorite
   */
  rowspan?: number;
  /**
   * Colspan for app favorite
   */
  colspan?: number;

  /**
   * Whether to provide context for apps
   */
  provideContext?: boolean;

  locked?: boolean;

  restrictions?: IRestrictions;

  /**
   * Comma seperated list of origins that are accepted by myWorkplace API.
   * If api origin is not set, then the origin will be extracted from url (default behavior).
   * Wildcards are possible.
   * Examples:
   * <ul>
   * <li>"https://myworkplace.bmwgroup.net"
   * <li>"https://*.bmwgroup.net,https://*bmwgroup.com"
   * </ul>
   */
  apiOrigin?: string;
}

export const APP_TASKS_MANAGER: string = 'nextone';
export const APP_CATALOG: string = 'app-catalog';
export const APP_SETTINGS: string = 'user-settings';
export const APP_TEAMS: string = 'group-manager';
export const DISMISS_BANNER = 'dismissBanner';
export const APP_MY_PERMISSIONS = '_my-permissions';
