import { Component } from '../component';
import { AuthFlowProgressDialog } from './auth-flow-progress-dialog';
import authFlowProgressDialogTemplate from './auth-flow-progress-dialog.html';
/**
 * Wrapper directive for {AuthFlowProgressDialog}
 * @returns {{}}
 * @ngInject
 */
export function authFlowProgressDialogDirective(): ng.IDirective {
  'use strict';

  return Component.createDirective(AuthFlowProgressDialog, {
    restrict: 'E',
    templateUrl: authFlowProgressDialogTemplate,
    scope: {
      close: '&',
      dismiss: '&',
      resolve: '&',
    },
  });
}
