'use strict';
import { WidgetService } from '../../widget/widget.service';
import { DashboardService } from '../../dashboard/dashboard.service';
import { FavoriteDialog } from './favorite-dialog';
import { ImageService } from '../../fileHandling/image.service';
import { DeviceService } from '../../../util/device.service';
import { WidgetDefinitions } from '../../widget/widget.data.interface';
import _ from 'lodash';

/**
 * Edit favorite dialog model.
 */
export class AddFavorite extends FavoriteDialog {
  /**
   * @constructor
   * @ngInject
   */
  constructor(
    widgetService: WidgetService,
    dashboardService: DashboardService,
    $translate: angular.translate.ITranslateService,
    imageService: ImageService,
    deviceService: DeviceService
  ) {
    super(widgetService, dashboardService, $translate, imageService, deviceService);
    this.dialogTitle = 'dialogs.favorite.title';
  }

  /**
   * Doesn't actually submit anything, but closes the dialog and send the computed new widget descriptor
   * to the caller. By caller we mean whoever opened the add favorite dialog.
   *
   * @param formCtrl
   */
  submit(formCtrl: angular.IFormController): void {
    if (formCtrl.$invalid) {
      this.validateBeforeSubmit(formCtrl);
      return;
    }

    this._widgetDescriptor.customSettings.title = this.title;
    this._widgetDescriptor.customSettings.url = this.dataUrl;

    /**
     * no image editing has happened
     */
    if (
      this.croppedCanvasData === null ||
      _.isUndefined(this.croppedCanvasData.croppedBlob) ||
      this.croppedCanvasData.croppedBlob === null
    ) {
      this.close({ $value: this._widgetDescriptor });
      return;
    }

    this.saveImgDataAndClose();
  }

  /**
   * Only show edit image part when editing favorites.
   * @type {boolean}
   */
  get showUrlField(): boolean {
    return !this.cropperVisible && this._widgetDescriptor && this._widgetDescriptor.type === WidgetDefinitions.TILE;
  }
}
