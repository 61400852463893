import { BaseDialog } from './base-dialog';
import _ from 'lodash';

/**
 * A base model class for all modal components the should contain a form.
 */
export abstract class FormDialog extends BaseDialog {
  protected tooltipTranslations: { [key: string]: { [subKey: string]: string } };

  /**
   * @ngInject
   */
  constructor() {
    super();
    this.tooltipTranslations = {};
  }

  /**
   * Called when a key is pressed while the add favorite form is active.
   * Calls submit function if ENTER has been pressed.
   */
  protected handleKeyUp(event: KeyboardEvent, formCtrl: angular.IFormController): void {
    if (event.keyCode === 13) {
      this.submit(formCtrl);
    }
  }

  /**
   * Force re-validation of form fields.
   * If ctrl.setTouched is not invoked, fields will not be marked as invalid, when the form has not been touched yet.
   * However, we need the behavior.
   */
  protected validateBeforeSubmit(formCtrl: angular.IFormController): void {
    _.keys(formCtrl.$error).forEach((key: string) => {
      formCtrl.$error[key].forEach((ctrl: angular.INgModelController) => {
        ctrl.$setTouched();
        ctrl.$validate();
      });
    });
  }

  abstract submit(formCtrl: angular.IFormController): void;
}
