import { Component } from '../component';
import { AddTaskMobile } from './add-task-mobile';
import addTaskMobileTemplate from './add-task-mobile.html';
/**
 * Directive wrapper for add task on mobile.
 * @returns {ng.IDirective}
 * @ngInject
 */
export function addTaskMobileDirective(): ng.IDirective {
  'use strict';
  return Component.createDirective(AddTaskMobile, {
    restrict: 'E',
    templateUrl: addTaskMobileTemplate,
    scope: {
      close: '&',
      dismiss: '&',
      resolve: '&',
    },
  });
}
