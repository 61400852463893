import { IOData, IWidgetData } from './widget.data.interface';
import { WorkplaceContextService } from '../workplace/workplace.context.service';
import { UserService } from '../user/user.service';
import { AppsService } from '../apps/apps.service';
import { DeviceService } from '../../util/device.service';
import { IWidgetDescriptor } from './widget.descriptor.model';
import { IHttpServerError } from '../errors/http.server.error.interface';
import { ActionLogService } from '../actionLog/action-log.service';
import { ActionConstants } from '../actionLog/action-constants';
import { DashboardService } from '../dashboard/dashboard.service';
import { IDashboard, IDashboardCategory } from '../dashboard/dashboard.model.interface';
import { ILanguage } from '../../util/language.model.interface';
import { IApplication } from '../apps/application.model.interface';
import JSData from 'js-data';
import { IHttpPromiseCallbackArg } from 'angular';
import _ from 'lodash';

import { NotificationService } from '../notification/notification.service';

/**
 * Use the widget service to retrieve widget definitions or to load widget data.
 *
 * @author Tobias Straller [Tobias.Straller.bp@nttdata.com]
 */
export class WidgetService {
  static CHANNEL: string = 'WidgetServiceChannel';
  static DYNAMIC_TILE_CHANNEL: string = 'WidgetServiceDynamicTileChannel';
  static WIDGET_UPDATED: string = 'WidgetServiceWidgetUpdated';
  static DYNAMIC_TILE_UPDATE: string = 'WidgetServiceDynamicTileUpdate';
  static CLOSE_MAXIMIZED_WIDGET: string = 'CloseMaximizedWidget';

  channel: IChannelDefinition<IWidgetServiceMessage>;
  dynamicTileChannel: IChannelDefinition<IWidgetServiceDynamicTileUpdateMessage>;
  widgets: IWidgetDescriptor<any>[];

  private _widgetDefinitionStore: JSData.DSResourceDefinition<IWidgetDescriptor<any>>;
  private _dashboardStore: JSData.DSResourceDefinition<IDashboard>;
  private _qService: ng.IQService;
  private _httpService: ng.IHttpService;
  private _workplaceContextService: WorkplaceContextService;
  private _userService: UserService;
  private _appsService: AppsService;
  private _deviceService: DeviceService;
  private _postal: IPostal;
  private _actionLogService: ActionLogService;
  private _dashboardService: DashboardService;
  private _language: ILanguage;
  private _timeoutService: ng.ITimeoutService;
  private _findWidgetsPromise: any;
  private _notificationService: NotificationService;

  /**
   * @ngInject
   */
  constructor(
    widgetDefinitionStore: JSData.DSResourceDefinition<IWidgetDescriptor<any>>,
    dashboardStore: JSData.DSResourceDefinition<IDashboard>,
    $q: ng.IQService,
    $http: ng.IHttpService,
    workplaceContextService: WorkplaceContextService,
    userService: UserService,
    appsService: AppsService,
    deviceService: DeviceService,
    postal: IPostal,
    debounce: any,
    actionLogService: ActionLogService,
    dashboardService: DashboardService,
    language: ILanguage,
    $timeout: ng.ITimeoutService,
    notificationService: NotificationService
  ) {
    this._widgetDefinitionStore = widgetDefinitionStore;
    this._dashboardStore = dashboardStore;
    this._qService = $q;
    this._httpService = $http;
    this._workplaceContextService = workplaceContextService;
    this._appsService = appsService;
    this._userService = userService;
    this._deviceService = deviceService;
    this._postal = postal;
    this._actionLogService = actionLogService;
    this._dashboardService = dashboardService;
    this.channel = this._postal.channel(WidgetService.CHANNEL);
    this._notificationService = notificationService;
    this.dynamicTileChannel = this._postal.channel(WidgetService.DYNAMIC_TILE_CHANNEL);
    this.widgets = [];
    this._language = language;
    this._timeoutService = $timeout;
    this.publishDynamicTileUpdate = debounce(this.publishDynamicTileUpdate.bind(this), 500, true);
  }

  /**
   * Create link on dashboard
   * @param data - the widget to be added
   * @param dashboardId - the dashboard on which we add the widget
   * @param category - category id (optional)
   */
  createFavoriteLink(data: IWidgetDescriptor<any>, dashboardId: string, category?: string): ng.IPromise<IDashboard> {
    const deferred = this._qService.defer<IDashboard>();
    /** get dashboard from store */
    const dashboard = this._dashboardStore.get(dashboardId);
    if (!dashboard || !dashboard.categories) {
      /** dashboard is not open, we only do ordering */
      console.warn('dashboard has no categories');
      return;
    }
    /** build category id, if we have category use that, else take last category from dashboard */
    const categoryId = category ? category : dashboard.categories[dashboard.categories.length - 1].id;
    /** create widget */
    this.createWidget(data, dashboardId, categoryId)
      .then((widget: IWidgetDescriptor<any>) => {
        if (widget && widget.id) {
          const storedDashboard = this._dashboardStore.get(dashboardId);
          if (storedDashboard) {
            storedDashboard.categories = storedDashboard.categories.map((categ: IDashboardCategory) => {
              if (categ.id === categoryId) {
                categ.widgets.push(widget);
              }
              return categ;
            });
          }
          /** publish that we added a widget so the UI dashboard updates itself */
          this._dashboardService.channel.publish(DashboardService.TOPIC_WIDGET_ADDED, {
            dashboard: dashboard,
            widgetId: widget.id,
            dropzoneForceEnabled: false,
            categoryId: categoryId,
          });

          /** Notify the user that we added a widget */
          const title = widget.settings && widget.settings.title ? widget.settings.title : widget.title;

          if (title) {
            this._notificationService.showSuccess('dialogs.favorite.add.success', {
              name: title,
              dashboard: dashboard.description,
            });
          }

          const resultDashboard = _.clone(storedDashboard);
          deferred.resolve(resultDashboard);
        } else {
          deferred.reject();
        }
      })
      .catch((reason: IHttpServerError) => deferred.reject(reason));

    return deferred.promise;
  }

  /** Add a widget to a dashboard. */
  createWidget(
    data: IWidgetDescriptor<any>,
    dashboardId: string,
    categoryId: string
  ): ng.IPromise<IWidgetDescriptor<any>> {
    const deferred = this._qService.defer<IWidgetDescriptor<any>>();
    this._actionLogService.logAction({
      category: ActionConstants.CATEGORY_DASHBOARD,
      action: `${ActionConstants.ACTION_WIDGET_CREATE}-${data.widgetDefinitionId}`,
      actionInfo: JSON.stringify(data.customSettings),
    });
    /** create widget in BE */
    const payloadData = this.stringifySettingsProperties(data);
    this._httpService
      .post(
        `./rest/v2/dashboards/${dashboardId}/categories/${categoryId}/widgets`,
        { widget: payloadData },
        {
          params: { lang: this._language.lang },
        }
      )
      .then((response: IHttpPromiseCallbackArg<IWidgetDescriptor<any>>) => {
        if (response && response.data) {
          deferred.resolve(this.destringifySettingsProperties(response.data));
        }
      })
      .catch((err: IHttpServerError) => deferred.reject(err));
    return deferred.promise;
  }

  /**
   * Get a widget from the dashboardStore, by searching for the widget inside categories
   */
  getWidget(id: string): IWidgetDescriptor<any> {
    const storedDashboards = this._dashboardStore.getAll().filter((d: IDashboard) => !d.initialState);
    if (!storedDashboards || storedDashboards.length <= 0) {
      return null;
    }

    let found = null;
    storedDashboards.forEach((dash: IDashboard) => {
      if (!found) {
        dash.categories.forEach((categ: IDashboardCategory) => {
          if (!found) {
            const widget = categ.widgets.find((wid: IWidgetDescriptor<any>) => wid.id === id);
            if (widget) {
              found = widget;
            }
          }
        });
      }
    });
    return found;
  }

  /**
   * Store widget into the dashboard store
   * Update happens by reference (if this ever changes, we need to actually inject into store after calculus)
   * @param data - the widget
   */

  storeWidget(data: IWidgetDescriptor<any>): void {
    const storedDashboards = this._dashboardStore.getAll().filter((d: IDashboard) => !d.initialState);
    if (!data || !storedDashboards || storedDashboards.length <= 0) {
      return null;
    }

    /** Updated store by reference */
    storedDashboards.forEach((dash: IDashboard) => {
      dash.categories.forEach((categ: IDashboardCategory) => {
        categ.widgets = [...categ.widgets].map((wid: IWidgetDescriptor<any>) => {
          if (wid.id === data.id) {
            wid = data;
          }
          return wid;
        });
      });
    });
  }

  /**
   * Order widgets
   * @param widgetIds
   * @param dashboardId
   * @param categoryId
   * @param sourceDashboardId
   * @param widget
   * @param deleteFromOldCategory
   */
  orderWidgets(
    widgetIds: string[],
    dashboardId: string,
    categoryId: string,
    sourceDashboardId?: string,
    widget?: IWidgetDescriptor<any>,
    deleteFromOldCategory: string = null
  ): ng.IPromise<void> {
    const baseEndpoint = `./rest/v2/dashboards/${dashboardId}/categories/${categoryId}/widgets`;
    const endpoint = sourceDashboardId ? `${baseEndpoint}?sourceDashboardId=${sourceDashboardId}` : baseEndpoint;
    return this._httpService
      .put(endpoint, widgetIds)
      .then((response: IHttpPromiseCallbackArg<any>) => {
        if (response && response.data) {
          /** get destination id - if modified dashboard was of system type, we will have a clone with a different id */
          const destinationId = response.data.toString();
          /** delete widget from old category - we need this sent to the BE, since FE is already updating it */
          if (deleteFromOldCategory && widget) {
            return this.deleteWidget(destinationId, deleteFromOldCategory, widget.id, true).then(
              () => destinationId,
              () => {
                this._notificationService.showError('delete.from.old.category.error');
                return destinationId;
              }
            );
          }
          return destinationId;
        }
      })
      .then(destinationId => {
        if (destinationId) {
          const storedDashboard = this._dashboardStore.get(dashboardId);
          if (!storedDashboard || !storedDashboard.categories) {
            return;
          }

          if (!widget) {
            /**
             * dashboard is open (we have categories), but we don't know the widget
             * we have to refresh the dashboard
             */
            this._dashboardService.refreshDashboard(dashboardId).then((dashboard: IDashboard) => {
              /**
               * publish that we added a widget so the UI dashboard updates itself
               * widgetId should always be last in this case, since widget is unknown, we can't really reorder
               * !!! if this ever changes we need to pass the actual widgetId
               */
              this._dashboardService.channel.publish(DashboardService.TOPIC_WIDGET_ADDED, {
                dashboard: dashboard,
                widgetId: _.last(widgetIds),
                dropzoneForceEnabled: false,
                categoryId,
              });
            });
            return;
          }

          storedDashboard.categories.map((categ: IDashboardCategory) => {
            if (categ.id === categoryId) {
              categ.widgets = categ.widgets.map((w: IWidgetDescriptor<any>) => {
                if (_.isString(w) && w === widget.id) {
                  w = widget;
                }
                return w;
              });
            }
            return categ;
          });
        }
      });
  }

  /**
   * Request data for a widget from the data service configured in the widget definition.
   * Context parameters specified in the widget definition are added to the request.
   * If a server error is detected, it is forwarded to the component rendering for handling.
   */
  getWidgetData(definition: IWidgetDescriptor<any>, context?: any): ng.IPromise<IWidgetData> {
    const deferred = this._qService.defer<IWidgetData>();
    if (!definition.dataUrl) {
      deferred.reject();
    } else {
      this._httpService
        .get(definition.dataUrl, <angular.IRequestShortcutConfig>{
          params: _.pick(context, definition.dataUrlContextParams),
          errorNotification: false,
          noCache: true,
        })
        .then((response: ng.IHttpPromiseCallbackArg<IWidgetData | IOData>) => {
          if ((<IOData>response.data).d) {
            deferred.resolve(this.transformODataToWidgetData(<IOData>response.data));
          } else {
            deferred.resolve(<IWidgetData>response.data);
          }
        })
        .catch((reason: IHttpServerError) => deferred.reject(reason));
    }
    return deferred.promise;
  }

  /**
   * Removes the widget from the store. Calls the backend to remove the widget instance from the dashboard.
   */
  deleteWidget(
    dashboardId: string,
    categoryId: string,
    widgetId: string,
    forceDelete: boolean = false
  ): ng.IPromise<boolean> {
    const defered = this._qService.defer<boolean>();
    /** get dashboard from store and search for the widget to delete */
    const dashboard: IDashboard = this._dashboardStore.get(dashboardId);
    let found = false;
    dashboard.categories.map((categ: IDashboardCategory) => {
      if (categ.id === categoryId) {
        if (categ.widgets.find((w: IWidgetDescriptor<any>) => w.id === widgetId)) {
          found = true;
        }
      }
    });

    if (found || forceDelete) {
      /** call BE delete and update the store */
      this._httpService
        .delete(`./rest/v2/dashboards/${dashboardId}/categories/${categoryId}/widgets/${widgetId}`)
        .then((response: IHttpPromiseCallbackArg<any>) => {
          if (response && response.data) {
            const storedDashboard = this._dashboardStore.get(dashboardId);
            if (storedDashboard) {
              storedDashboard.categories.map((categ: IDashboardCategory) => {
                if (categ.id === categoryId) {
                  categ.widgets = categ.widgets.filter((w: IWidgetDescriptor<any>) => w.id !== widgetId);
                }
                return categ;
              });
              /** updateItems = true */
              defered.resolve(true);
            }
          } else {
            defered.reject();
          }
        })
        .catch(() => defered.reject());
    } else {
      /** if widget wasn't found, reject */
      defered.reject();
    }
    return defered.promise;
  }

  /**
   * Transform OData response to widget data
   * @param {IOData} odata
   */
  transformODataToWidgetData(odata: IOData): IWidgetData {
    return {
      data: odata.d && odata.d.results ? odata.d.results : [],
    };
  }

  /**
   *
   * @param app
   */
  publishDynamicTileUpdate(app: IApplication): void {
    this.dynamicTileChannel.publish(WidgetService.DYNAMIC_TILE_UPDATE, { app });
  }

  private stringifySettingsProperties(data: IWidgetDescriptor<any>): IWidgetDescriptor<any> {
    if (!data) {
      return null;
    }
    let newData = { ...data };
    newData.customSettings = _.isString(data.customSettings)
      ? data.customSettings
      : JSON.stringify(data.customSettings);
    newData.defaultSettings = _.isString(data.defaultSettings)
      ? data.defaultSettings
      : JSON.stringify(data.defaultSettings);
    newData.settings = _.isString(data.settings) ? data.settings : JSON.stringify(data.settings);
    return newData;
  }

  private destringifySettingsProperties(data: IWidgetDescriptor<any>): IWidgetDescriptor<any> {
    if (!data) {
      return null;
    }
    let newData = { ...data };

    const custSet =
      newData.customSettings && _.isString(newData.customSettings)
        ? JSON.parse(newData.customSettings)
        : newData.customSettings;
    const defSet =
      newData.defaultSettings && _.isString(newData.defaultSettings)
        ? JSON.parse(newData.defaultSettings)
        : newData.defaultSettings;

    newData.settings = _.merge({}, defSet, custSet);
    newData.customSettings = custSet;
    newData.defaultSettings = defSet;
    return newData;
  }
}

/**
 * Message object, used by the widget service's event publishing channel
 */
export interface IWidgetServiceMessage {
  widget: IWidgetDescriptor<any>;
  dashboardId: string;
}

/**
 * Message to update dynamic tiles
 */
export interface IWidgetServiceDynamicTileUpdateMessage {
  app: IApplication;
}
