/**
 * AuthService provides functionality
 */
import { WorkplaceContextService } from '../workplace/workplace.context.service';
import { IWorkplaceProperty } from '../workplace/workplace-property.interface';
import { ActionLogService } from '../actionLog/action-log.service';
import { ActionConstants } from '../actionLog/action-constants';
import { IAppBehavior } from '../appBehavior/app-behavior.model.interface';
import { WorkplaceApiService } from '../workplace/workplace.api.service';
import { getEndSessionUrl } from '../app.auth';

export class AuthService {
  private _hasWenCookie: Promise<void>;
  private _interval: ng.IPromise<any>;

  channel: IChannelDefinition<void>;

  /**
   * @ngInject
   */
  constructor(
    private $http: ng.IHttpService,
    private $log: ng.ILogService,
    private workplaceContextService: WorkplaceContextService,
    private $interval: ng.IIntervalService,
    private $q: ng.IQService,
    private actionLogService: ActionLogService,
    private appBehavior: IAppBehavior,
    private $window: ng.IWindowService,
    private workplaceApiService: WorkplaceApiService
  ) {}

  async startAuthSessionCheck(): Promise<void> {
    let property: IWorkplaceProperty = await this.workplaceContextService.getProperty(
      'web.eam.next.client.checksession.interval'
    );
    this._interval = this.$interval(this.checkAuthSession.bind(this), +property.value);
    this.checkAuthSession();
  }

  stopAuthSessionCheck(): void {
    if (this._interval) {
      this.$interval.cancel(this._interval);
    }
  }

  /**
   * In case the session is expired, the promise will NEVER resolve and calling functions will wait forever!
   * Instead we start session expired handling.
   */
  async checkAuthSession(): Promise<boolean> {
    try {
      await this.hasWenCookie();
      return new Promise<boolean>((resolve: Function) => {
        const config = <angular.IRequestShortcutConfig>{
          errorNotification: false,
          noCache: true,
        };
        this.$http.get('./rest/validate-session', config).then(
          (response: ng.IHttpPromiseCallbackArg<IAuthSessionState>) => {
            if (response.data.valid) {
              return resolve(true);
            }
            // invalid user session
            this.handleInvalidSession();
          },
          (response: ng.IHttpPromiseCallbackArg<any>) => {
            // server error while calling check session endpoint, we assume session is destroyed
            this.handleInvalidSession();
          }
        );
      });
    } catch (e) {
      this.handleInvalidSession();
    }
  }

  checkWenCookie(): ng.IPromise<void> {
    let deferred = this.$q.defer<void>();
    this.hasWenCookie()
      .catch(() => {
        // this.handleInvalidSession();
      })
      .then(() => {
        deferred.resolve();
      });
    return deferred.promise;
  }

  /**
   * Cache information whether we have retrieved a WEN cookie
   */
  async hasWenCookie(): Promise<void> {
    // cache the promise
    if (!this._hasWenCookie) {
      this._hasWenCookie = this.getWenCookie();
    }
    return this._hasWenCookie;
  }

  private handleInvalidSession(): void {
    this.workplaceContextService.handleSessionExpired();
    this.stopAuthSessionCheck();
  }

  /**
   * Implements a workaround to transform a siteminder cookie to a WEN cookie.
   * Can be toggled by workplace properties.
   */
  private async getWenCookie(): Promise<void> {
    let property: IWorkplaceProperty = await this.workplaceContextService.getProperty('web.eam.next.client.getcookie.enabled');
    if (property.value === 'true') {
      const config = <angular.IRequestShortcutConfig>{
        errorNotification: false,
        noCache: true,
        transformResponse: [(data: any) => data],
      };
      return new Promise<void>((resolve: Function, reject: Function) => {
        this.$http.get('./rest/wenCookie', config).then(
          (response: ng.IHttpPromiseCallbackArg<{}>) => {
            resolve();
            this.$log.info('AuthService -> getWenCookie: successfully requested WEN cookie');
          },
          () => {
            this.$log.warn('AuthService -> getWenCookie: could not request WEN cookie.');
            reject();
          }
        );
      });
    }
    return Promise.resolve();
  }

  async logout(sessionExpired: boolean = false) {
    if (sessionExpired) {
      try {
        await this.$http.get(this.$window.location.href);
      } catch (e) {
        console.error(e);
      }
    } else {
      this.actionLogService.logAction({
        category: ActionConstants.CATEGORY_ROLES,
        action: ActionConstants.ACTION_ROLE_MENU,
        actionInfo: 'logout',
      });
    }
    this.appBehavior.isLogout = true;
    await this.workplaceApiService.waitForLogout();

    const response = await getEndSessionUrl();

    this.$window.location.href = response.logoutURL;
  }
}

export interface IAuthSessionState {
  valid: boolean;
  sessionUid?: string;
  uid: string;
  realm: string;
}
