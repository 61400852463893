'use strict';
import { Component } from '../components/component';
import { IApplication } from './application.model.interface';
import { NotificationService } from '../notification/notification.service';
import { RestrictionsService } from '../restrictions/restrictions.service';
import { ITabServiceChannelTabMessage, TabService } from '../tab/tab.service';
import { AppsService } from './apps.service';

export class LockedApp extends Component {
  app: IApplication;
  message: string;
  restrictionText: string;
  lockedText: string;

  private notificationService: NotificationService;
  private restrictionsService: RestrictionsService;
  private translateService: angular.translate.ITranslateService;
  private subscriptions: ISubscriptionDefinition<any>[];
  private tabService: TabService;
  private appsService: AppsService;

  /**
   * @ngInject
   */
  constructor(
    notificationService: NotificationService,
    restrictionsService: RestrictionsService,
    $translate: angular.translate.ITranslateService,
    appsService: AppsService,
    tabService: TabService
  ) {
    super();
    this.notificationService = notificationService;
    this.restrictionsService = restrictionsService;
    this.translateService = $translate;
    this.tabService = tabService;
    this.appsService = appsService;
  }

  $onInit() {
    this.translateService('apps.open.not.available').then((t: string) => {
      this.lockedText = t;
      if (this.app.locked && this.restrictionText) {
        this.notificationService.showError(this.restrictionText);
        return;
      }
      if (this.app.restrictions) {
        this.restrictionsService.getRestrictionText(this.app.restrictions).then((t: string) => {
          this.restrictionText = t;
          this.notificationService.showError(this.restrictionText);
        });
      }
    });

    this.subscriptions = [];
    this.subscriptions.push(
      this.tabService.channel.subscribe(TabService.TOPIC_TAB_CLOSE, (message: ITabServiceChannelTabMessage) => {
        if (message.tabId === this.app.name) {
          this.appsService.removeFrame(this.app.name, true);
          this.tabService.removeTab(this.app.name);
        }
      })
    );
  }

  destroy(): void {
    this.appsService = null;
    this.translateService = null;
    this.notificationService = null;
    this.restrictionsService = null;
    this.tabService = null;
    this.subscriptions.forEach((s: ISubscriptionDefinition<any>) => s.unsubscribe());
  }
}
