import { ICatalogApplication } from './catalog-application.model.interface';
import { ICatalogApplicationSearchConfig, ICatalogApplicationSearchResult } from './catalog.model.interface';
import { ILanguage } from '../../util/language.model.interface';
import JSData from 'js-data';
import { IHttpPromiseCallbackArg } from 'angular';
import { DeviceService } from '../../util/device.service';
import _ from 'lodash';

/**
 * Service for managing catalog apps.
 */
export class CatalogService {
  catalogApps: ICatalogApplication[];
  findApps: (searchConfig: ICatalogApplicationSearchConfig, callback: (apps: ICatalogApplication[]) => void) => void;

  private _catalogStore: JSData.DSResourceDefinition<ICatalogApplication>;
  private _httpService: ng.IHttpService;
  private _language: ILanguage;
  private _deviceService: DeviceService;

  /**
   * @ngInject
   */
  constructor(
    catalogStore: JSData.DSResourceDefinition<ICatalogApplication>,
    debounce: any,
    $http: ng.IHttpService,
    language: ILanguage,
    deviceService: DeviceService
  ) {
    this._catalogStore = catalogStore;
    this._httpService = $http;
    this._language = language;
    this._deviceService = deviceService;
    this.findApps = debounce(this._findApps, 500, false, true);
  }

  _findApps(searchConfig: ICatalogApplicationSearchConfig, callback: (apps: ICatalogApplication[]) => void): void {
    this._httpService({
      url: './rest/store/search/',
      method: 'post',
      params: {
        lang: this._language.lang,
        maxSize: searchConfig.maxSize || 6,
        deviceType: this._deviceService.device,
      },
      data: [
        {
          type: 'text',
          value: searchConfig.searchTerm,
        },
      ],
    }).then((promise: IHttpPromiseCallbackArg<ICatalogApplicationSearchResult[]>) => {
      const results: ICatalogApplicationSearchResult[] = promise.data;
      let apps: ICatalogApplication[] = [];
      let layouts: ICatalogApplication[] = [];
      results.forEach((result: ICatalogApplicationSearchResult) => {
        apps = [...apps, ...result.apps];
        layouts = [...layouts, ...result.layouts];
      });
      this.catalogApps = [
        ...apps.filter((app: ICatalogApplication) => !this._isHiddenApp(app)),
        ...layouts.filter((app: ICatalogApplication) => !this._isHiddenApp(app)),
      ];
      callback(this.catalogApps);
    });
  }

  _isHiddenApp(app: ICatalogApplication): boolean {
    return _.startsWith(app.id, 'direct_') || _.startsWith(app.id, 'hidden_') || _.startsWith(app.id, '_');
  }
}
