import { ILanguage } from './language.model.interface';

export class LanguageProvider {
  public locale: string;

  /**
   * @ngInject
   */
  constructor() {
    // nothing here
  }

  /**
   * @ngInject
   */
  $get(): ILanguage {
    return {
      locale: this.locale,
      lang: this.locale.split('-')[0],
    };
  }
}
