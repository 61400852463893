'use strict';
import { BaseDialog } from './base-dialog';
import { IDashboard, IDashboardCategory } from '../../dashboard/dashboard.model.interface';
import { DashboardService } from '../../dashboard/dashboard.service';
import { AppsService } from '../../apps/apps.service';
import { IApplication } from '../../apps/application.model.interface';
import { NotificationService } from '../../notification/notification.service';
import { RestrictionsService } from '../../restrictions/restrictions.service';
import { descriptionSorter } from '../../../util/description-sorter';
import { IWidgetDescriptor } from '../../widget/widget.descriptor.model';
import { WidgetDefinitions } from '../../widget/widget.data.interface';
import { ICroppedCanvasData } from '../../fileHandling/image.model.interface';
import { CROP_ASPECT_RATIO_2 } from '../cropper/cropper';
import { ImageService } from '../../fileHandling/image.service';
import { WidgetService } from '../../widget/widget.service';
import _ from 'lodash';
import { TabService } from '../../tab/tab.service';
import { ITab } from '../../tab/tab';
import { ITabContent } from '../../tab/tab.content.interface';

interface SelectedDashboard {
  id: string | number;
}

interface DashboardTabContent {
  dashboardName: number;
  containsDashboard: boolean;
}

export class SaveFavoriteDialog extends BaseDialog {
  config: any;
  dashboards: IDashboard[] = [];

  /**
   * Widget's default icon, as given by the Widget.ts getDefaultIcon method.
   */
  defaultIcon: string;
  /**
   * Cropped image data model
   */
  croppedCanvasData: ICroppedCanvasData = null;
  /**
   * Original image's base 64 encoded source.
   */
  image: string;

  loadingX: boolean = false;
  maxLineLimitReached: boolean = false;

  title: string = '';
  restrictedTextFocused: boolean = false;
  selectedDashboard: IDashboard = null;
  selectedCategories: IDashboardCategory[] = [];
  selectedCategory: IDashboardCategory = null;

  preSelectedDashboard: SelectedDashboard;

  protected _widgetDescriptor: IWidgetDescriptor<any>;
  protected _imageService: ImageService;

  private dashboardsService: DashboardService;
  private widgetService: WidgetService;
  private appsService: AppsService;
  private notificationService: NotificationService;
  private restrictionsService: RestrictionsService;
  private _scope: angular.IScope;

  /**
   * Add shared favorite to own dashboard component.
   * @ngInject
   */
  constructor(
    dashboardService: DashboardService,
    appsService: AppsService,
    notificationService: NotificationService,
    restrictionsService: RestrictionsService,
    imageService: ImageService,
    widgetService: WidgetService,
    private tabService: TabService
  ) {
    super();
    this.dashboardsService = dashboardService;
    this.widgetService = widgetService;
    this.appsService = appsService;
    this.notificationService = notificationService;
    this.restrictionsService = restrictionsService;
    this._imageService = imageService;
  }

  private getSelectedDashboard(): SelectedDashboard {
    const dashboardContent = this.tabService.getTabManagerDefault().tabs.find((tab: ITab<ITabContent>) => tab.active)
      ?.content as unknown as DashboardTabContent;
    return dashboardContent.containsDashboard ? { id: dashboardContent.dashboardName } : null;
  }

  $onInit() {
    this.dashboardsService.getDashboards().then((dashboards: IDashboard[]) => {
      const userDashboards = dashboards.filter((dash: IDashboard) => dash.userCanEdit);
      this.dashboards = userDashboards.sort(descriptionSorter);
      this.selectedDashboard = this.dashboards[0];
      this.preSelectedDashboard = this.getSelectedDashboard() || { id: this.dashboards[0].name };
    });

    this.config = this.resolve().config;

    if (this.config) {
      this._widgetDescriptor = this.config.id
        ? _.cloneDeep(this.widgetService.getWidget(this.config.id))
        : this.config.widget;
      if (!this._widgetDescriptor) {
        this._widgetDescriptor = Object.assign(
          {},
          {
            type: WidgetDefinitions.TILE,
            widgetDefinitionId: WidgetDefinitions.TILE,
            rowspan: 1,
            colspan: 1,
            newColspan: 2,
            newRowspan: 2,
            hidden: false,
            customSettings: {},
          }
        );
      }
      this.defaultIcon = this.config.defaultIcon || 'placeholder-link';
    }
    this.title = this.labelTranslationData.name;
  }

  onRenderComponent(el: JQuery, scope: angular.IScope): void {
    this._scope = scope;
  }

  get labelTranslationData(): any {
    return {
      name: this.config.widget
        ? this.config.widget.settings && this.config.widget.settings.title
          ? this.config.widget.settings.title
          : this.config.widget.title
          ? this.config.widget.title
          : this.config.title
        : this.config.title,
    };
  }

  get widgetDescriptor(): IWidgetDescriptor<any> {
    return this._widgetDescriptor;
  }

  ok(): void {
    /** Payload Data */
    this._widgetDescriptor.customSettings.title =
      this.title && this.title.trim() !== '' ? this.title : this.labelTranslationData.name;
    let payloadData = {
      dashboard: this.selectedDashboard,
      category: this.selectedCategory,
      title: this.title && this.title.trim() !== '' ? this.title : this.labelTranslationData.name,
      widget: this._widgetDescriptor,
    };
    /** Check if app or not */
    if (this.config.widget && this.config.widget.customSettings && this.config.widget.customSettings.app) {
      /** if widget is an app */
      this.appsService
        .getApp(this.config.widget.customSettings.app)
        // the user doesn't have access to the app. show an error message.
        .catch(() =>
          this.appsService
            .fetchSingleApp(this.config.widget.customSettings.app)
            .then((app: IApplication) => {
              if (app.locked || !this.restrictionsService.isEmpty(app.restrictions)) {
                this.notificationService.showError('notification.sharing.search.favorite.cannot.be.accepted', {
                  description: app.description,
                });
              }
            })
            .catch((reason: any) => console.log('reason', reason))
            .finally(() => this.dismiss())
        )
        .finally(() => {
          if (
            this.croppedCanvasData === null ||
            _.isUndefined(this.croppedCanvasData.croppedBlob) ||
            this.croppedCanvasData.croppedBlob === null
          ) {
            /** no image editing has happened */
            this.close({ $value: payloadData });
          } else {
            /** image was edited */
            this.saveImgDataAndClose(payloadData);
          }
        });
      return;
    }
    /** if widget is not an app */
    if (
      this.croppedCanvasData === null ||
      _.isUndefined(this.croppedCanvasData.croppedBlob) ||
      this.croppedCanvasData.croppedBlob === null
    ) {
      /** no image editing has happened */
      this.close({ $value: payloadData });
    } else {
      /** image was edited */
      this.saveImgDataAndClose(payloadData);
    }
  }

  /** SELECT DASHBOARD */
  onDashboardSelection(item: IDashboard): void {
    if (!item || !item.id) {
      return;
    }
    this.loadingX = true;
    this.dashboardsService.getDashboard(item.id, true).then((dashboard: IDashboard) => {
      if (!dashboard && !dashboard.categories) {
        this.notificationService.showError('dialogs.saveFavorite.getDashboard.error');
        return;
      }
      this.selectedDashboard = _.cloneDeep(dashboard);
      this.selectedCategories = _.cloneDeep(dashboard.categories);
      /** If categories, select the first one */
      if (
        this.selectedCategories &&
        this.selectedCategories.length > 0 &&
        (!this.selectedCategory ||
          (this.selectedCategory &&
            this.selectedCategories.filter((c: IDashboardCategory) => c.id === this.selectedCategory.id).length === 0))
      ) {
        this.selectedCategory = _.cloneDeep(this.selectedCategories[0]);
      }
      this.loadingX = false;
    });
  }

  /** SELECT CATEGORY */
  onCategorySelection(item: IDashboardCategory): void {
    if (!item || !item.id) {
      return;
    }
    this.selectedCategory = item;
  }

  destroy(el: JQuery): void {
    this.dashboardsService = null;
    this.appsService = null;
    this.notificationService = null;
    this.restrictionsService = null;
  }

  /**
   * Called when a the user considers his cropping to be final
   */
  acceptCrop(): void {
    this.image = null;
  }

  /**
   * Called when a the user changes his mind and quits selecting a new wiget image background
   */
  cancelCrop(): void {
    this.croppedCanvasData = null;
    this.image = null;
  }

  /**
   * Resets the cropped widget background image to the default one.
   */
  resetToDefaultImage(): void {
    if (this._widgetDescriptor && this._widgetDescriptor.settings && this._widgetDescriptor.settings.imgId) {
      delete this._widgetDescriptor.settings.imgId;
      delete this._widgetDescriptor.customSettings.imgId;
      this._widgetDescriptor.rowspan = 1;
      this._widgetDescriptor.colspan = 1;
    }

    this.croppedCanvasData = null;
    this.image = null;
  }

  /**
   * Only show edit image part when editing favorites.
   * @type {boolean}
   */
  get showEditWidgetBackgroundImage(): boolean {
    return this.config.permissions && this.config.permissions.editImage;
  }

  /**
   * Cropper is visible when there's a source for the cropperImage tag.
   *
   * @returns {boolean}
   */
  get cropperVisible(): boolean {
    return !_.isUndefined(this.image) && this.image !== null;
  }

  /**
   * Wheter the reset to default image button should be visible or not.
   * That is the case when either a crop has been performed or there's a saved
   * image in the db for the widget.
   */
  get resetToDefaultVisible(): boolean {
    return (
      (this.croppedCanvasData && this.croppedCanvasData.data) ||
      (this._widgetDescriptor.settings && this._widgetDescriptor.settings.imgId)
    );
  }

  /**
   * Saves the image first, through the image service, than updates the widget descriptor with
   * the new image's id and closes the dialog.
   */
  protected saveImgDataAndClose(payload: any): void {
    /**
     * Delete the old image first, if present.
     * We will get orphans in the DB otherwise
     */
    if (this._widgetDescriptor.settings && this._widgetDescriptor.settings.imgId) {
      this._imageService.deleteImage(this._widgetDescriptor.settings.imgId).then(() => {
        this._widgetDescriptor.settings.imgId = null;
        this.saveImgDataAndClose(payload);
      });
      return;
    }
    /**
     * save the newly selected image first and close the dialog with an updated descriptor instance
     */
    this._imageService.saveImage(this.croppedCanvasData.croppedBlob).then((imgId: string) => {
      this._widgetDescriptor.customSettings.imgId = '' + imgId;
      this._widgetDescriptor.colspan = this.croppedCanvasData.aspectRatio === CROP_ASPECT_RATIO_2 ? 2 : 1;

      payload.imageId = '' + imgId;
      payload.widget = this._widgetDescriptor;
      this.close({ $value: payload });
    });
  }

  onRestrictedTextFocus(isFocused: boolean): void {
    this.restrictedTextFocused = isFocused;
  }
}
