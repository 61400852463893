import { IApplication } from './application.model.interface';
import JSData from 'js-data';
/**
 * @author Tobias Straller [Tobias.Straller.bp@nttdata.com]
 *
 * @ngInject
 */
export function appsStoreFactory(DS: JSData.DS): JSData.DSResourceDefinition<IApplication> {
  'use strict';

  return DS.defineResource<IApplication>({
    name: 'apps',
    idAttribute: 'name',
    endpoint: './rest/apps',
    watchChanges: false,
  });
}
