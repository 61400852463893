import { getToken } from './app.auth';
import { NotificationService } from './notification/notification.service';
import { WorkplaceContextService } from './workplace/workplace.context.service';
import _ from 'lodash';

/**
 * Interceptor logic for $http service.
 * Implements application wide custom logic for error handling.
 *
 * As default behavior, the interceptor will show an error notification.
 * The behavior can be overridden per request, by passing the config property errorNotification:
 *
 * <code>
 *     $http.get(url, {errorNotification: { '400': true, '500': false } | false/true, noCache: false});
 * </code>
 *
 * @author Tobias Straller [Tobias.Straller@nttdata.com]
 * @returns {ng.IHttpInterceptor}
 * @ngInject
 */
export function httpInterceptorFactory(postal: IPostal, $q: ng.IQService): ng.IHttpInterceptor {
  'use strict';

  function notifyError(messageKey: string): void {
    postal.publish({
      channel: NotificationService.CHANNEL,
      topic: NotificationService.TOPIC_MESSAGE,
      data: {
        type: 'error',
        message: messageKey,
      },
    });
  }

  function notifySessionExpired(): void {
    postal.publish({
      channel: WorkplaceContextService.CHANNEL,
      topic: WorkplaceContextService.TOPIC_SESSION_EXPIRED,
    });
  }

  return {
    request(config: any): any {
      var noCache = config.noCache || false;
      config.headers.Authorization = `Bearer ${getToken()}`;

      if (noCache) {
        if (!config.params) {
          config.params = {};
        }
        config.params.c = _.uniqueId(new Date().getTime() + '_');
      }
      return config;
    },
    requestError(rejection: any): any {
      return this.responseError(rejection);
    },
    responseError(rejection: any): any {
      var showErrorNotification: boolean = true;

      /**
       * Show notifications by default.
       * If overwritten, obeys the following rule:
       *    - overwritten with an false boolean object -> no notifications shown
       *    - overwritten width {[key: 'errorCode']: true/false} - will show notification for those errors that
       *      are not listed as object keys and for those that are listed and have a value of true
       */
      if (rejection && rejection.config && typeof rejection.config.errorNotification !== 'undefined') {
        if (!!!rejection.config.errorNotification) {
          showErrorNotification = false;
        } else {
          var foundKey = _.keys(rejection.config.errorNotification).find((key: string) => {
            return key === rejection.status.toString();
          });
          showErrorNotification = _.isUndefined(foundKey) || !!rejection.config.errorNotification[foundKey];
        }
      }

      if (showErrorNotification) {
        switch (rejection.status) {
          case -1:
            // aborted on client side
            break;
          case 403:
            notifyError('error.http.status.403');
            break;
          case 404:
            notifyError('error.http.status.404');
            break;
          case 406:
            notifyError('error.file.contains.virus');
            break;
          case 415:
            notifyError('error.file.content.type.not.allowed');
            break;
          case 503:
            notifyError('error.http.status.503');
            break;
          case 502:
            notifyError('error.http.status.503');
            break;
          case 511:
            notifySessionExpired();
            break;
          default:
            notifyError('error.http.status.500');
        }
      }
      return $q.reject(rejection);
    },
  };
}
