import { ErrorPolicy, FetchResult, gql } from '@apollo/client';
import { GraphQLService } from '../../../../workplace/graphql.service';
import { LocalUserSettingsStorageService } from '../../../../workplace/local-user-settings-storage.service';
import { IUserSettingsStoreable } from '../../../../workplace/user-settings-storeable.interface';
import { ISettingsPath } from '../../domain/settings-path';
import { ILocalStorageWidgetSettings, WidgetSettings } from '../../domain/widget-settings';
import { IWidgetDefinition } from '../../domain/widget-definition';
import { DashboardService } from '../../../../dashboard/dashboard.service';

export class WidgetSettingsService {
  private readonly GET_SETTINGS = gql`
    query getWidgetSettings($id: String!) {
      widgetSettingsGet(id: $id) {
        settings
        updatedAt
      }
    }
  `;

  private readonly SAVE_SETTINGS = gql`
    mutation createWidgetSettings($widgetSettings: widgetSettingsDtoInput!) {
      widgetSettingsCreate(input: $widgetSettings) {
        instanceId
        dashboardId
        settings
        updatedAt
      }
    }
  `;

  private readonly GET_WIDGET_DEFINITIONS = gql`
    query getWidgetDefinitions($lang: String!) {
      widgetDefinitionGetAll(lang: $lang) {
        id
        storeSettingsInDatabase
        widgetDescriptor {
          widgetDefinitionId
        }
      }
    }
  `;

  /**
   * @ngInject
   */
  constructor(
    private readonly graphqlService: GraphQLService,
    private readonly userSettingsStorageService: LocalUserSettingsStorageService,
    private readonly dashboardService: DashboardService
  ) {}

  /**
   * Utility method to typify the settings path for the widgets.
   * @param storeAble
   * @private
   */
  private static fromSettingsPath(storeAble: IUserSettingsStoreable): ISettingsPath {
    const settingsPath: string[] = storeAble.getSettingsStoragePath();
    return {
      dashboardName: settingsPath[0],
      categoryId: settingsPath[1],
      widgetId: settingsPath[2],
      instanceId: settingsPath[3],
    };
  }

  /**
   * Loads settings for the widget. this method removes local storage entries if they exist for the current widget and
   * creates new entries on the settings-api database
   * @param storeAble
   */
  async loadSettings(storeAble: IUserSettingsStoreable): Promise<unknown> {
    const settingsPath: ISettingsPath = WidgetSettingsService.fromSettingsPath(storeAble);
    const widgetDefinitions: IWidgetDefinition[] = await this.getWidgetDefinitions();

    const widgetDefinition = widgetDefinitions.find(
      widgetDefinition => widgetDefinition.widgetDescriptor.widgetDefinitionId === settingsPath.widgetId
    );

    //Check if widget is database or local storage configured.
    if (!widgetDefinition?.storeSettingsInDatabase) {
      const localStorageSettings: ILocalStorageWidgetSettings = this.loadSettingsFromLocalStorage(storeAble);
      return localStorageSettings?.settings ?? null;
    }

    const currentLocalStorage = this.loadSettingsFromLocalStorage(storeAble);

    if (currentLocalStorage) {
      return this.moveWidgetToPersistence(currentLocalStorage, storeAble, settingsPath);
    } else {
      // return from database. Ignores errors. if errors are thrown just returns null anyway.
      const remoteSettings = await this.loadSettingsFromRemote(settingsPath);

      if (remoteSettings?.settings) {
        const { settings, updatedAt }: ILocalStorageWidgetSettings = remoteSettings;
        // update local storage
        this.saveSettingsOnLocalStorage(storeAble, { settings, updatedAt });
        return settings;
      }
    }

    // Settings do not exist on local storage nor on database
    return null;
  }

  /**
   * Saves settings on the local storage and on the database. If settings are of type @code{ILocalStorageWidgetSettings}, these are
   * just updated, if they are not in this format, they will be updated to this format.
   * @param storeAble
   * @param settings settings to be stored
   */
  async saveSettings(
    storeAble: IUserSettingsStoreable,
    settings: ILocalStorageWidgetSettings | unknown
  ): Promise<ILocalStorageWidgetSettings> {
    const settingsPath: ISettingsPath = WidgetSettingsService.fromSettingsPath(storeAble);

    try {
      const widgetDefinitions: IWidgetDefinition[] = await this.getWidgetDefinitions();

      const widgetDefinition = widgetDefinitions.find(
        widgetDefinition => widgetDefinition.widgetDescriptor.widgetDefinitionId === settingsPath.widgetId
      );

      const updatedAt = +new Date();
      let settingsWithUpdatedTime = settings;
      // check if settings have new format {settings: , updatedAt: }
      if (!this.areSettingsInValidFormat(settings)) {
        //update to new format
        settingsWithUpdatedTime = { settings, updatedAt };
      }

      //Save new version on local storage
      this.saveSettingsOnLocalStorage(storeAble, settingsWithUpdatedTime as ILocalStorageWidgetSettings);

      //Check if widget is database or local storage configured.
      if (widgetDefinition?.storeSettingsInDatabase) {
        // Request to save on database
        const result = await this.saveSettingsOnRemote({ ...settingsPath, settings, updatedAt });
        if (result?.data?.widgetSettingsCreate?.settings) {
          return result.data.widgetSettingsCreate;
        }
      }

      return this.loadSettingsFromLocalStorage(storeAble);
    } catch (error) {
      //if not able to update it return settings from local storage
      return this.loadSettingsFromLocalStorage(storeAble);
    }
  }

  /**
   * Queries the settings api for the widget settings. In case the settings does not exist on the database,
   * creates a new request to add it. Local settings are eliminated in the case where the settings exist on the database,
   * or in the case where the settings were successfully updated in the database.
   * @param currentLocalStorage current object retrieved from the local storage.
   * @param storeAble store able object
   * @param settingsPath settings path for the current widget.
   * @private
   */
  private async moveWidgetToPersistence(
    currentLocalStorage: ILocalStorageWidgetSettings | undefined,
    storeAble: IUserSettingsStoreable,
    settingsPath: ISettingsPath
  ): Promise<unknown> {
    const remoteSettings = await this.loadSettingsFromRemote(settingsPath);

    if (remoteSettings?.settings) {
      if (remoteSettings.updatedAt < currentLocalStorage.updatedAt) {
        // local settings are more recent. Update remote.
        const fetchResult = await this.saveSettingsOnRemote(
          {
            ...settingsPath,
            settings: currentLocalStorage.settings,
            updatedAt: currentLocalStorage.updatedAt,
          },
          'ignore'
        );

        if (fetchResult.data?.widgetSettingsCreate?.settings) {
          const storedValue: WidgetSettings = fetchResult.data.widgetSettingsCreate;
          this.saveSettingsOnLocalStorage(storeAble, {
            settings: storedValue.settings,
            updatedAt: storedValue.updatedAt,
          });
          return storedValue.settings;
        }
        return remoteSettings.settings;
      }

      // do not remove local storage settings instead update it
      this.saveSettingsOnLocalStorage(storeAble, {
        settings: remoteSettings.settings,
        updatedAt: remoteSettings.updatedAt,
      });
      return remoteSettings.settings;
    }

    let settingsToUpdate: ILocalStorageWidgetSettings = currentLocalStorage;
    // check if settings are in proper valid format
    if (!this.areSettingsInValidFormat(currentLocalStorage)) {
      settingsToUpdate = {
        settings: currentLocalStorage.settings,
        updatedAt: +new Date(),
      };
    }

    // do not remove local storage settings instead update it
    this.saveSettingsOnLocalStorage(storeAble, settingsToUpdate);

    const result = await this.saveSettingsOnRemote(
      {
        ...settingsPath,
        settings: settingsToUpdate.settings,
        updatedAt: settingsToUpdate.updatedAt,
      },
      'ignore'
    );

    return result?.data?.widgetSettingsCreate?.settings ?? settingsToUpdate.settings;
  }

  private async loadSettingsFromRemote(path: ISettingsPath): Promise<ILocalStorageWidgetSettings> {
    try {
      const { instanceId } = path;
      const response = await this.graphqlService.query<{ widgetSettingsGet: ILocalStorageWidgetSettings }>(
        this.GET_SETTINGS,
        {
          variables: { id: instanceId },
          errorPolicy: 'ignore',
        }
      );
      if (response?.data?.widgetSettingsGet) {
        const remoteSettings = response.data.widgetSettingsGet;
        return { settings: remoteSettings.settings, updatedAt: remoteSettings.updatedAt };
      }
      return null;
    } catch (e) {
      // Do nothing. Happens when network is down.
      return null;
    }
  }

  private async getWidgetDefinitions(): Promise<IWidgetDefinition[]> {
    try {
      const response = await this.graphqlService.query<{
        widgetDefinitionGetAll: IWidgetDefinition[];
      }>(this.GET_WIDGET_DEFINITIONS, {
        fetchPolicy: 'cache-first',
        errorPolicy: 'ignore',
        variables: { lang: 'en' },
      });
      if (response?.data?.widgetDefinitionGetAll) {
        return response.data.widgetDefinitionGetAll;
      }
      return null;
    } catch (e) {
      // Do nothing. Happens when network is down.
      return null;
    }
  }

  /**
   * Saves the settings on the remote database. Default error policy is to throw all graphql errors. Returns null in case
   * of unexpected notwork error.
   * @param widgetSettings settings to be updated on the database.
   * @param errorPolicy error policy for the graphql request. Set it to undefined in order to get all errors or 'ignore' to completely ignore errors.
   * @private
   */
  private async saveSettingsOnRemote(
    widgetSettings: WidgetSettings,
    errorPolicy: ErrorPolicy = undefined
  ): Promise<FetchResult<any>> {
    try {
      return await this.graphqlService.mutation(this.SAVE_SETTINGS, {
        variables: {
          widgetSettings: {
            instanceId: widgetSettings.instanceId,
            dashboardId: widgetSettings.dashboardName,
            settings: widgetSettings.settings,
            updatedAt: widgetSettings.updatedAt,
          },
        },
        errorPolicy,
      });
    } catch (e) {
      // errorPolicy ignore seems to not be working with mutation current apollo version
      // in the case where it breaks, just return null.
      return null;
    }
  }

  /**
   * Wrapper around storage service. Saves the settings on the local storage
   * @param storeAble
   * @param settings settings to be stored. They are stored in a raw format.
   * @private
   */
  private saveSettingsOnLocalStorage(storeAble: IUserSettingsStoreable, settings: ILocalStorageWidgetSettings): void {
    this.userSettingsStorageService.saveSettings(storeAble, settings);
  }

  /**
   * Loads the settings from the local storage. If the settings from the local storage are not in the right format, @see{ILocalStorageWidgetSettings},
   * they get updated to the current supported format.
   * @param storeAble
   * @private
   */
  private loadSettingsFromLocalStorage(storeAble: IUserSettingsStoreable): ILocalStorageWidgetSettings {
    const localStorage = this.userSettingsStorageService.loadSettings(storeAble);

    if (localStorage) {
      // check if settings are in the right format.
      if (!this.areSettingsInValidFormat(localStorage)) {
        // if it's not in the right format we assume these are legacy settings. So they were not created now.
        // give it a time in the past, so it gets updated on the server, and do not crash with other configurations
        // possibly on the database.
        const dateInThePast = this.settingsDateInThePast();
        return { settings: localStorage, updatedAt: dateInThePast };
      }
    }
    // in this case localStorage is null. So we are returning the same value has it is being expected from the widgets.
    return localStorage;
  }

  private settingsDateInThePast(): number {
    const dateInThePast = new Date();
    dateInThePast.setFullYear(dateInThePast.getFullYear() - 1);
    return +dateInThePast;
  }

  private areSettingsInValidFormat(storage: any): boolean {
    const properties = Object.getOwnPropertyNames(storage);
    return properties?.length === 2 && storage.settings && storage.updatedAt;
  }
}
