import { Component } from '../components/component';
import { ISupportConfig } from './support-config.interface';
import { WorkplaceContextService } from '../workplace/workplace.context.service';
import { IWorkplaceProperty } from '../workplace/workplace-property.interface';
import { UserService } from '../user/user.service';
import { IUser } from '../user/user.model.interface';
import { UrlHelper } from '../../util/url.helper';
import { ILanguage } from '../../util/language.model.interface';

/**
 * Component for showing an app support overlay
 */
export class SupportOverlay extends Component {
  static CHANNEL: string = 'SupportOverlayChannel';
  static TOPIC_HIDE: string = 'TOPIC_SUPPORT_OVERLAY_HIDE';

  channel: IChannelDefinition<{}>;
  supportUrl: string;
  el: JQuery;

  private _labels: { [key: string]: string } = {};
  private _animateService: angular.animate.IAnimateService;
  private _user: IUser;
  private _supportConfig: ISupportConfig;
  private _httpService: ng.IHttpService;
  private _articleUrl: string = null;
  private _language: ILanguage;

  /**
   *
   * @param $animate
   * @ngInject
   */
  constructor(
    $animate: angular.animate.IAnimateService,
    postal: IPostal,
    workplaceContextService: WorkplaceContextService,
    $translate: angular.translate.ITranslateService,
    userService: UserService,
    $http: ng.IHttpService,
    language: ILanguage
  ) {
    super();
    this._animateService = $animate;
    this._httpService = $http;
    this.channel = postal.channel(SupportOverlay.CHANNEL);
    this._language = language;
    if (!this._supportConfig) {
      this._supportConfig = {
        msgType: '',
        msgCode: '',
        category: '',
      };
    }
    $translate([
      'support.form.application',
      'support.form.username',
      'support.form.appRole',
      'support.form.businessRole',
      'support.form.email',
      'support.form.phone',
      'support.form.msgCode',
      'support.form.appState',
      'support.form.serviceGroup',
    ]).then((labels: { [key: string]: string }) => {
      this._labels = labels;
    });

    workplaceContextService.getProperty('serviceNow.url').then((property: IWorkplaceProperty) => {
      this.supportUrl = property.value;
    });

    userService.getUser().then((user: IUser) => {
      this._user = user;
    });
  }

  set supportConfig(config: ISupportConfig) {
    this._supportConfig = config;
    // call service now api and check if there is at least one result for the given errror code
    this._httpService
      .get(`./rest/external/serviceNow?q=${this.supportConfig.msgCode}&l=${this._language.lang}`)
      .then((response: ng.IHttpPromiseCallbackArg<any>) => {
        const results = (response.data && response.data.result && response.data.result.results) || [];
        if (results.length) {
          if (results.length === 1) {
            this._articleUrl = `${this.supportUrl}/${results[0].url}`;
          } else {
            this._articleUrl = `${this.supportUrl}/sp?id=search&q=${this.supportConfig.msgCode}&t=kb&l=${this._language.lang}`;
          }
        } else {
          this._articleUrl = null;
        }
      });
  }

  get supportConfig(): ISupportConfig {
    return this._supportConfig;
  }

  /**
   * Whether there is an article
   */
  get hasArticle(): boolean {
    return this._articleUrl !== null;
  }

  /**
   * Get Url for Help article
   */
  get articleUrl(): string {
    return this._articleUrl;
  }

  /**
   * Get URL for incident form
   */
  get incidentUrl(): string {
    if (!this._user) {
      return '';
    }
    var form: { [key: string]: string } = {};
    form[this._labels['support.form.application']] = this.supportConfig.appName;
    form[this._labels['support.form.username']] = `${this._user.name} ${this._user.surname}`;
    form[this._labels['support.form.appRole']] = this.supportConfig.appRole;
    form[this._labels['support.form.businessRole']] = this._user.getSelectedRole()
      ? this._user.getSelectedRole().roleDescription
      : null;
    form[this._labels['support.form.email']] = this._user.email;
    form[this._labels['support.form.phone']] = this._user.phone;
    form[this._labels['support.form.msgCode']] = this.supportConfig.msgCode;
    form[this._labels['support.form.appState']] = this.supportConfig.appState;
    form[this._labels['support.form.serviceGroup']] = this.supportConfig.serviceGroup;
    form['subject'] = `${this.supportConfig.appName} - ${this.supportConfig.msgCode}`;
    var encodedForm = UrlHelper.toQueryString(form).replace(/&/g, encodeURIComponent('&')).replace(/=/g, encodeURIComponent('='));
    return `${this.supportUrl}/sp?id=sc_cat_item&sys_id=b1137cee4f752e009bc6df601310c723&auto-fill=${encodedForm}`;
  }

  /**
   * Get URL for chat.
   */
  get chatUrl(): string {
    return `${this.supportUrl}/$chat_support.do?queueID=37d8fe6737f712005b09303643990ec1`;
  }

  /**
   * Show the overlay
   */
  show(): void {
    this._animateService.addClass(this.el, 'animate-show');
  }

  /**
   * Hide overlay
   */
  hide(): void {
    if (!this._animateService) {
      return;
    }
    this._animateService.removeClass(this.el, 'animate-show').then(() => {
      this.channel.publish(SupportOverlay.TOPIC_HIDE);
      this.destroy();
    });
  }

  /**
   * Handle click on article button
   * @param $event
   */
  handleArticleClick($event: JQueryEventObject): void {
    if (this.hasArticle) {
      window.open(this.articleUrl, 'serviceNow');
    }
  }

  onRenderComponent(el: JQuery): void {
    this.el = el;
  }

  /**
   * Custom destroy function
   */
  destroy(): void {
    this._animateService = null;
    this.el = null;
    this.channel = null;
  }
}
