import { IEmployee } from './employee.model.interface';
import JSData from 'js-data';

/**
 * Creates the employee store.
 *
 * @author Dragos Damian [Dragos.Damian@nttdata.ro] on 13.10.2016.
 *
 * @param DS
 * @returns {DSResourceDefinition<IEmployee>|(DSResourceDefinition<IEmployee>)}
 *
 * @ngInject
 */
export function employeeStoreFactory(DS: JSData.DS): JSData.DSResourceDefinition<IEmployee> {
  'use strict';
  var id = 0;

  /**
   * Function to generate a unique id string.
   *
   * @returns {string}
   */
  var generateId = () => {
    id++;
    return '' + id;
  };

  /**
   * Injects a fake ID attribute for the store in order to avoid duplication of results.
   *
   * @type {JSData.DSResourceDefinition<IEmployee>|(JSData.DSResourceDefinition<IEmployee>&TActions)}
   */
  var employees = DS.defineResource<IEmployee>({
    name: 'employees',
    endpoint: './rest/users/employees',
    watchChanges: false,
    computed: {
      id: [
        'name',
        'surname',
        (name: string, surname: string): string => {
          return generateId();
        },
      ],
    },
  });
  return employees;
}
