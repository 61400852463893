import { ICatalogApplication } from './catalog-application.model.interface';
import JSData from 'js-data';
/**
 * @ngInject
 */
export function catalogStoreFactory(DS: JSData.DS): JSData.DSResourceDefinition<ICatalogApplication> {
  'use strict';

  return DS.defineResource<ICatalogApplication>({
    name: 'catalog',
    idAttribute: 'name',
    endpoint: './rest/store/search',
    watchChanges: false,
  });
}
