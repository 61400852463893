import { Component } from '../../component';
import { ShareStoreItemDialog } from './share-store-item-dialog';
import shareStoreItemTemplate from './share-store-item-dialog.html';

/**
 * Directive wrapper dialog for sharing a store item.
 * @returns {ng.IDirective}
 * @ngInject
 */
export function shareStoreItemDialogDirective(): ng.IDirective {
  'use strict';
  return Component.createDirective(ShareStoreItemDialog, {
    restrict: 'E',
    templateUrl: shareStoreItemTemplate,
    scope: {
      close: '&',
      dismiss: '&',
      resolve: '&',
    },
  });
}
