import * as Interact from 'interactjs';
/**
 * Sortable implementation using interactjs
 *
 * Dragging directly over an iframe will break. Use a mask overlaying the iframe.
 *
 * Do not modify the dom elements which are under control of ngRepeat. That will cause strange sideeffects.
 * Updates on the list should always be done by model.
 *
 * Do not create multiple interactables for one dom element. Instead add behaviours (draggable, dropzone, etc.) to the already
 * existing interactable.
 *
 * TODO [tobi] code for draggables (ghosting, transfer objects) can be moved to a seperate directive
 *
 */
export class SortableController<T> {
  /**
   * Either x or y.
   * Set by attribute.
   */
  ddAxis: string;
  /**
   * The drag and drop group of this sortable.
   * When set
   * Set by attribute.
   */
  ddGroup: string;
  /**
   * Handler being called when sort is completed
   * Set by attribute.
   */
  onSort: (params: { transfer: T; index: number }) => void;
  /**
   * Handler being called when sort is completed
   * Set by attribute.
   */
  onStart: (params: { transfer: T }) => void;
  /**
   * Handler being called when sort is completed
   * Set by attribute.
   */
  onEnd: (params: { transfer: T }) => void;

  /**
   * Handler being called when draggable is moved over the current sortable
   * @param params
   */
  onDropEnter: (params: { transfer: T }) => void;

  /**
   * Handler being called when draggable is moved out of the current sortable
   * @param params
   */
  onDropLeave: (params: { transfer: T }) => void;

  private _draggables: HTMLElement[];
  private _positions: { x1: number; x2: number; y1: number; y2: number }[];
  private _animateService: angular.animate.IAnimateService;
  private _animateCssService: angular.animate.IAnimateCssService;
  private _timeoutService: ng.ITimeoutService;
  private _sortableEl: JQuery;
  private _$: JQueryStatic;
  private _anim1: angular.animate.IAnimateCssRunner = null;
  private _anim2: angular.animate.IAnimateCssRunner = null;

  /**
   *
   * @param $scope
   * @ngInject
   */
  constructor(
    $scope: ng.IScope,
    jQuery: JQueryStatic,
    $animate: angular.animate.IAnimateService,
    $animateCss: angular.animate.IAnimateCssService,
    $timeout: ng.ITimeoutService
  ) {
    this._draggables = [];
    this._positions = [];
    this._animateService = $animate;
    this._animateCssService = $animateCss;
    this._timeoutService = $timeout;
    this._$ = jQuery;
    if (typeof this.ddAxis === 'undefined') {
      this.ddAxis = 'x';
    }
    if (typeof this.ddGroup === 'undefined') {
      this.ddGroup = 'sortable';
    }
  }

  /**
   * Create the dropzone for the sortable element
   * @param el
   */
  initialize(el: JQuery): void {
    this._sortableEl = el;
    Interact.interact(this._sortableEl.get(0)).dropzone({
      accept: '.' + this.ddGroup,
      ondropmove: this.onDropzoneDropMove.bind(this),
      ondragenter: this.onDropzoneDragEnter.bind(this),
      ondragleave: this.onDropzoneDragLeave.bind(this),
      ondrop: this.onDropzoneDrop.bind(this),
    });
  }

  addDraggable(el: JQuery, transfer: T, draggableOptions?: Interact.DraggableOptions): Interact.Interactable {
    el.addClass(this.ddGroup);
    var draggable = Interact.interact(el.get(0), { preventDefault: 'never' }).draggable(
      Object.assign({}, draggableOptions, {
        onstart: this.onDraggableStart.bind(this),
        onend: this.onDraggableEnd.bind(this),
        onmove: this.onDraggableMove.bind(this),
      })
    );
    this._draggables.push(el.get(0));

    el.css({
      '-ms-touch-action': 'none',
      'touch-action': 'none',
    });
    el.data('transfer', transfer);
    return draggable;
  }

  onDraggableStart(event: Interact.DragEvent): void {
    var ghost = this.createGhostElement(<HTMLElement>event.target);
    ghost.insertBefore(event.target);
    var placeholder = this.createPlaceholderElement(<HTMLElement>event.target);
    placeholder.insertBefore(event.target);
    var target = this._$(event.target);
    target.hide();
    var index = this.getElementIndex(placeholder);
    target.data('index', index);
    target.data('ghost', ghost);
    target.data('placeholder', placeholder);
    this.onStart({ transfer: target.data('transfer') });
  }

  onDraggableEnd(event: Interact.DragEvent): void {
    var target = this._$(event.target);
    var ghost = target.data('ghost');
    var placeholder = target.data('placeholder');

    ghost.remove();
    placeholder.remove();

    target.show();

    // clean up
    target.removeData('sortable');
    target.removeData('index');
    target.removeData('ghost');
    target.removeData('placeholder');
    this.onEnd({ transfer: target.data('transfer') });
    this._draggables.forEach((a: HTMLElement) => a.removeAttribute('style'));
    this._draggables.sort((a: HTMLElement, b: HTMLElement) => {
      return this._sortableEl.children().index(a) - this._sortableEl.children().index(b);
    });
  }

  onDraggableMove(event: Interact.DragEvent): void {
    var target = this._$(event.target);
    var ghost = target.data('ghost');
    // keep the dragged position in the data-x/data-y attributes
    var x = (parseFloat(ghost.data('x')) || 0) + event.dx;
    var y = (parseFloat(ghost.data('y')) || 0) + event.dy;

    // translate the element
    ghost.css({
      transform: 'translate(' + x + 'px, ' + y + 'px)',
    });

    // update the position attributes
    ghost.data('x', x);
    ghost.data('y', y);
  }

  animateItems(item1: HTMLElement, item2: HTMLElement, direction: number): void {
    var pos1 = this._$(item1).position();
    var pos2 = this._$(item2).position();
    if (this._anim1 !== null) {
      this._anim1.end();
    }
    if (this._anim2 !== null) {
      this._anim2.end();
    }
    this._anim1 = this._animateCssService(this._$(item1), {
      from: {
        transform: `translate(${-1 * direction * Math.abs(pos1.left - pos2.left)}px, ${
          -1 * direction * Math.abs(pos1.top - pos2.top)
        }px)`,
      },
      to: {
        transform: `translate(0px,0px)`,
      },
      duration: 0.15,
      easing: 'ease-out',
    });
    this._anim2 = this._animateCssService(this._$(item2), {
      from: {
        transform: `translate(${direction * Math.abs(pos1.left - pos2.left)}px, ${direction * Math.abs(pos1.top - pos2.top)}px)`,
      },
      to: {
        transform: `translate(0px,0px)`,
      },
      duration: 0.15,
      easing: 'ease-out',
    });
    this._timeoutService(() => {
      this._anim1
        .start()
        .then(() => {
          this._anim1 = null;
        })
        .catch(() => {
          this._anim1 = null;
        });
      this._anim2
        .start()
        .then(() => {
          this._anim2 = null;
        })
        .catch(() => {
          this._anim2 = null;
        });
    }, 50);
  }

  /**
   *
   * @param event
   */
  onDropzoneDragEnter(event: Interact.DropEvent): void {
    var relatedTarget = this._$(event.relatedTarget);
    var placeholder = relatedTarget.data('placeholder');
    this.updatePositions(placeholder);
    this.insertPlaceholder(placeholder, this.findPositionIndex(event.dragEvent.pageX, event.dragEvent.pageY));
    this.onDropEnter({ transfer: relatedTarget.data('transfer') });
  }

  /**
   *
   * @param event
   */
  onDropzoneDragLeave(event: Interact.DropEvent): void {
    var relatedTarget = this._$(event.relatedTarget);
    var placeholder = relatedTarget.data('placeholder');
    placeholder.detach();
    this.onDropLeave({ transfer: relatedTarget.data('transfer') });
  }

  /**
   *
   * @param event
   */
  onDropzoneDropMove(event: Interact.DropEvent): void {
    var index = this.findPositionIndex(event.dragEvent.pageX, event.dragEvent.pageY);
    var relatedTarget = this._$(event.relatedTarget);
    var placeholder = relatedTarget.data('placeholder');
    var oldIndex = relatedTarget.data('index');
    // only have to insert placeholder at new position if relative position has changed
    if (index !== oldIndex && !this.isAnimating()) {
      var el = this.insertPlaceholder(placeholder, index, oldIndex);
      relatedTarget.data('index', index);
      this.updatePositions(placeholder);
      if (el) {
        this.animateItems(placeholder.get(0), el, index < oldIndex ? -1 : 1);
      }
    }
  }

  /**
   *
   * @param event
   */
  onDropzoneDrop(event: Interact.DropEvent): void {
    var relatedTarget = this._$(event.relatedTarget);
    var index = relatedTarget.data('index');
    this.onSort({
      transfer: relatedTarget.data('transfer'),
      index: index,
    });
  }

  /**
   * Check whether element would be inserted before of after the current drop target.
   * @param event
   */
  getNewIndex(event: Interact.DropEvent): number {
    var target = this._$(<HTMLElement>event.target);
    var offsetTarget = target.offset();
    var index = this.getElementIndex(target);
    if (this.ddAxis === 'x') {
      return event.dragEvent.pageX < offsetTarget.left + target.outerWidth() / 2 ? index : index + 1;
    } else if (this.ddAxis === 'y') {
      return event.dragEvent.pageY < offsetTarget.top + target.outerHeight() / 2 ? index : index + 1;
    } else {
      return index + 1;
    }
  }

  /**
   * Returns the current index of the given element
   * @param el
   */
  getElementIndex(el: JQuery): number {
    var index = el.parent().children().not('.util-sortable-ghost').not(':hidden').index(el);
    return index;
  }

  /**
   * Creates the ghost element for the given target.
   * Default is a clone of the target element with the same width/height.
   * The ghost element has the class <tt>util-sortable-ghost</tt>
   * @param target
   * @returns {JQuery}
   */
  createGhostElement(target: HTMLElement): JQuery {
    var $target = this._$(target);
    var position = $target.position();
    return $target.show().clone().addClass('util-sortable-ghost').css({
      position: 'absolute',
      zIndex: 99,
      opacity: 0.85,
      width: target.clientWidth,
      height: target.clientHeight,
      top: position.top,
      left: position.left,
    });
  }

  /**
   * Creates a placeholder element for the given target.
   * Default is a clone of the target element with the same width/height.
   * The placeholder element has the class <tt>util-sortable-placeholder</tt>
   *
   * @param target
   * @returns {JQuery}
   */
  createPlaceholderElement(target: HTMLElement): JQuery {
    var $target = this._$(target);
    return $target.show().clone().addClass('util-sortable-placeholder');
  }

  /**
   * Remove and unset an interactable
   * @param interactable
   */
  removeDraggable(draggable: Interact.Interactable, el: JQuery): void {
    el.removeClass(this.ddGroup);
    var index = this._draggables.indexOf(el.get(0));
    if (index > -1) {
      this._draggables.splice(index, 1);
    }
    draggable.unset();
    el.removeData('transfer');
    el.removeData('sortable');
    el.removeData('ghost');
    el.removeData('placeholder');
    el.removeData('index');
  }

  /**
   * Destroy
   */
  destroy(): void {
    // interactables will be destroyed by the child directive which calls #removeInteractable
    this._draggables = [];
    this._$ = null;
  }

  updatePositions(placeholder: JQuery): void {
    this._positions = [
      {
        x1: 0,
        x2: 0,
        y1: 0,
        y2: 0,
      },
    ]
      .concat(
        this.getDraggableElements().map((el: HTMLElement) => {
          var $el = this._$(el);
          var offset = $el.offset();
          var width = $el.outerWidth();
          var height = $el.outerHeight();
          return {
            x1: offset.left,
            x2: offset.left + width,
            y1: offset.top,
            y2: offset.top + height,
          };
        })
      )
      .map(
        (
          pos: { x1: number; x2: number; y1: number; y2: number },
          index: number,
          all: { x1: number; x2: number; y1: number; y2: number }[]
        ) => {
          var nextPos = all[index + 1];
          return {
            x1: pos.x1 + (pos.x2 - pos.x1) / 2,
            x2: nextPos ? nextPos.x1 + (nextPos.x2 - nextPos.x1) / 2 : pos.x2,
            y1: pos.y1 + (pos.y2 - pos.y1) / 2,
            y2: nextPos ? nextPos.y1 + (nextPos.y2 - nextPos.y1) / 2 : pos.y2,
          };
        }
      );
  }

  insertPlaceholder(placeholder: JQuery, index: number, oldIndex?: number): HTMLElement {
    var elements = this.getDraggableElements();
    var el;
    el = oldIndex <= index ? elements[index - 1] : elements[index];
    if (!el) {
      oldIndex <= index ? this._sortableEl.prepend(placeholder.get(0)) : this._sortableEl.append(placeholder.get(0));
    } else {
      oldIndex <= index ? placeholder.insertAfter(el) : placeholder.insertBefore(el);
    }
    return el;
  }

  findPositionIndex(x: number, y: number): number {
    return this._positions.indexOf(
      this._positions.find((pos: { x1: number; x2: number; y1: number; y2: number }, index: number): boolean => {
        var length = this._positions.length;
        if (index === 0) {
          if (this.ddAxis === 'x' && x < pos.x1) {
            return true;
          }
          if (this.ddAxis === 'y' && x < pos.y1) {
            return true;
          }
        }
        if (index === length - 1) {
          if (this.ddAxis === 'x' && x > pos.x2) {
            return true;
          }
          if (this.ddAxis === 'y' && x > pos.y2) {
            return true;
          }
        }
        return this.ddAxis === 'x' ? x >= pos.x1 && x <= pos.x2 : y >= pos.y1 && y <= pos.y2;
      })
    );
  }

  getDraggableElements(placeholder?: JQuery): HTMLElement[] {
    var elements: HTMLElement[] = [];
    this._$(this._draggables)
      .not(':hidden')
      .add(placeholder)
      .each((index: number, el: HTMLElement) => {
        elements.push(el);
      });
    return elements;
  }

  /**
   *
   */
  private isAnimating(): boolean {
    return this._anim1 !== null || this._anim2 !== null;
  }
}
