import { IWidgetDescriptor } from './widget.descriptor.model';
import { IWidgetData } from './widget.data.interface';
import { IOperationControl } from '../dashboard/dashboard.model.interface';
import _ from 'lodash';

export class WidgetControlsDisplayAdapter {
  private widgetDefinition: IWidgetDescriptor<any>;
  private widgetData: IWidgetData;
  private controlAdaptors: { [key: string]: AbstractWidgetControlAdaptor };
  private visibleControls: boolean = false;

  /**
   * Adapter for handling of showing/hiding of a widget's header controls
   * Focused on speed, since its methods are called many time the angular renderer.
   *
   * @param definition
   * @param data
   */
  constructor(definition: IWidgetDescriptor<any>, data: IWidgetData) {
    this.widgetDefinition = definition;
    this.widgetData = data;
    this.controlAdaptors = {};
    this.buildControlAdaptorsMap();
  }

  /**
   * Check for widget controls
   */
  hasVisibleControls(): boolean {
    return this.visibleControls;
  }

  /**
   * Checks whether the widget will show a certain control
   * @param control
   * @returns {any}
   */
  hasControl(control: string): boolean {
    return this.controlAdaptors[control] && this.controlAdaptors[control].isShown();
  }

  /**
   * Checks wheter a widgets control that triggers a dropdown menu has the option with the given name
   *
   * @param option
   */
  hasOption(control: string, option: string): boolean {
    return this.controlAdaptors[control] && this.controlAdaptors[control].hasOption(option);
  }

  /**
   * Checks to see if a control has options at all
   *
   * @param control
   * @returns {IWidgetControl[]|boolean}
   */
  hasOptions(control: string): boolean {
    return this.controlAdaptors[control] && this.controlAdaptors[control].hasOptions();
  }

  private buildControlAdaptorsMap(): void {
    if (this.widgetDefinition.controls && this.widgetDefinition.controls.length > 0) {
      this.widgetDefinition.controls.forEach((ctrl: IOperationControl) => {
        this.controlAdaptors[ctrl.control] = this.widgetControlAdaptorsFactory(ctrl);
        this.visibleControls = this.controlAdaptors[ctrl.control].isShown();
      });
    }
  }

  private widgetControlAdaptorsFactory(control: IOperationControl): AbstractWidgetControlAdaptor {
    switch (control.control) {
      case 'legend':
        return new LegendControlAdaptor(control, this.widgetDefinition, this.widgetData);
      case 'settings':
        return new SettingsControlAdapter(control, this.widgetDefinition);
      case 'shared':
        return new SharedControlAdapter(control, this.widgetDefinition);
      default:
        return new BasicControlAdaptor(control, this.widgetDefinition);
    }
  }
}

export abstract class AbstractWidgetControlAdaptor {
  protected definition: IWidgetDescriptor<any>;
  protected control: IOperationControl;

  constructor(control: IOperationControl, def: IWidgetDescriptor<any>) {
    this.definition = def;
    this.control = control;
  }

  abstract isShown(): boolean;

  hasOption(option: string): boolean {
    return _.isArray(this.control.options) && this.control.options.indexOf(option) !== -1;
  }

  hasOptions(): boolean {
    return _.isArray(this.control.options) && this.control.options.length > 0;
  }
}

export class BasicControlAdaptor extends AbstractWidgetControlAdaptor {
  isShown(): boolean {
    return true;
  }
}

export class LegendControlAdaptor extends AbstractWidgetControlAdaptor {
  private data: IWidgetData;

  constructor(control: IOperationControl, def: IWidgetDescriptor<any>, data: IWidgetData) {
    super(control, def);
    this.data = data;
  }

  isShown(): boolean {
    return !_.isEmpty(this.definition.description) || (this.data && this.data.data && this.data.data.length > 0);
  }
}

export class SharedControlAdapter extends AbstractWidgetControlAdaptor {
  isShown(): boolean {
    return !_.isEmpty(this.definition.sharedBy);
  }
}

export class SettingsControlAdapter extends AbstractWidgetControlAdaptor {
  isShown(): boolean {
    return this.hasOptions();
  }
}
