/**
 * Created by MARIAB on 16.11.2015.
 */

import { Tasks } from './tasks';
import { Component } from '../component';
import usertasksTemplate from './tasks.html';
/**
 * Wraps {@link Tasks} component into a directive.
 *
 * @author Tobias Straller [Tobias.Straller.bp@nttdata.com]
 *
 * @ngInject
 */
export function usertasksDirective(): ng.IDirective {
  'use strict';
  return Component.createDirective(Tasks, {
    restrict: 'E',
    templateUrl: usertasksTemplate,
  });
}
