export function createWebComponentHandlers(element: Element, handlers: { [key: string]: (...args) => unknown }) {
  if (element) {
    for (const event of Object.keys(handlers)) {
      element.addEventListener(event, handlers[event]);
    }
  }
}

export function removeWebComponentHandlers(element: Element, handlers: { [key: string]: (...args) => unknown }) {
  if (element) {
    for (const event of Object.keys(handlers)) {
      element.removeEventListener(event, handlers[event]);
    }
  }
}
