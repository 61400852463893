import { LINK_TYPE, getLinkType, convertLinkType } from './link.type';
import { clipboardCopy } from './clipboard.copy';

function openUrl(link: string, target: string): void {
  window.open(link, target);
}

function getOsSpecificLink(link: string = ''): string {
  return convertLinkType(link);
}

function openFile(link: string): void {
  let osSpecificLink = getOsSpecificLink(link);
  window.open(osSpecificLink, '_blank');
}

function getLinkTarget(link: string = ''): string {
  const linkType: LINK_TYPE = getLinkType(link);
  switch (linkType) {
    case LINK_TYPE.EXTERNAL_APP:
      return '_self';
    default:
      return '_blank';
  }
}

function openLink(link: string): void {
  const linkType: LINK_TYPE = getLinkType(link);
  switch (linkType) {
    case LINK_TYPE.URL:
      openUrl(link, '_blank');
      break;
    case LINK_TYPE.EXTERNAL_APP:
      openUrl(link, '_self');
      break;
    case LINK_TYPE.FILE:
      openFile(link);
      break;
    default:
      break;
  }
}

function copyLink(link: string): void {
  let osSpecificLink = getOsSpecificLink(link);
  clipboardCopy(osSpecificLink);
}

export { openLink, copyLink, getLinkTarget, getOsSpecificLink };
