import { Component } from '../component';
import { MenuItem } from './menu.item';
import menuItemTemplate from './menu.item.html';

/**
 * Wraps {@link AppsMenu} component into a directive.
 *
 * @author Tobias Straller [Tobias.Straller.bp@nttdata.com]
 *
 * @ngInject
 */
export function menuItemDirective($compile: ng.ICompileService): ng.IDirective {
  'use strict';
  return Component.createDirective(MenuItem, {
    restrict: 'E',
    scope: {
      data: '=',
      menuId: '=',
    },
    templateUrl: menuItemTemplate,
  });
}
