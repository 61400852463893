import { Component } from '../component';

/**
 * Spinning loader component
 *
 * @author Tobias Straller [Tobias.Straller.bp@nttdata.com]
 */
export class Loader extends Component {
  /**
   * Set by attribute
   */

  loading: boolean;
}
