import { TasksPolling } from './task/tasks.provider';
import { NotificationProvider } from './notification/notification.provider';
import translations_de from '../assets/i18n/locale-de.json';
import translations_en from '../assets/i18n/locale-en.json';
import translations_es from '../assets/i18n/locale-es.json';
import translations_hu from '../assets/i18n/locale-hu.json';

('use strict');
import { MenuOverlay } from './menu/menu.overlay';
import { Icons } from './app.icons';
import { LanguageProvider } from '../util/language.provider';
import Tether from 'tether';
import jQueryBridget from 'jquery-bridget';
import Masonry from 'masonry-layout';
import { ActivitiesPolling } from './task-activity/task-activity.provider';

declare var Locale: string;
declare var require: any;

/**
 * Separates configuration from module definition, components, and active code.
 *
 * Configuration blocks (registered with module.config()) get executed during provider registration,
 * and can only be injected providers and constants (see module.provider() and module.constant()).
 * This is typically where you would configure application-wide stuff that needs to be configured before the services are created.
 *
 * Use to configure external libraries.
 *
 * @author Tobias Straller [Tobias.Straller.bp@nttdata.com]
 *
 */
export class Config {
  /**
   * Task Polling interval in milliseconds
   *
   * @type {number}
   * @ngInject
   */
  static TASK_POLLING_INTERVAL: number = 10 * 60000;
  static ACTIVITY_POLLING_INTERVAL: number = 10 * 60000;

  /** @ngInject */
  constructor(
    $httpProvider: ng.IHttpProvider,
    $logProvider: ng.ILogProvider,
    $translateProvider: angular.translate.ITranslateProvider,
    notificationServiceProvider: NotificationProvider,
    tasksServiceProvider: TasksPolling,
    taskActivityServiceProvider: ActivitiesPolling,
    $compileProvider: ng.ICompileProvider,
    languageProvider: LanguageProvider,
    $locationProvider: ng.ILocationProvider
  ) {
    $locationProvider.hashPrefix('');
    // enable log
    $logProvider.debugEnabled(true);
    languageProvider.locale = Locale;
    $translateProvider
      .translations('de', translations_de)
      .translations('en', translations_en)
      .translations('es', translations_es)
      .translations('hu', translations_hu)
      .useSanitizeValueStrategy('escapeParameters')
      .fallbackLanguage('en')
      .preferredLanguage(Locale.split('-')[0]);

    notificationServiceProvider.setNotificationOptions({
      closeHtml: `<button><i class="${Icons.CLOSE}"></i></button>`,
      closeButton: true,
      debug: false,
      newestOnTop: false,
      progressBar: false,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      onclick: null,
      showDuration: 300,
      hideDuration: 1000,
      timeOut: 5000,
      extendedTimeOut: 1000,
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    });

    $httpProvider.interceptors.push('httpInterceptor');

    tasksServiceProvider.setPollingInterval(Config.TASK_POLLING_INTERVAL);
    taskActivityServiceProvider.setPollingInterval(Config.ACTIVITY_POLLING_INTERVAL);

    Tether.modules.push({
      position: MenuOverlay.customTetherPositioning,
    });

    jQueryBridget('masonry', Masonry, $);

    $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|mailto|im|tel|file|smb|onenote|awb|awbs|ms-.*):/);
  }
}
