'use strict';
import { BaseDialog } from './base-dialog';

declare var Camera: any;
declare var resolveLocalFileSystemURL: Function;

export class CordovaDialog extends BaseDialog {
  config: any;
  imgSrc: string;

  /**
   * Showcase basic cordova functionality
   * @ngInject
   */
  constructor() {
    super();
  }

  $onInit() {
    this.config = this.resolve().config;
  }

  ok(): void {
    this.close();
  }

  get cameraImageStyle(): any {
    return {
      backgroundImage: `url(${this.imgSrc})`,
    };
  }

  takeImg(): void {
    (<any>navigator).camera.getPicture(
      function cameraSuccess(imageUri: string): void {
        console.log('cordova-dialog -> cameraSuccess', imageUri);
        resolveLocalFileSystemURL(imageUri, (entry: any): void => {
          this.imgSrc = entry.toInternalURL();
        });
      }.bind(this),
      function cameraError(error: string): void {
        console.error('Unable to obtain picture: ' + error, 'app');
      },
      {
        // Some common settings are 20, 50, and 100
        quality: 50,
        destinationType: Camera.DestinationType.FILE_URI,
        targetHeight: 90,
        targetWidth: 90,
        // In this app, dynamically set the picture source, Camera or photo gallery
        sourceType: Camera.PictureSourceType.CAMERA,
        encodingType: Camera.EncodingType.JPEG,
        mediaType: Camera.MediaType.PICTURE,
        allowEdit: true,
        correctOrientation: true, // Corrects Android orientation quirks
      }
    );
  }

  destroy(el: JQuery): void {
    // empty
  }
}
