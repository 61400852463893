import { Component } from '../component';
import { ShareDashboardDialog } from './share-dashboard-dialog';
import shareDashboardDialogTemplate from './share-dashboard-dialog.html';
/**
 * Directive wrapper for sharing a dialog.
 * @returns {ng.IDirective}
 * @ngInject
 */
export function shareDashboardDialogDirective(): ng.IDirective {
  'use strict';
  return Component.createDirective(ShareDashboardDialog, {
    restrict: 'E',
    templateUrl: shareDashboardDialogTemplate,
    scope: {
      close: '&',
      dismiss: '&',
      resolve: '&',
    },
  });
}
