import { Layout } from './layout.model';
import { Node } from '../../util/tree/node';
import { LayoutProvider } from './layout.provider';
import * as uiRouter from 'angular-ui-router';
import angular from 'angular';
import _ from 'lodash';
import { IUserSettingsStorage } from '../workplace/user-settings-storage.interface';
import { IUserSettingsStoreable } from '../workplace/user-settings-storeable.interface';
import { UserSettingService } from '../userSetting/user-setting.service';
import { DeviceService } from '../../util/device.service';

/**
 * Keeps track of layout states
 * @author Tobias Straller [Tobias.Straller.bp@nttdata.com]
 */
export interface ILayoutService {
  /** Stores the current layout information */
  layout: Layout;
  /**
   * Map of sidebar configurations
   */
  sidebars: { [index: string]: ISidebarConfig };
  /**
   * Whether we are showing the mobile layout
   */
  mobileLayout: boolean;

  /**
   * Add a sidebar configuration to the layout
   * @param id
   * @param config
   */
  addSidebar(id: string, config: any): void;

  /**
   * Checks whether sidebar exists
   * @param id
   */
  hasSidebarConfig(id: string): boolean;

  /**
   * Merge properties
   * @param id
   * @param config
   */
  mergeSidebarConfig(id: string, config: any): void;

  /**
   * Get sidebar config for given id
   * @param id
   */
  getSidebarConfig(id: string): ISidebarConfig;

  /**
   * Expand sidebar with given id
   * @param id
   */
  expandSidebar(id: string): void;

  /**
   * Collapse sidebar with given id
   * @param id
   */
  collapseSidebar(id: string): void;

  /**
   * Toggle sidebar with given id.
   * In providing states, the sidebar is only toggled in a subsequent call if the state is equal to the previous state.
   * @param id
   * @param [state]
   * @returns true -> sidebar is expanded
   */
  toggleSidebar(id: string, state?: string): boolean;

  /**
   * Removes the sidebar with the given id
   * @param id
   */
  removeSidebar(id: string): void;

  /**
   * Sets the state of dragging.
   *
   * @param value
   */
  setDragActive(value: boolean): void;

  /**
   * Getter for dragging state.
   */
  getDragActive(): boolean;
}

/**
 * Interface for sidebar configurations
 */
export interface ISidebarConfig {
  expanded: boolean;
  state: string;
}

/**
 *
 * @author Tobias Straller [Tobias.Straller.bp@nttdata.com]
 */
export class LayoutService implements ILayoutService, IUserSettingsStoreable {
  static CHANNEL_RESIZE: string = 'LayoutServiceResizeChannel';
  static TOPIC_RESIZE: string = 'LayoutServiceResize';
  static TOPIC_RESPONSIVE_LAYOUT: string = 'LayoutServiceResponsiveLayout';
  static CHANNEL_UPDATE: string = 'LayoutServiceUpdateChannel';
  static TOPIC_UPDATE: string = 'LayoutServiceUpdate';
  static CHANNEL_EVENT: string = 'LayoutServiceEventChannel';
  static TOPIC_EVENT_BODY_CLICK: string = 'LayoutServiceEventBodyClick';
  static TOPIC_EVENT_HIDE_POPOVERS: string = 'LayoutServiceHidePopovers';
  static CHANNEL_SIDEBAR: string = 'LayoutServiceChannelSidebar';
  static TOPIC_SIDEBAR: string = 'LayoutServiceTopicSidebar';
  static TOPIC_DRAG_START: string = 'LayoutServiceDragStart';
  static TOPIC_DRAG_END: string = 'LayoutServiceDragEnd';
  static SET_AUTO_SCROLL_CONTAINER: string = 'SetAutoScrollContainer';
  static CLOSE_WEB_COMPONENTS_SIDE_PANEL: string = 'closeWebComponentsSidePanel';

  // @see ILayoutService#layout
  layout: Layout;

  /**
   * @deprecated
   *
   * TODO: remove this line after settings migration has been done
   */
  layoutLoaded = false;

  /**
   * Whether the layout is in mobile mode
   *
   * @type {boolean}
   */
  mobileLayout: boolean = false;

  settingsStorageKey = 'workplace';

  // @see ILayoutService#sidebars
  sidebars: { [index: string]: ISidebarConfig };
  channelSidebar: IChannelDefinition<ILayoutServiceChannelSidebarMessage>;
  channelResize: IChannelDefinition<ILayoutServiceChannelResizeMessage>;
  channelUpdate: IChannelDefinition<ILayoutServiceChannelUpdateMessage>;
  channelEvent: IChannelDefinition<ILayoutServiceChannelEventMessage>;
  channelScrollContainer: IChannelDefinition<ILayoutScrollContainerEventMessage>;

  private _dimensionViewport: { width: number; height: number };
  private _animateService: angular.animate.IAnimateService;
  private _queueService: ng.IQService;
  private _$: JQueryStatic;
  private _timeoutService: ng.ITimeoutService;
  private _isLayoutDividerDragging: boolean = false;
  private _withinIFrame: boolean = false;
  private _state: uiRouter.IStateService;
  private _userSettingService: UserSettingService;
  private _responsiveInfo: { xs: boolean; sm: boolean; md: boolean; lg: boolean } = {
    xs: false,
    sm: false,
    md: false,
    lg: false,
  };
  private _dragActive: boolean = false;
  private _sidebarAnimations: { [id: string]: ng.IPromise<any>[] };
  private _userSettingsStorageService: IUserSettingsStorage;

  /**
   *
   * @ngInject
   */
  constructor(
    public postal: IPostal,
    jQuery: JQueryStatic,
    $animate: angular.animate.IAnimateService,
    $q: ng.IQService,
    $timeout: ng.ITimeoutService,
    $state: uiRouter.IStateService,
    userSettingsStorageService: IUserSettingsStorage,
    userSettingService: UserSettingService,
    private readonly deviceService: DeviceService
  ) {
    this.sidebars = {};
    this._dimensionViewport = this.getViewportWidth(jQuery);
    jQuery(window).on('resize', _.throttle(this.handleResize.bind(this, jQuery), 100));
    jQuery(window).on('orientationchange', _.throttle(this.handleResize.bind(this, jQuery), 100));
    jQuery(document.body).on('click', (event: JQueryEventObject): void => {
      this.publishBodyClickEventMessage({ event: event });
      document.documentElement.style.cursor = '';
    });
    jQuery(window).on('blur', (event: JQueryEventObject) => {
      if (this._withinIFrame) {
        this.publishBodyClickEventMessage({ event: event });
      }
    });
    jQuery(document.body).on('mouseenter', '.app-apps-frame-component', null, () => (this._withinIFrame = true));
    jQuery(document.body).on('mouseleave', '.app-apps-frame-component', null, () => (this._withinIFrame = false));
    this.channelUpdate = postal.channel(LayoutService.CHANNEL_UPDATE);
    this.channelEvent = postal.channel(LayoutService.CHANNEL_EVENT);
    this.channelSidebar = postal.channel(LayoutService.CHANNEL_SIDEBAR);
    this.channelScrollContainer = postal.channel(LayoutService.SET_AUTO_SCROLL_CONTAINER);
    this._animateService = $animate;
    this._queueService = $q;
    this._timeoutService = $timeout;
    this._$ = jQuery;
    this._state = $state;
    this._userSettingsStorageService = userSettingsStorageService;
    this._sidebarAnimations = {};
    this._userSettingService = userSettingService;
    this.layout = Node.deserialize(Layout, <any>_.cloneDeep(LayoutProvider.INITIAL_LAYOUT));
    this.loadSettings();
    this.layoutLoaded = !!userSettingsStorageService.loadSettings(this);
    this.resizeListener();
  }

  getSettingsStoragePath(): string[] {
    return ['layout'];
  }

  /**
   * Set responsive information
   * @param responsiveInfo
   */
  set responsiveInfo(responsiveInfo: { xs: boolean; sm: boolean; md: boolean; lg: boolean }) {
    const responsiveInfoChanged = Object.keys(responsiveInfo).reduce((lastValue: boolean, key: string): boolean => {
      const changed = responsiveInfo[key] !== this._responsiveInfo[key];
      if (changed) {
        this._responsiveInfo[key] = responsiveInfo[key];
      }
      return lastValue || changed;
    }, false);
    if (responsiveInfoChanged) {
      this.mobileLayout = responsiveInfo.xs;
      this.publishResponsiveLayoutMessage({
        dimensionViewport: this._dimensionViewport,
        mobileLayout: this.mobileLayout,
        responsiveInfo: this.responsiveInfo,
      });
    }
  }

  /**
   * Get responsive information of the current layout
   */
  get responsiveInfo(): { xs: boolean; sm: boolean; md: boolean; lg: boolean } {
    return this._responsiveInfo;
  }

  /**
   *
   * @param value
   */
  set isLayoutDividerDragging(value: boolean) {
    this._isLayoutDividerDragging = value;
  }

  get isLayoutDividerDragging(): boolean {
    return this._isLayoutDividerDragging;
  }

  /**
   * @see ILayoutService#addSidebar
   * @param id
   * @param config
   */
  addSidebar(id: string, config: any = {}): void {
    this.sidebars[id] = config;
  }

  /**
   * Checks whether a sidebar exists
   * @param id
   */
  hasSidebarConfig(id: string): boolean {
    return typeof this.sidebars[id] !== 'undefined';
  }

  /**
   * @see ILayoutService#getSidebarConfig
   * @param id
   * @returns {ISidebarConfig}
   * @throws error if sidebar with given id was not found
   */
  getSidebarConfig(id: string): ISidebarConfig {
    const cfg = this.sidebars[id];
    if (!cfg) {
      throw new Error(`LayoutService -> toggleSidebar: Sidebar with id ${id} does not exist`);
    }
    return cfg;
  }

  /**
   * @see ILayoutService#mergeSidebarConfig
   * @param id
   * @param config
   */
  mergeSidebarConfig(id: string, config: any): void {
    const cfg = this.getSidebarConfig(id);
    if (cfg) {
      this.addSidebar(id, angular.merge({}, cfg, config));
    }
  }

  collapseAll(id: string = null): void {
    const sidebarIds = Object.keys(this.sidebars);
    const filteredSidebars = id ? sidebarIds.filter((sidebarId: string) => sidebarId !== id) : sidebarIds;
    filteredSidebars.forEach((sidebarId: string) => this.collapseSidebar(sidebarId));
  }

  /**
   * @see ILayoutService#expandSidebar
   * @param sidebarId
   */
  expandSidebar(sidebarId: string): void {
    if (this.mobileLayout) {
      this.collapseAll(sidebarId);
    }
    const config = this.getSidebarConfig(sidebarId);
    if (config && !config.expanded) {
      config.expanded = true;
      this.publishSidebarMessage({ sidebarId, config });
      this.cancelSidebarAnimations(sidebarId);
      const promises: ng.IPromise<any>[] = [];
      this._$(`#${sidebarId}, .app-animation-${sidebarId}`).each(
        function (index, el) {
          promises.push(this._animateService.addClass(this._$(el), `app-animation-${sidebarId}-expanded`));
        }.bind(this)
      );
      this._sidebarAnimations[sidebarId] = promises;
      this._queueService.all(promises).then(() => (this._sidebarAnimations[sidebarId] = null));
    }
  }

  /**
   * @see ILayoutService#collapseSidebar
   * @param sidebarId
   */
  collapseSidebar(sidebarId: string): ng.IPromise<void> {
    const config = this.getSidebarConfig(sidebarId);
    if (config && config.expanded) {
      config.expanded = false;
      this.publishSidebarMessage({ sidebarId, config });
      this.cancelSidebarAnimations(sidebarId);
      const promises: ng.IPromise<any>[] = [];
      this._$(`#${sidebarId}, .app-animation-${sidebarId}`)
        .addClass(`app-animation-${sidebarId}-expanded`)
        .each(
          function (index: number, el: HTMLElement) {
            promises.push(this._animateService.removeClass(this._$(el), `app-animation-${sidebarId}-expanded`));
            return;
          }.bind(this)
        );
      this._sidebarAnimations[sidebarId] = promises;
      return this._queueService.all(promises).then(() => {
        this._state.go('app');
        this._sidebarAnimations[sidebarId] = null;
      });
    }
  }

  /**
   * @see ILayoutService#toggleSidebar
   * @param id
   * @param state
   * @returns {Boolean} true -> sidebar is expanded
   */
  toggleSidebar(id: string, state: string = null): boolean {
    const cfg = this.getSidebarConfig(id);
    if (state !== null && state !== cfg.state) {
      this.expandSidebar(id);
      cfg.state = state;
    } else {
      cfg.expanded ? this.collapseSidebar(id) : this.expandSidebar(id);
    }
    return cfg.expanded;
  }

  /**
   * @see ILayoutService#removeSidebar
   * @param id
   */
  removeSidebar(id: string): void {
    this.sidebars[id] = null;
    delete this.sidebars[id];
  }

  /**
   * Update the layout after a view split.
   * The dimensions of the original view will be split accoriding to the direction of the split.
   * The view and new view together will have the same dimension as the view before the split.
   *
   * @param viewId view to split
   * @param newViewId id of the new view
   * @param direction row or column
   * @param index 0 = insert new view before, 1 = insert new view after
   */
  updateLayoutViewSplit(viewId: string, newViewId: string, direction: string, index: number): void {
    const view = this.layout.findChildById<Layout>(viewId);
    if (view) {
      view.split(newViewId, direction, index);
      this.publishUpdateMessage({});
    }
  }

  /**
   * Update the layout with a new aspect ratio. This affects all child layouts which have to update their dimensions.
   * @param layout
   * @param aspectRatio
   */
  updateLayoutAspectRatio(layout: Layout, aspectRatio: number[]): void {
    layout.aspectRatio = aspectRatio;
    layout.walkDepthFirstPre((child: Layout) => child.updateDimensions(), false);
    this.publishUpdateMessage({});
  }

  /**
   * Remove a view from the layout.
   *
   * @param viewId
   */
  updateLayoutViewRemove(viewId: string): void {
    const view = this.layout.findChildById<Layout>(viewId);
    if (view) {
      view.remove();
      if (this.layout.leaves().length === 0) {
        // no views
        this.layout = Node.deserialize(Layout, LayoutProvider.INITIAL_LAYOUT);
      }
      this.publishUpdateMessage({});
    }
  }

  /**
   * Handle layout resize
   */
  handleResize(jQuery: JQueryStatic): void {
    this._dimensionViewport = this.getViewportWidth(jQuery);
    if (this.mobileLayout) {
      this.collapseSidebar('navigationbar');
      this.collapseSidebar('taskbar');
    }
    this.publishResizeMessage({
      dimensionViewport: this._dimensionViewport,
      responsiveInfo: this.responsiveInfo,
    });
  }

  /**
   * Publish a message that we changed the scroll container when DnD
   */
  publishScrollContainerMessage(message: ILayoutScrollContainerEventMessage): void {
    this.channelScrollContainer.publish(LayoutService.SET_AUTO_SCROLL_CONTAINER, message);
  }

  /**
   * Publish a resize message
   */
  publishResizeMessage(message: ILayoutServiceChannelResizeMessage): void {
    this.channelResize.publish(LayoutService.TOPIC_RESIZE, message);
  }

  /**
   * Publish a message that we have a responsive layout change
   */
  publishResponsiveLayoutMessage(message: ILayoutServiceChannelResizeMessage): void {
    this.channelResize.publish(LayoutService.TOPIC_RESPONSIVE_LAYOUT, message);
  }

  /**
   * Publish a layout update message
   */
  publishUpdateMessage(message: ILayoutServiceChannelUpdateMessage): void {
    this.channelUpdate.publish(LayoutService.TOPIC_UPDATE, message);
  }

  /**
   * Publish a sidebar message
   */
  publishSidebarMessage(message: ILayoutServiceChannelSidebarMessage): void {
    this.channelSidebar.publish(LayoutService.TOPIC_SIDEBAR, message);
  }

  /**
   * Publish an event message
   */
  publishBodyClickEventMessage(message: ILayoutServiceChannelEventMessage): void {
    this.channelEvent.publish(LayoutService.TOPIC_EVENT_BODY_CLICK, message);
  }

  publishHidePopovers(): void {
    this.channelEvent.publish(LayoutService.TOPIC_EVENT_HIDE_POPOVERS);
  }

  /**
   * A tab is going to be dragged
   */
  publishDragStart(): void {
    this.channelEvent.publish(LayoutService.TOPIC_DRAG_START);
  }

  /**
   * Stop tab drag event.
   */
  publishDragEnd(): void {
    this.channelEvent.publish(LayoutService.TOPIC_DRAG_END);
  }

  /**
   * Returns whether the given layout is a split layout. Use the layout root node to find out.
   * @param layout
   */
  isSplitLayout(layout?: Layout): boolean {
    if (typeof layout === 'undefined') {
      layout = this.layout;
    }
    return layout.containsSplit();
  }

  /**
   * Finds a layout opposite to the given layout
   */
  findOppositeLayout(layoutId: string, root?: Layout): Layout {
    if (typeof root === 'undefined') {
      root = this.layout;
    }
    const layout = <Layout>root.findChildById(layoutId);
    if (layout && layout.parent) {
      const index = layout.parent.children.indexOf(layout);
      return <Layout>layout.parent.children[(index + 1) % 2];
    }
  }

  /**
   * Setter for dragging state.
   *
   * @param value
   * @param dragSource
   */
  setDragActive(value: boolean, dragSource: string = 'default'): void {
    this._dragActive = value;
    if (value) {
      this._$(document.body).addClass('item-dragging');
      if (dragSource === 'tab') {
        this._$(document.body).addClass('tab-dragging');
      }
      return;
    }
    this._$(document.body).removeClass('item-dragging');
    this._$(document.body).removeClass('tab-dragging');
  }

  /**
   * Getter for dragging state.
   */
  getDragActive(): boolean {
    return this._dragActive;
  }

  private getViewportWidth(jQuery: JQueryStatic): { width: number; height: number } {
    const $window = jQuery(window);
    return {
      width: $window.width(),
      height: $window.height(),
    };
  }

  private cancelSidebarAnimations(id: string): void {
    if (!this._sidebarAnimations[id]) {
      return;
    }
    this._sidebarAnimations[id].forEach((animationPromise: ng.IPromise<any>) =>
      this._animateService.cancel(animationPromise)
    );
  }

  /**
   * Save settings.
   */
  saveSettings(): void {
    this._userSettingsStorageService.saveSettings(this, Node.serialize(this.layout));
  }

  /**
   * Load settings.
   */
  private loadSettings(): void {
    this._userSettingService.isRestoreSessionActive().then(sessionRestoreActive => {
      const serializedLayout = this._userSettingsStorageService.loadSettings(this);

      if (sessionRestoreActive && serializedLayout) {
        this.layout = <any>Node.deserialize(Layout, serializedLayout);
      }
    });
  }

  private resizeListener(): void {
    this.channelResize = this.postal.channel(LayoutService.CHANNEL_RESIZE);
    this.channelResize.subscribe(LayoutService.CHANNEL_RESIZE, (obj: ILayoutServiceChannelResizeMessage) => {
      this.deviceService.detectDevice();
    });
  }
}

/**
 * Interface for resize messages
 */
export interface ILayoutServiceChannelResizeMessage {
  dimensionViewport: { width: number; height: number };
  mobileLayout?: boolean;
  responsiveInfo: { xs: boolean; sm: boolean; md: boolean; lg: boolean };
}

export interface ILayoutScrollContainerEventMessage {
  target: JQuery | HTMLElement;
}

/**
 * Interface for sidebar messages
 */
export interface ILayoutServiceChannelSidebarMessage {
  sidebarId: string;
  config: ISidebarConfig;
}

/**
 * Interface for event messages
 */
export interface ILayoutServiceChannelEventMessage {
  event: JQueryEventObject;
}

/**
 * Interface for layout update messages
 */
export interface ILayoutServiceChannelUpdateMessage {
  // no properties defined yet
}
