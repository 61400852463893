export class AuthTokenService {
  private static instance: AuthTokenService;

  private token: string;
  private readonly authEventType = 'auth-token-update';
  private readonly sessionStorageKey = 'accessToken';
  private isSaveTokenOnSessionStorage = false;

  /**
   * The Singleton's constructor should always be private to prevent direct
   * construction calls with the `new` operator.
   */
  private constructor() {}

  /**
   * The static method that controls the access to the singleton instance.
   *
   * This implementation let you subclass the Singleton class while keeping
   * just one instance of each subclass around.
   */
  public static getInstance(): AuthTokenService {
    if (!AuthTokenService.instance) {
      AuthTokenService.instance = new AuthTokenService();
    }

    return AuthTokenService.instance;
  }

  /**
   * Stores the token in memory.
   */
  setToken(token: string): void {
    this.token = token;

    if (this.isSaveTokenOnSessionStorage) {
      this.saveTokenOnSessionStorage();
    }
  }

  /**
   * Retrieves the token.
   */
  getToken(): string {
    return this.token;
  }

  /**
   * Saves the token on the session storage. ONLY to be used in development environments.
   */
  saveTokenOnSessionStorage() {
    this.isSaveTokenOnSessionStorage = true;
    sessionStorage.setItem(this.sessionStorageKey, this.token);
  }

  /**
   * Removes the token. Setting it to null.
   */
  removeToken(): void {
    this.token = null;

    if (this.isSaveTokenOnSessionStorage) {
      sessionStorage.removeItem('accessToken');
    }
  }
}
