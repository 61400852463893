import { DraggableController } from './draggable.controller';
import _ from 'lodash';
/**
 * Directive for creating dropzones
 *
 * @returns {ng.IDirective}
 * @ngInject
 */
export function draggableDirective(): ng.IDirective {
  'use strict';
  return {
    restrict: 'A',
    scope: {
      ddGroup: '@',
      ddAxis: '@',
      draggableOnStart: '&',
      draggableOnEnd: '&',
      draggableOnMove: '&',
      draggableOnDown: '&',
      draggableOnUp: '&',
    },
    controller: DraggableController,
    controllerAs: 'vm',
    bindToController: true,
    link<T>($scope: ng.IScope, el: JQuery, attr: ng.IAttributes, vm: DraggableController): void {
      vm.initialize(el);
      vm.draggableOnStart = <(params: {}) => void>_.wrap(vm.draggableOnStart, (fn: Function, params: any) => {
        fn(params);
        $scope.$apply();
      });
      vm.draggableOnEnd = <(params: {}) => void>_.wrap(vm.draggableOnEnd, (fn: Function, params: any) => {
        fn(params);
        $scope.$apply();
      });
      vm.draggableOnMove = <(params: {}) => void>_.wrap(vm.draggableOnMove, (fn: Function, params: any) => {
        fn(params);
        $scope.$apply();
      });
      vm.draggableOnDown = <(params: {}) => void>_.wrap(vm.draggableOnDown, (fn: Function, params: any) => {
        fn(params);
        $scope.$apply();
      });
      vm.draggableOnUp = <(params: {}) => void>_.wrap(vm.draggableOnUp, (fn: Function, params: any) => {
        fn(params);
        $scope.$apply();
      });
      el.on('$destroy', () => {
        vm.destroy();
      });
    },
  };
}
