import _ from 'lodash';

/**
 * Validator to detect uris to a windows local filesystem, e.g. C:\
 *
 * @ngInject
 */

export function isNoLocalUriWindows(): ng.IDirective {
  'use strict';

  var uriRegEx: RegExp = /^(file):(\/\/|\\\\)(\w+:{0,1}\w*@)?(\S*?)[a-z,A-Z]+:(\\|\/)\S+$/;

  return {
    require: 'ngModel',
    link: function (scope: angular.IScope, elm: JQuery, attrs: any, ctrl: angular.INgModelController): void {
      _.set(ctrl.$validators, 'isnolocaluriwindows', (modelValue: string, viewValue: string): boolean => {
        if (ctrl.$isEmpty(modelValue)) {
          // consider empty models to be valid
          return true;
        }

        if (uriRegEx.test(viewValue)) {
          // it is invalid
          return false;
        }

        // it is valid
        return true;
      });
    },
  };
}
