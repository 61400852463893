import { Component } from '../component';
import { IMenuItemModel } from '../../menu/menu.item.model';
import { MenuService } from '../../menu/menu.service';
import { LayoutService } from '../../layout/layout.service';
import { DashboardService } from '../../dashboard/dashboard.service';
import { IWorkplaceProperty } from '../../workplace/workplace-property.interface';
import { WorkplaceContextService } from '../../workplace/workplace.context.service';
import { DeviceService } from '../../../util/device.service';
import * as uiRouter from 'angular-ui-router';

/**
 * Navbar component
 *
 * @author Tobias Straller [Tobias.Straller.bp@nttdata.com]
 *
 */
export class TitlebarSplit extends Component {
  /**
   * Set by attribute
   */
  env: string;
  menuId: string;
  isMobile: boolean;
  items: IMenuItemModel[];

  public layoutService: LayoutService;
  private _stateService: uiRouter.IStateService;
  private _translateService: angular.translate.ITranslateService;
  private _menuService: MenuService;
  private _dashboardService: DashboardService;

  /**
   *  @param {ILayoutService} layoutService
   *  @constructor
   *
   *  @ngInject
   */
  constructor(
    $state: uiRouter.IStateService,
    $translate: angular.translate.ITranslateService,
    private workplaceContextService: WorkplaceContextService,
    layoutService: LayoutService,
    menuService: MenuService,
    dashboardService: DashboardService,
    deviceService: DeviceService
  ) {
    super();
    this.env = '';
    this._stateService = $state;
    this.layoutService = layoutService;
    this._translateService = $translate;
    this._menuService = menuService;
    this._dashboardService = dashboardService;
    this.items = [];
    this.isMobile = deviceService.isMobile();
  }

  $onInit() {
    this.workplaceContextService.getProperty('workplace.environment').then((prop: IWorkplaceProperty) => (this.env = prop.value));
    this._menuService.getMenuItemsById(this.menuId).then((items: IMenuItemModel[]) => (this.items = items));
  }

  /**
   * Dynamic style properties for the component
   *
   * @returns {{flex: string}}
   */
  get style(): any {
    return { display: 'flex' };
  }

  get navbarExpanded(): boolean {
    return this.layoutService.hasSidebarConfig('navigationbar')
      ? this.layoutService.getSidebarConfig('navigationbar').expanded
      : false;
  }

  /**
   * Toggles the primary sidebar.
   */
  togglePrimary($event: ng.IAngularEvent): void {
    this.layoutService.toggleSidebar('navigationbar');
    this._dashboardService.publishWidgetContextMenuClose();
    $event.stopPropagation();
  }

  destroy(): void {
    this._stateService = null;
    this.layoutService = null;
    this._menuService = null;
  }
}
