import JSData from 'js-data';
import { default as DSLocalStorageAdapter } from 'js-data-localstorage';
/**
 * Define a data store which is connected to local storage
 */

export function localStoreFactory(): JSData.DS {
  'use strict';

  var adapter = new DSLocalStorageAdapter();

  var store = new JSData.DS();
  store.registerAdapter('localstorage', adapter, { default: true });

  return store;
}
