import { Component } from '../component';
import { SaveFavoriteDialog } from './save-favorite-dialog';
import saveDialogTemplate from './save-favorite-dialog.html';
/**
 * Wrapper directive for {SaveFavoriteDialog}
 * @returns {{}}
 * @ngInject
 */
export function saveFavoriteDirective(): ng.IDirective {
  'use strict';

  return Component.createDirective(SaveFavoriteDialog, {
    restrict: 'E',
    templateUrl: saveDialogTemplate,
    scope: {
      close: '&',
      dismiss: '&',
      resolve: '&',
    },
  });
}
