import { IQService } from 'angular';
import { AuthTokenService } from '../auth/auth-token.service';
import {
  JIRA_LOGIN_ENDPOINT,
  JIRA_LOGIN_FALLBACK,
  POPUP_STATUS_CHECK_INTERVAL,
  JIRA_SECURE_LOGIN_ENDPOINT,
} from './jira-auth.configs';
import { JiraAuthType, JiraPopupMessage } from './jira-message';

export class JiraAuthService {
  private statusTimer: number;

  private deferred: ng.IDeferred<JiraPopupMessage>;

  private authType: JiraAuthType;

  private deferService: IQService;

  /**
   * @ngInject
   */
  constructor($q: IQService, private readonly authTokenService: AuthTokenService) {
    this.deferService = $q;
  }

  async startAuthFlow(event: JiraAuthType = 'jira-login'): Promise<JiraPopupMessage> {
    this.deferred = this.deferService.defer<JiraPopupMessage>();
    this.authType = event;
    try {
      const jiraURL = await this.postAuthentication();
      this.openJiraWindow(jiraURL);
      return this.deferred.promise;
    } catch (err) {
      this.deferred.reject({ ...JIRA_LOGIN_FALLBACK, event });
      this.removeMessageEventListener();
      return this.deferred.promise;
    }
  }

  private async postAuthentication(): Promise<any> {
    const jiraURL = this.authType === 'jira-login' ? JIRA_LOGIN_ENDPOINT : JIRA_SECURE_LOGIN_ENDPOINT;
    const response = await fetch(jiraURL, {
      method: 'POST',
      body: null,
      headers: {
        'Content-Type': 'text/plain; charset=utf-8',
        authorization: `Bearer ${this.authTokenService.getToken()}`,
      },
    });

    return response.text();
  }

  private openJiraWindow(url: string): void {
    const popupRef = window.open(url, this.authType, 'width=600,height=600');
    this.addCloseEventListener();
    this.statusTimer = this.initStatusCheck(popupRef);
  }

  private addCloseEventListener(): void {
    window.addEventListener('message', this.handlePopupMessage.bind(this), { once: true });
  }

  private removeMessageEventListener(): void {
    window.removeEventListener('message', this.handlePopupMessage.bind(this));
  }

  private initStatusCheck(popup: Window): number {
    return setInterval(() => {
      if (popup?.closed) {
        clearInterval(this.statusTimer);
        this.deferred.reject({ ...JIRA_LOGIN_FALLBACK, event: this.authType });
      }
    }, POPUP_STATUS_CHECK_INTERVAL) as unknown as number;
  }

  private handlePopupMessage(event: MessageEvent): void {
    if (event?.data?.event !== this.authType) {
      this.deferred.reject({ ...JIRA_LOGIN_FALLBACK, event: this.authType });
      this.removeMessageEventListener();
      return;
    }
    clearInterval(this.statusTimer);
    this.removeMessageEventListener();
    this.deferred.resolve(event?.data);
  }
}
