import { DropzoneController } from './dropzone.controller';
import _ from 'lodash';

/**
 * Directive for creating dropzones
 *
 * @returns {ng.IDirective}
 * @ngInject
 */
export function dropzoneDirective(): ng.IDirective {
  'use strict';
  return {
    restrict: 'A',
    scope: {
      onDrop: '&dropzoneOnDrop',
      onDropMove: '&dropzoneOnDropMove',
      onDropzoneActivate: '&?dropzoneOnActivate',
      onDropzoneDeactivate: '&?dropzoneOnDeactivate',
      onDragEnter: '&dropzoneOnDragEnter',
      onDragLeave: '&?dropzoneOnDragLeave',
      ddGroup: '@',
      enabled: '=dropzoneEnabled',
    },
    controller: DropzoneController,
    controllerAs: 'vm',
    bindToController: true,
    link<T>($scope: ng.IScope, el: JQuery, attr: ng.IAttributes, vm: DropzoneController<T>): void {
      vm.initialize(el);
      vm.onDrop = <(params: {}) => void>_.wrap(vm.onDrop, (fn: Function, params: any) => {
        fn(params);
      });
      vm.onDropMove = <(params: {}) => void>_.wrap(vm.onDropMove, (fn: Function, params: any) => {
        fn(params);
      });
      vm.onDragEnter = <(params: {}) => void>_.wrap(vm.onDragEnter, (fn: Function, params: any) => fn(params));
      vm.onDragLeave = <(params: {}) => void>_.wrap(vm.onDragLeave, (fn: Function, params: any) => {
        // optional
        if (fn) {
          fn(params);
        }
      });
      vm.onDropzoneActivate = <(params: {}) => void>_.wrap(vm.onDropzoneActivate, (fn: Function, params: any) => {
        // optional property
        if (fn) {
          fn(params);
        }
      });
      vm.onDropzoneDeactivate = <(params: {}) => void>_.wrap(vm.onDropzoneDeactivate, (fn: Function, params: any) => {
        // optional property
        if (fn) {
          fn(params);
        }
      });
      const unbind = $scope.$watch('vm.enabled', (value: boolean) => vm.enable(value));
      el.on('$destroy', () => {
        vm.destroy();
        unbind();
      });
    },
  };
}
