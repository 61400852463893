import { ConfirmDialog } from './confirm';
import { SessionDataService } from '../../sessionData/session-data.service';
import { SettingService } from '../../setting/setting.service';
import { WorkplaceApiService } from '../../workplace/workplace.api.service';

('use strict');
import { UserSettingService } from '../../userSetting/user-setting.service';
import { IUserSetting, IUserSettingOption } from '../../userSetting/user-setting.model.interface';
import { IAppBehavior } from '../../appBehavior/app-behavior.model.interface';
import { ActionConstants } from '../../actionLog/action-constants';
import { ActionLogService } from '../../actionLog/action-log.service';
import { ILanguage } from '../../../util/language.model.interface';
import _ from 'lodash';
import { AuthService } from '../../auth/AuthService';

/**
 * Logout confirmation modal dialog.
 * Logout is also used when the user session has expired! See WorkplaceContextService#handleSessionExpired
 */
export class LogoutConfirmationDialog extends ConfirmDialog {
  checkbox: boolean;
  saveState: boolean;

  _workplaceApiService: WorkplaceApiService;
  _sessionDataService: SessionDataService;
  _settingService: SettingService;
  _userSettingService: UserSettingService;

  /**
   * @ngInject
   */
  constructor(
    sessionDataService: SessionDataService,
    settingService: SettingService,
    workplaceApiService: WorkplaceApiService,
    userSettingService: UserSettingService,
    protected authService: AuthService
  ) {
    super();
    this._sessionDataService = sessionDataService;
    this._settingService = settingService;
    this._workplaceApiService = workplaceApiService;
    this.checkbox = false;
    this.saveState = false;
    this._userSettingService = userSettingService;

    /**
     * Check if user setting is restore state.
     */
    this._userSettingService.getStartupSettings().then((startupSetting: IUserSetting) => {
      if (startupSetting) {
        var index = _.findIndex(startupSetting.optionsList, (opt: IUserSettingOption) => {
          return opt.description === 'savelaststate';
        });
        this.saveState = startupSetting.optionsList[index].enabled;
      }
    });
  }

  /**
   * Go through SiteMinder adapted mechanism
   */
  async ok() {
    await this.authService.logout();
  }

  destroy(el: JQuery): void {
    this._sessionDataService = null;
    this._settingService = null;
    this._workplaceApiService = null;
    this.authService = null;
  }
}
