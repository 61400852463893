import { AppsService } from '../apps/apps.service';
import { IDashboard } from '../dashboard/dashboard.model.interface';
import { IApplication } from '../apps/application.model.interface';
import { DashboardService, IDashboardServiceMessage } from '../dashboard/dashboard.service';
import { DeviceService } from '../../util/device.service';
import { SettingService } from '../setting/setting.service';
import { ISetting, SettingType } from '../setting/setting.model.interface';
import { ITabServiceChannelTabMessage, TabService } from '../tab/tab.service';
import {
  ILayoutServiceChannelResizeMessage,
  ILayoutServiceChannelSidebarMessage,
  LayoutService,
} from './layout.service';
import { IAppsFrame } from '../apps/apps.frame.interface';
import { Layout } from './layout.model';
import { UserService } from '../user/user.service';
import { ITabContent } from '../tab/tab.content.interface';
import { ITab } from '../tab/tab';
import { IMenuServiceSubMenuMessage, MenuService } from '../menu/menu.service';
import { WorkplaceApiService } from '../workplace/workplace.api.service';
import { ActionLogService } from '../actionLog/action-log.service';
import { ActionConstants } from '../actionLog/action-constants';
import { IMenuHeaderIcon } from '../apps/menu-header-icon.model.interface';
import { PopupService } from '../notification/popup.service';
import { WorkplaceContextService } from '../workplace/workplace.context.service';
import { NotificationService } from '../notification/notification.service';
import { IWorkplaceProperty } from '../workplace/workplace-property.interface';
import { FrameLayoutService } from '../frameLayout/frame-layout.service';
import { IFrameLayout } from '../frameLayout/frame-layout.model.interface';
import { ILanguage } from '../../util/language.model.interface';
import * as uiRouter from 'angular-ui-router';
import { IHttpPromiseCallbackArg } from 'angular';
import { OnboardingFlowService } from '../web-components/onboarding-flow/onboarding-flow.service';
import { APP_ROUTES } from '../app.route';
import { DRAG_AND_DROP_SPLIT_SCREEN } from '../components/tabview/analytics/feature-analytics-conf';
import { TrackingService } from '../feature-tracking/tracking.service';

('use strict');

export interface IAppListItem {
  name: string;
  desc: string;
  url: string;
  mobile: boolean;
}

/**
 * Layout controller
 *
 * @author Tobias Straller [Tobias.Straller.bp@nttdata.com]
 */
export class LayoutController {
  apps: IApplication[];
  dashboards: IDashboard[];
  appsLayouts: IFrameLayout[];
  dashboardsHeaderIcons: IMenuHeaderIcon[];
  isLayoutDividerDragging: boolean;
  navigationbarExpandedSetting: ISetting<boolean>;
  navigationbarSidebarSetting: ISetting<boolean>;
  loadingNavbar: boolean;
  appList: any;
  appListGlossary: any;
  env: string;

  loadedOnboard = false;

  private _layoutService: LayoutService;
  private _appsService: AppsService;
  private _dashboardService: DashboardService;
  private _deviceService: DeviceService;
  private _settingService: SettingService;
  private _tabService: TabService;
  private _minimizeSidebarSetting: ISetting<boolean>;
  private _timeoutService: ng.ITimeoutService;
  private _stateService: uiRouter.IStateService;
  private _workplaceApiService: WorkplaceApiService;
  private _actionLogService: ActionLogService;
  private _popupService: PopupService;
  private _workplaceContextService: WorkplaceContextService;
  private _notificationService: NotificationService;
  private _httpService: ng.IHttpService;
  private _locationService: ng.ILocationService;
  private _frameLayoutService: FrameLayoutService;
  private subscriptions: ISubscriptionDefinition<any>[];

  /**
   * @constructor
   *
   * @ngInject
   */
  constructor(
    layoutService: LayoutService,
    tabService: TabService,
    appsService: AppsService,
    dashboardService: DashboardService,
    deviceService: DeviceService,
    settingService: SettingService,
    $timeout: ng.ITimeoutService,
    userService: UserService,
    $state: uiRouter.IStateService,
    menuService: MenuService,
    workplaceApiService: WorkplaceApiService,
    actionLogService: ActionLogService,
    //guidedTourService: GuidedTourService,
    popupService: PopupService,
    workplaceContextService: WorkplaceContextService,
    notificationService: NotificationService,
    $http: ng.IHttpService,
    $location: ng.ILocationService,
    frameLayoutService: FrameLayoutService,
    language: ILanguage,
    jQuery: JQueryStatic,
    public readonly onboardingFlowService: OnboardingFlowService,
    private readonly trackingService: TrackingService
  ) {
    jQuery('#appLoader').remove();
    this._layoutService = layoutService;
    this._appsService = appsService;
    this._dashboardService = dashboardService;
    this._deviceService = deviceService;
    this._settingService = settingService;
    this._tabService = tabService;
    this._timeoutService = $timeout;
    this._stateService = $state;
    this._workplaceApiService = workplaceApiService;
    this._actionLogService = actionLogService;
    this._popupService = popupService;
    this._workplaceContextService = workplaceContextService;
    this._notificationService = notificationService;
    this._httpService = $http;
    this._locationService = $location;
    this._frameLayoutService = frameLayoutService;
    this.env = '';
    this.apps = [];
    this.dashboards = [];
    this.appsLayouts = [];
    this.loadingNavbar = true;
    this.appList = [];
    this.appListGlossary = {};
    this.subscriptions = [];

    for (let i = 10; i < 36; i++) {
      this.appListGlossary[i.toString(36)] = false;
    }

    this._workplaceContextService.getProperty('env').then((prop: IWorkplaceProperty) => {
      this.env = 'dev';
    });

    this._workplaceContextService.getProperty('workplace.origin').then((prop: IWorkplaceProperty) => {
      if (prop.value !== 'intranet') {
        return;
      }

      // build the list of apps shown when the the workplace is down
      this.getAppsList(language.lang, prop.value).then((res: IAppListItem[]) => {
        let firstLetter;
        res.forEach((item: IAppListItem) => {
          if (this.isMobile() && !item.mobile) {
            return;
          }
          firstLetter = item.desc.substr(0, 1).toLowerCase();
          this.appListGlossary[firstLetter] = true;
          if (!this.appList[firstLetter]) {
            this.appList[firstLetter] = [];
          }
          this.appList[firstLetter].push(item);
        });
      });
    });

    appsService
      .getApps()
      // do not show hidden apps
      .then(appsService.filterHiddenApps.bind(appsService))
      .then((apps: IApplication[]) => {
        this.apps = apps;
        this.loadingNavbar = false;
      })
      .catch(() => this._stateService.go('error'));

    dashboardService
      .getDashboards()
      .then((dashboards: IDashboard[]) => (this.dashboards = dashboards.map((d: IDashboard) => d)));
    frameLayoutService.getLayouts().then((appsLayouts: IFrameLayout[]) => (this.appsLayouts = appsLayouts));
    this._settingService.getSetting('minimizeSidebar').then((setting: any) => (this._minimizeSidebarSetting = setting));
    this._settingService
      .getSetting('navigationbarExpanded')
      .then((setting: ISetting<any>) => (this.navigationbarExpandedSetting = setting));
    this.navigationbarSidebarSetting = {
      id: 'navigationbarSidebar',
      type: SettingType.BOOLEAN,
      value: false,
    };

    this.subscriptions.push(
      this._tabService.channel.subscribe(TabService.TOPIC_TABVIEW_SPLIT, (message: ITabServiceChannelTabMessage) =>
        this._layoutService.updateLayoutViewSplit(
          message.tabviewId,
          message.split.newTabViewId,
          message.split.direction,
          message.split.index
        )
      )
    );
    this.subscriptions.push(
      this._tabService.channel.subscribe(TabService.TOPIC_TABVIEW_UNREGISTER, (message: ITabServiceChannelTabMessage) =>
        this._layoutService.updateLayoutViewRemove(message.tabviewId)
      )
    );
    this.subscriptions.push(
      this._layoutService.channelResize.subscribe(
        LayoutService.TOPIC_RESPONSIVE_LAYOUT,
        (message: ILayoutServiceChannelResizeMessage) => {
          const defaultTabManager = this._tabService.getTabManagerDefault();
          if (message.responsiveInfo.sm && defaultTabManager) {
            this._tabService.moveAllTabsTo(defaultTabManager.id);
          }
        }
      )
    );
    this.subscriptions.push(
      this._layoutService.channelSidebar.subscribe(
        LayoutService.TOPIC_SIDEBAR,
        (message: ILayoutServiceChannelSidebarMessage) => {
          if (message.sidebarId === 'navigationbar') {
            this.navigationbarExpandedSetting.value = message.config.expanded;
            this._settingService.updateSetting(this.navigationbarExpandedSetting);
            menuService.hideActiveSubMenu();
          }
        }
      )
    );
    this.subscriptions.push(
      menuService.channelSubMenu.subscribe(MenuService.TOPIC_SUBMENU_SHOW, (message: IMenuServiceSubMenuMessage) => {
        if (message.menuId !== 'navigationbar') {
          this._layoutService.collapseSidebar('navigationbar');
        }
      })
    );

    this.subscriptions.push(
      dashboardService.channel.subscribe(DashboardService.DASHBOARD_REPLACED, () =>
        this._dashboardService.getDashboards().then((d: IDashboard[]) => {
          this.dashboards = d;
        })
      )
    );

    this.subscriptions.push(
      dashboardService.channel.subscribe(DashboardService.DASHBOARD_DELETED, (message: IDashboardServiceMessage) => {
        this._tabService.closeTab(message.dashboard.name);
        this._tabService.removeTab(message.dashboard.name);
        this.dashboards = this.dashboards.filter((d: IDashboard) => d.name !== message.dashboard.name);
      })
    );

    this.subscriptions.push(
      dashboardService.channel.subscribe(
        DashboardService.DASHBOARD_SHARE_INFO_UPDATED,
        (data: IDashboardServiceMessage) =>
          this._dashboardService.getDashboard(data.dashboardId).then((d: IDashboard) => {
            this.dashboards = this.dashboards.map((dashboard: IDashboard) => {
              if (dashboard.id === d.id) {
                dashboard = d;
              }
              return dashboard;
            });
          })
      )
    );

    this.subscriptions.push(
      userService.channel.subscribe(UserService.TOPIC_ROLES_UPDATED, () => this.closeSidebarReloadApps())
    );

    this.subscriptions.push(
      dashboardService.channel.subscribe(DashboardService.DASHBOARD_RENAMED, (message: IDashboardServiceMessage) => {
        this.dashboards = this.dashboards.map((d: IDashboard) =>
          d.name === message.dashboard.name ? { ...d, ...message.dashboard } : d
        );
        const activeTab: ITab<ITabContent>[] = this._tabService.findOpenTabsForDashboard(message.dashboard.name) || [];
        activeTab.forEach((tab: ITab<ITabContent>) => (tab.title = message.dashboard.description));
      })
    );

    this.subscriptions.push(
      this._dashboardService.channel.subscribe(
        DashboardService.DASHBOARD_CREATED,
        (message: IDashboardServiceMessage) => {
          menuService.hideActiveSubMenu();
          this.dashboards = this.dashboards.concat([message.dashboard]);
        }
      )
    );
  }

  get appListLength(): number {
    return Object.keys(this.appList).length;
  }

  /**
   * Layout class
   * @returns {string}
   */
  get layoutClasses(): string {
    const layoutClasses = Object.keys(this._layoutService.sidebars).map(
      function (id: string): string {
        const config = this._layoutService.getSidebarConfig(id);
        if (config && config.expanded) {
          return id + '-expanded';
        }
      }.bind(this)
    );
    if (!this._minimizeSidebarSetting || !this._minimizeSidebarSetting.value) {
      layoutClasses.push('setting-close-sidebar');
    }
    if (this._layoutService.isLayoutDividerDragging) {
      layoutClasses.push('divider-dragging');
    }
    if (!this.navigationbarSidebarSetting.value) {
      layoutClasses.push('setting-navigationbar-overlay');
    }
    if (this.loadingNavbar) {
      layoutClasses.push('loading-navbar');
    }
    layoutClasses.push(`${this.env}-environment`);
    return layoutClasses.join(' ').trim();
  }

  /**
   * Layout to use
   * @returns {Layout}
   */
  get layout(): Layout {
    return this._layoutService.layout;
  }

  /**
   * While the start page is loading (start page is currently the dashboard "home"
   */
  get loading(): boolean {
    return this.dashboards.length === 0;
  }

  /**
   * Application frames
   * @returns {IAppsFrame[]}
   */
  get frames(): IAppsFrame[] {
    return this._appsService.getFrames();
  }

  closeSidebarReloadApps(): void {
    if (this._layoutService.mobileLayout) {
      this._layoutService.collapseSidebar('navigationbar');
    }
    this._appsService.reloadApps().then((apps: IApplication[]) => {
      this.apps = apps;
    });
  }

  getStyleForFrame(dashboard: IDashboard): any {
    const tm = this._tabService.findTabManagerForTabId(dashboard.name);
    if (tm) {
      const layout = <Layout>this.layout.findChildById(tm.id);
      if (layout) {
        const dim = layout.dimensions;
        return {
          top: `${100 * dim.top}%`,
          left: `${100 * dim.left}%`,
          width: `${100 * dim.width}%`,
          height: `${100 * dim.height}%`,
        };
      } else {
        return {};
      }
    }
  }

  getStyleForFrameInner(dashboard: IDashboard): any {
    const tm = this._tabService.findTabManagerForTabId(dashboard.name);
    if (tm) {
      const layout = <Layout>this.layout.findChildById(tm.id);
      return layout
        ? {
            marginTop:
              layout.parent &&
              (<Layout>layout.parent).direction === 'column' &&
              layout.parent.children.indexOf(layout) > 0
                ? '56px'
                : '44px',
          }
        : {};
    }
  }

  /**
   * App item in navigationbar has been clicked
   * @param app
   */
  appItemClickHandler(app: IApplication): void {
    if (this._layoutService.mobileLayout || !this.navigationbarSidebarSetting.value) {
      this._timeoutService(() => {
        this._layoutService.collapseSidebar('navigationbar');
      });
    }
    this._actionLogService.logAction({
      category: ActionConstants.CATEGORY_APPS,
      action: ActionConstants.ACTION_APPS_LAUNCH_NAVIGATON_MENU,
      actionInfo: app.name,
    });
    this._appsService.openApp(app);
  }

  /**
   * Dashboard item in navigationbar has been clicked
   * @param dashboard
   */
  dashboardItemClickHandler(dashboard: IDashboard): void {
    if (this._layoutService.mobileLayout || !this.navigationbarSidebarSetting.value) {
      this._timeoutService(() => {
        this._layoutService.collapseSidebar('navigationbar');
      });
    }
    this._actionLogService.logAction({
      category: ActionConstants.CATEGORY_DASHBOARD,
      action: ActionConstants.ACTION_DASHBOARDS_OPEN_DASHBOARD,
      actionInfo: dashboard.name,
    });
    this._dashboardService.openDashboard(dashboard);
  }

  /**
   * Split the layout by creating 2 tabviews. If already in split layout, join tabs into a single tabview.
   * @param transfer
   * @param params
   */
  onSplit(transfer: { tab: ITab<ITabContent>; tabview: string }, params: { direction: string; index: number }): void {
    this.trackingService.getTracker()?.trackFeature(DRAG_AND_DROP_SPLIT_SCREEN, 1);
    const crtTabManager = this._tabService.getTabManagerDefault();
    this._tabService.moveAllTabsTo(crtTabManager.id);
    this._tabService.splitTabview({
      tabviewId: crtTabManager.id,
      tabId: transfer.tab.id,
      direction: params.direction,
      index: params.index,
    });
  }

  /**
   * Check whether a layout split is possible.
   * Split is possible when
   *  - not in mobile layout
   *  - number of tabs is greater than 1
   *  - direction is different from current split
   *
   * @param direction
   * @param index
   */
  canSplit(direction: string, index: number): boolean {
    const defaultTabManager = this._tabService.getTabManagerDefault();
    if (!defaultTabManager) {
      return false;
    }
    const mainLayout = <Layout>this._layoutService.layout.findChildById(defaultTabManager.id);
    let layoutDirection;
    if (mainLayout) {
      const parent = <Layout>mainLayout.parent;
      layoutDirection = parent ? parent.direction : null;
    }
    return !this._layoutService.mobileLayout && this._tabService.getTabCount() > 1 && layoutDirection !== direction;
  }

  /**
   * Callback from layout spy
   * @param hidden
   */
  responsiveSpyCallback(responsiveInfo: any): void {
    this._layoutService.responsiveInfo = responsiveInfo;
  }

  /**
   * Reload the workplace
   */
  reloadWorkplace(): void {
    this._workplaceApiService.reloadWorkplace();
  }

  /**
   * Displays the add new dashboard modal.
   * Add the new dashboard to the burger menu and open it when the modal ist closed with 'Save'.
   */
  showAddDashboardModal(): void {
    this._layoutService
      .collapseSidebar('navigationbar')
      .then(() => this._popupService.showModalWindow('addDashboardDialog'))
      .then((dashboardTitle: string) => this._dashboardService.createDashboard(dashboardTitle))
      .then((newDashboard: IDashboard) => {
        this.dashboards.push(newDashboard);
        this._dashboardService.openDashboard(newDashboard);
      });
  }

  get demoMode(): boolean {
    return this._workplaceContextService.demoMode === 'true';
  }

  startCordova(): void {
    this._popupService.showModalWindow('cordovaDialog');
  }

  getAppsList(lang: string, origin: string = 'intranet'): ng.IPromise<IAppListItem[]> {
    return this._httpService
      .get(`./apps.downtime.${origin}.json`)
      .then((response: IHttpPromiseCallbackArg<any>) => response.data[lang]);
  }

  openAppFromErrorPage(url: string): void {
    window.open(url);
  }

  isMobile(): boolean {
    return this._deviceService.isMobile();
  }

  goToAppsSection(section: string): void {
    this._locationService.hash(section);
  }

  destroy(): void {
    this.subscriptions.forEach((subscription: ISubscriptionDefinition<any>) => subscription.unsubscribe());
    this.subscriptions = null;
  }
}
