import { Component } from '../component';
import { TypeaheadDropdown } from './typeahead-dropdown';
import typeaheadTemplate from './typeahead-dropdown.html';

export function typeaheadDropdownDirective(): ng.IDirective {
  'use strict';

  return Component.createDirective(TypeaheadDropdown, {
    restrict: 'E',
    templateUrl: typeaheadTemplate,
    scope: {
      items: '=',
      preselected: '=',
      placeholder: '@',
      changeHandler: '&',
      focusHandler: '&',
      resetHandler: '&',
      autoFocused: '&',
      previewIcon: '@',
      alwaysShowReset: '@',
      disabled: '&',
      displayLabel: '@',
    },
  });
}
