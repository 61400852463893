import { Component } from '../component';
import { ActionItem } from './action.item';
import actionItemTemplate from './action.item.html';
/**
 * An action item, such as logout/role help shown on the roles' overlay
 *
 * @returns {ng.IDirective}
 */
export function actionItemDirective(): ng.IDirective {
  'use strict';
  return Component.createDirective(ActionItem, {
    restrict: 'E',
    templateUrl: actionItemTemplate,
    scope: {
      iconCls: '@',
      textKey: '@',
      htmlContent: '@',
      href: '@',
    },
  });
}
