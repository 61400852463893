'use strict';

import { WorkplaceNativeDeviceService } from '../../workplace/workplace-native-device.service';
import { BaseDialog } from './base-dialog';

/**
 * Session expired dialog. We will need some different handling that logout, but until we know what it is, it will just do the same as logout.
 */
export class AboutDialog extends BaseDialog {
  private mobileVersionInfo: any = {};
  private deviceInfo: any = {};

  /**
   * @ngInject
   */
  constructor(workplaceNativeDeviceService: WorkplaceNativeDeviceService) {
    super();
    if (workplaceNativeDeviceService.isPlatformIOS()) {
      workplaceNativeDeviceService.deviceInfo().then((deviceInfo: any) => (this.deviceInfo = deviceInfo));
      workplaceNativeDeviceService.appInfo().then((appInfo: any) => (this.mobileVersionInfo = appInfo));
    }
  }
}
