import { Component } from '../component';
import { Userinfo } from './userinfo';
import userinfoTemplate from './userinfo.html';

/**
 * Wraps {@link AppsMenu} component into a directive.
 *
 * @author Tobias Straller [Tobias.Straller.bp@nttdata.com]
 *
 * @ngInject
 */
export function userinfoDirective(): ng.IDirective {
  'use strict';
  return Component.createDirective(Userinfo, {
    restrict: 'E',
    scope: {},
    templateUrl: userinfoTemplate,
  });
}
