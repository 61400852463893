import { IBarcodeScannerResult } from './barcode-scanner-result.interface';
import { NotificationService } from '../notification/notification.service';
import { WorkplaceContextService } from './workplace.context.service';
import { PopupService } from '../notification/popup.service';
import _ from 'lodash';

declare var cordova: any;
declare var LocalFileSystem: any;
declare var requestFileSystem: Function;
declare var resolveLocalFileSystemURL: Function;
declare var fetch: Function;
declare var CsMobileScanner: any;
declare var downloader: any;
declare var device: any;

const BROWSER_TYPE_SECURE = 'secure';

/**
 * Wrapper service for native device interaction with cordova
 */
export class WorkplaceNativeDeviceService {
  filesInProgress: { [key: string]: number };

  private nativeEventListeners: { [event: string]: Function[] };
  private deviceReady: boolean;
  private downloadDialogInstance: angular.ui.bootstrap.IModalServiceInstance;
  private downloadDialogTimer: ng.IPromise<void>;

  /**
   * @ngInject
   */
  constructor(
    private notificationService: NotificationService,
    private $log: ng.ILogService,
    private workplaceContextService: WorkplaceContextService,
    private popupService: PopupService,
    private $timeout: ng.ITimeoutService,
    private $rootScope: ng.IScope
  ) {
    this.nativeEventListeners = {
      deviceready: [],
      pause: [],
      resume: [],
    };

    document.addEventListener('deviceready', () => {
      this.deviceReady = true;
      this.initDownload();
      document.addEventListener('DOWNLOADER_initialized', () => {
        console.log(`cordova-plugin-file-downloader: initialized`);
      });
      document.addEventListener('DOWNLOADER_error', (e: any) => {
        console.log(`cordova-plugin-file-downloader: error`, e);
      });
      this.handleNativeEvent('deviceready');
    });

    document.addEventListener('pause', () => {
      this.handleNativeEvent('pause');
    });

    document.addEventListener('resume', () => {
      this.handleNativeEvent('resume');
    });

    this.filesInProgress = {};
  }

  /**
   * Whether a download is in progress
   */
  get downloadInProgress(): boolean {
    return !!Object.keys(this.filesInProgress).length;
  }

  /**
   * Use cordova platform plugin to check for ios
   * @returns {boolean}
   */
  isPlatformIOS(): boolean {
    return typeof cordova !== 'undefined' && cordova.platformId && cordova.platformId === 'ios';
  }

  /**
   * Use cordova platform plugin to check for windows
   * @returns {boolean}
   */
  isPlatformWindows(): boolean {
    return typeof cordova !== 'undefined' && cordova.platformId && _.startsWith('windows', cordova.platformId);
  }

  /**
   * Use cordova camera plugin
   * For a list of options see https://cordova.apache.org/docs/en/latest/reference/cordova-plugin-camera/#module_camera.CameraOptions
   * @param options
   * @returns {Promise<T>}
   */
  cameraGetPicture(options: any): Promise<string> {
    return new Promise((resolve: Function, reject: Function): void => {
      (<any>navigator).camera.getPicture(
        function cameraSuccess(imageUri: string): void {
          resolve(imageUri);
        }.bind(this),
        function cameraError(error: string): void {
          reject(error);
        },
        options
      );
    });
  }

  /**
   * Use cordova barcode scanner plugin
   * @param options
   */
  barcodeScannerScan(options: any): Promise<IBarcodeScannerResult> {
    return new Promise((resolve: Function, reject: Function): void => {
      cordova.plugins.barcodeScanner.scan(
        function scanSuccess(result: IBarcodeScannerResult): void {
          resolve(result);
        }.bind(this),
        function scanError(error: string): void {
          reject(error);
        },
        options
      );
    });
  }

  /**
   *
   * @returns {Promise<void>}
   */
  writeFile(
    filename: string,
    dataUrl: string,
    { localFileSystem }: { localFileSystem: number } = {
      localFileSystem: 0,
    }
  ): Promise<void> {
    console.log('workplace-native-device.service -> writeFile: dataUrl received', dataUrl);
    return new Promise<void>((resolve: Function, reject: Function) => {
      requestFileSystem(
        localFileSystem,
        0,
        (fs: any) => {
          console.log('workplace-native-device.service -> : requested filesystem', localFileSystem);
          fs.root.getFile(
            filename,
            { create: true, exclusive: false },
            (fileEntry: any) => {
              console.log('workplace-native-device.service -> : getFile', fileEntry);
              fileEntry.createWriter((fileWriter: any) => {
                console.log('workplace-native-device.service -> created fileWriter:', fileWriter);
                fileWriter.onwriteend = () => resolve(fileEntry);
                fileWriter.onerror = reject;
                console.log('workplace-native-device.service -> : ', dataUrl);
                fetch(dataUrl)
                  .then(function (res: any): Blob {
                    return res.blob();
                  })
                  .then(function (blob: Blob): void {
                    fileWriter.write(blob);
                  });
                console.log('workplace-native-device.service -> : wrote!');
              });
            },
            reject
          );
        },
        reject
      );
    });
  }

  /**
   *
   * @returns {Promise<string>}
   */
  readFile(
    filename: string,
    { localFileSystem }: { localFileSystem: number } = { localFileSystem: 0 }
  ): Promise<string> {
    return new Promise((resolve: Function, reject: any) => {
      requestFileSystem(
        localFileSystem,
        0,
        (fs: any) => {
          console.log('workplace-native-device.service -> : requested filesystem', localFileSystem);
          fs.root.getFile(
            filename,
            { create: true, exclusive: false },
            (fileEntry: any) => {
              console.log('workplace-native-device.service -> : getFile', fileEntry);
              fileEntry.file((file: any) => {
                //https://stackoverflow.com/questions/45542462/angular-4-x-cordova-filereader-fails-silently-white-screen-of-death
                const reader = new ((<any>FileReader).__zone_symbol__OriginalDelegate || FileReader)();
                reader.onloadend = function (): void {
                  console.log('workplace-native-device.service -> readFile: ', reader.result);
                  resolve(reader.result);
                };
                reader.onerror = reject;
                reader.readAsDataURL(file);
              });
            },
            reject
          );
        },
        reject
      );
    });
  }

  /**
   * Read file by native URL (starting with file://)
   * @param nativeUrl
   */
  readFileByNativeURL(nativeUrl: string): Promise<string> {
    return new Promise((resolve: Function, reject: any) => {
      resolveLocalFileSystemURL(
        nativeUrl,
        (fileEntry: any) => {
          fileEntry.file((file: any) => {
            const reader = new FileReader();
            reader.onloadend = function (): void {
              console.log('workplace-native-device.service -> readFileByNativeURL: ', reader.result);
              resolve(reader.result);
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
          });
        },
        reject
      );
    });
  }

  /**
   *
   * @param {string} dirName
   * @param {number} localFileSystem
   * @returns {Promise<any[]>}
   */
  listEntries(
    dirName: string = '/',
    { localFileSystem }: { localFileSystem: number } = { localFileSystem: 0 }
  ): Promise<any[]> {
    return new Promise((resolve: Function, reject: any) => {
      requestFileSystem(localFileSystem, 0, (fs: any) => {
        fs.root.getDirectory(
          dirName,
          { create: false },
          (dirEntry: any) => {
            const dirReader = dirEntry.createReader();
            dirReader.readEntries(resolve, reject);
          },
          reject
        );
      });
    });
  }

  /**
   *
   * @param {string} dirName
   * @param {number} localFileSystem
   * @returns {Promise<void>}
   */
  createDirectory(
    dirName: string,
    { localFileSystem }: { localFileSystem: number } = { localFileSystem: 0 }
  ): Promise<void> {
    return new Promise<void>((resolve: Function, reject: any) => {
      requestFileSystem(localFileSystem, 0, (fs: any) => {
        let reduced = dirName.split('/').reduce((promisedEntry: Promise<any>, current: string): any => {
          return promisedEntry.then((dirEntry: any) => {
            console.log('workplace-native-device.service -> : createDirectory', dirEntry, current);
            return new Promise((resolve: Function, reject: any) => {
              dirEntry.getDirectory(current, { create: true }, resolve, reject);
            });
          });
        }, Promise.resolve(fs.root));
        reduced.then(resolve).catch(reject);
      });
    });
  }

  /**
   *
   * @param {string} dirName
   * @param {number} localFileSystem
   */
  removeEntry(
    { fullPath, isDirectory }: { fullPath: string; isDirectory: boolean },
    { localFileSystem }: { localFileSystem: number } = { localFileSystem: 0 }
  ): Promise<void> {
    return new Promise<void>((resolve: Function, reject: any) => {
      requestFileSystem(localFileSystem, 0, (fs: any) => {
        let typeFn = isDirectory ? 'getDirectory' : 'getFile';
        fs.root[typeFn](
          fullPath,
          { create: false },
          (entry: any): void => {
            entry.isDirectory
              ? entry.removeRecursively(() => resolve(), reject, reject)
              : entry.remove(() => resolve(), reject, reject);
          },
          reject
        );
      });
    });
  }

  /**
   *
   * @param {string} url
   * @param {string} target
   * @param options
   * @param {string} browserType
   * @returns {Window}
   */
  openWindow(url: string, target?: string, options?: any, browserType: string = BROWSER_TYPE_SECURE): Window {
    if (this.isPlatformIOS()) {
      return this.openWindowIOS(url, target, options, browserType);
    }
    return window.open(url, '_system', options);
  }

  openInAppBrowser(url: string): Window {
    return cordova.InAppBrowser.open(url, '_blank', 'toolbar=yes,enableViewportScale=yes,usewkwebview=yes');
  }

  /**
   * Adds a device event listener, currently supported events are:
   * deviceready, pause, resume
   * @param {string} eventName
   * @param {Function} handler
   */
  addNativeEventListener(eventName: string, handler: Function): void {
    if (!['deviceready', 'pause', 'resume'].includes(eventName)) {
      throw new Error('Event is not supported');
    }
    if (this.deviceReady) {
      handler('deviceReady');
    }
    if (this.nativeEventListeners[eventName]) {
      this.nativeEventListeners[eventName].push(handler);
    }
  }

  /**
   * Remove a device event listener
   * @param {string} eventName
   * @param {Function} handler
   */
  removeNativeEventListener(eventName: string, handler: Function): void {
    if (this.nativeEventListeners[eventName]) {
      var index = this.nativeEventListeners[eventName].indexOf(handler);
      if (index > -1) {
        this.nativeEventListeners[eventName].splice(index, 1);
      }
    }
  }

  /**
   * Opens a file on the device filesystem with its default app
   */
  nativeDeviceOpenFile(config: { filePath: string; mimeType: string; showDialog: boolean }): Promise<void> {
    const { filePath, mimeType, showDialog } = config;
    return new Promise<void>((resolve: Function, reject: Function) => {
      if (cordova.plugins.fileOpener2) {
        const openFn = showDialog ? cordova.plugins.fileOpener2.showOpenWithDialog : cordova.plugins.fileOpener2.open;
        openFn(filePath, mimeType, {
          success: resolve,
          error: reject,
        });
      } else {
        reject({ message: 'Missing plugin cordova.plugins.fileOpener2' });
      }
    });
  }

  /**
   * Downloads one or multiple files to the temporary local file system.
   * @param url
   * @param name
   */
  nativeDeviceFileDownloader(url: string, options: { name?: string; headers?: any } = {}): Promise<any> {
    let { name, headers } = options;
    return new Promise((resolve: Function, reject: Function) => {
      if (typeof downloader === 'undefined') {
        return reject({ message: 'Missing plugin cordova-plugin-file-downloader' });
      }
      if (this.downloadInProgress) {
        return reject({ message: 'Download already in progress' });
      }
      let success, error, progress;
      progress = (event: any) => {
        const filename = event.data[1].toString();
        const progress = event.data[0];
        this.progressDownload(filename, progress);
      };
      success = (event: any) => {
        console.log('workplace-native-device.service -> nativeDeviceFileDownloader: success');
        document.removeEventListener('DOWNLOADER_downloadSuccess', success);
        document.removeEventListener('DOWNLOADER_downloadError', error);
        document.removeEventListener('DOWNLOADER_downloadProgress', progress);
        this.successDownload();
        if (event.data) {
          resolve(event.data[0]);
        }
      };
      error = (event: any) => {
        console.log('workplace-native-device.service -> nativeDeviceFileDownloader: error');
        document.removeEventListener('DOWNLOADER_downloadError', error);
        document.removeEventListener('DOWNLOADER_downloadError', success);
        document.removeEventListener('DOWNLOADER_downloadProgress', progress);
        const httpStatus = (event.data && event.data.length && event.data[0].http_status) || 500;
        this.errorDownload(httpStatus);
        reject(event);
      };
      document.addEventListener('DOWNLOADER_downloadSuccess', success);
      document.addEventListener('DOWNLOADER_downloadError', error);
      document.addEventListener('DOWNLOADER_downloadProgress', progress);
      const filename = downloader.get(url, null, name, headers);
      this.startDownload(filename);
    });
  }

  /**
   * Show download dialog
   */
  startDownload(filename: string): void {
    if (typeof this.filesInProgress[filename] === 'undefined') {
      this.filesInProgress[filename] = 0;
    }
    if (!this.downloadDialogTimer) {
      this.downloadDialogTimer = this.$timeout(() => {
        this.downloadDialogTimer = null;
        this.downloadDialogInstance = this.popupService.createModalWindow('downloadDialog');
        this.downloadDialogInstance.result.catch(() => {
          this.abortDownload();
        });
      }, 1000);
    }
  }

  /**
   *
   * @param filename
   * @param progress
   */
  progressDownload(filename: string, progress: number): void {
    this.$rootScope.$applyAsync(() => {
      if (typeof this.filesInProgress[filename] !== 'undefined') {
        this.filesInProgress[filename] = progress;
      }
    });
  }

  abortDownload(): void {
    if (this.downloadDialogTimer) {
      this.$timeout.cancel(this.downloadDialogTimer);
      this.downloadDialogTimer = null;
    }
    if (typeof downloader !== 'undefined') {
      downloader.abort();
      this.initDownload();
    }
    this.filesInProgress = {};
    this.notificationService.showWarn('Download cancelled.');
  }

  initDownload(): void {
    if (typeof downloader !== 'undefined') {
      downloader.init({ folder: 'downloads', fileSystem: LocalFileSystem.TEMPORARY, wifiOnly: false });
    }
  }

  successDownload(): void {
    if (this.downloadDialogTimer) {
      this.$timeout.cancel(this.downloadDialogTimer);
      this.downloadDialogTimer = null;
    }
    if (this.downloadDialogInstance) {
      this.downloadDialogInstance.close();
      this.downloadDialogInstance = null;
    }
    this.filesInProgress = {};
    this.notificationService.showSuccess('api.download.success');
  }

  errorDownload(httpStatus: number): void {
    if (this.downloadDialogTimer) {
      this.$timeout.cancel(this.downloadDialogTimer);
      this.downloadDialogTimer = null;
    }
    if (this.downloadDialogInstance) {
      this.downloadDialogInstance.close();
      this.downloadDialogInstance = null;
    }
    if (typeof downloader !== 'undefined') {
      try {
        downloader.abort();
      } catch (e) {
        console.log(e);
      }
      this.initDownload();
    }
    this.filesInProgress = {};
    this.notificationService.showError(`api.download.error.${httpStatus}`, null, 'api.download.error.title');
  }

  /**
   * Wrapper function for the COSYS Mobile Scanner
   */
  async cosysScannerScan(cfg: ICosysScannerConfig, appId: string): Promise<ICosysScannerData> {
    const whitelist = await this.workplaceContextService.getProperty('nativeDevice.cosys.scanner.app.whitelist');
    if (!appId || !whitelist.value.split(',').includes(appId)) {
      return Promise.reject<ICosysScannerData>(<ICosysScannerError>{
        status: 'ERROR',
        error_code: '1002',
        error_msg: `App ${appId} is not whitelisted to use this api function.`,
        timestamp: new Date().getTime(),
      });
    }

    let picker;

    try {
      await this.cosysScannerActivateLicense(cfg);

      let settings = new CsMobileScanner.CsPickerSettings();
      Object.keys(cfg.settings).forEach((key: string) => {
        switch (key) {
          case 'symbologies':
            return Object.keys(cfg.settings[key]).forEach((symbologyKey: string) =>
              Object.assign(settings[key][symbologyKey], cfg.settings[key][symbologyKey])
            );
          default:
            settings[key] = cfg.settings[key];
        }
      });

      picker = new CsMobileScanner.CsPicker();
      this.$log.info(`WorkplaceNativeDeviceService -> cosysScannerScan: Initializing scanner.`);
      picker.initialize();
      picker.setSettings(settings);

      return new Promise<ICosysScannerData>((resolve: Function, reject: Function): void => {
        try {
          picker.show({
            didScan: (data: any): void => {
              this.$log.info(`WorkplaceNativeDeviceService -> cosysScannerScan: Scan completed.`);
              picker.cancel();
              picker.deinitialize();
              resolve({
                status: 'OK',
                data,
                timestamp: new Date().getTime(),
              });
            },
            didCancel: (): void => {
              this.$log.info(`WorkplaceNativeDeviceService -> cosysScannerScan: Scan cancelled.`);
              reject(<ICosysScannerError>{
                status: 'ERROR',
                error_code: '1001',
                error_msg: 'User has canceled',
                timestamp: new Date().getTime(),
              });
            },
          });
        } catch (e) {
          reject(<ICosysScannerError>{
            status: 'ERROR',
            error_code: e.code,
            error_msg: typeof e === 'string' ? e : e.message,
            timestamp: new Date().getTime(),
          });
        }
      });
    } catch (e) {
      if (picker) {
        picker.cancel();
        picker.deinitialize();
      }
      this.$log.error(`WorkplaceNativeDeviceService -> cosysScannerScan: ${e.message}`);
      return Promise.reject<ICosysScannerData>(<ICosysScannerError>{
        status: 'ERROR',
        error_code: '' + e.code,
        error_msg: typeof e === 'string' ? e : e.message,
        timestamp: new Date().getTime(),
      });
    }
  }

  /**
   * Scan using anyline sdk
   * https://documentation.anyline.com/toc/platforms/cordova/getting_started.html
   * @param cfg
   */
  anylineScan(licenseKey: string, ibanViewConfig: any): Promise<any> {
    return new Promise((resolve: Function, reject: Function) => {
      cordova.exec(
        (result: any) => {
          resolve(result);
        },
        (message: string) => {
          reject({ message });
        },
        'AnylineSDK',
        'scan',
        [licenseKey, ibanViewConfig]
      );
    });
  }

  /**
   * Anyline for Cognex Scanners
   * https://documentation.anyline.com/toc/platforms/cordova/getting_started.html
   * @param cfg
   */
  anylineScanMX(licenseKey: string, ibanViewConfig: any): Promise<any> {
    return new Promise((resolve: Function, reject: Function) => {
      cordova.exec(
        (result: any) => {
          resolve(result);
        },
        (message: string) => {
          reject({ message });
        },
        'AnylineSDK',
        'scanMX',
        [licenseKey, ibanViewConfig]
      );
    });
  }

  /**
   * Returns device info
   * Sample: {\"available\":true,\"platform\":\"iOS\",\"version\":\"13.3\",\"uuid\":\"28E9765C-8248-41A4-AAF1-9ABA339XXXX\",
   * \"cordova\":\"5.1.0\",\"model\":\"iPhone10,4\",\"manufacturer\":\"Apple\",\"isVirtual\":false, \"serial\":\"unknown\"}
   */
  deviceInfo(): Promise<any> {
    if (typeof device === 'undefined') {
      return Promise.reject({ message: 'Device info not available' });
    }
    return Promise.resolve(device);
  }

  /**
   * Returns app information such as the bundle identifier and the current version number
   */
  appInfo(): Promise<any> {
    if (cordova.getAppVersion) {
      return Promise.all([
        cordova.getAppVersion.getAppName(),
        cordova.getAppVersion.getPackageName(),
        cordova.getAppVersion.getVersionCode(),
        cordova.getAppVersion.getVersionNumber(),
      ]).then(([appName, packageName, versionCode, versionNumber]: string[]): any => {
        return { appName, packageName, versionCode, versionNumber };
      });
    }
    throw new Error('cordova-plugin-app-version is missing');
  }

  /**
   * Activate license for the cosys scanner.
   */
  private cosysScannerActivateLicense(cfg: ICosysScannerConfig): Promise<{}> {
    this.$log.info(
      `WorkplaceNativeDeviceService -> cosysScannerScan: Activating license using url '${cfg.license.url}.'`
    );
    return new Promise((resolve: Function, reject: Function) => {
      const registry = new CsMobileScanner.CsLicenseRegistry();
      registry.activateLicense(
        cfg.license,
        () => {
          resolve();
        },
        (e: any) => {
          reject(e);
        }
      );
    });
  }

  /**
   * Handle device event
   * @param {string} event
   */
  private handleNativeEvent(eventName: string): void {
    this.nativeEventListeners[eventName].forEach((handler: Function) => {
      handler(eventName);
    });
  }

  /**
   * On iOS use URL scheme for VMWare Web Browser
   * @param {string} browserType can be "system" or "secure"
   * @returns {Window}
   */
  openWindowIOS(url: string, target?: string, options?: any, browserType?: string): Window {
    if (!browserType) {
      browserType = BROWSER_TYPE_SECURE;
    }
    let notificationService = this.notificationService;
    if (browserType === BROWSER_TYPE_SECURE) {
      url = url.replace('https://', 'awbs://');
      url = url.replace('http://', 'awb://');
    }
    if (url.indexOf('awb:') === 0 || url.indexOf('awbs:') === 0) {
      notificationService.showInfo('mobile.open.vmwareBrowser.message', null, 'mobile.open.vmwareBrowser.title');
    }
    return window.open(url, '_system', options);
  }
}

/**
 * Interface for COSYS Mobile Scanner config object
 */
interface ICosysScannerConfig {
  settings: any;
  license: {
    url: string;
    poolNumber: string;
  };
}

/**
 * Data object
 */
interface ICosysScannerData {
  status: string;
  /* Data as returned by COSYS Mobile Scanner */
  data: any;
  timestamp: number;
}

/**
 * Error object
 */
interface ICosysScannerError {
  /* Value is always ERROR */
  status: string;
  error_code: string;
  error_msg: string;
  timestamp: number;
}
