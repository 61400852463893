import { ApolloLink, FetchResult } from '@apollo/client';
import { Operation, Observable } from '@apollo/client/core';
import { print } from 'graphql';
import ReconnectingEventSource from 'reconnecting-eventsource';

type SSELinkOptions = EventSourceInit & { uri: string };

export class SSELink extends ApolloLink {
  constructor(private options: SSELinkOptions) {
    super();
  }

  public request(operation: Operation): Observable<FetchResult> {
    const url = new URL(this.options.uri, document.location as any);

    url.searchParams.append('query', print(operation.query));
    url.searchParams.append('extensions', JSON.stringify(operation.operationName));
    url.searchParams.append('variables', JSON.stringify(operation.variables));
    if (operation.extensions) {
      url.searchParams.append('extensions', JSON.stringify(operation.extensions));
    }

    return new Observable(sink => {
      const eventsource = new ReconnectingEventSource(url.toString(), this.options);
      eventsource.onmessage = function (event) {
        const data = JSON.parse(event.data);
        console.debug('<<< Message received: ', data);
        sink.next(data);

        if (eventsource.readyState === EventSource.CLOSED) {
          console.warn('>>> Event Source State changed to Closed.');
          sink.complete();
        }
      };
      eventsource.onerror = function (error) {
        console.error('>>> Unexpected error when handling SSE: ', error);
      };
      return () => eventsource.close();
    }) as Observable<FetchResult>;
  }
}
